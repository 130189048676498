import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import Skeleton from 'components/Skeleton'
import { useState } from 'react'
import { FaHotjar } from 'react-icons/fa'
import api from 'services/api'
import { ButtonLink } from './ButtonLink'

function StatsWrapper({ children, ...props }) {
  return (
    <Box
      d="flex"
      flexDir="column"
      alignItems="center"
      textAlign="left"
      {...props}
    >
      {children}{' '}
    </Box>
  )
}

export const Stats = ({ data, isLoading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [isAskingInvitesLoading, setIsAskingInvitesLoading] = useState(false)

  const handleAskForInviteCodes = async () => {
    setIsAskingInvitesLoading(true)

    try {
      await api.get('/invite-codes')

      toast({
        title: 'Invite codes requested',
        description: 'The Village Team will be in touch shortly',
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Invite codes request failed',
        description: 'Please try again later',
        status: 'error',
      })
    }

    setIsAskingInvitesLoading(false)
  }

  return (
    <Stack spacing={4}>
      <StatsWrapper>
        <Text
          w="100%"
          fontWeight="bold"
          fontSize="sm"
          textAlign="left"
          d="flex"
          alignItems="center"
          isTruncated
        >
          <Skeleton isLoaded={!isLoading} mr={1}>
            <chakra.span fontSize="lg">
              {data?.directWarm.toLocaleString()}+
            </chakra.span>
          </Skeleton>
          {'  '}
          reliable direct connections
        </Text>

        <Text
          w="100%"
          fontSize="sm"
          color={mode('gray.500', 'grayAlpha.5')}
          fontStyle="italic"
          textAlign="left"
          d="flex"
          alignItems="center"
        >
          out of{' '}
          <Skeleton isLoaded={!isLoading} mx={1}>
            {data?.directTotal.toLocaleString()}
          </Skeleton>{' '}
          contacts imported
        </Text>
      </StatsWrapper>

      <StatsWrapper>
        <Text
          w="100%"
          fontWeight="bold"
          fontSize="sm"
          textAlign="left"
          d="flex"
          alignItems="center"
        >
          <Skeleton isLoaded={!isLoading} mr={1}>
            <chakra.span fontSize="lg">
              {data?.networkWarm.toLocaleString()}+
            </chakra.span>
          </Skeleton>
          {'  '}
          intros available
        </Text>

        <Text
          w="100%"
          fontSize="sm"
          color={mode('gray.500', 'grayAlpha.5')}
          fontStyle="italic"
          textAlign="left"
          d="flex"
          alignItems="center"
        >
          via{' '}
          <Skeleton isLoaded={!isLoading} mx={1} d="flex" alignItems="center">
            <AvatarGroup size="2xs" mx={1} max={2} spacing="-0.5rem">
              {data?.friends &&
                data?.friends
                  .slice(0, 2)
                  .map((friend) => (
                    <Avatar size="2xs" src={friend.friend.avatar ?? ''} />
                  ))}
            </AvatarGroup>{' '}
            <chakra.span
              color={mode('black', 'white')}
              textDecor="underline"
              cursor="pointer"
              onClick={onOpen}
            >
              {data?.friends && data?.friends.length} friend
              {data?.friends && data?.friends.length > 1 && 's'}
            </chakra.span>
          </Skeleton>
          on Village
        </Text>
      </StatsWrapper>

      {data.invitedBy && (
        <StatsWrapper>
          <Text
            w="100%"
            fontWeight="bold"
            fontSize="sm"
            textAlign="left"
            d="flex"
            alignItems="center"
          >
            Invited by
          </Text>
          <Text
            w="100%"
            fontSize="sm"
            fontStyle="italic"
            textAlign="left"
            d="flex"
            alignItems="center"
          >
            <Avatar src={data.invitedBy.avatar} size="2xs" mr={1} />
            {data.invitedBy.firstname} {data.invitedBy.lastname}
          </Text>
        </StatsWrapper>
      )}

      <StatsWrapper>
        <ButtonLink
          color={mode('middle.500', 'middle.500')}
          onClick={handleAskForInviteCodes}
          isLoading={isAskingInvitesLoading}
          mr="auto"
        >
          Ask for invite codes
        </ButtonLink>
      </StatsWrapper>

      {/* <FriendsStats friends={data?.friends} /> */}

      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalContent>
          <ModalBody p={6}>
            <Box mb={4} fontWeight="bold">
              Friends on Village
            </Box>
            <Box maxH="400px" overflow="auto" px={2} mb={6}>
              {data?.friends &&
                data?.friends
                  .sort((a, b) =>
                    a.friend.firstname.localeCompare(b.friend.firstname)
                  )
                  .map((friend) => (
                    <Box w="100%" d="flex" alignItems="center" mb={1}>
                      <Avatar
                        size="xs"
                        mr={2}
                        src={friend.friend.avatar ?? ''}
                      />
                      <Text>{friend.friend.firstname}</Text>
                      <Text ml="auto" mr={1}>
                        {friend.connections > 0 ? (
                          <chakra.span>
                            +{friend.connections.toLocaleString()} intros
                          </chakra.span>
                        ) : (
                          <chakra.span fontStyle="italic" fontSize="xs">
                            processing
                          </chakra.span>
                        )}
                      </Text>{' '}
                      {friend.connections > 0 && <Icon as={FaHotjar} />}
                    </Box>
                  ))}
            </Box>
            <Box>
              <Button
                bg="transparent"
                borderRadius="full"
                color={mode('gray.500', 'white')}
                border={'1px solid'}
                borderColor={mode('gray.500', 'white')}
                w="100%"
                _hover={{
                  filter: 'opacity(0.8)',
                }}
                onClick={handleAskForInviteCodes}
                isLoading={isAskingInvitesLoading}
              >
                Ask for invite codes
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

import { Tag, Tooltip } from '@chakra-ui/react'

export default function ConnectionDegree({ degree }) {
  return (
    <Tooltip
      label={`This person is ${
        degree === 'first' ? '' : 'not'
      } directly connected with you.`}
      fontSize="sm"
    >
      <Tag mb="2" size="sm">
        {degree} degree
      </Tag>
    </Tooltip>
  )
}

import React from 'react'
import {
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  useDisclosure,
  Box,
  useColorMode,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { parseStrengthColor } from 'utils/parsers'
import RelationshipConnector from './RelationshipConnector'
import RelationshipStrength from './RelationshipStrength'
import PathsModal from './PathsModal'
import { useAuth } from 'context/AuthContext'
import CompanyPeople from './CompanyPeople'
import CompanyModal from './CompanyModal'

export const AvatarWithWarmth = ({
  src,
  size,
  relationship,
  taskEntity,
  taskIdentity,
  ...styleProps
}) => {
  const borderColor = parseStrengthColor(relationship.warmth_score)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { user } = useAuth()

  const renderPopoverContent = () => {
    const connectors =
      relationship.connectors && typeof relationship.connectors === 'string'
        ? JSON.parse(relationship.connectors)
        : []

    const avatars = relationship.avatars ? relationship.avatars.split(',') : []

    const isCompany = taskEntity === 'company'

    const ModalComponent = isCompany ? CompanyModal : PathsModal

    const PathComponent = isCompany ? CompanyPeople : RelationshipConnector

    const openModal = (e) => {
      e.stopPropagation()
      onOpen()
    }

    return (
      <Flex
        bg={mode('rgb(255,255,255,1)', 'rgb(24,24,24,1)')}
        alignItems="center"
      >
        <RelationshipStrength
          relationship={{ warmth_score: relationship.warmth_score }}
          identities={{
            left_identity_id: Number(user.identityId),
            right_identity_id: relationship.right_identity_id,
          }}
        />
        <ModalComponent
          company={{ id: taskIdentity }}
          relationship={relationship}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
        <PathComponent
          openPathsModal={openModal}
          avatars={avatars}
          total={relationship.total || 0}
          onOpen={openModal}
          connectors={connectors}
        />
      </Flex>
    )
  }

  const modePostfix = useColorMode().colorMode === 'light' ? '.100' : '.200'

  return (
    <Popover openDelay={0} placement="bottom" trigger="hover">
      <PopoverTrigger>
        <Box
          borderRadius="full"
          {...styleProps}
          border="2px"
          borderColor={`${borderColor}${modePostfix}`}
        >
          <Avatar src={src} size={size} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        bg={mode('rgb(255,255,255,0.8)', 'rgb(24,24,24,0.8)')}
        w="100%"
        p={3}
        border="none"
      >
        {renderPopoverContent()}
      </PopoverContent>
    </Popover>
  )
}

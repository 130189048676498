import {
  Box,
  Button,
  chakra,
  Collapse,
  Divider,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'
import NewIntegrationButton from 'components/Integrations/NewIntegrationButton'
import LoginButton from 'components/LoginButton'
import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { FaArrowRight, FaCheck } from 'react-icons/fa'
import { FiChrome } from 'react-icons/fi'
import { MdCheckBox, MdClose } from 'react-icons/md'
import GraphLoader from './GraphLoader'

function IntegrationStatus({ integration, setIntegration, allIntegrations }) {
  const { addIntegration } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const hasMoreThanOneIntegration = allIntegrations.length > 1

  const wereAllScopesGranted =
    integration &&
    ((integration?.sync_data?.missingScopes &&
      integration?.sync_data?.missingScopes.length === 0) ||
      integration?.sync_data.insufficientPermissions === false)

  return (
    <ListItem>
      <Text as="span">
        {integration?.sync_data?.email}{' '}
        {!wereAllScopesGranted && integration?.sync_data?.missingScopes && (
          <>
            -{' '}
            <chakra.span color="red.200">
              All checkboxes must be authorized
            </chakra.span>{' '}
            {hasMoreThanOneIntegration && (
              <LoginButton
                customButton={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    isLoading={isLoading}
                    rightIcon={FaArrowRight}
                    variant="link"
                    color="red.400"
                    size="xl"
                    textDecor="underline"
                  >
                    Authorize Access
                  </Button>
                )}
                customCallback={async (googleCode) => {
                  setIsLoading(true)
                  const integration = await addIntegration({ code: googleCode })
                  setIntegration(integration)
                  setIsLoading(false)
                }}
                extraPermissions
              />
            )}
          </>
        )}
      </Text>
    </ListItem>
  )
}

export default function StepIntegrations({
  isIntegrationLoading,
  googleIntegration,
  setGoogleIntegration,
  additionalIntegrations,
  setAdditionalIntegrations,
  isLoggedOnLinkedin,
  nextStep,
  isBasicIntegrationDone,
}) {
  const { addIntegration } = useAuth()
  const toast = useToast()

  const [isIntegrationVisible, setIsIntegrationVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const allGoogleIntegrations = [googleIntegration, ...additionalIntegrations]

  useEffect(() => {
    if (isBasicIntegrationDone) {
      setIsIntegrationVisible(false)
    }
  }, [isBasicIntegrationDone])

  useEffect(() => {
    const allIntegrationsFinished =
      allGoogleIntegrations.length > 1 &&
      isBasicIntegrationDone &&
      allGoogleIntegrations.every(
        (integration) =>
          integration &&
          ((integration?.sync_data?.missingScopes &&
            integration?.sync_data?.missingScopes.length === 0) ||
            integration?.sync_data.insufficientPermissions === false)
      )

    if (allIntegrationsFinished) {
      setIsIntegrationVisible(false)
    }
  }, [additionalIntegrations.length])

  const wereAllScopesGranted =
    googleIntegration &&
    ((googleIntegration?.sync_data?.missingScopes &&
      googleIntegration?.sync_data?.missingScopes.length === 0) ||
      googleIntegration?.sync_data.insufficientPermissions === false)

  const handleIntegrationCallback = (integration) => {
    const syncDataRaw = integration.sync_data

    if (syncDataRaw) {
      const syncData =
        typeof syncDataRaw === 'string' ? JSON.parse(syncDataRaw) : syncDataRaw
      if (syncData.missingScopes && syncData.missingScopes.length > 0) {
        toast({
          title: 'Missing permissions',
          description: `All checkboxes must be authorized to continue`,
          status: 'error',
        })
      }
    }
  }

  if (isIntegrationLoading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return (
    <Stack shouldWrapChildren spacing="4">
      {isBasicIntegrationDone ? (
        <Box d="flex" alignItems="center">
          <Box w="100%">
            <GraphLoader />
          </Box>

          <Text fontSize="xl" color="green.500">
            We're crunching through millions of data points to build your warm
            social graph. So far, we've found <Spinner size="xs" mr={1} />
            <strong>
              {googleIntegration?.contacts?.toLocaleString()} people
            </strong>{' '}
            in your network.{' '}
          </Text>
        </Box>
      ) : (
        <Text fontSize="xl" color="white">
          Unlike other social networks that let you add people you might not
          know, Village securely crunches your meeting history to create the
          most reliable professional network.{' '}
        </Text>
      )}

      <Collapse in={isIntegrationVisible}>
        <Stack shouldWrapChildren spacing="4">
          <Box color="white">
            <Box
              d="flex"
              alignItems="center"
              color={wereAllScopesGranted && 'green.500'}
              mb={2}
            >
              {wereAllScopesGranted ? (
                <MdCheckBox fontSize="2.75rem" />
              ) : (
                <MdClose
                  fontSize="2.75rem"
                  style={{
                    color: `var(--chakra-colors-red-800)`,
                  }}
                />
              )}
              <Box ml={1}>
                <Text fontWeight="semibold">Connect Contacts & Calendar</Text>

                <Tooltip
                  bg={mode('white', 'grayAlpha.700')}
                  color={mode('black', 'white')}
                  hasArrow
                  label={
                    <Box p={2}>
                      <Text fontWeight="bold" mb={2}>
                        We only use the minimal amount of data from your meeting
                        history to set a strength score with each of your
                        contacts
                      </Text>
                      <Text size="sm" mb={2} d="flex">
                        <chakra.span mr={1}>🔒</chakra.span>We don’t store your
                        events data
                      </Text>
                      <Text size="sm" mb={2} d="flex">
                        <chakra.span mr={1}>🔒</chakra.span>We don’t process
                        events where you’re not an attendee (e.g.
                        shared/coworkers’ calendars)
                      </Text>
                      <Text size="sm" mb={2} d="flex">
                        <chakra.span mr={1}>🔒</chakra.span>We don’t show
                        private contacts that have no public professionals
                        related to them
                      </Text>
                      <Text size="sm" mb={2} d="flex">
                        <chakra.span mr={1}>🔒</chakra.span>We don’t share your
                        contacts’s phone numbers, emails and details of
                        interactions you’ve had with them
                      </Text>
                    </Box>
                  }
                >
                  <Text
                    fontSize="xs"
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    w="max-content"
                  >
                    We don’t store your calendar data. Learn more{' '}
                    <Icon as={BsInfoCircle} ml={1} />
                  </Text>
                </Tooltip>
              </Box>
            </Box>

            <Box ml={12}>
              <Box mt={2}>
                <List>
                  {allGoogleIntegrations.map((integration, index) => (
                    <IntegrationStatus
                      key={index}
                      integration={integration}
                      setIntegration={(returnedIntegration) => {
                        if (index === 0) {
                          setGoogleIntegration({
                            ...googleIntegration,
                            ...returnedIntegration,
                            sync_data:
                              returnedIntegration &&
                              returnedIntegration.sync_data
                                ? typeof returnedIntegration.sync_data ===
                                  'string'
                                  ? JSON.parse(returnedIntegration.sync_data)
                                  : returnedIntegration.sync_data
                                : false,
                          })
                        } else {
                          const newIntegrations = [...additionalIntegrations]
                          newIntegrations[index - 1] = returnedIntegration
                          setAdditionalIntegrations(newIntegrations)
                        }

                        handleIntegrationCallback(returnedIntegration)
                      }}
                      allIntegrations={allGoogleIntegrations}
                    />
                  ))}
                </List>
              </Box>

              {wereAllScopesGranted || allGoogleIntegrations.length > 1 ? (
                <>
                  <NewIntegrationButton
                    setIntegration={(integration) => {
                      const isThereAlready = allGoogleIntegrations.findIndex(
                        (i) => i.id === integration.id
                      )
                      if (isThereAlready === -1) {
                        setAdditionalIntegrations([
                          ...additionalIntegrations,
                          integration,
                        ])
                      } else {
                        const newIntegrations = [...additionalIntegrations]
                        newIntegrations[isThereAlready - 1] = integration
                        setAdditionalIntegrations(newIntegrations)
                      }
                      handleIntegrationCallback(integration)
                    }}
                  />
                  <Text fontSize="xs">
                    The more accounts you add, the more valuable your network
                    will be.
                  </Text>
                </>
              ) : (
                <LoginButton
                  customButton={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      isLoading={isLoading}
                      size="sm"
                      my={2}
                      colorScheme="middle"
                      variant="outline"
                    >
                      Authorize Access
                    </Button>
                  )}
                  customCallback={async (googleCode) => {
                    setIsLoading(true)
                    const integration = await addIntegration({
                      code: googleCode,
                    })
                    setGoogleIntegration({
                      ...googleIntegration,
                      ...integration,
                      sync_data:
                        integration && integration.sync_data
                          ? typeof integration.sync_data === 'string'
                            ? JSON.parse(integration.sync_data)
                            : integration.sync_data
                          : false,
                    })
                    setIsLoading(false)
                    handleIntegrationCallback(integration)
                  }}
                  extraPermissions
                />
              )}
            </Box>
          </Box>

          <Divider />

          <Box color="white">
            <Box
              d="flex"
              alignItems="center"
              color={isLoggedOnLinkedin !== 'no reply' && 'green.500'}
              mb={2}
            >
              {isLoggedOnLinkedin !== 'no reply' ? (
                <MdCheckBox fontSize="2.75rem" />
              ) : (
                <MdClose
                  fontSize="2.75rem"
                  style={{
                    color: `var(--chakra-colors-red-800)`,
                  }}
                />
              )}
              <Box ml={1}>
                <Text
                  fontWeight="semibold"
                  textDecor={
                    isLoggedOnLinkedin !== 'no reply' && 'line-through'
                  }
                >
                  Install Chrome Extension
                </Text>
                <Tooltip
                  bg={mode('white', 'grayAlpha.700')}
                  color={mode('black', 'white')}
                  hasArrow
                  label={
                    <Box p={2}>
                      <Text mb={2}>
                        We need this to get the list of all your LinkedIn
                        connections and match them with your contacts so you can
                        perform useful network searches (e.g. My contacts who
                        are Software Engineers).
                      </Text>
                    </Box>
                  }
                >
                  <Text
                    fontSize="xs"
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    w="max-content"
                  >
                    Why we need this? <Icon as={BsInfoCircle} ml={1} />
                  </Text>
                </Tooltip>
              </Box>
            </Box>

            <Collapse in={!(isLoggedOnLinkedin !== 'no reply')}>
              <Box ml={12}>
                <Button
                  as="a"
                  href="https://chrome.google.com/webstore/detail/village/ciiaaeopfhhhghglhdfbphciifbgcmeh"
                  target="_blank"
                  size="sm"
                  leftIcon={<FiChrome />}
                  my={2}
                  variant="outline"
                  colorScheme={
                    isLoggedOnLinkedin !== 'no reply' ? 'green' : 'middle'
                  }
                  isDisabled={isLoggedOnLinkedin !== 'no reply'}
                  rightIcon={isLoggedOnLinkedin !== 'no reply' && <FaCheck />}
                >
                  Install Chrome extension
                </Button>
              </Box>
            </Collapse>
            {isLoggedOnLinkedin === false && (
              <Text ml={12} d="inline-block">
                You must be logged in on Linkedin to start enriching your
                contacts{' '}
                <Button
                  as="a"
                  href="https://www.linkedin.com/login"
                  target="_blank"
                  variant="link"
                  color="red.400"
                  size="xl"
                  textDecor="underline"
                >
                  Login on Linkedin
                </Button>
              </Text>
            )}
          </Box>
        </Stack>
      </Collapse>

      <Box>
        {isBasicIntegrationDone && (
          <LoginButton
            customButton={(renderProps) => (
              <Button
                onClick={() => {
                  renderProps.onClick()
                  setIsIntegrationVisible(true)
                }}
                disabled={renderProps.disabled}
                color="white"
                size="md"
                colorScheme="middle"
                variant="outline"
                mr={2}
              >
                Add more accounts
              </Button>
            )}
            customCallback={async (googleCode) => {
              const integration = await addIntegration({
                code: googleCode,
              })

              const isThereAlready = allGoogleIntegrations.findIndex(
                (i) => i.id === integration.id
              )
              if (isThereAlready === -1) {
                setAdditionalIntegrations([
                  ...additionalIntegrations,
                  integration,
                ])
              } else {
                const newIntegrations = [...additionalIntegrations]
                newIntegrations[isThereAlready - 1] = integration
                setAdditionalIntegrations(newIntegrations)
              }
            }}
            extraPermissions
          />
        )}

        {/* <Button
          rightIcon={<FaArrowRight />}
          size="md"
          variant="village"
          borderRadius="md"
          onClick={nextStep}
          isDisabled={!isBasicIntegrationDone}
        >
          Continue
        </Button> */}
      </Box>
    </Stack>
  )
}

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Badge,
  Box,
  Checkbox,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ButtonWithConfirmation } from 'components/ButtonWithConfirmation'
import { useToast } from 'hooks/useToast'
import moment from 'moment'
import { useEffect, useState } from 'react'
import api from 'services/api'
import Pick from './Pick'

export default function PicksTracker({ userId }) {
  const toast = useToast()

  const [users, setUsers] = useState([])

  const thisWeekYear = moment().format('[W]WW[Y]GG')
  const nextWeekYear = moment().add(1, 'week').format('[W]WW[Y]GG')

  useEffect(() => {
    const fetchWeeklyIntros = async () => {
      try {
        const { data } = await api.get(`/admin/picks?weekyear=${nextWeekYear}`)

        setUsers(data)
      } catch (err) {}
    }

    fetchWeeklyIntros()
  }, [])

  const toggleFlagSendPicks = async (index) => {
    try {
      await api.patch(`/admin/user/${users[index].id}`, {
        flag_send_picks: !users[index].flag_send_picks,
      })

      const usersCopy = [...users]

      usersCopy[index].flag_send_picks = !usersCopy[index].flag_send_picks
      setUsers(usersCopy)

      toast({
        status: 'success',
        title: 'User updated successfully',
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleSendPicks = async (index, isTest) => {
    try {
      await api.get(
        `/admin/send-picks/${users[index].id}${isTest ? '?test=true' : ''}`
      )

      if (!isTest) {
        const usersCopy = [...users]

        usersCopy[index].picks = []
        setUsers(usersCopy)
      }

      toast({
        status: 'success',
        title: 'Picks sent successfully' + (isTest ? ' (test)' : ''),
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const deletePick = async (pickId, userIndex, pickIndex) => {
    await api.delete(`/pick/${pickId}`)

    const usersCopy = [...users]
    const user = usersCopy[userIndex]
    user.picks.splice(pickIndex, 1)
    setUsers(usersCopy)

    toast({
      status: 'success',
      title: 'Pick deleted successfully',
    })
  }

  return (
    <Box mb={userId && 6}>
      <Box mb={2}>
        <Text>
          We're currently on week {thisWeekYear} and these are the picks for
          week {nextWeekYear}.
        </Text>
        <Text color="grayAlpha.1" fontSize="sm">
          They will be sent out next Monday.
        </Text>
      </Box>
      <Accordion allowMultiple>
        {users.map((user, userIndex) => {
          if (userId && userId !== user.id) {
            return null
          }

          const { avatar, firstname } = user.Identity
          const totalPicks = user.picks.length

          return (
            <AccordionItem key={`user-${user.id}`}>
              <AccordionButton>
                <AccordionIcon />
                <Box d="flex" alignItems="center" w="100%">
                  <Avatar size="sm" src={avatar} mr={1} />
                  <Text mr={1}>{firstname}</Text>
                  {totalPicks >= 5 ? '✅' : `🔴 (${5 - totalPicks} pending)`}
                  <Checkbox
                    isChecked={user.flag_send_picks}
                    onChange={() => toggleFlagSendPicks(userIndex)}
                    ml={2}
                  />
                  <Badge variant="gray" fontSize="x-small" size="sm" ml={2}>
                    {user.id}
                  </Badge>
                  {user.City && (
                    <Badge variant="gray" fontSize="x-small" size="sm" ml={2}>
                      {user.City.name}
                    </Badge>
                  )}

                  <Box d="flex" alignItems="center" ml="auto">
                    <ButtonWithConfirmation
                      ml="auto"
                      variant="villageOutline"
                      isDisabled={totalPicks === 0}
                      onClick={() => handleSendPicks(userIndex, true)}
                      mr={2}
                    >
                      Test send picks
                    </ButtonWithConfirmation>

                    <ButtonWithConfirmation
                      variant="villageOutline"
                      isDisabled={totalPicks === 0}
                      onClick={() => handleSendPicks(userIndex)}
                    >
                      Manually send picks
                    </ButtonWithConfirmation>
                  </Box>
                </Box>
              </AccordionButton>

              <AccordionPanel pb={4}>
                <Stack spacing={1} pl={6}>
                  {user.picks.map((pick, pickIndex) => {
                    return (
                      <Pick
                        key={`pick-${pick.id}`}
                        pick={pick}
                        deletePick={() =>
                          deletePick(pick.id, userIndex, pickIndex)
                        }
                      />
                    )
                  })}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </Box>
  )
}

import React from 'react'
import { Flex, propNames, Text, useDisclosure } from '@chakra-ui/react'
import RelationshipStrength from 'components/RelationshipStrength'
import PathsModal from 'components/PathsModal'
import CompanyModal from 'components/CompanyModal'

export const RelationshipSummary = ({
  pathCount,
  pathName,
  warmthScore,
  isCompanyModal,
  companyId,
  relationship,
  ...props
}) => {
  const ModalComponent = isCompanyModal ? CompanyModal : PathsModal

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (isCompanyModal && !companyId) return null

  return (
    <Flex sx={{ gap: '6px' }} {...props} flexWrap="wrap" alignItems="center">
      Your path to {pathName} is
      <RelationshipStrength
        p="0.25rem 0.75rem"
        relationship={{
          warmth_score: warmthScore,
        }}
      />
      <ModalComponent
        relationship={relationship}
        company={{ id: companyId }}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      <Text
        as="a"
        textDecor="underline"
        href=""
        onClick={(e) => {
          e.preventDefault()
          onOpen()
        }}
      >
        {`See all ${pathCount} paths ->`}
      </Text>
    </Flex>
  )
}

import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'
import { useAuth } from 'context/AuthContext'
import { FaArrowRight } from 'react-icons/fa'

export default function RelationshipConnector({
  connectors,
  openPathsModal,
  isDeepFetch,
}) {
  const {
    user: { identityId: userIdentityId, avatar: userAvatar },
    selectedUser,
  } = useAuth()

  const selectedUserIdentity = selectedUser
    ? selectedUser.Identity
    : {
        id: userIdentityId,
        avatar: userAvatar,
      }

  if (isDeepFetch && !connectors.length) {
    return <Badge color="oldGray.100">None</Badge>
  }

  return (
    <Box d="flex" alignItems="center" onClick={openPathsModal} cursor="pointer">
      <AvatarGroup size="sm" max={2} spacing="-0.5rem">
        {connectors
          .sort((a, b) => {
            if (
              Number(a.identity_id) === selectedUserIdentity.id &&
              a.calculated_warmth_score > 0
            ) {
              return -1
            } else if (
              Number(b.identity_id) === selectedUserIdentity.id &&
              b.calculated_warmth_score > 0
            ) {
              return 1
            } else {
              return b.calculated_warmth_score - a.calculated_warmth_score
            }
          })
          .map((c, cIndex) => {
            if (Number(c.identity_id) === selectedUserIdentity.id) {
              return (
                <Avatar
                  key={cIndex}
                  src={selectedUserIdentity.avatar}
                  w="40px"
                  h="40px"
                >
                  <AvatarBadge
                    bg="primary.500"
                    color="white"
                    fontWeight="bold"
                    fontSize="xx-small"
                    transform="translateY(7px)"
                    left="0"
                    px={1}
                  >
                    You
                  </AvatarBadge>{' '}
                </Avatar>
              )
            } else {
              return <Avatar src={c.avatar} w="40px" h="40px" />
            }
          })}
      </AvatarGroup>
      <Button
        rightIcon={<FaArrowRight />}
        size="sm"
        variant="link"
        textDecor="underline"
        fontWeight="semibold"
        ml={2}
        color={mode('#718096', '#E2E8F0')}
      >
        {connectors.length} path{connectors.length > 1 && 's'}
      </Button>
    </Box>
  )
}

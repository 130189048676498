import {
  Avatar,
  useColorModeValue as mode,
  useDisclosure,
  AvatarGroup,
  Tooltip,
} from '@chakra-ui/react'
import { ProfilePageModal } from 'components/Profile/ProfilePageModal'
import { useState } from 'react'
import { encryptId } from 'utils/encryption'

export const Recommenders = ({ recommenders, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [name, setName] = useState('name')

  const [identityId, setIdentityId] = useState('')

  return (
    <>
      <Tooltip color={mode('black', 'white')} label={name}>
        <AvatarGroup spacing="-2" w="20px" h="20px" {...props}>
          {recommenders.map((recommender, index) => (
            <Avatar
              key={index}
              onMouseEnter={() => {
                setName(recommender.name)
                setIdentityId(encryptId(recommender.identity_id))
              }}
              w="20px"
              h="20px"
              onClick={(e) => {
                onOpen()
                e.stopPropagation()
              }}
              src={recommender.avatar}
            />
          ))}
        </AvatarGroup>
      </Tooltip>
      <ProfilePageModal
        UserProfile
        profileToVisit={identityId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

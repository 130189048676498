import React from 'react'
import {
  useColorModeValue as mode,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  CloseButton,
} from '@chakra-ui/react'

export const ConfirmationModal = ({ isOpen, onClose, closeAction, isNew }) => {
  return (
    <Modal isOpen={isOpen} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <CloseButton pos="absolute" right="4" top="4" onClick={onClose} />
        <ModalBody>
          Are you sure you want to cancel {isNew ? 'posting' : 'editing'} this
          opportunity?
        </ModalBody>
        <ModalFooter>
          <Button variant="village" mr={3} onClick={onClose}>
            No, continue editing
          </Button>
          <Button onClick={closeAction} colorScheme="red" variant="outline">
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import React from 'react'
import {
  Box,
  Flex,
  Text,
  Avatar,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'
import { OpportunityTag } from './OpportunityTag'
import RelationshipStrength from 'components/RelationshipStrength'
import RelationshipConnector from 'components/RelationshipConnector'
import PathsModal from 'components/PathsModal'
import { useAuth } from 'context/AuthContext'
import { ProfileTitle } from 'components/ProfileTitle'
import { AdvancedTooltip } from 'components/AdvancedTooltip'
import { FaLock, FaUserLock } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'

const sharedWithParser = {
  custom: {
    label: 'Confidential',
    icon: <FaLock size={12} />,
  },

  private: {
    label: 'Semi-confidential',
    explanation: 'Include close friends',
    icon: <FaUserLock size={15} />,
  },

  'semi-private': {
    value: 'semi-private',
    label: 'Semi-confidential',
    explanation: 'Include close friends of friends',
    icon: <FaUserLock size={15} />,
  },
  anyone: {
    label: 'Public',
    icon: <IoEarthSharp size={12} />,
  },
}

export const OpportunityCard = ({ opportunity }) => {
  const {
    title,
    relationshipWithPoster,
    encrypted_id,
    poster_identity_id,
    category_id,
    id,
    recommendations,
    sharedWith,
    referralReward,
  } = opportunity

  const { user } = useAuth()

  const renderHeadline = () => {
    if (!relationshipWithPoster) return null

    if (relationshipWithPoster.current_work_position) {
      return `${relationshipWithPoster.current_work_position} at ${relationshipWithPoster.current_work_company_name}`
    }

    return relationshipWithPoster.headline
  }

  const renderPath = () => {
    if (poster_identity_id === user.identityId) return null

    const connectors =
      relationshipWithPoster?.connectors &&
      typeof relationshipWithPoster?.connectors === 'string'
        ? JSON.parse(relationshipWithPoster?.connectors)
        : []

    return (
      <>
        <RelationshipStrength
          relationship={{
            warmth_score: relationshipWithPoster?.warmth_score,
          }}
          identities={{
            left_identity_id: relationshipWithPoster?.left_identity_id,
            right_identity_id: relationshipWithPoster?.right_identity_id,
          }}
        />
        <PathsModal
          relationship={relationshipWithPoster}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
        <RelationshipConnector
          openPathsModal={() => {
            onOpen()
          }}
          connectors={connectors}
        />
      </>
    )
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box
      mb="8"
      p="4"
      borderWidth={3}
      borderColor={mode('gray.1', 'grayAlpha.500')}
      borderRadius="10px"
    >
      <Flex mb="9" sx={{ gap: 8 }} flexWrap="wrap" alignItems="center">
        <Flex alignItems="center">
          <Avatar
            name={relationshipWithPoster?.fullName}
            src={relationshipWithPoster?.avatar}
            w={42}
            h={42}
          />
          <Box lineHeight={1.15} ml="2.5">
            <ProfileTitle
              name={relationshipWithPoster?.fullName}
              identityId={poster_identity_id}
            />
            <Text color="#8C8383" mt="0.5" fontSize="xs">
              {renderHeadline()}
            </Text>
          </Box>
        </Flex>
        <Flex
          sx={{ gap: 8 }}
          alignItems="center"
          wrap="wrap"
          ml={['0', '0', '0', 'auto']}
        >
          {renderPath()}
        </Flex>
      </Flex>
      <Text
        mt="9"
        fontSize="lg"
        _hover={{
          textDecoration: 'underline',
          textUnderlineOffset: '2px',
        }}
        as="a"
        href={`/opportunity/${encrypted_id}`}
        fontWeight="bold"
      >
        {title}
      </Text>
      <Flex flexWrap="wrap" sx={{ gap: 14 }} alignItems="center" mt="6">
        <OpportunityTag categoryId={category_id} />
        {referralReward && (
          <AdvancedTooltip
            p="2"
            bg={mode('gray.1', 'grayAlpha.500')}
            tooltipContent={
              <Text fontSize="sm" textAlign="center">
                {referralReward}
              </Text>
            }
          >
            <Text as="span" fontWeight="bold" fontSize="sm" color="#8C8383">
              $${'  '}
              <Text as="span" fontWeight="normal" fontSize="sm" color="#8C8383">
                Offering referral reward
              </Text>
            </Text>
          </AdvancedTooltip>
        )}
        <Flex color="#8C8383" alignItems="center">
          <Text mr="2.5">{' • '}</Text>
          {sharedWithParser[sharedWith].icon}
          <Text ml="2" as="span" fontSize="sm">
            {sharedWithParser[sharedWith].label}
          </Text>
        </Flex>
      </Flex>
      <Flex mt="6" sx={{ gap: 8 }}>
        {recommendations.length > 0 && (
          <AvatarGroup max={2} spacing="-1.5" size="2xs">
            {recommendations.map((recommendation, index) => (
              <Avatar
                key={index}
                name={recommendation.fullName}
                src={recommendation.avatar}
              />
            ))}
          </AvatarGroup>
        )}
        <Text fontSize="sm" color="green.400">
          {recommendations.length}{' '}
          {recommendations.length > 1 ? 'people' : 'person'} recommended matches
        </Text>
      </Flex>
    </Box>
  )
}

import { useState, createContext, useContext } from 'react'

const SelectedContext = createContext()

export const useSelected = () => {
  const context = useContext(SelectedContext)

  return context
}

export function SelectedProvider({ children }) {
  const [selected, setSelected] = useState([])

  const toggleSelect = (entityData, isSelected) => {
    const { entity_id, entity, ...rest } = entityData

    if (isSelected) {
      const index = selected.findIndex(
        (s) => s.entity === entity && s.entity_id === entity_id
      )
      const selectedCopy = [...selected]
      selectedCopy.splice(index, 1)
      setSelected(selectedCopy)
    } else {
      setSelected([
        ...selected,
        { entity_id: entity_id, entity: entity, ...rest },
      ])
    }
  }

  const handleSelectAll = (isChecked, data) => {
    if (isChecked) {
      setSelected([])
    } else {
      const newSelecteds = [...selected, ...data].filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (el) =>
              el.entity === value.entity && el.entity_id === value.entity_id
          )
      )

      setSelected(newSelecteds)
    }
  }

  return (
    <SelectedContext.Provider
      value={{
        selected,
        setSelected,
        toggleSelect,
        handleSelectAll,
      }}
    >
      {children}
    </SelectedContext.Provider>
  )
}

import { Button, useColorModeValue as mode } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const ButtonLink = ({ children, ...props }) => {
  return (
    <Button
      rightIcon={<FaArrowRight />}
      size="sm"
      color={mode('black', 'white')}
      variant="link"
      textDecor="underline"
      fontWeight="semibold"
      as={props.to && Link}
      {...props}
    >
      {children}
    </Button>
  )
}

import { Box, Icon, IconButton } from '@chakra-ui/react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

export default function TablePagination({ count, offset, setOffset }) {
  const handlePreviousPage = () => {
    setOffset(offset - 10)
  }

  const handleNextPage = () => {
    setOffset(offset + 10)
  }

  return (
    <Box
      d="flex"
      justifyContent="flex-end"
      alignItems="center"
      p={5}
      pos="absolute"
      right="50px"
      bottom="10px"
    >
      <Box mr={4}>
        {offset + 1}-{offset + 10 > count ? count : offset + 10} of {count}
      </Box>
      <Box>
        <IconButton
          disabled={offset === 0}
          onClick={handlePreviousPage}
          icon={<Icon as={FaChevronLeft} />}
          size="sm"
          mr={1}
        />
        <IconButton
          disabled={offset + 10 >= count}
          onClick={handleNextPage}
          icon={<Icon as={FaChevronRight} />}
          size="sm"
        />
      </Box>
    </Box>
  )
}

import { chakra } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

const ChakraLink = chakra(NavLink)

export default function Link({ ...props }) {
  return (
    <ChakraLink
      exact
      fontSize="sm"
      fontWeight="500"
      color="black"
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.50',
      }}
      activeStyle={{
        fontWeight: 'bold',
      }}
      {...props}
    />
  )
}

import React from 'react'
import { Route } from 'react-router-dom'

import LandingLayout from 'layouts/LandingLayout'
import { SharedLayout } from 'layouts/SharedLayout'

export default function OpenRoute({ isProtected, children, ...rest }) {
  const LayoutComponent = isProtected ? LandingLayout : SharedLayout
  return (
    <Route
      {...rest}
      render={() => <LayoutComponent>{children}</LayoutComponent>}
    />
  )
}

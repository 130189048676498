import {
  Avatar,
  Box,
  Container,
  Flex,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { ButtonLink } from 'components/ButtonLink'
import { CuratedItem } from 'components/CuratedItem'
import { SectionHeader } from 'components/NewUI/SectionHeader'
import { SectionSubtitle } from 'components/NewUI/SectionSubtitle'
import { Sidebar } from 'components/NewUI/Sidebar'
import { SidebarItemWrapper } from 'components/NewUI/SidebarItemWrapper'
import { StackWithDivider } from 'components/StackWithDivider'
import { Stats } from 'components/Stats'
import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

export default function Dashboard() {
  const { user } = useAuth()
  const history = useHistory()

  const [isStatsLoading, setIsStatsLoading] = useState(false)
  const [stats, setStats] = useState({
    directWarm: 0,
    directTotal: 0,
    networkWarm: 0,
    networkTotal: 0,
    invitedBy: false,
  })

  const [isNetworkDataLoading, setIsNetworkDataLoading] = useState(false)
  const [networkData, setNetworkData] = useState({
    inviter: 'John',
    totalFriends: 0,
    topCompany: ['Google', 'Facebook', 'Amazon'],
    topPeople: ['John', 'Jane', 'Jack'],
    totalWarmthCompany: 0,
    totalWarmthPeople: 0,
  })

  const [opportunities, setOpportunities] = useState([])
  const [isOpportunitiesLoading, setIsOpportunitiesLoading] = useState(false)

  useEffect(() => {
    const fetchStats = async () => {
      setIsStatsLoading(true)
      try {
        const { data } = await api.get('/stats')
        setStats(data)
      } catch (err) {
        console.error(err)
      }
      setIsStatsLoading(false)
    }

    const fetchNetworkData = async () => {
      setIsNetworkDataLoading(true)
      try {
        const { data } = await api.get('/inviter')
        setNetworkData(data)

        setIsNetworkDataLoading(false)
      } catch (err) {}
    }

    const fetchOpportunities = async () => {
      setIsOpportunitiesLoading(true)
      try {
        const { data } = await api.get('/own/opportunities')
        setOpportunities(data.opportunities)
      } catch (err) {
        console.error(err)
      }
      setIsOpportunitiesLoading(false)
    }

    fetchStats()
    fetchNetworkData()
    // fetchOpportunities()
  }, [])

  return (
    <Container maxW="1130px">
      <Box pb="1">
        <Flex sx={{ gap: 40 }}>
          <Sidebar>
            <SidebarItemWrapper mt="6" py="8">
              <Avatar
                src={user.avatar}
                borderRadius="2.5rem"
                size="xl"
                mb={4}
              />

              <SectionHeader label={user.name} />
              <SectionSubtitle label={user.headline} mb={2} />

              <ButtonLink fontWeight="normal" isDisabled>
                Edit Profile
              </ButtonLink>
            </SidebarItemWrapper>

            {Boolean(user.is_sync_complete) && (
              <SidebarItemWrapper mt="6" py="8">
                <Stats data={stats} isLoading={isStatsLoading} />
              </SidebarItemWrapper>
            )}
            {/* 
            <Box mt="6" borderRadius="base">
              <SectionHeader label="🎯 I’m looking for..." />
              <SectionSubtitle
                label="Share what you're looking for securely with close friends so
                they can help you."
                mb={2}
              />
              <Stack>
                {opportunities.slice(0, 2).map((opportunity) => (
                  <PostedOpportunityWrapper
                    key={opportunity.id}
                    opportunity={opportunity}
                    display={'block'}
                    setActiveOpportuntiyId={() =>
                      history.push('/opportunities')
                    }
                  />
                ))}
              </Stack>
            </Box>

            <Box mt="6" pb={10} borderRadius="base">
              <SectionHeader label="↔️ Synced accounts" />
              <SectionSubtitle label="The more accounts you sync, the better the matches and network discovery." />
            </Box> */}

            <Box></Box>
          </Sidebar>
          <Box mt="12" pb="15" w="100%" maxW="770px">
            {/* <SyncBanner /> */}

            <Box mb={12}>
              <SectionHeader
                preffix="🔥 Curated:"
                label="Top people you can get introduced to"
                mb={8}
              />

              <StackWithDivider spacing={4}>
                {networkData?.topPeople.map((relationship, index) => (
                  <CuratedItem
                    key={`relationship-${index}`}
                    entity={{
                      name: `${relationship?.firstname} ${relationship?.lastname}`,
                      title: `${relationship?.current_work_position} at ${relationship?.current_work_company_name}`,
                      avatar: relationship.avatar ? relationship.avatar : '',
                      warmth_score: relationship.warmth_score,
                      identityId: relationship.id,
                      connector: {
                        name: relationship.connector,
                        avatar: relationship.connector_avatar,
                      },
                    }}
                    isLoading={isNetworkDataLoading}
                  />
                ))}
              </StackWithDivider>
              <ButtonLink
                bg={mode('gray.50', 'whiteAlpha.400')}
                color={mode('gray.400', 'white')}
                borderRadius="none"
                height="2.5rem"
                w="100%"
                fontWeight="normal"
                mt={4}
                to="/people"
              >
                Browser More
              </ButtonLink>
            </Box>

            <Box mb={12}>
              <SectionHeader
                preffix="🔥 Curated:"
                label="Top companies you can get introduced to"
                mb={8}
              />

              <StackWithDivider spacing={4}>
                {networkData?.topCompany.map((company, index) => (
                  <CuratedItem
                    key={`company-${index}`}
                    entity={{
                      name: company.name,
                      title: company.employee_size
                        ? `${company.employee_size.toLocaleString()} employees`
                        : '',
                      avatar: company.logo ? company.logo : '',
                      warmth_score: company.warmth_score,
                      companyId: company.id,
                      connector: {
                        name: company.connector ?? '',
                        avatar: company.connector_avatar ?? '',
                      },
                    }}
                    isLoading={isNetworkDataLoading}
                  />
                ))}{' '}
              </StackWithDivider>
              <ButtonLink
                bg={mode('gray.50', 'whiteAlpha.400')}
                color={mode('gray.400', 'white')}
                borderRadius="none"
                height="2.5rem"
                w="100%"
                fontWeight="normal"
                mt={4}
                to="/companies"
              >
                Browser More
              </ButtonLink>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

import { extendTheme } from '@chakra-ui/react'
import { theme as defaultTheme } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'
import { notionDarkColors, notionLightColors } from './notion'

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    ...notionDarkColors,
    ...notionLightColors,

    primary: {
      500: '#2DBED8',
    },
    secondary: {
      500: '#08A649',
    },
    middle: {
      50: '#56B0A410',
      500: '#56B0A4',
    },

    gray: {
      1: '#ECECEC',
      5: '#FAFAFA', // this is the one that is used in the sidebar+new UI
      10: '#A4A4A4',
      15: '#C0C0C0',
      20: '#F2F5F7',
      25: '#F5F5F5',
      ...defaultTheme.colors.blackAlpha,
    },

    blue: {
      25: '#f7fdff',
      ...defaultTheme.colors.blue,
    },

    turquoise: {
      500: '#5AB7B9',
    },

    oldGray: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },

    grayAlpha: {
      0: '#f1f1f1',
      1: '#c0c0c0',
      5: '#858585',
      50: '#505050',
      100: '#484848',
      200: '#404040',
      300: '#383838',
      400: '#303030',
      500: '#282828',
      600: '#202020',
      700: '#181818',
      800: '#101010',
      900: '#080808',
    },
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  components: {
    Avatar: {
      baseStyle: (props) => ({
        container: {
          border: 'none',
          bg: 'middle.500',
          color: 'white',
        },
      }),
    },
    Badge: {
      baseStyle: {
        borderRadius: 'full',
      },
      variants: {
        gray: (props) => ({
          color: mode('gray.800', 'grayAlpha.1')(props),
          background: mode('gray.100', 'grayAlpha.300')(props),
        }),
      },
    },
    Button: {
      baseStyle: (props) => ({
        borderRadius: 'full',
      }),
      variants: {
        village: {
          padding: '1.25rem',
          color: 'white',
          bg: 'turquoise.500',
          transition: `all 0.1s linear`,
          _hover: {
            filter: 'brightness(90%)',
          },
        },
        villageOutline: (props) => ({
          color: mode('gray.500', 'white')(props),
          border: '1px solid',
          borderColor: mode('gray.500', 'white')(props),
          bg: 'transparent',
          _hover: {
            filter: 'opacity(0.8)',
          },
        }),
        'village-black': (props) => ({
          padding: '1.25rem 1.5rem',
          color: mode('black', 'white')(props),
          border: '2px solid',
          borderColor: mode('black', 'white')(props),
          bg: mode('black', 'white')(props),
          _hover: {
            bg: mode('#292523', '#cbcbcb')(props),
            filter: mode('brightness(150%)', 'brightness(80%)')(props),
            _disabled: {
              bg: mode('#232729', '#cbcbcb')(props),
              filter: mode('brightness(100%)', 'brightness(80%)')(props),
            },
          },
        }),
      },
    },
    Checkbox: {
      baseStyle: (props) => ({
        control: {
          _checked: {
            bg: mode('black', 'white')(props),
            borderColor: mode('black', 'white')(props),

            _hover: {
              bg: mode('black', 'white')(props),
              borderColor: mode('black', 'white')(props),
              opacity: '0.8',
            },
          },

          _indeterminate: {
            bg: mode('black', 'white')(props),
            borderColor: mode('black', 'white')(props),

            _hover: {
              bg: mode('black', 'white')(props),
              borderColor: mode('black', 'white')(props),
              opacity: '0.8',
            },
          },
        },
      }),
    },
    Input: {
      baseStyle: (props) => ({
        field: {
          bg: mode('white', 'grayAlpha.700')(props),
          color: mode('black', 'white')(props),
          border: mode('1px solid', '1px solid')(props),
          borderColor: mode('gray.200', 'grayAlpha.700')(props),
          _placeholder: {
            color: mode('gray.400', 'grayAlpha.50')(props),
          },
        },
      }),
      defaultProps: {
        variant: null, // null here
      },
    },
    FormLabel: {
      baseStyle: (props) => ({
        color: mode('black', 'white')(props),
        fontSize: '14',
      }),
    },
    Menu: {
      baseStyle: (props) => ({
        list: {
          bg: mode('white', 'grayAlpha.700')(props),
        },
      }),
    },
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: mode('white', 'grayAlpha.700')(props),
        },
        overlay: {
          bg: mode('blackAlpha.600', 'blackAlpha.800')(props),
        },
      }),
    },
    Popover: {
      baseStyle: (props) => ({
        content: {
          bg: mode('white', 'grayAlpha.700')(props),
        },
      }),
    },
    Tooltip: {
      baseStyle: (props) => ({
        bg: mode('white', 'grayAlpha.700')(props),
        color: mode('black', 'white')(props),
      }),
    },
  },

  styles: {
    global: (props) => ({
      'html, body': {
        bg: mode('white', 'black')(props),
        color: mode('black', 'white')(props),
      },
      '.RichEditor-root': {
        background: 'transparent',
        color: mode('black', 'white')(props),
      },
      '.RichEditor-editor': {
        cursor: 'text',
        fontSize: '16px',
        marginTop: '10px',
      },
      '.RichEditor-editor .public-DraftEditorPlaceholder-root': {
        margin: '0 -15px -15px',
        padding: '15px',
      },
      '.RichEditor-editor .public-DraftEditor-content': {
        margin: '0 -15px -15px',
        padding: '15px',
        minHeight: '100px',
      },
      '.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root': {
        display: 'none',
      },
      '.RichEditor-editor .RichEditor-blockquote': {
        borderLeft: '5px solid #eee',
        color: '#666',
        fontStyle: 'italic',
        margin: '16px 0',
        padding: '10px 20px',
      },
      '.RichEditor-editor .public-DraftStyleDefault-pre': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontSize: '16px',
        padding: '20px',
      },
      '.RichEditor-controls': {
        fontSize: '14px',
        marginBottom: '5px',
        userSelect: 'none',
      },
      '.RichEditor-styleButton': {
        color: '#999',
        cursor: 'pointer',
        marginRight: '16px',
        padding: '2px 0',
        display: 'inline-block',
      },
      '.RichEditor-activeButton': {
        color: '#5890ff !important',
      },
      '.public-DraftStyleDefault-block': {
        marginBottom: 2,
      },

      '.react-calendar': {
        mb: 2,
        borderRadius: '25px',
        overflow: 'hidden',
        fontFamily: 'Inter',
      },

      '.react-calendar__navigation': {
        mb: 0,
      },
      // '.react-calendar *': {
      //   bg: mode('white', 'grayAlpha.700')(props),
      // },

      '.react-calendar__tile, .react-calendar__navigation, .react-calendar__month-view__weekdays': {
        bg: mode('white', 'grayAlpha.500')(props),
      },

      '.react-calendar__tile--active': {
        bg: 'middle.500',
        color: 'white',
      },

      '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
        bg: 'middle.500',
      },

      '.react-calendar__tile:disabled': {
        bg: mode('grayAlpha.1', 'grayAlpha.300')(props),
      },

      '.react-calendar__navigation button:disabled': {
        bg: mode('grayAlpha.1', 'grayAlpha.300')(props),
      },
      '.sunsama-editor': {
        /* overflow: 'auto',
        height: '100%',
        justifyContent: 'flex-start !important', */
      },
      '.heading-name': {
        h1: {
          fontSize: '28px',
        },
        h2: {
          fontSize: '24px',
        },
        h3: {
          fontSize: '20px',
        },
      },
      '.ProseMirror': {
        minHeight: '68vh',
      },
      //using this for prosemirrors content uneditable state
      // this was being used for opportunitycards
      'div[contenteditable="false"]': {},
      '.block-menu-trigger': {
        display: 'none !important',
      },
      '.date-picker': {
        background: 'red',
        width: '100%',
        padding: 'var(--chakra-space-6)',
        height: '50px',
        marginTop: 'var(--chakra-space-1)',
        bg: mode('gray.1', 'black')(props),
      },
    }),
  },
})

export default theme

import {
  Avatar,
  Box,
  Button,
  chakra,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import Waitlist from './Waitlist'

export default function LandingInfo({
  title,
  subtitle,
  subSubtitle,
  person,
  stats,
  children,
}) {
  const {
    isOpen: isWaitlistOpen,
    onOpen: onWaitlistOpen,
    onClose: onWaitlistClose,
  } = useDisclosure()

  return (
    <Box>
      <Box mt={12} mb={12}>
        <Heading d="flex" alignItems="center" mb={1}>
          {title ? title : `Hey, ${person.firstname}!`}
        </Heading>

        <Text fontSize="xl">{subtitle}</Text>

        {subSubtitle && (
          <Text fontSize="lg" color={'grayAlpha.1'}>
            {subSubtitle}
          </Text>
        )}
      </Box>

      <Box mb={32}>{children}</Box>

      <Box mb={32}>
        <Heading d="flex" alignItems="center" mb={2}>
          Need intros to people?
        </Heading>

        <Text mb={2} fontSize="lg">
          Village facilitates building new relationships with people your close
          friends already know and trust. On Village you can meet investors,
          business partners, top talent, recruiters, and even BFFs to-be!
        </Text>

        <Text mb={3} fontSize="lg">
          <Avatar
            src={stats?.identity?.avatar}
            name={`${stats?.identity?.firstname} ${stats?.identity?.lastname}`}
            mr={1}
            size="xs"
          />{' '}
          <strong>{stats?.identity?.firstname}</strong> is using Village and can
          give you access to{' '}
          <chakra.strong color="green.500">
            {stats?.peopleWarm} people
          </chakra.strong>{' '}
          and{' '}
          <chakra.strong color="green.500">
            {stats?.companyWarm} companies
          </chakra.strong>{' '}
          they know and interacted with in the past.
        </Text>

        <Button
          onClick={onWaitlistOpen}
          variant="villageOutline"
          rightIcon={<FaArrowRight />}
        >
          Ask {stats?.identity?.firstname} to give you access
        </Button>

        <Waitlist isOpen={isWaitlistOpen} onClose={onWaitlistClose} />
      </Box>
    </Box>
  )
}

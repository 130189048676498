import { chakra, Text } from '@chakra-ui/react'
import React from 'react'

export const SectionHeader = ({ label, preffix, ...styleProps }) => {
  return (
    <Text fontWeight="semibold" fontSize="xl" {...styleProps}>
      {preffix ? (
        <chakra.span fontWeight="black" mr={2}>
          {preffix}
        </chakra.span>
      ) : (
        ''
      )}
      {label}
    </Text>
  )
}

import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import DashboardLayout from 'layouts/DashboardLayout'
import { useAuth } from 'context/AuthContext'

export default function ProtectedRoute({ children, ...rest }) {
  const { signed } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return signed ? (
          <DashboardLayout>{children}</DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

import React from 'react'
import {
  Box,
  Avatar,
  Flex,
  Text,
  Link,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import moment from 'moment'
import { encryptId } from 'utils/encryption'

export const WorkHistory = ({ history }) => {
  if (!history) return null

  return (
    <Box>
      {history.map((work, index) => (
        <Flex
          direction={['column', 'column', 'row']}
          key={index}
          alignItems="flex-start"
          textAlign="left"
          lineHeight="19px"
          sx={{ gap: 16 }}
          mb="15px"
        >
          <Flex
            ml={['0', '0', '-1', '-1']}
            maxW="175px"
            w={['auto', 'auto', '35%', '35%']}
            justifyContent={['center', 'center', 'flex-end', 'flex-end']}
          >
            <Text as="span" fontSize={16} color="#9D9D9D">
              {moment(work.period_from).format("MMM 'YYYY")} -{' '}
              {work.period_to
                ? moment(work.period_to).format("MMM 'YYYY")
                : 'Present'}
            </Text>
          </Flex>
          <Text
            ml={['0', '0', '10px', '10px']}
            as="span"
            fontSize={16}
            color={mode('black', 'white')}
          >
            {work.title} at
            <Avatar
              ml="7px"
              mr="6px"
              size="2xs"
              name={work.Company.name}
              src={work.Company.logo}
            />
            <Link
              textDecoration="underline"
              fontWeight="bold"
              color={mode('black', 'white')}
              href={`/company/profile/${
                work.Company.slug ?? encryptId(work.Company.id)
              }`}
            >
              {work.Company.name}
            </Link>
          </Text>
        </Flex>
      ))}
    </Box>
  )
}

import { Box, Text, useColorModeValue as mode } from '@chakra-ui/react'

export const PostedByListItem = ({ active, onClick, label }) => {
  return (
    <Box
      px="6"
      mx="-6"
      py="2"
      onClick={onClick}
      cursor="pointer"
      bg={active ? mode('gray.1', 'white') : 'transparent'}
    >
      <Text
        fontWeight={active ? 'bold' : 'regular'}
        variant="link"
        color={active ? 'black' : mode('black', 'white')}
      >
        {label}
      </Text>
    </Box>
  )
}

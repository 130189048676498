import { Box, Spinner } from '@chakra-ui/react'
import IntroRequest from 'components/IntroRequest'
import LandingInfo from 'components/LandingInfo'
import { useEffect, useState } from 'react'
import api from 'services/api'
import { getQueryStringValue } from 'utils/getQueryStringValue'

export default function Request() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    intros: [],
    introducer: null,
    stats: null,
  })
  const [token, setToken] = useState(null)

  useEffect(() => {
    const fetchIntroducer = async (token) => {
      setIsLoading(true)

      try {
        const { data } = await api.get(`/intro?token=${token}`)

        setData({
          intros: data.intros.reduce((acc, curr) => {
            if (acc[curr.introducer_id]) {
              acc[curr.introducer_id].push(curr)
            } else {
              acc[curr.introducer_id] = [curr]
            }

            return acc
          }, {}),
          introducer: data.intros[0],
          stats: data.stats,
        })
      } catch (err) {
        console.log(err)
      }

      setIsLoading(false)
    }

    const tokenFromQueryString = getQueryStringValue({ value: 'token' })
    if (tokenFromQueryString) {
      setToken(tokenFromQueryString)
      fetchIntroducer(tokenFromQueryString)
    }
  }, [])

  const updateIntro = ({ value, intro, key }) => {
    const newData = { ...data }
    const introRef = newData.intros[intro.introducer_id].find(
      (i) => i.intro_id === intro.intro_id
    )
    introRef[key] = value
    setData(newData)
  }

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <Box>
      <LandingInfo
        subtitle={'Can you make intros to these people?'}
        subSubtitle={
          'If you accept, I will then send you a forwardable email you can use for the intros.'
        }
        person={{
          firstname: data?.introducer?.introducer_firstname,
          lastname: data?.introducer?.introducer_lastname,
          avatar: data?.introducer?.introducer_avatar,
        }}
        stats={data?.stats}
      >
        {Object.values(data.intros).map((intros) => (
          <Box>
            {intros.map((intro) => (
              <IntroRequest
                key={intro.id}
                intro={intro}
                token={token}
                requester={{
                  firstname: data?.stats?.identity?.firstname,
                }}
                updateIntro={updateIntro}
              />
            ))}
          </Box>
        ))}
      </LandingInfo>
    </Box>
  )
}

import { chakra, Box, useColorModeValue as mode } from '@chakra-ui/react'

import { NavLink } from 'react-router-dom'

export default function OpenLayout({ children }) {
  return (
    <Box
      minW="100vw"
      minH="100vh"
      d="flex"
      justifyContent="center"
      alignItems="center"
      bg="black"
      pos="relative"
    >
      {children}
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        pos="absolute"
        bottom="15"
        fontSize="sm"
        textDecor="underline"
        color="grayAlpha.50"
      >
        <chakra.a as={NavLink} to="/privacy" mr={3}>
          Privacy Policy
        </chakra.a>

        <chakra.a as={NavLink} to="/terms">
          Terms of Use
        </chakra.a>
      </Box>
    </Box>
  )
}

import {
  chakra,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import { useState } from 'react'
import api from 'services/api'

export default function JobHistoryPopover({ children, identity_id }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [workHistory, setWorkHistory] = useState([])
  const [isWorkHistoryLoading, setIsWorkHistoryLoading] = useState(false)

  const getWorkHistory = async () => {
    setIsWorkHistoryLoading(true)

    try {
      const { data } = await api.get(`/work-history/${identity_id}`)
      setWorkHistory(data)
    } catch (err) {}

    setIsWorkHistoryLoading(false)
  }

  return (
    <Popover
      trigger="hover"
      onOpen={() => {
        getWorkHistory()
        onOpen()
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent w="100%" p={3} border="none">
        {isWorkHistoryLoading && <Spinner />}

        <List>
          {Boolean(workHistory.length) &&
            workHistory.map((w, wIndex) => {
              return (
                <ListItem key={wIndex} mb={1}>
                  <chakra.span
                    fontStyle="italic"
                    d="inline-block"
                    w="13ch"
                    color={
                      w.period_to === null
                        ? mode('green.800', 'green.100')
                        : mode('red.800', 'red.100')
                    }
                    textAlign="right"
                    mr={3}
                  >
                    {moment(w.period_from).format("MMM[']YY")} -{' '}
                    {w.period_to === null
                      ? 'now'
                      : moment(w.period_to).format("MMM[']YY")}
                  </chakra.span>
                  {w.title} at{' '}
                  <chakra.span fontWeight="bold">{w.Company.name}</chakra.span>
                </ListItem>
              )
            })}
        </List>
      </PopoverContent>
    </Popover>
  )
}

const etaParser = (eta) => {
  if (eta <= 60) {
    return `${eta} min`
  }

  const etaInHours = Math.round((eta / 60) * 2) / 2

  return `${etaInHours} hour${etaInHours > 1 ? 's' : ''}`
}

const reasonParser = (reason) => {
  let parsed = reason.trim()
  if (parsed.length === 0) return ''
  let parsedArr = parsed.split('')
  if (parsedArr.length === 0) return ''
  parsedArr[0] = parsedArr[0].toLowerCase()
  if (parsedArr[parsedArr.length - 1] === '.') {
    parsedArr.pop()
  }
  return parsedArr.join('')
}

const concatPeople = (people) => {
  if (people.length === 1) {
    return `${people[0].name}`
  }

  if (people.length === 2) {
    return `${people[0].name} and ${people[1].name}`
  }

  return `${people[0].name}, ${people[1].name} and ${people.length - 2} other${
    people.length - 2 > 1 ? 's' : ''
  }`
}

const htmlParser = (html, selector) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const lockedPart = doc.querySelectorAll(selector)
  const container = document.createElement('div')
  container.append(...lockedPart)
  return container.innerHTML
}

const urlMatcher = (url) => {
  const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9]+$/
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/
  const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/.+$/
  const faceBookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/
  const dropBoxRegex = /^(https?:\/\/)?(www\.)?dropbox\.com\/.+$/
  const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/
  const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/

  const regexes = [
    linkedInRegex,
    youtubeRegex,
    twitterRegex,
    faceBookRegex,
    dropBoxRegex,
    instagramRegex,
    vimeoRegex,
  ]

  for (const regex of regexes) {
    if (regex.test(url)) {
      return true
    }
  }
  return false
}

const parseStrengthColor = (value, companySize, isTopWarmthLikely) => {
  const parsedValue = Number(value)

  if (Number(companySize) > 30 || isTopWarmthLikely) return 'oldGray'

  if (parsedValue === -1 || isNaN(parsedValue)) return 'blue'

  if (parsedValue === 0) return 'oldGray'
  if (parsedValue < 2.5) return 'red'
  if (parsedValue <= 5) return 'orange'
  if (parsedValue <= 7.5) return 'yellow'
  return 'green'
}

const intentParser = (categoryId, company, location) => {
  let result = {}
  switch (categoryId) {
    case 1:
      result = {
        title: 'VP of Product in San Francisco',
        reward:
          '1st month’s salary paid within a year after an introduced candidate is successfully hired',
        expiryDate: 'Default: 30 days',
        company,
        sharedWith: 'semi-private',
        sharePermission: 'yes-trackable',
        broadcast: 'yes',
        content:
          '## About \\[company name\\]\n\nAdd a short brief about the company here\n\n\\\n## What the ideal candidate looks like\n\nAdd here a description or bulleted list of\n\nE.g.\n\n* 3 years of working experience with Python Django framework\n\n  \\\n\n## What they’ll be doing\n\nAdd here a description or bulleted list of your expectations from the candidate.\n\n\\\n## Work logistics\n\n* **HQ Location:** E.g. San Francisco\n* **Remote Preference:** Fully remote / Hybrid / Office\n* **Annual Compensation:** $50-$100k + 0.2% equity\n* **Work authorization requirement:** authorized to work in the US\n* **Commitment:** E.g. Full-time, Part-time, Freelance\n* **Seniority Level:** Mid-career\n\n  \\\n\n## What it’s like to work at X\n\nIf applicable, add any benefits here, like WFH budget, relocation benefits, health insurance etc.\n\nIf applicable, add details about the work culture to make the job more exciting to candidates.\n\n\\\n\\\n',
      }
      break
    case 2:
      result = {
        title: company?.title,
        reward:
          '1st month’s salary paid within a year after a job referral leads to getting hired.',
        expiryDate: 'Default: 30 days',
        company: null,
        sharedWith: 'private', //excluding coworkers is set up on backend.
        sharePermission: 'yes-trackable',
        broadcast: 'yes',
        content:
          '## Bio\n\nAdd here a short summary about you…\n\n## Ideal role & space\n\nAdd here a description or a few bullets of what you’d like to do in your new role.\n\nE.g. I’d like to work as a software engineer primarily but would prefer a broader role where i can wear multiple hats, build empathy with users and have a stronger say on our roadmap.\n\n## Key areas of expertise\n\nE.g.\n\n* Product management (pretty good at it - 5 yrs experience)\n* Data Science (familiar, but would love to get deeper)\n\n## Logistical preferences\n\n1. **Remote preference:** e.g. fully remote\n2. **Authorized to work in:** eg. can work in the US or Canada\n3. **Currently located in:** e.g. Istanbul, Turkey\n4. **Plan to relocate to (if applicable):** e.g. Germany\n\n## Companies I like\n\nAdd here a bullet list of companies your network can anchor to when making recommendations.\n\nIf it’s a company you actively want a referral to, feel free to mention that.\n\n## Social profiles\n\nAdd any relevant profile links that allows others to see more of your previous work. E.g. LinkedIn profile, Github, Behance, etc.\n\n\\\n',
      }
      break
    case 3:
      result = {
        title: `${company?.name} Seed Round`,
        reward: '2% finder’s fee for amount invested after closing',
        expiryDate: 'Default: 30 days',
        company,
        sharedWith: 'private',
        sharePermission: 'yes-trackable',
        broadcast: 'yes',
        content:
          '## The Team\n\nAdd bios and links of founders and any key team members here.\n\n*E.g.*\n\n* ***Marc Horowitz***\\*, CEO at Pied Piper: 2x founder and ex-Intuit\\*\n\n  *(LinkedIn: xxx)*\n\n## Company Overview\n\nE.g. Pied Piper is the fastest compression technology for streaming services.\n\n\\\n++Product Demo:++ E.g. link a loom demo, or share website and credentials to use.\n\n++Space:++ E.g. fintech, marketplaces, enterprise software, B2B SaaS, social network\n\n++Business model:++ E.g. We haven’t monetized yet, but we aim to monetize as a SaaS with tiers starting $100/month\n\n++Market:++ E.g. $10bn market with a monthly user fee to streaming services, ISPs, and VPN services.\n\n## Traction to-date\n\nIf relevant, add any details about where you are.\n\n## Planned milestones\n\nE.g. expand to major cities in California\n\n## Funding ask\n\nE.g. We’re looking to raise a $2m-$3m SAFE for our seed round\n\n## Pitch Deck\n\nAdd a link to the pitch deck if you have one here.\n\n\\\n',
      }
      break

    case 20:
      result = {
        title: 'Investing in B2B SaaS in SF Bay Area',
        reward: '2% finder’s fee for amount raised after closing ',
        expiryDate: 'Default: 30 days',
        company,
        sharedWith: 'private',
        sharePermission: 'yes-publicly',
        broadcast: 'yes',
        content:
          '## \\[VC Firm Name\\] Overview\n\n*Add a brief about the firm here. E.g.* we’re a $200m early stage fund investing in companies based in San Francisco and New York.\n\n## Team\n\nE.g.\n\n* ***Marc Horowitz*** *(Partner): 2x founder and ex-FANG operator*\n\n  *(LinkedIn profile: xxx)*\n\n## Companies we like to invest in\n\n*Add a description or bullets of what makes a good investment and in which spaces.*\n\n## How we invest\n\n*Add a high level description here if needed. E.g. We’re typically the first check in and follow-on with our other funds.* \n\n\\\n++Funding stage:++ *E.g. Pre-seed and Seed*\n\n++Investment range:++ *E.g. $500k-$5m* \n\n++Sweet spot:++ *E.g. $1m*\n\n++Lead investor:++ Yes/No\n\n\\\n',
      }
      break

    case 12:
      result = {
        title: 'Retail Franchise Opportunity in Nigeria',
        reward: '',
        expiryDate: 'Default: 30 days',
        company,
        sharedWith: 'semi-private',
        sharePermission: 'yes-publicly',
        broadcast: 'yes',
        content:
          '## Partnership overview\n\n*E.g. We’re looking to partner with CRM companies on our complimentary SaaS product*\n\n## Ideal fit\n\n*E.g. Add what qualifies a good partner*\n\n## Example companies we’d like to partner with\n\n*E.g.*\n\n* *HubSpot (if you know anyone please intro us!)*\n* *Pipedrive*\n\n## Benefits for partners\n\n*E.g. revenue sharing on all users acquired through their website plugin.*',
      }
      break
    case 14:
      result = {
        title: 'Clubhouse speakers for a fintech room',
        reward: '',
        expiryDate: 'Default: 30 days',
        company,
        sharedWith: 'semi-private',
        sharePermission: 'yes-publicly',
        broadcast: 'yes',
        content:
          '## Project overview\n\n*Add a description about the project you want to collaborate on*\n\n## Ideal candidates\n\n*Add a description or bulleted list of who you’d like to collaborate with so your network can help you*\n\n## Required commitment\n\n*E.g. is this an hourly gig?*\n\n## What’s in it for you\n\nE.g. if this is a public speaking collaboration with an opportunity for exposure, you can mention it here',
      }
      break
    case 16:
      result = {
        title: 'Recruiters who use SalesForce',
        reward: '80% discount for 12 months',
        expiryDate: ' Default: 30 days',
        company,
        sharedWith: 'semi-private',
        sharePermission: 'yes-publicly',
        broadcast: 'yes',
        content:
          '## Company brief\n\n*Add a description here about what your company does*\n\n## Ideal customers we’re looking for\n\n*Add a description or bulleted list here*\n\n## Why it matters to them?\n\n*Add a description or bulleted list here. E.g. you can mention your key differentiators*\n\n## What do they get for being an early customer\n\n*E.g. We’re offering a lifetime subscription to early users.*',
      }
      break
  }

  return result
}

export {
  etaParser,
  reasonParser,
  concatPeople,
  htmlParser,
  urlMatcher,
  parseStrengthColor,
  intentParser,
}

import {
  Box,
  Button,
  Collapse,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import TripleButtonGroup from 'components/TripleButtonGroup'
import api from 'services/api'

const feedbackParser = {
  accepted: {
    label: 'Accept',
    color: 'green',
  },
  denied: {
    label: 'Not sure this is a fit',
    color: 'red',
  },
  unknown: {
    label: 'I don’t know the person',
    color: 'purple',
  },
}

export default function FeedbackButtons({
  intro,
  token,
  feedback,
  comment,
  updateIntro,
}) {
  const toast = useToast()

  const { intro_id, intro_name } = intro

  const handleUpdateFeedback = async (feedback) => {
    try {
      await api.patch(`/intro/${intro_id}?token=${token}`, {
        introducer_feedback: feedback,
      })

      toast({
        title: 'Feedback saved',
        description: 'Your feedback has been saved',
        status: 'success',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSaveComment = async () => {
    try {
      await api.patch(`/intro/${intro_id}?token=${token}`, {
        introducer_comment: comment,
      })

      toast({
        title: 'Comment saved',
        description: 'Your comment has been saved',
        status: 'success',
      })
    } catch (error) {
      console.log(error)
    }
  }

  if (['pending', 'completed', 'canceled'].includes(feedback)) {
    return (
      <Box
        d="flex"
        flexDir={['column', 'row']}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Collapse in={feedback === 'pending'}>
          <Button
            variant="village"
            size="md"
            onClick={() => {
              updateIntro({
                value: 'completed',
                intro: intro,
                key: 'introducer_feedback',
              })
              handleUpdateFeedback('completed')
            }}
            isDisabled={feedback === 'completed'}
            w={['100%', 'auto']}
            mr={[0, 2]}
            mb={[2, 0]}
          >
            I've sent the Forwardable
          </Button>
        </Collapse>
      </Box>
    )
  }

  return (
    <>
      <Collapse in={!feedback}>
        <TripleButtonGroup
          mainButton={{
            label: `Accept${feedback === 'accepted' ? 'ed' : ''}`,
            onClick: () => {
              updateIntro({
                value: 'accepted',
                intro: intro,
                key: 'introducer_feedback',
              })
              handleUpdateFeedback('accepted')
            },
            isDisabled: feedback === 'accepted',
          }}
          secondaryButton={{
            label: 'Not sure this is a fit',
            onClick: () => {
              updateIntro({
                value: 'denied',
                intro: intro,
                key: 'introducer_feedback',
              })
              handleUpdateFeedback('denied')
            },
            isDisabled: feedback === 'denied',
          }}
          linkButton={{
            label: `I don’t know ${intro_name.split(' ')[0]}`,
            onClick: () => {
              updateIntro({
                value: 'unknown',
                intro: intro,
                key: 'introducer_feedback',
              })
              handleUpdateFeedback('unknown')
            },
            isDisabled: feedback === 'unknown',
          }}
        />
      </Collapse>

      <Collapse in={feedback}>
        <Box
          d="flex"
          flexDir={['column', 'row']}
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
        >
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                {' '}
                <PopoverTrigger>
                  <Button
                    variant="villageOutline"
                    size="md"
                    w={['100%', 'auto']}
                  >
                    Add a comment
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader borderBottom="none">
                      Do you want to add a comment?
                    </PopoverHeader>
                    <PopoverBody>
                      <Textarea
                        value={comment}
                        onChange={(e) => {
                          updateIntro({
                            value: e.target.value,
                            intro: intro,
                            key: 'introducer_comment',
                          })
                        }}
                      />
                    </PopoverBody>
                    <PopoverFooter
                      borderTop="none"
                      d="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          handleSaveComment()
                          onClose()
                        }}
                        size="sm"
                      >
                        Save comment
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        </Box>
        <Box textAlign="right">
          <Button
            variant="link"
            size="xs"
            onClick={() => {
              updateIntro({
                value: null,
                intro: intro,
                key: 'introducer_feedback',
              })
              handleUpdateFeedback(null)
            }}
            mr={[0, 3]}
            mb={[2, 0]}
            textDecor="underline"
            w={['100%', 'auto']}
            fontWeight="normal"
            color="grayAlpha.5"
          >
            Clear feedback
          </Button>
        </Box>
      </Collapse>
    </>
  )
}

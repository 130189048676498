import { chakra, useColorModeValue as mode } from '@chakra-ui/react'

const text = {
  accepted: 'Accepted',
  denied: 'Not a fit',
  canceled: 'Canceled',
  unknown: `Doesn't know`,
  pending: `Forwardable sent, waiting for intro`,
  completed: `Completed`,
}

export default function FeedbackBadge({ feedback, introName }) {
  if (!feedback) {
    return (
      <chakra.span fontSize="xs" fontStyle="italic">
        No feedback yet
      </chakra.span>
    )
  }

  const colors = {
    accepted: mode('green.600', 'green.200'),
    completed: mode('green.600', 'green.200'),
    pending: mode('yellow.600', 'yellow.200'),
    denied: mode('red.600', 'red.200'),
    canceled: mode('red.600', 'red.200'),
    unknown: mode('red.600', 'red.200'),
  }

  return (
    <chakra.span fontSize="xs" color={colors[feedback]}>
      {text[feedback]}
      {feedback === 'unknown' && ` ${introName}`}
    </chakra.span>
  )
}

import React, { useState } from 'react'
import {
  Box,
  Flex,
  Container,
  useColorModeValue as mode,
  useDisclosure,
  Grid,
  Button,
  Text,
} from '@chakra-ui/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { OpportunityCard } from 'components/OpportunityMarketplace/OpportunityCard'
import { PostedByList } from 'components/OpportunityMarketplace/PostedByList/PostedByList'
import { OpportunityType } from 'components/OpportunityMarketplace/OpportunityType'
import { FaPlusCircle } from 'react-icons/fa'
import { PostOpportunityModal } from 'components/OpportunityMarketplace/PostOpportunityModal'
import { PostedOpportunity } from 'components/OpportunityMarketplace/PostedOpportunity'
import { Sidebar } from 'components/NewUI/Sidebar'
import { SectionHeader } from 'components/NewUI/SectionHeader'
import { SectionSubtitle } from 'components/NewUI/SectionSubtitle'
import Skeleton from 'components/Skeleton'

export const OpportunityMarketplaceView = ({
  opportunities,
  ownOpportunities,
  setOwnOpportunities,
  changeFilter,
  categoryId,
  setCategoryId,
  opportunityTypes,
  page,
  setPage,
  hasMore,
  fetchMore,
  listId,
  setListId,
  filterLoading,
  scrollRef,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [activeOpportunityId, setActiveOpportuntiyId] = useState(null)

  const renderOpportunities = () => {
    if (Array.isArray(opportunities)) {
      if (opportunities.length === 0 && !filterLoading) {
        return 'There are no opportunities at the moment'
      }

      return opportunities.map((opportunity) => (
        <OpportunityCard key={opportunity.id} opportunity={opportunity} />
      ))
    }
  }

  const renderOwnOpportunities = () => {
    if (Array.isArray(ownOpportunities)) {
      return ownOpportunities.map((opportunity) => (
        <PostedOpportunity
          display={!activeOpportunityId ? 'block' : 'none'}
          isOpen={activeOpportunityId === opportunity.id}
          setActiveOpportuntiyId={setActiveOpportuntiyId}
          key={opportunity.id}
          ownOpportunities={ownOpportunities}
          setOwnOpportunities={setOwnOpportunities}
          opportunity={opportunity}
        />
      ))
    }
  }

  return (
    <Container maxW="1130px">
      <Box pb="1">
        <Flex sx={{ gap: 40 }}>
          <Sidebar>
            <OpportunityType
              changeFilter={changeFilter}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              opportunityTypes={opportunityTypes}
            />
            <PostedByList
              listId={listId}
              setListId={setListId}
              changeFilter={changeFilter}
            />
          </Sidebar>
          <Box ref={scrollRef} mt="12" pb="15" w="100%" maxW="770px">
            <InfiniteScroll
              dataLength={opportunities.length} //This is important field to render the next data
              next={() => {
                setPage(page + 1)
                fetchMore(page + 1)
              }}
              hasMore={hasMore}
              loader={
                <Skeleton
                  my="6"
                  borderRadius="base"
                  h="276px"
                  startColor={mode('gray.100', 'grayAlpha.50')}
                  endColor={mode('gray.500', 'grayAlpha.500')}
                />
              }
              endMessage={
                opportunities.length > 0 && (
                  <Text>
                    Couldn't find more opportunities for you. Please come back
                    later.
                  </Text>
                )
              }
            >
              <Box mb="7" lineHeight={1.1}>
                <SectionHeader label="My private opportunities" />
                <SectionSubtitle label="Post private opportunities with the perfect balance of confidentiality & controlled virality." />
              </Box>
              <Grid
                gap="4"
                gridTemplateColumns={[
                  '1',
                  '1',
                  'repeat(2,1fr)',
                  'repeat(3,212px)',
                ]}
                mb="12"
              >
                {!isOpen && renderOwnOpportunities()}
                <Button
                  onClick={() => onOpen()}
                  w="125px"
                  h="98px"
                  borderRadius="base"
                  borderWidth={2}
                  borderColor={mode('gray.1', 'grayAlpha.500')}
                  p="2.5"
                  bg="transparent"
                >
                  <FaPlusCircle color={mode('#C6C6C6', 'white')} size={52} />
                </Button>
              </Grid>
              <Box mb="7" lineHeight={1.1}>
                <SectionHeader label="Private opportunities you have access to" />
                <SectionSubtitle label="Help the people you care about fill opportunities and get rewarded." />
              </Box>

              {filterLoading ? (
                <Skeleton
                  my="6"
                  borderRadius="base"
                  h="276px"
                  startColor={mode('gray.100', 'grayAlpha.50')}
                  endColor={mode('gray.500', 'grayAlpha.500')}
                />
              ) : (
                !isOpen && !activeOpportunityId && renderOpportunities()
              )}
              <PostOpportunityModal
                ownOpportunities={ownOpportunities}
                setOwnOpportunities={setOwnOpportunities}
                isOpen={isOpen}
                onClose={onClose}
              />
            </InfiniteScroll>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

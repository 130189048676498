import {
  Avatar,
  Box,
  Flex,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import JobHistoryPopover from './JobHistoryPopover'
import { ProfileTitle } from './ProfileTitle'
import Skeleton from './Skeleton'

export const HorizontalProfile = ({ entity, isLoading, ...styleProps }) => {
  const { name, title, subtitle, avatar, ...entityProps } = entity

  return (
    <Box {...styleProps}>
      <Flex align="center" w="100%">
        <Avatar src={avatar} size="sm" w={8} mr={2} />
        <Box w="90%">
          <Skeleton isLoaded={!isLoading}>
            <ProfileTitle name={name} fontWeight="bold" {...entityProps} />
          </Skeleton>
          <Skeleton isLoaded={!isLoading}>
            {entityProps.identityId ? (
              <JobHistoryPopover identity_id={entityProps.identityId}>
                <Text fontSize="sm" lineHeight="shorter" isTruncated>
                  {title}
                </Text>
              </JobHistoryPopover>
            ) : (
              <Text fontSize="sm" lineHeight="shorter" isTruncated>
                {title}
              </Text>
            )}
          </Skeleton>
        </Box>
      </Flex>
      {subtitle && (
        <Skeleton isLoaded={!isLoading}>
          <Text
            fontSize="sm"
            lineHeight="shorter"
            ml={10}
            mt={1}
            color={mode('gray.600', 'grayAlpha.5')}
          >
            {subtitle}
          </Text>
        </Skeleton>
      )}
    </Box>
  )
}

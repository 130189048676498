import '@fontsource/inter/400.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/900.css'
import 'react-datepicker/dist/react-datepicker.css'

import 'focus-visible/dist/focus-visible'

import { ChakraProvider } from '@chakra-ui/react'
import theme from 'styles/theme'

import { AuthProvider } from 'context/AuthContext'
import { KanbanProvider } from 'context/KanbanContext'

import Routes from 'routes'
import { SelectedProvider } from 'context/SelectedContext'
import { Global, css } from '@emotion/react'

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <AuthProvider>
        <SelectedProvider>
          <KanbanProvider>
            <Routes />
          </KanbanProvider>
        </SelectedProvider>
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App

import React from 'react'
import {
  Container,
  Box,
  SkeletonText,
  useColorModeValue as mode,
  Flex,
  Skeleton,
  Grid,
} from '@chakra-ui/react'

export const OpportunitySkeleton = () => {
  return (
    <Container maxW="1130px">
      <Box /*px="18px" */ pyb="7px">
        <Flex sx={{ gap: '30px' }} justifyContent="space-between">
          <Box
            w="315px"
            display={['none', 'none', 'block', 'block']}
            h="100vh"
            position="sticky"
            top="0"
          >
            <Skeleton
              w="315px"
              mt="25px"
              borderRadius="6px"
              h="293px"
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
            />
            <Skeleton
              w="315px"
              mt="25px"
              borderRadius="6px"
              h="293px"
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
            />
          </Box>
          <Box mt="24px" w="100%">
            <Flex alignItems="center" mb="29px" lineHeight={1.1}>
              <Box w="100%">
                <SkeletonText
                  w="25%"
                  flex="1"
                  startColor={mode('gray.100', 'grayAlpha.50')}
                  endColor={mode('gray.500', 'grayAlpha.500')}
                  noOfLines={1}
                />
                <SkeletonText
                  mt="4"
                  w="60%"
                  flex="1"
                  startColor={mode('gray.100', 'grayAlpha.50')}
                  endColor={mode('gray.500', 'grayAlpha.500')}
                  noOfLines={1}
                />
              </Box>
            </Flex>
            <Grid
              gap={17}
              gridTemplateColumns={[
                '1',
                '1',
                'repeat(2,297px)',
                'repeat(3,297px)',
              ]}
              mt="25px"
              mb="48px"
            >
              {[1, 2].map((item, index) => (
                <Skeleton
                  borderRadius="6px"
                  maxW="296px"
                  h="119px"
                  startColor={mode('gray.100', 'grayAlpha.50')}
                  endColor={mode('gray.500', 'grayAlpha.500')}
                />
              ))}
              <Skeleton
                borderRadius="6px"
                w="119px"
                h="119px"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
              />
            </Grid>
            <Box mb="33px" lineHeight={1.1}>
              <SkeletonText
                w="35%"
                flex="1"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
                noOfLines={1}
              />
              <SkeletonText
                w="60%"
                flex="1"
                mt="4"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
                noOfLines={1}
              />
              <Skeleton
                mt="6"
                borderRadius="6px"
                w={['100%', '100%', '95%', '90%']}
                maxW="1012px"
                h="345px"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

import { Avatar, Button, Flex } from '@chakra-ui/react'
import { HorizontalProfile } from './HorizontalProfile'
import { ProfileTitle } from './ProfileTitle'
import RelationshipStrength from './RelationshipStrength'

export const CuratedItem = ({ entity, isLoading }) => {
  const { warmth_score, connector, ...entityRest } = entity

  return (
    <Flex align={'center'}>
      <HorizontalProfile entity={entityRest} isLoading={isLoading} w="45%" />

      <Flex w="20%" justify="center" align="center">
        <RelationshipStrength relationship={{ warmth_score: warmth_score }} />
        <Avatar
          name={connector.name}
          src={connector.avatar}
          size="sm"
          left="-5px"
        />
      </Flex>

      <Flex w="35%">
        <Button
          as={ProfileTitle}
          variant="villageOutline"
          ml="auto"
          _hover={{
            textDecoration: 'none',
          }}
          isDisabled={isLoading}
          {...entityRest}
          name="Get intro"
        >
          Get intro
        </Button>
      </Flex>
    </Flex>
  )
}

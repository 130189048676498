import { Badge, Flex, Text, Stack, useColorModeValue } from '@chakra-ui/react'
import Link from 'components/Link'

const MobileNavItem = ({ label, href, isBeta }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        to={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} color={useColorModeValue('black', 'gray.200')}>
          {label}
          {isBeta && (
            <Badge
              bg="black"
              color="white"
              borderRadius="full"
              fontSize="x-small"
              ml={2}
            >
              BETA
            </Badge>
          )}
        </Text>
      </Flex>
    </Stack>
  )
}

export default function MobileNav({ navItems }) {
  return (
    <Stack
      bg={useColorModeValue('white', 'black')}
      p={4}
      display={{ md: 'none' }}
    >
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  useColorModeValue as mode,
  Flex,
  Text,
  Button,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'
import Typeahead from 'components/Typeahead'
import Selected from 'components/RequestIntro/Selected'
import { SelectedUser } from './SelectedUser'
import { reasonParser } from 'utils/parsers'
import { useToast } from 'hooks/useToast'
import { NewProfileModal } from './NewProfileModal'

export const RecommendationModal = ({
  isOpen,
  onClose,
  handleSearchOptions,
  options,
  loading,
  searchLoading,
  handleSubmit,
}) => {
  const [step, setStep] = useState(1)

  const [selected, setSelected] = useState([])

  const [reason, setReason] = useState([])

  const isFinalStepAllowed = !reason.some((r) => !reasonParser(r))

  const toast = useToast()

  const [sharedInputValue, setSharedInputValue] = useState('')

  const renderCompanyInfo = (option) => {
    const { employee_size, website } = option

    let result = ''

    if (!employee_size && !website) return result

    if (employee_size) {
      if (employee_size > 1) {
        result = `${employee_size} employees`
      } else {
        result = `${employee_size} employee`
      }
    }

    if (website) {
      if (result.length > 1) {
        result += ' | ' + website
      } else {
        result = website
      }
    }

    return result
  }

  const {
    isOpen: isOpenNewProfileModal,
    onOpen: onOpenNewProfileModal,
    onClose: onCloseNewProfileModal,
  } = useDisclosure()

  const renderSecondaryAction = (inputValue, setInputValue) => {
    if (inputValue.length < 2) return null

    setSharedInputValue(inputValue)

    return (
      <Flex
        alignItems="center"
        onClick={() => onOpenNewProfileModal()}
        p={3}
        cursor="pointer"
        bg={mode('white', 'grayAlpha.700')}
        _hover={{
          bg: mode('white', 'grayAlpha.600'),
        }}
      >
        <Text as="span" fontWeight="400">
          Not found?{' '}
          <Text as="span" fontWeight="bold">
            Add {inputValue}
          </Text>
        </Text>
      </Flex>
    )
  }

  const isOptionSelected = (option) => {
    const isSelected = selected.find((selected) => selected.id === option.id)
    if (isSelected) {
      return true
    }

    return false
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          px="0"
          maxH="75vh"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${mode(
                'var(--chakra-colors-gray-200)',
                'var(--chakra-colors-grayAlpha-200)'
              )}`,
              borderRadius: '24px',
            },
          }}
          overflowX="hidden"
        >
          <Box minH="20vh" pt="5" px="5">
            <Text
              px="2"
              color={mode('black', 'white')}
              fontWeight="bold"
              fontSize="xl"
              mb="3"
            >
              Recommend person or company
            </Text>
            {step === 1 && (
              <Typeahead
                divideResults={[
                  { title: 'Company', entity: 'company' },
                  { title: 'People', entity: 'identity' },
                ]}
                isOptionSelected={isOptionSelected}
                selected={selected}
                setSelected={setSelected}
                isMultiSelect
                inputStyle={{
                  h: '80px',
                  bg: mode('gray.50', 'grayAlpha.800'),
                  p: 4,
                  paddingLeft: '32px',
                  borderRadius: 'md',
                  autoComplete: 'off',
                }}
                leftIcon={
                  searchLoading ? (
                    <Spinner
                      color={mode('gray.400', 'grayAlpha.50')}
                      w="15px"
                      h="15px"
                    />
                  ) : (
                    <FaSearch
                      color={mode('rgba(0, 0, 0, 0.24)', '#505050')}
                      size={15}
                    />
                  )
                }
                placeholder="Type a name, email, or linkedin profile URL"
                options={options.map((option) => ({
                  ...option,
                  name: option.fullName,
                  title: option.fullName,
                  subtitle: option.current_work_company_name
                    ? `${option.current_work_position} at ${option.current_work_company_name}`
                    : option.headline
                    ? option.headline
                    : option.employee_size || option.website
                    ? renderCompanyInfo(option)
                    : null,
                  avatar: option.avatar ?? true,
                }))}
                chooseOption={(option) => {
                  if (!isOptionSelected(option)) {
                    setSelected([
                      ...selected,
                      {
                        id: option.id,
                        name: option.name,
                        id: option.id,
                        entityType: option.entity,
                        work: option.current_work_company_name
                          ? `${option.current_work_position} at ${option.current_work_company_name}`
                          : option.headline
                          ? option.headline
                          : null,
                        avatar: option.avatar ?? true,
                        strength: option.warmth_score,
                      },
                    ])
                    setReason([...reason, ''])
                    return
                  }
                  const filter = selected.filter(
                    (selectedOption) => selectedOption.id !== option.id
                  )
                  setSelected(filter)
                }}
                fetchOptions={handleSearchOptions}
                secondaryAction={renderSecondaryAction}
              />
            )}
            {step === 1 && (
              <>
                {selected.length > 0 && (
                  <Flex mt="8">
                    <Text>Selected:</Text>
                    <Flex flexWrap="wrap" ml="2" sx={{ gap: '8px' }}>
                      {selected.map((selectedOption, index) => (
                        <SelectedUser
                          name={selectedOption.name}
                          key={index}
                          onClick={() => {
                            const filter = selected.filter(
                              (selectedUser) =>
                                selectedUser.id !== selectedOption.id
                            )
                            setSelected(filter)
                          }}
                        />
                      ))}
                    </Flex>
                  </Flex>
                )}
              </>
            )}
            {step === 2 && (
              <Box px="4" pt="4" pb="10" bg={mode('gray.50', 'grayAlpha.800')}>
                {selected.map((select, index) => (
                  <Selected
                    key={select.id}
                    selected={select}
                    reason={reason[index]}
                    setReason={(inputValue) => {
                      const newReason = [...reason]
                      newReason[index] = inputValue
                      setReason(newReason)
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </ModalBody>
        <Flex
          py="4"
          px="6"
          pos={step === 2 && 'absolute'}
          bottom="0"
          bgGradient={mode(
            'linear(rgba(0,0,0,0.0),white, white, white)',
            'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
          )}
          w="100%"
          alignItems="center"
          justifyContent="flex-end"
        >
          {step === 2 && (
            <Button
              mt="4"
              variant="villageOutline"
              isLoading={loading}
              onClick={() => setStep(1)}
            >
              Back
            </Button>
          )}
          <Button
            mt="4"
            ml="2"
            variant="village"
            w="137px"
            isDisabled={step === 1 && selected.length === 0}
            color="white"
            isLoading={loading}
            onClick={() => {
              if (step === 1) {
                setStep(2)
                return
              }
              if (!isFinalStepAllowed) {
                toast({
                  title: 'Please fill in all the reasons',
                  status: 'error',
                })
                return
              }
              handleSubmit({ selected, reason })
              setSelected([])
              setReason([])
              setStep(1)
            }}
            h="10"
          >
            {step === 2 ? 'Submit' : 'Continue'}
          </Button>
          <NewProfileModal
            isOpen={isOpenNewProfileModal}
            onClose={onCloseNewProfileModal}
            inputValue={sharedInputValue}
            selected={selected}
            setSelected={setSelected}
            reason={reason}
            setReason={setReason}
          />
        </Flex>
      </ModalContent>
    </Modal>
  )
}

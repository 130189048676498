import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  useColorModeValue as mode,
  useDisclosure,
  Badge,
  Text,
} from '@chakra-ui/react'

import { useKanban } from 'context/KanbanContext'
import { useSelected } from 'context/SelectedContext'
import { useState } from 'react'
import { FaCheck, FaPlus } from 'react-icons/fa'
import NewBoardPopover from './NewBoardPopover'

export default function AddToBoard({
  isFloating,
  selected,
  hideIcon,
  textMl,
  identityId,
  ...props
}) {
  const {
    onOpen: onMenuOpen,
    isOpen: isMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure()
  const {
    onOpen: onFormOpen,
    isOpen: isFormOpen,
    onClose: onFormClose,
  } = useDisclosure()
  const { boards, onAddTask } = useKanban()
  const { setSelected } = useSelected()

  const [isSelectedAlreadySaved, setIsSelectedAlreadySaved] = useState(
    selected.length === 1 &&
      Object.values(boards)
        .reduce((acc, board) => {
          acc = [...acc, ...Object.values(board.tasks)]

          return acc
        }, [])
        .filter((task) => task.task_identity === selected[0].entity_id).length >
        0
  )

  return (
    <Menu
      isOpen={isMenuOpen}
      onOpen={onMenuOpen}
      onClose={() => {
        onMenuClose()
        onFormClose()
      }}
      closeOnSelect={false}
    >
      {isFloating ? (
        <MenuButton
          as={Button}
          leftIcon={<FaPlus color="white" />}
          variant="village"
          visibility={selected.length === 0 ? 'hidden' : 'visible'}
          pos={'fixed'}
          left={'3'}
          bottom={'3'}
          zIndex={'99999'}
          bg={mode('black', 'grayAlpha.700')}
          {...props}
        >
          Add to board
        </MenuButton>
      ) : (
        <MenuButton
          as={Button}
          leftIcon={
            hideIcon ? null : isSelectedAlreadySaved ? (
              <FaCheck color="white" w={3} h={3} />
            ) : (
              <FaPlus color="middle.500" w={3} h={3} />
            )
          }
          borderRadius="full"
          color={isSelectedAlreadySaved ? 'white' : mode('gray.500', 'white')}
          border="1px solid"
          borderColor={
            isSelectedAlreadySaved ? 'black' : mode('gray.500', 'white')
          }
          bg={isSelectedAlreadySaved && 'black'}
          _hover={{
            filter: 'opacity(0.8)',
          }}
          w="107px"
          {...props}
        >
          <Text ml={textMl ?? null}>
            Save{isSelectedAlreadySaved ? 'd' : ''}
          </Text>
        </MenuButton>
      )}

      <MenuList zIndex={100}>
        {boards && (
          <MenuGroup title="Existing boards">
            {Object.entries(boards).map(([boardName, board]) => {
              const doesBoardAlreadyContainSelected = isSelectedAlreadySaved
                ? Object.values(board.tasks).findIndex(
                    (t) =>
                      t.task_identity === selected[0]?.entity_id ||
                      t.task_identity === identityId
                  ) !== -1
                : false

              return (
                <MenuItem
                  onClick={
                    !doesBoardAlreadyContainSelected
                      ? () => {
                          setIsSelectedAlreadySaved(true)
                          console.log('selected', selected)
                          if (identityId) {
                            onAddTask(
                              [{ entity_id: identityId, entity: 'identity' }],
                              board.id
                            )
                          } else {
                            onAddTask(selected, board.id)
                          }
                          setSelected([])
                          onMenuClose()
                        }
                      : undefined
                  }
                  cursor={doesBoardAlreadyContainSelected && 'not-allowed'}
                  filter={doesBoardAlreadyContainSelected && 'opacity(0.5)'}
                >
                  {boardName}

                  {doesBoardAlreadyContainSelected && (
                    <Badge
                      bg="black"
                      color="white"
                      borderRadius="full"
                      fontSize="x-small"
                      ml={2}
                    >
                      SAVED
                    </Badge>
                  )}
                </MenuItem>
              )
            })}
          </MenuGroup>
        )}

        <MenuDivider />
        <MenuGroup title="Create board">
          <NewBoardPopover
            isOpen={isFormOpen}
            onOpen={onFormOpen}
            afterSubmit={(boardId) => {
              setIsSelectedAlreadySaved(true)
              onFormClose()
              onAddTask(selected, boardId)
              setSelected([])
              onMenuClose()
            }}
          >
            <MenuItem
              icon={<FaPlus />}
              px={3}
              py={2}
              _hover={{
                bg: 'gray.100',
              }}
            >
              New board
            </MenuItem>
          </NewBoardPopover>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

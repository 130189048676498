import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})
api.CancelToken = axios.CancelToken
api.isCancel = axios.isCancel

export default api

import React from 'react'
import { Text, useDisclosure } from '@chakra-ui/react'
import { ProfilePageModal } from './Profile/ProfilePageModal'
import { encryptId } from 'utils/encryption'

export const ProfileTitle = ({
  identityId,
  companySlug,
  companyId,
  name,
  ...textStyle
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const link = identityId
    ? `/people/profile/${encryptId(identityId)}`
    : companySlug
    ? `/company/profile/${companySlug}`
    : `/company/profile/${encryptId(companyId)}`

  const profileToVisit = identityId
    ? encryptId(identityId)
    : companySlug
    ? companySlug
    : encryptId(companyId)

  return (
    <>
      <Text
        as="a"
        href={link}
        onClick={(e) => {
          e.preventDefault()
          onOpen()
        }}
        _hover={{
          textDecoration: 'underline',
          textUnderlineOffset: '2px',
        }}
        {...textStyle}
      >
        {name}
      </Text>
      <ProfilePageModal
        isOpen={isOpen}
        onClose={onClose}
        UserProfile={identityId ?? false}
        profileToVisit={profileToVisit}
      />
    </>
  )
}

import {
  Avatar,
  Box,
  chakra,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FiTarget } from 'react-icons/fi'
import FeedbackButtons from './FeedbackButtons'

export default function IntroRequest({ intro, token, requester, ...props }) {
  const {
    intro_name,
    intro_avatar,
    intro_headline,
    intro_reason,
    introducer_feedback,
    introducer_comment,
  } = intro

  const isHeadlineTruncated = useBreakpointValue({ base: false, md: true })

  const feedbackParser = (feedback, data, name) => {
    if (feedback === 'accepted') {
      if (data === 'label')
        return `Awesome! We’ve let ${name} know so they can send you a forwardable email you can use for the intro.`
      if (data === 'boxColor') return mode('green.300', 'green.900')
      if (data === 'textColor') return mode('green.700', 'green.200')
      if (data === 'status') return 'Offered'
    }

    if (feedback === 'denied') {
      if (data === 'label')
        return `We’ll let ${name} know this is a not a good fit at this point`
      if (data === 'boxColor') return mode('red.300', 'red.900')
      if (data === 'textColor') return mode('red.700', 'red.200')
      if (data === 'status') return 'Declined'
    }

    if (feedback === 'unknown') {
      if (data === 'status') return 'Declined'
    }

    if (feedback === 'pending') {
      if (data === 'status') return 'Pending send forwardable'
    }
  }

  return (
    <Box
      bg={mode('gray.100', 'grayAlpha.700')}
      borderRadius="25px"
      mb={[8, 4]}
      {...props}
    >
      <Box
        py={4}
        d="flex"
        flexDir={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        filter={
          introducer_feedback &&
          introducer_feedback !== 'pending' &&
          'opacity(0.6)'
        }
      >
        <Box
          w={props.isIntroducer ? ['100%', '40%'] : ['100%', 'auto']}
          pl={props.isIntroducer ? [0, 12] : [2, 4]}
          pr={[2, 0]}
        >
          <Box
            d="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDir={['column', 'row']}
            mb={1}
          >
            <Box w="4rem" mr={[0, 2]} mb={[2, 0]}>
              <Avatar
                name={intro_name}
                size="lg"
                src={intro_avatar}
                bg={'primary.500'}
                color="white"
              />
            </Box>

            <Box
              d="flex"
              alignItems={['center', 'flex-start']}
              flexDir="column"
              textAlign={['center', 'left']}
              mb={[2, 0]}
            >
              <Text
                isTruncated
                maxW="70%"
                fontWeight="bold"
                fontSize="lg"
                minW="25ch"
              >
                {intro_name}
              </Text>
              <Text
                isTruncated={isHeadlineTruncated}
                maxW={props.isIntroducer ? '70%' : '45ch'}
                minW="25ch"
              >
                {intro_headline}
              </Text>
            </Box>
          </Box>

          <Box
            ml={[0, '4rem']}
            mb={[4, 0]}
            pl={[0, 2]}
            textAlign={['center', 'left']}
            d="flex"
            alignItems="center"
            justifyContent={['center', 'flex-start']}
          >
            <Text
              fontStyle={'italic'}
              fontSize="sm"
              isTruncated
              d="flex"
              alignItems="center"
            >
              <Icon as={FiTarget} mr={1} />{' '}
              <chakra.strong mr={1}>Reason to connect:</chakra.strong>{' '}
              {intro_reason}
            </Text>
          </Box>
        </Box>

        {props.isIntroducer && (
          <Box
            w={['100%', '20%']}
            textAlign="center"
            color={feedbackParser(introducer_feedback, 'textColor')}
          >
            {introducer_feedback
              ? feedbackParser(introducer_feedback, 'status')
              : 'Pending approval'}
          </Box>
        )}

        <Box
          ml={['0', 'auto']}
          w={props.isIntroducer ? ['100%', '40%'] : ['100%', 'auto']}
          pl={[2, 0]}
          pr={[2, 4]}
        >
          <FeedbackButtons
            intro={intro}
            token={token}
            feedback={introducer_feedback}
            comment={introducer_comment}
            updateIntro={props.updateIntro}
          />
        </Box>
      </Box>
      {requester && (
        <Box
          py={1}
          px={4}
          pl={props.isIntroducer && 12}
          pb={props.isIntroducer && 4}
          bg={
            !props.isIntroducer &&
            (introducer_feedback === 'accepted' ||
              introducer_feedback === 'denied') &&
            feedbackParser(introducer_feedback, 'boxColor')
          }
          color={mode('grayAlpha.700', 'grayAlpha.0')}
          borderBottomLeftRadius="25px"
          borderBottomRightRadius="25px"
        >
          <Text fontSize="smaller">
            {introducer_feedback === 'accepted' ||
            introducer_feedback === 'denied'
              ? feedbackParser(
                  introducer_feedback,
                  'label',
                  requester?.firstname
                )
              : ''}
          </Text>
        </Box>
      )}
    </Box>
  )
}

import { Box, Text, useColorModeValue as mode } from '@chakra-ui/react'

export default function TimeSlot({ time }) {
  return (
    <Box
      px={4}
      py={2}
      borderRadius="25px"
      w="100%"
      h={12}
      d="flex"
      justifyContent="center"
      alignItems="center"
      bg={mode('white', 'grayAlpha.700')}
      mb={2}
      _hover={{ bg: mode('gray.50', 'grayAlpha.600') }}
      cursor="pointer"
    >
      <Text fontWeight="bold">{time}</Text>
    </Box>
  )
}

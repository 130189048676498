import { Box, IconButton } from '@chakra-ui/react'
import { useKanban } from 'context/KanbanContext'
import { Droppable } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { FaPlus } from 'react-icons/fa'

import Column from './Column'

export default function KanbanBoard({ data }) {
  const { onDragEnd, handleCreateColumn } = useKanban()
  // onDragUpdate

  return (
    /* onDragUpdate={onDragUpdate} */
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={data.id} type="board" direction="horizontal">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            d="flex"
            pos="relative"
            alignItems="flex-start"
          >
            {data?.columns &&
              data.columns.length &&
              data.columns.map((column, columnIndex) => {
                const tasks = column.taskIds.map((taskId) => data.tasks[taskId])

                return (
                  <Column
                    isOpportunity={column.isOpportunity}
                    key={column.id}
                    index={columnIndex}
                    column={column}
                    tasks={tasks}
                  />
                )
              })}

            <IconButton
              icon={<FaPlus />}
              size="sm"
              variant="solid"
              _focus={{
                boxShadow: 'none',
              }}
              onClick={() => handleCreateColumn(data.id)}
              pos="absolute"
              left={`calc((260px + var(--chakra-space-4)) * ${
                data.columns.filter((c) => c).length
              })`}
            />
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

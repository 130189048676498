import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { CompanyProfileViewContainer } from 'views/Profile/CompanyProfileViewContainer'
import { UserProfileViewContainer } from 'views/Profile/UserProfileViewContainer'

export const ProfilePageModal = ({
  isOpen,
  onClose,
  UserProfile,
  profileToVisit,
  boardIdToOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader py="0">
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          px="0"
          maxH="75vh"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          overflow
          overflowX="hidden"
        >
          {UserProfile ? (
            <UserProfileViewContainer
              boardIdToOpen={boardIdToOpen}
              profileToVisit={profileToVisit}
            />
          ) : (
            <CompanyProfileViewContainer
              boardIdToOpen={boardIdToOpen}
              profileToVisit={profileToVisit}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

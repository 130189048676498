import React from 'react'
import {
  Box,
  Avatar,
  Flex,
  Text,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import { FaPhoneAlt } from 'react-icons/fa'
import { AiFillMessage } from 'react-icons/ai'
import AddToBoard from 'components/KanbanBoard/AddToBoard'
import { useSelected } from 'context/SelectedContext'
import RelationshipConnector from 'components/RelationshipConnector'
import RelationshipStrength from 'components/RelationshipStrength'
import PathsModal from 'components/PathsModal'
import { useAuth } from 'context/AuthContext'
import CompanyPeople from 'components/CompanyPeople'
import CompanyModal from 'components/CompanyModal'

export const ProfileStats = ({
  helpfulness,
  introsBetween,
  inviterInfo,
  contactInfo,
  path,
  companyInfo,
  strongConnection,
  identityId,
}) => {
  const { user } = useAuth()

  const { selected } = useSelected()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const disableBoard = () => {
    if (user?.identityId === identityId) return true

    return false
  }

  const renderPath = () => {
    if (!path || !user) return null

    if (identityId === user?.identityId) return null

    const connectors =
      path.connectors && typeof path.connectors === 'string'
        ? JSON.parse(path.connectors)
        : []

    const avatars = path.avatars ? path.avatars.split(',') : []

    const isCompany = !identityId

    const ModalComponent = isCompany ? CompanyModal : PathsModal

    const PathComponent = isCompany ? CompanyPeople : RelationshipConnector

    return (
      <>
        <RelationshipStrength
          relationship={{ warmth_score: path?.warmth_score }}
          identities={{
            left_identity_id: path?.left_identity_id,
            right_identity_id: path?.right_identity_id,
          }}
        />
        <ModalComponent
          relationship={path}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          company={{ id: path?.company_id }}
          openPathsModal={onOpen}
        />
        <PathComponent
          avatars={avatars}
          total={path.total || 0}
          onOpen={onOpen}
          openPathsModal={onOpen}
          connectors={connectors}
        />
      </>
    )
  }

  const renderEmailMenu = () => {
    if (!contactInfo || contactInfo.account_identifier === null || !user)
      return null

    const emails = contactInfo.account_identifier
      .split(',')
      .filter((element) => element.includes('e:'))

    if (!emails.length) return null

    return (
      <Menu zIndex={5}>
        {({ isOpen }) => (
          <>
            <MenuButton
              w={['auto', 'auto', 'auto', 'auto']}
              h="44px"
              leftIcon={
                <AiFillMessage size="20px" color={mode('white', 'black')} />
              }
              variant="village-black"
              isActive={isOpen}
              as={Button}
            >
              <Text fontWeight="bold" color={mode('white', 'black')}>
                Send Email
              </Text>
            </MenuButton>
            <MenuList zIndex="5">
              {emails.map((email) => (
                <MenuItem
                  as="a"
                  href={`mailto:${email.substring(2, email.length)}`}
                >
                  {email.substring(2, email.length)}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  const renderPhoneMenu = () => {
    if (!contactInfo || contactInfo.account_identifier === null || !user)
      return null

    const phoneNumbers = contactInfo.account_identifier
      .split(',')
      .filter((element) => element.includes('p:'))

    if (!phoneNumbers.length) return null

    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              h="44px"
              leftIcon={
                <FaPhoneAlt size="20px" color={mode('white', 'black')} />
              }
              variant="village-black"
              isActive={isOpen}
              as={Button}
            >
              <Text fontWeight="bold" color={mode('white', 'black')}>
                Call
              </Text>
            </MenuButton>
            <MenuList zIndex="5" justifyContent="center">
              {phoneNumbers.map((number) => (
                <MenuItem
                  as="a"
                  href={`tel:${number.substring(2, number.length)}`}
                >
                  {number.substring(2, number.length)}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  const renderStrongConnections = () => {
    if (typeof strongConnection !== 'number' || !user) return null

    if (strongConnection === 0) return null

    return (
      <Box w={['100%', '100%', '23.5%', '23.5%']} lineHeight="1.25">
        <Text color="#A8A8A8" fontWeight="bold">
          {strongConnection > 2500 ? '2500+' : strongConnection}
        </Text>
        <Text color="#A8A8A8">strong connections</Text>
      </Box>
    )
  }

  const renderHelpfulness = () => {
    if (!helpfulness || !user) return null

    if (helpfulness?.givenIntros === 0) return null

    return (
      <Box
        w={['100%', '100%', '23.5%', '23.5%']}
        mt={['4', '4', '0']}
        lineHeight="1.25"
      >
        <Text color="#A8A8A8" fontWeight="bold">
          {helpfulness?.givenIntros}
        </Text>
        <Text color="#A8A8A8">Intros made</Text>
      </Box>
    )
  }

  const renderIntrosBetween = () => {
    if (!user || !introsBetween) return null

    if (identityId === user?.identityId) return null

    if (introsBetween?.given === 0 && introsBetween?.received === 0) return null

    return (
      <Box
        w={['100%', '100%', '23.5%', '23.5%']}
        mt={['4', '4', '0']}
        lineHeight="1.25"
      >
        <Text color="#A8A8A8" fontWeight="bold">
          {introsBetween.given} / {introsBetween.received}
        </Text>
        <Text color="#A8A8A8">Intros between you</Text>
      </Box>
    )
  }

  const renderInviterInfo = () => {
    if (!user || !inviterInfo) return null

    return (
      <Box
        w={['100%', '100%', '23.5%', '23.5%']}
        mt={['4', '4', '0']}
        lineHeight="1.25"
      >
        <Text color="#A8A8A8">invited by</Text>
        <Flex
          mt="0.5"
          justifyContent={['center', 'center', 'flex-start', 'flex-start']}
          alignItems="center"
        >
          <Avatar
            size="xs"
            name={`${inviterInfo?.firstname} ${inviterInfo?.lastname}`}
            src={inviterInfo?.avatar}
          />
          <Text ml="1.5" fontWeight="bold" color="#A8A8A8">
            {`${inviterInfo.firstname} ${inviterInfo.lastname} `}
          </Text>
        </Flex>
      </Box>
    )
  }

  const renderCompanyDescription = () => {
    if (!companyInfo) return null

    const payload = JSON.parse(companyInfo.payload)

    if (!payload) return null

    if ('description' in payload) {
      return (
        <Text fontSize="md">
          {payload.description || 'No description found.'}
        </Text>
      )
    }
  }

  return (
    <>
      <Flex
        direction={['column', 'column', 'row']}
        alignItems="center"
        textAlign={['center', 'center', 'left']}
        px="19px"
        sx={{ gap: 24 }}
        mt="5"
        css={{
          '& > *': {
            marginBottom: 'var(--chakra-space-6)',
          },
        }}
      >
        {renderStrongConnections()}
        {renderHelpfulness()}
        {renderIntrosBetween()}
        {renderInviterInfo()}
        {renderCompanyDescription()}
      </Flex>

      {user && (
        <Flex
          wrap="wrap"
          direction={['column', 'column', 'row']}
          w="100%"
          alignItems="center"
          borderRadius="25"
          sx={{ gap: 12 }}
        >
          {contactInfo && renderEmailMenu()}
          {contactInfo && renderPhoneMenu()}
          <AddToBoard
            textMl="0.5"
            identityId={identityId}
            hideIcon
            disabled={disableBoard()}
            borderWidth={2}
            borderRadius={50}
            h="44px"
            fontSize={16}
            selected={selected}
          />
          <Flex
            sx={{ gap: 10 }}
            ml={['0', '0', 'auto', 'auto']}
            alignItems="center"
          >
            {renderPath()}
          </Flex>
        </Flex>
      )}
    </>
  )
}

import React from 'react'
import {
  Box,
  Text,
  Container,
  Flex,
  AvatarGroup,
  useColorModeValue as mode,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react'

import Skeleton from 'components/Skeleton'

export const OpportunityViewSkeleton = () => {
  return (
    <Container maxW="1130px">
      <Box px="4" py="2">
        <Skeleton w="60%" mt="5" h="40px" />
        <Flex sx={{ gap: '7px' }} flexWrap="wrap" mt="10" alignItems="center">
          <Skeleton
            width="29px"
            height="29px"
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
          />
          <Skeleton
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
            w="20"
            h="3"
          />
          posted by
          <SkeletonCircle
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
            width="29px"
            height="29px"
          />
          <Skeleton
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
            w="28"
            h="3"
          />
        </Flex>
        <SkeletonText
          mt="4"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
          noOfLines={8}
        />
        <SkeletonText
          mt="8"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
          noOfLines={8}
        />
      </Box>
      <Box
        borderTopRadius="8px"
        position="fixed"
        zIndex={5}
        bottom="0"
        bg={mode('gray.20', 'grayAlpha.500')}
        p="6"
        w="100%"
        mx={['-4', '-4', '-4', '-4', 0]}
        maxW="1130px"
      >
        <Flex
          flexWrap="wrap"
          justifyContent={['center', 'center', 'space-between']}
          alignItems="center"
          sx={{ gap: 16 }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ gap: 16 }}
          >
            <AvatarGroup size="sm" max={3}>
              <SkeletonCircle
                mx="7px"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
                width="29px"
                height="29px"
              />
              <SkeletonCircle
                mx="7px"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
                width="29px"
                height="29px"
              />
              <SkeletonCircle
                mx="7px"
                startColor={mode('gray.100', 'grayAlpha.50')}
                endColor={mode('gray.500', 'grayAlpha.500')}
                width="29px"
                height="29px"
              />
            </AvatarGroup>
            <Skeleton
              w="260px"
              h="16px"
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
              noOfLines={1}
            />
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ gap: 7 }}
          >
            <Skeleton w="20" borderRadius="25px" p="1.25rem 0.75rem" />
            <Skeleton w="40" borderRadius="25px" p="1.25rem 0.75rem" />
            <Skeleton w="40" borderRadius="25px" p="1.25rem 0.75rem" />
          </Flex>
        </Flex>
      </Box>
    </Container>
  )
}

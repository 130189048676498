import {
  Button,
  chakra,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { EditorState, RichUtils } from 'draft-js'
import { useRef, useState } from 'react'
import { FaLink, FaUnlink } from 'react-icons/fa'

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

export const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <chakra.a href={url} color="blue.500" textDecor="underline">
      {props.children}
    </chakra.a>
  )
}

export default function InlineLinkControl({ editorState, setEditorState }) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const initialFocusRef = useRef()

  const [link, setLink] = useState('')

  const promptForLink = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
      let url = `https://`
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }
      onOpen()
      setLink(url)
    }
  }

  const confirmLink = (e) => {
    e.preventDefault()

    const contentState = editorState.getCurrentContent()

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    // Apply entity
    let nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })

    // Apply selection
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey
    )

    setEditorState(nextEditorState)
    onClose()
  }

  const removeLink = (e) => {
    e.preventDefault()
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null))
    }
  }

  return (
    <>
      <Popover
        initialFocusRef={initialFocusRef}
        isOpen={isOpen}
        onOpen={promptForLink}
        onClose={onClose}
      >
        <PopoverTrigger>
          <IconButton
            icon={<Icon as={FaLink} />}
            size="xs"
            variant="ghost"
            className={`RichEditor-styleButton${
              isOpen ? ' RichEditor-activeButton' : ''
            }`}
          >
            Link
          </IconButton>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody as="form" onSubmit={confirmLink}>
            <Input
              value={link}
              onChange={(e) => setLink(e.target.value)}
              mb={1}
              ref={initialFocusRef}
              border="none"
              _focus={{
                border: 'none',
              }}
            />

            <Button variant="village" w="100%" type="submit">
              Save link
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <IconButton
        icon={<Icon as={FaUnlink} />}
        size="xs"
        variant="ghost"
        onClick={removeLink}
      >
        Link
      </IconButton>
    </>
  )
}

import { ViewContainer } from 'components/ViewContainer'
import { useAuth } from 'context/AuthContext'
import { OpportunityProvider } from 'context/OpportunityContext'
import React from 'react'
import { BsBuilding, BsPeople } from 'react-icons/bs'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { CommunitiesView } from 'views/Communities/CommunitiesView'
import Connection from 'views/Connection'
import Dashboard from 'views/Dashboard'
import Demo from 'views/Demo'
import FakeLogin from 'views/FakeLogin'
import IntrosRequestedFromMe from 'views/Intros/IntrosRequestedFromMe'
import MyIntroRequestsToOthers from 'views/Intros/MyIntroRequestsToOthers'
import Login from 'views/Login'
import Meeting from 'views/Meeting'
import MyBoards from 'views/MyBoards'
import Companies from 'views/Network/Companies'
import People from 'views/Network/People'
import { OpportunityViewContainer } from 'views/Opportunity/OpportunityViewContainer'
import { OpportunityMarketplaceViewContainer } from 'views/OpportunityMarketplace/OpportunityMarketplaceViewContainer'
import Privacy from 'views/Privacy'
import PrivateAccess from 'views/PrivateAccess'
import { CompanyProfileViewContainer } from 'views/Profile/CompanyProfileViewContainer'
import { UserProfileViewContainer } from 'views/Profile/UserProfileViewContainer'
import Register from 'views/Register'
import Request from 'views/Request'
import Schedule from 'views/Schedule'
import Settings from 'views/Settings'
import Terms from 'views/Terms'
import { OpportunityTrackingViewContainer } from 'views/Tracking/Opportunities/OpportunityTrackingViewContainer'
import LandingRoute from './LandingRoute'
import OpenRoute from './OpenRoute'
import ProtectedRoute from './ProtectedRoute'

// comment

const networkPages = [
  {
    component: <People />,
    name: 'People',
    path: '/people',
    icon: <BsPeople />,
  },
  {
    component: <Companies />,
    name: 'Companies',
    path: '/companies',
    icon: <BsBuilding />,
  },
  // {
  //   component: <Jobs />,
  //   name: 'Jobs',
  //   path: '/jobs',
  // },
]

const introPages = [
  {
    component: <MyIntroRequestsToOthers />,
    name: 'My requests',
    path: '/intros/outgoing',
  },
  {
    component: <IntrosRequestedFromMe />,
    name: 'Requested from me',
    path: '/intros/incoming',
  },
]

export default function Routes() {
  const { user } = useAuth()

  const ProfileRoute = user ? ProtectedRoute : LandingRoute

  return (
    <Router>
      <Switch>
        {/* <Route path="*">
          <Maintenance />
        </Route> */}

        <OpenRoute exact path="/">
          <PrivateAccess />
        </OpenRoute>

        <OpenRoute exact path="/privateaccess">
          <PrivateAccess />
        </OpenRoute>

        <OpenRoute exact path="/login">
          <Login />
        </OpenRoute>

        <OpenRoute exact path="/privacy">
          <Privacy />
        </OpenRoute>

        <OpenRoute exact path="/terms">
          <Terms />
        </OpenRoute>

        <OpenRoute exact path="/fakelogin">
          <FakeLogin />
        </OpenRoute>

        <OpenRoute exact path="/demo">
          <Demo />
        </OpenRoute>

        <OpenRoute exact path="/register">
          <Register />
        </OpenRoute>

        <LandingRoute exact path="/request" isProtected>
          <Request />
        </LandingRoute>

        <LandingRoute exact path="/meeting" isProtected>
          <Meeting />
        </LandingRoute>

        <LandingRoute exact path="/schedule">
          <Schedule />
        </LandingRoute>

        {networkPages.map(({ component, path }, index, arr) => (
          <ProtectedRoute exact path={path} key={`network-page-${index}`}>
            <ViewContainer links={arr}>{component}</ViewContainer>
          </ProtectedRoute>
        ))}

        {introPages.map(({ component, path }, index, arr) => (
          <ProtectedRoute exact path={path} key={`intros-page-${index}`}>
            <ViewContainer links={arr}>{component}</ViewContainer>
          </ProtectedRoute>
        ))}

        <ProtectedRoute exact path="/dashboard">
          <Dashboard />
        </ProtectedRoute>

        <ProtectedRoute exact path="/myboards">
          <MyBoards />
        </ProtectedRoute>

        <ProtectedRoute exact path="/connection/:id">
          <Connection />
        </ProtectedRoute>

        <ProtectedRoute exact path="/settings">
          <Settings />
        </ProtectedRoute>

        <ProtectedRoute exact path="/opportunities/:category?/:list?">
          <OpportunityProvider>
            <OpportunityMarketplaceViewContainer />
          </OpportunityProvider>
        </ProtectedRoute>

        <ProtectedRoute exact path="/tracking/opportunities/:id">
          <OpportunityTrackingViewContainer />
        </ProtectedRoute>

        <ProfileRoute isProtectedLayout exact path="/people/profile/:id">
          <UserProfileViewContainer />
        </ProfileRoute>

        <ProfileRoute isProtectedLayout exact path="/company/profile/:id">
          <CompanyProfileViewContainer />
        </ProfileRoute>

        <ProfileRoute isProtectedLayout exact path="/opportunity/:id/:token?">
          <OpportunityViewContainer />
        </ProfileRoute>

        <LandingRoute exact path="/communities">
          <CommunitiesView />
        </LandingRoute>

        <Route exact path="/intros">
          <Redirect to="/intros/outgoing" />
        </Route>

        <Route path="/network">
          <Redirect to="/people" />
        </Route>

        <Route path="*">
          <p>404</p>
        </Route>
      </Switch>
    </Router>
  )
}

import { findLinkEntities, Link } from 'components/RichTextEditor/Link'
import { CompositeDecorator, ContentState, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { useState } from 'react'

function useRichTextEditorState(startText) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(startText),
      new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
      ])
    )
  )

  const updateEditorState = (html) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const editablePart = doc.querySelector('div.editable')

    const { contentBlocks } = htmlToDraft(editablePart.innerHTML)

    setEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks),
        new CompositeDecorator([
          {
            strategy: findLinkEntities,
            component: Link,
          },
        ])
      )
    )
  }

  return [editorState, setEditorState, updateEditorState]
}

export default useRichTextEditorState

import { IconButton } from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'

export default function AdminButton({ handleClick, ...props }) {
  return (
    <IconButton
      ml={2}
      icon={<FaStar />}
      borderRadius="full"
      onClick={handleClick}
      {...props}
    />
  )
}

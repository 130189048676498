import { useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useState } from 'react'
import api from '../services/api'

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)

  return context
}

export const AuthProvider = ({ children }) => {
  const toast = useToast()

  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const signed = !!user
  const isAdmin = signed && user.id === 79

  const initializePendo = () => {
    console.log('Initializing Pendo...')

    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user.id, // Required if user is logged in
        },
      })
      console.log('Pendo initialized', {
        visitor: {
          id: user.id,
        },
      })
    } else {
      setTimeout(initializePendo, 500)
    }
  }

  useEffect(() => {
    console.log(
      'process.env.REACT_APP_ENVIRONMENT',
      process.env.REACT_APP_ENVIRONMENT
    )

    if (process.env.REACT_APP_ENVIRONMENT === 'production' && user) {
      initializePendo()
    }
  }, [signed])

  useEffect(() => {
    const fetchUser = async (token) => {
      setIsLoading(true)
      try {
        api.defaults.headers['x-access-token'] = token
        // set user in state
        const { data } = await api.get('/user')
        setUser(data)

        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        Cookies.remove('village.token')
        return
      }
    }

    const token = Cookies.get('village.token')
    if (token) {
      fetchUser(token)
    }
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.get('/users')
        setUsers(data)
      } catch (err) {
        console.log(err)
      }
    }

    isAdmin && fetchUsers()
  }, [isAdmin])

  async function register({ code, invite }) {
    try {
      const {
        data: { token, user },
      } = await api.get(`/auth/google?googleCode=${code}&inviteCode=${invite}`)

      Cookies.set('village.token', token, { secure: true, expires: 60 })
      api.defaults.headers['x-access-token'] = token

      setUser(user)
      toast({ title: 'Registered' })
    } catch ({ response }) {
      const description = response?.data?.message ?? 'Something went wrong'
      toast({ title: 'Error', description, status: 'error' })
    }
  }

  async function signIn({ code, extraPermissions }) {
    !extraPermissions && setIsLoading(true)

    try {
      const {
        data: { token, user },
      } = await api.get(`/auth/google?googleCode=${code}`)

      if (!extraPermissions) {
        Cookies.set('village.token', token, { secure: true, expires: 60 })
        api.defaults.headers['x-access-token'] = token
        setUser(user)
        toast({ title: 'Logged in' })
      }

      setIsLoading(false)
    } catch ({ response }) {
      const description = response?.data?.message ?? 'Something went wrong'
      toast({ title: 'Error', description, status: 'error' })
      setIsLoading(false)
    }
  }

  async function signOut() {
    Cookies.remove('village.token')
    delete api.defaults.headers['x-access-token']
    setUser(null)
    toast({ title: 'Logged out' })
  }

  async function addIntegration({ code }) {
    try {
      const {
        data: { user },
      } = await api.get(`/auth/google?googleCode=${code}&addIntegration=true`)

      console.log('user', user)

      return user.integration
    } catch ({ response }) {
      const description = response?.data?.message ?? 'Something went wrong'
      toast({ title: 'Error', description, status: 'error' })
    }
  }

  async function fakeSignIn({ id, secret }) {
    setIsLoading(true)

    try {
      const {
        data: { token, user },
      } = await api.get(`/fake-login/${id}?secret=${secret}`)

      Cookies.set('village.token', token, { secure: true, expires: 60 })

      api.defaults.headers['x-access-token'] = token

      setUser({ ...user, isAdmin: true })

      toast({ title: 'Logged in' })

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  async function demoSignIn({ secret }) {
    setIsLoading(true)

    try {
      const {
        data: { token, user },
      } = await api.get(`/demo-login?secret=${secret}`)

      Cookies.set('village.token', token, { secure: true, expires: 60 })

      api.defaults.headers['x-access-token'] = token

      setUser({ ...user, isAdmin: true })

      toast({ title: 'Logged in' })

      setIsLoading(false)
    } catch (err) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message || '',
        status: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        users,
        selectedUser,
        setSelectedUser,
        register,
        signIn,
        fakeSignIn,
        demoSignIn,
        addIntegration,
        setAuthorizedGmail: (email) =>
          setUser({ ...user, authorized_gmail: email }),
        setSyncComplete: () => setUser({ ...user, is_sync_complete: true }),
        setUserUsername: (username) => setUser({ ...user, username }),
        setOnboardingData: (onboarding_data) =>
          setUser({ ...user, onboarding_data }),
        signed,
        logout: signOut,
        loading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

import {
  Box,
  Button,
  ModalBody,
  ModalFooter,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import AuthorizeGmail from 'components/AuthorizeGmail'
import EmailTemplate from 'components/EmailTemplate'
import { useAuth } from 'context/AuthContext'
import useRichTextEditorState from 'hooks/useRichTextEditorState'
import { useEffect, useState } from 'react'
import api from 'services/api'
import { encryptId } from 'utils/encryption'
import { concatPeople, htmlParser } from 'utils/parsers'

export default function TemplateStep({
  chosenAccount,
  handleRequestIntro,
  isRequestingIntro,
  introducer,
  targets,
  returnButton,
}) {
  const {
    user: { name: userName, authorized_gmail },
  } = useAuth()

  const [userFirstName] = userName.split(' ')

  const [
    editorState,
    setEditorState,
    updateEditorState,
  ] = useRichTextEditorState(
    `Hey,\nHope all is well.\nI’m looking to make some connections and thought that you might know some of those people. I added them below and the reason for each.`
  )

  const [emailLockedText, setEmailLockedText] = useState(false)
  const [subject, setSubject] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      setIsLoading(true)

      const [introducerFirstName] = introducer.name.split(' ')

      try {
        const {
          data: { html, subject },
        } = await api.post('/template', {
          templateId: 'd-02e04c7801044b35a4a3641713551e2d',
          templateData: {
            introducerFirstName: introducerFirstName,
            requesterFirstName: userFirstName,
            brief: 'XXXXX',
            targets: targets.map((t) => ({
              fullName: t.name,
              headline: t.work,
              reason: t.reason,
              profilePic: t.avatar,
              feedbackUrl: '',
              profileId: encryptId(t.entity_id),
            })),
            requesterFullName: userName,
            feedbackUrl: '',
            targetsSummarySubject: concatPeople(targets),
          },
        })

        setSubject(subject)
        setEmailLockedText(htmlParser(html, 'div.locked'))
        updateEditorState(html)
      } catch (err) {
        console.log('err', err)
      }
      setIsLoading(false)
    }

    fetchEmailTemplate()
  }, [])

  return (
    <>
      <ModalBody>
        <EmailTemplate
          from={
            authorized_gmail
              ? `${userName} (${authorized_gmail})`
              : `${userName} (no sending account set up yet)`
          }
          to={`${introducer.name} (${chosenAccount})`}
          subject={subject}
          setSubject={setSubject}
          editorState={editorState}
          setEditorState={setEditorState}
          lockedText={emailLockedText}
          isLoading={isLoading}
          mb={4}
          maxH={`min(15rem, 30vh)`}
          overflowY="auto"
          overflowX="hidden"
          borderRadius="md"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${mode(
                'var(--chakra-colors-gray-200)',
                'var(--chakra-colors-grayAlpha-200)'
              )}`,
              borderRadius: '24px',
            },
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          pos="absolute"
          w="100%"
          bottom={authorized_gmail && '-4rem'}
          zIndex="999"
          left="50%"
          transform={`translate(-50%, -50%)`}
          pb={6}
          pt={10}
          px={12}
          bgGradient={mode(
            'linear(rgba(0,0,0,0.0),white, white, white)',
            'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
          )} // add 4 colors, first 0 opacity the rest same color modal
        >
          {!authorized_gmail && (
            <Box
              p={3}
              mt={12}
              border="1px dashed"
              borderColor={mode('grayAlpha.50', 'grayAlpha.5')}
              borderRadius="md"
              mb={3}
            >
              <AuthorizeGmail />
            </Box>
          )}
          <Box d="flex" justifyContent="space-between" alignItems="center">
            {returnButton}
            <Button
              variant="village"
              isLoading={isRequestingIntro}
              onClick={() => handleRequestIntro(editorState, subject)}
              isDisabled={!chosenAccount || !authorized_gmail || isLoading}
            >
              Confirm Send
            </Button>
          </Box>
        </Box>
      </ModalFooter>
    </>
  )
}

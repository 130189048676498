import {
  Skeleton as ChakraSkeleton,
  useColorModeValue as mode,
} from '@chakra-ui/react'

export default function Skeleton({ ...props }) {
  return (
    <ChakraSkeleton
      startColor={mode('gray.100', 'grayAlpha.50')}
      endColor={mode('gray.500', 'grayAlpha.500')}
      {...props}
    />
  )
}

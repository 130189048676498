import { useToast } from '@chakra-ui/react'
import { useState } from 'react'

import api from 'services/api'
import { deepFetch, deepFetchProfile } from 'services/extension'

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function useDeepFetch() {
  const toast = useToast()

  const [isDeepFetchEnabled, setIsDeepFetchEnabled] = useState(true)

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loadingIndex, setLoadingIndex] = useState(false)

  const [timeoutId, setTimeoutId] = useState(null)

  const deepFetchProfilesArray = async (filters, relationshipsProfiles) => {
    setTimeoutId(null)

    try {
      let profiles = await deepFetch(filters)
      const nonRepeatedProfiles = profiles.filter(
        (p) => !relationshipsProfiles.includes(p.id)
      )
      const { data } = await api.post('/light-profiles', {
        profiles: nonRepeatedProfiles,
      })

      setData(data)
    } catch (err) {
      setData([])
    }

    setIsLoading(false)
  }

  const deepFetchProfileItem = async (profileId, index) => {
    setLoadingIndex(index)

    try {
      const profileFromBackend = await api.post('/deepfetch', {
        profile: false,
        profileId,
      })

      if (profileFromBackend.data) {
        const dataCopy = [...data]
        dataCopy[index] = profileFromBackend.data
        setData(dataCopy)
      } else {
        const profile = await deepFetchProfile(profileId)
        if (profile.job_history.length) {
          const response = await api.post('/deepfetch', {
            profile,
            profileId: false,
          })

          if (response.data.profile) {
            const dataCopy = [...data]
            dataCopy[index] = response.data
            setData(dataCopy)
          }
        }
      }
    } catch (err) {
      toast({
        title: 'Something went wrong',
        description: 'We could not lookup likely paths for this profile',
        status: 'error',
      })
      console.log('deepFetchProfile err', err)
    }
    setLoadingIndex(false)
  }

  const callDeepFetch = (filters, relationshipsProfiles) => {
    if (isDeepFetchEnabled) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      const timeout = setTimeout(
        () => deepFetchProfilesArray(filters, relationshipsProfiles),
        500
      )
      setTimeoutId(timeout)
    }
  }

  return {
    callDeepFetch,
    data,
    deepFetchProfile: deepFetchProfileItem,
    deepFetchProfileLoadingIndex: loadingIndex,
    clearData: () => setData([]),
    isLoading,
    setIsLoading,
    isDeepFetchEnabled,
    setIsDeepFetchEnabled,
  }
}

export default useDeepFetch

import { useEffect, useState } from 'react'

const updateUrl = (path, pathParams, index, value) => {
  /* please make sure order of your filters */
  /* example x/category/item */
  /* so your filter objects have the same order of keys */

  const pathParamsCopy = { ...pathParams }
  pathParamsCopy[index] = value

  window.history.replaceState(
    null,
    '',
    `${path}/${Object.values(pathParamsCopy).join('/')}`
  )
}

export const usePathParams = (path, initialValue) => {
  const [pathParams, setPathParams] = useState(initialValue)

  const setValue = (index, newValue) => {
    updateUrl(path, pathParams, index, newValue)
    setPathParams({ ...pathParams, [index]: newValue })
  }

  useEffect(() => {
    setValue()
  }, [])

  return { pathParams, setValue }
}

import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import CompanyModal from 'components/CompanyModal'
import CompanyPeople from 'components/CompanyPeople'
import RelationshipStrength from 'components/RelationshipStrength'
import Row from 'components/Row'
import Skeleton from 'components/Skeleton'
import { FaLinkedinIn } from 'react-icons/fa'
import { encryptId } from 'utils/encryption'
import AddToBoard from './KanbanBoard/AddToBoard'
import { ProfilePageModal } from './Profile/ProfilePageModal'

export default function CompaniesRow({
  company,
  toggleSelect,
  isSelected,
  loading,
  // pastCompanies,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isProfileModalOpen,
    onOpen: onProfileModalOpen,
    onClose: onProfileModalClose,
  } = useDisclosure()

  if (loading)
    return (
      <Row>
        <Skeleton height="24px" my="4px" w="100%" />
      </Row>
    )

  const avatars = company.avatars ? company.avatars.split(',') : []

  return (
    <Row>
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="2%">
        <Checkbox isChecked={isSelected} onChange={toggleSelect} />
      </Box>

      <Box minW="15%" maxW="15%" d="flex" alignItems="center" px={1}>
        <Avatar
          name={`${company.name}`}
          src={company.logo}
          mr={2}
          size="md"
          bg="primary.500"
          color="white"
        />
        <Box
          d="flex"
          alignItems="flex-start"
          flexDir="column"
          minW="100%"
          maxW="100%"
        >
          <Text
            as="a"
            href={`/company/profile/${company.slug ?? encryptId(company.id)}`}
            onAuxClick={() =>
              window.open(
                `/company/profile/${company.slug ?? encryptId(company.id)}`
              )
            }
            onClick={(e) => {
              e.preventDefault()
              onProfileModalOpen()
            }}
            target="_blank"
            isTruncated
            maxW="70%"
            fontWeight="bold"
          >
            {company.name}
          </Text>
        </Box>
      </Box>

      <Box minW="14%" maxW="14%" d="flex" alignItems="center">
        <CompanyPeople
          avatars={avatars}
          total={company.total}
          onOpen={onOpen}
        />
      </Box>

      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RelationshipStrength
          relationship={{ warmth_score: company.warmth_score }}
        />
      </Box>

      <Box
        minW="14%"
        maxW="14%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="sm" isTruncated>
          {company.industry ?? <i>No data</i>}
        </Text>
      </Box>

      <Box
        minW="10%"
        maxW="10%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="sm" isTruncated>
          {company.employee_size ?? <i>No data</i>}
        </Text>
      </Box>

      <Box
        minW="8%"
        maxW="8%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        {company.slug && (
          <IconButton
            icon={<FaLinkedinIn />}
            size="xs"
            colorScheme="gray"
            as="a"
            href={`https://linkedin.com/company/${company.slug}`}
            target="_blank"
          />
        )}
      </Box>

      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        {company.website ? (
          <Text
            size="sm"
            as="a"
            variant="link"
            href={company.website}
            target="_blank"
            fontWeight="bold"
            fontSize="sm"
            color="blue.500"
            textDecor="underline"
            isTruncated
          >
            {company.website}
          </Text>
        ) : (
          <i>No data</i>
        )}
      </Box>

      <Box
        minW="11%"
        maxW="11%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddToBoard
          selected={[
            {
              entity_id: company.id,
              entity: 'company',
            },
          ]}
          entity="company"
        />
      </Box>

      <CompanyModal
        company={company}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        // pastCompanies={pastCompanies}
      />
      <ProfilePageModal
        profileToVisit={company.slug ?? encryptId(company.id)}
        isOpen={isProfileModalOpen}
        onClose={onProfileModalClose}
      />
    </Row>
  )
}

import { Avatar, Box, Text, useColorModeValue as mode } from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'
import { ProfileTitle } from 'components/ProfileTitle'
import TripleButtonGroup from 'components/TripleButtonGroup'

export default function Request({
  pick,
  updatePick,
  userReachedLimit,
  isUpdatingPick,
}) {
  const { target, requester } = pick

  return (
    <Box bg={mode('gray.100', 'grayAlpha.700')} borderRadius="25px" mb={[8, 4]}>
      <Box
        py={4}
        d="flex"
        flexDir={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box w={['100%', 'auto']} pl={[2, 4]} pr={[2, 0]}>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDir={['column', 'row']}
            mb={1}
          >
            <Box w="8rem" mr={[0, 2]} mb={[2, 0]}>
              <AvatarGroup>
                <Avatar
                  src={requester.avatar}
                  name={`${requester.firstname} ${requester.lastname}`}
                  size="lg"
                  bg={'primary.500'}
                  color="white"
                />
                <Avatar
                  src={target.avatar}
                  name={`${target.firstname} ${target.lastname}`}
                  size="lg"
                  bg={'primary.500'}
                  color="white"
                />
              </AvatarGroup>
            </Box>

            <Box
              d="flex"
              alignItems={['center', 'flex-start']}
              flexDir="column"
              textAlign={['center', 'left']}
              mb={[2, 0]}
            >
              <Text isTruncated fontSize="md" minW="25ch">
                <ProfileTitle
                  name={`${requester.firstname} ${requester.lastname}`}
                  identityId={requester.id}
                  fontWeight="bold"
                />{' '}
                wants to meet{' '}
                <ProfileTitle
                  name={`${target.firstname} ${target.lastname}`}
                  identityId={target.id}
                  fontWeight="bold"
                />{' '}
              </Text>
              <Text fontSize="sm">They both {pick.similarity_sentence}</Text>
            </Box>
          </Box>
        </Box>

        <Box ml={['0', 'auto']} w={['100%', 'auto']} pl={[2, 0]} pr={[2, 4]}>
          <TripleButtonGroup
            mainButton={{
              label: `Recommend meeting`,
              onClick: () => {
                updatePick('recommended')
              },
              isDisabled: isUpdatingPick || pick.status !== 'interested',
            }}
            secondaryButton={{
              label: `Not a good match`,
              onClick: () => {
                updatePick('disapproved')
              },
              isDisabled: isUpdatingPick || pick.status !== 'interested',
            }}
            linkButton={{
              label: `I don't know ${target.firstname} ${target.lastname} enough`,
              onClick: () => {
                updatePick('unknown')
              },
              isDisabled: isUpdatingPick || pick.status !== 'interested',
            }}
          />
        </Box>
      </Box>
      {['recommended', 'disapproved', 'unknown'].includes(pick.status) && (
        <Box
          py={1}
          px={4}
          color={mode('grayAlpha.700', 'grayAlpha.0')}
          bg={
            pick.status === 'recommended'
              ? mode('green.300', 'green.900')
              : mode('red.300', 'red.900')
          }
          borderBottomLeftRadius="25px"
          borderBottomRightRadius="25px"
        >
          <Text fontSize="smaller">
            {pick.status === 'recommended'
              ? `Sweet! We’ll ask next if ${target.firstname} wants to connect and we'll kick-off the intro!`
              : `Thanks for letting us know!`}
          </Text>
        </Box>
      )}
    </Box>
  )
}

import {
  Box,
  Container,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { BoardSection } from 'components/Profile/BoardSection'
import { ProfileInfo } from 'components/Profile/Info'
import { IntroActivity } from 'components/Profile/IntroActivity'
import { ProfileStats } from 'components/Profile/Stats'
import { useAuth } from 'context/AuthContext'
import React from 'react'

export const CompanyProfileView = ({
  profileData,
  activeBoard,
  setActiveBoard,
  note,
  handleInputChange,
  sendNote,
  sendNoteLoading,
  errors,
  deleteNote,
  slug,
}) => {
  const {
    company_info,
    board_data,
    introActivity,
    privateNotes,
    user_company_relation,
  } = profileData

  const { user } = useAuth()

  const renderIntroActivity = () => {
    if (errors?.introActivity || !user) return null

    if (!introActivity) return null

    const introActivityCount = Object.values(introActivity).flat().length

    if (introActivityCount === 0) return null

    return (
      <Box my="6" px="1">
        <Text mb="4" fontSize="2xl" fontWeight="bold">
          Latest activity
        </Text>
        <IntroActivity intros={introActivity?.introRequests} />
      </Box>
    )
  }

  return (
    <Container maxW="1130px">
      <Box p="6" mt="4">
        <Box borderRadius="3xl" p="22px" bg={mode('gray.25', 'grayAlpha.500')}>
          <ProfileInfo
            title={company_info?.name}
            avatar={company_info?.logo}
            headline={`${company_info?.employee_size ?? 0} employees - ${
              company_info?.industry ?? 'No data'
            }`}
            country={company_info?.country_name}
            website={company_info?.website}
            companySlug={slug}
            linkedinPath={company_info?.linkedin_path}
          />
          <ProfileStats
            path={user_company_relation}
            companyInfo={company_info}
          />
        </Box>
        <Box p="2">
          <BoardSection
            deleteNote={deleteNote}
            privateNotes={privateNotes}
            setActiveBoard={setActiveBoard}
            activeBoard={activeBoard}
            boardData={board_data}
            note={note}
            handleInputChange={handleInputChange}
            sendNote={sendNote}
            sendNoteLoading={sendNoteLoading}
          />
          {renderIntroActivity()}
        </Box>
      </Box>
    </Container>
  )
}

import { Box, Button, Icon } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { GoogleAuthorize } from 'react-google-authorize/dist/google-authorize'
import { FaGoogle } from 'react-icons/fa'
import api from 'services/api'

export default function AuthorizeGmail() {
  const { setAuthorizedGmail } = useAuth()

  const responseGoogle = async (response) => {
    try {
      if (!response.code) throw Error()

      const {
        data: { user },
      } = await api.get(
        `/auth/google?googleCode=${response.code}&authorizeGmail=true`
      )

      if (user.integration.authorized_gmail) {
        setAuthorizedGmail(user.integration.sync_data.email)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box d="flex" alignItems="center" fontSize="sm">
      <Icon as={FaGoogle} mr={1} />
      <GoogleAuthorize
        scope={'https://www.googleapis.com/auth/gmail.send'}
        accessType={'offline'}
        approvalPrompt={'force'}
        prompt={'consent'}
        clientId="561701119639-v7t16ktki50r8ah0bks7rhi4d8c1il31.apps.googleusercontent.com"
        onSuccess={responseGoogle}
        onFailure={(err) => console.log('fail', err)}
        autoLoad={false}
        cookiePolicy={'single_host_origin'}
        responseType="code"
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            mx={1}
            variant="link"
            textDecor="underline"
            size="sm"
            colorScheme="blue"
          >
            Connect Gmail
          </Button>
        )}
      />{' '}
      to send messages from your email address.
    </Box>
  )
}

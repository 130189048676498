import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FaSearch, FaTimes } from 'react-icons/fa'

export default function SearchFilter({
  type,
  value,
  setFilter,
  placeholders,
  ...rest
}) {
  return (
    <InputGroup mr={4} {...rest}>
      <InputLeftElement
        pointerEvents="none"
        children={
          <Icon as={FaSearch} color={mode('gray.400', 'grayAlpha.50')} />
        }
      />
      <Input
        placeholder={placeholders[type]}
        value={value}
        onChange={(e) => setFilter(type, e.target.value)}
        borderRadius="full"
      />
      {value.length && (
        <InputRightElement>
          <IconButton
            icon={<FaTimes />}
            mr={1}
            minW="25px"
            h="25px"
            variant="ghost"
            onClick={() => setFilter(type, '')}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

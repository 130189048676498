import { AvatarGroup as ChakraAvatarGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'

const AvatarGroup = styled(ChakraAvatarGroup)`
  span:first-child {
    font-size: 0.7em;
    z-index: 1;
  }
`

export default AvatarGroup

import { useToast } from '@chakra-ui/react'
import { ProfileSkeleton } from 'components/Profile/ProfileSkeleton'
import { useAuth } from 'context/AuthContext'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import { decryptId } from 'utils/encryption'
import { UserProfileView } from './UserProfileView'

export const UserProfileViewContainer = ({ profileToVisit, boardIdToOpen }) => {
  const { id } = useParams()

  const toast = useToast()

  const identityId = decryptId(profileToVisit) || decryptId(id)

  const { user } = useAuth()

  /* the reason we separate errors and data is if one endpoint fails we should still be able to see the other info */
  const [errors, setErrors] = useState({})

  const [profileData, setProfileData] = useState({})

  const [loading, setLoading] = useState(true)

  const [activeBoard, setActiveBoard] = useState(boardIdToOpen ?? 'priv')

  const [note, setNote] = useState('')

  const [sendNoteLoading, setSendNoteLoading] = useState(false)

  const sendNote = async () => {
    try {
      const trimmedNote = note.trim()

      if (trimmedNote === '') {
        toast({
          title: 'You cannot add an empty note.',
          status: 'error',
        })
        setErrors((prevState) => ({
          ...prevState,
          sendNote: true,
        }))
        return
      }

      const task = profileData?.board_data.find(
        (task) => task.board_id === activeBoard
      )

      const taskId = task?.task_id

      const API_URL = activeBoard === 'priv' ? '/notes' : '/board/notes'

      const body = {
        identityId,
        note: trimmedNote,
        taskId,
      }
      setSendNoteLoading(true)

      const { data } = await api.post(API_URL, body)

      const newNote = data.note

      newNote.board_id = activeBoard
      newNote.note_id = newNote.id

      const newProfileData = {
        ...profileData,
      }

      if (activeBoard === 'priv') {
        Reflect.set(newProfileData, 'privateNotes', {
          notes: [newNote, ...profileData.privateNotes.notes],
        })
      } else {
        Reflect.set(newProfileData, 'board_data', [
          newNote,
          ...profileData.board_data,
        ])
      }

      setProfileData(newProfileData)
      setNote('')
      setSendNoteLoading(false)

      if (errors.sendNote) {
        setErrors((prevState) => ({
          ...prevState,
          sendNote: false,
        }))
      }
    } catch (e) {
      setSendNoteLoading(false)
      toast({
        title: 'An error occured while adding note.',
        status: 'error',
      })
    }
  }

  const deleteNote = async (noteId, taskId) => {
    try {
      const API_URL = activeBoard === 'priv' ? '/notes' : '/board/notes'

      const data = {
        noteId,
        taskId,
      }

      await api.delete(API_URL, { data })

      if (activeBoard !== 'priv') {
        const index = profileData.board_data.findIndex(
          (note) => note.note_id === noteId
        )
        const updatedNote = {
          note_id: null,
          task_id: taskId,
          board_id: activeBoard,
          board_title: profileData.board_data[index].board_title,
        }
        const boardDataCopy = [...profileData.board_data]
        boardDataCopy.splice(index, 1, updatedNote)
        setProfileData({ ...profileData, board_data: boardDataCopy })
        return
      }

      const newProfileData = {
        ...profileData,
        privateNotes: {
          notes: profileData.privateNotes.notes.filter(
            (note) => note.id !== noteId
          ),
        },
      }

      setProfileData(newProfileData)
    } catch (e) {
      toast({
        title: 'An error occured while deleting note.',
        status: 'error',
      })
    }
  }

  const fetchProfileInfo = async () => {
    setLoading(true)
    const workHistoryRequest = api.get(`/work-history/${identityId}`)
    const contactRequest = api.get(`/contact/${identityId}`)
    const helpfulnessRequest = api.get(
      `/intros/helpfulness?identity_id=${identityId}`
    )
    const relationshipRequest = api.post(`/relationship`, {
      left_identity_id: identityId,
      right_identity_id: user?.identityId,
    })
    const relationshipsRequest = api.get(`/relationships/${identityId}`)
    const restOfTheProfileRequest = api.get(`/profile/person/${identityId}`)
    const introActivityRequest = api.get(
      `/intro/activity?identity_id=${identityId}`
    )
    const privateNotesRequest = api.get(`/notes?identityId=${identityId}`)

    const requests = [
      workHistoryRequest,
      contactRequest,
      helpfulnessRequest,
      relationshipRequest,
      relationshipsRequest,
      restOfTheProfileRequest,
      introActivityRequest,
      privateNotesRequest,
    ]

    const [
      workHistoryRes,
      contactRes,
      helpfulnessRes,
      relationshipRes,
      relationshipsRes,
      restRes,
      introActivityRes,
      privateNotesRes,
    ] = await Promise.allSettled(requests)

    if (restRes.status === 'rejected') {
      setErrors({ identityInfo: true })
      setLoading(false)
      return
    }

    if (introActivityRes.status === 'rejected') {
      setErrors({ introActivity: true })
    }

    let profileData = {}

    if (workHistoryRes.status === 'fulfilled') {
      Reflect.set(profileData, 'workHistory', workHistoryRes.value.data)
    }

    if (contactRes.status === 'fulfilled') {
      Reflect.set(profileData, 'contactInfo', contactRes.value.data)
    }

    if (helpfulnessRes.status === 'fulfilled') {
      Reflect.set(profileData, 'helpfulness', helpfulnessRes.value.data)
    }

    if (relationshipRes.status === 'fulfilled') {
      Reflect.set(profileData, 'relationship', relationshipRes.value.data)
    }

    if (relationshipsRes.status === 'fulfilled') {
      Reflect.set(profileData, 'relationships', relationshipsRes.value.data)
    }

    if (restRes.status === 'fulfilled') {
      profileData = {
        ...profileData,
        ...restRes.value.data,
      }
    }

    if (introActivityRes.status === 'fulfilled') {
      Reflect.set(profileData, 'introActivity', introActivityRes.value.data)
    }

    if (privateNotesRes.status === 'fulfilled') {
      Reflect.set(profileData, 'privateNotes', privateNotesRes.value.data)
    }

    setProfileData(profileData)
    setLoading(false)
  }

  useEffect(() => {
    fetchProfileInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityId])

  const handleInputChange = (e) => {
    e.preventDefault()
    setNote(e.target.value)
  }

  if (loading) return <ProfileSkeleton />

  if ('identityInfo' in errors) {
    return 'An error occured.'
  }

  return (
    <UserProfileView
      deleteNote={deleteNote}
      errors={errors}
      note={note}
      activeBoard={activeBoard}
      sendNote={sendNote}
      sendNoteLoading={sendNoteLoading}
      setActiveBoard={setActiveBoard}
      profileData={profileData}
      handleInputChange={handleInputChange}
    />
  )
}

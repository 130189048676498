import { Box, Text, SimpleGrid, useToast } from '@chakra-ui/react'

import { useEffect, useState } from 'react'

import api from 'services/api'
import { Integration } from './Integration'
import NewIntegrationButton from './NewIntegrationButton'

export function Integrations() {
  const toast = useToast()

  const [integrations, setIntegrations] = useState([])

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const { data } = await api.get('/integrations')
        setIntegrations(data)
      } catch (err) {
        console.error(err)
      }
    }

    fetchIntegrations()
  }, [])

  return (
    <SimpleGrid columns={[1, 3, 6]}>
      {integrations.map((integration) => (
        <Integration key={integration.id} integration={integration} />
      ))}

      <Box
        borderRadius="md"
        w="270px"
        p={8}
        minH="150px"
        d="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Text fontSize="sm" fontWeight="semibold" mb={2}>
          Add new Integration 👇
        </Text>
        <NewIntegrationButton
          setIntegration={(integration) => {
            if (
              !integrations.find(
                (i) => i.account_identifier === integration.account_identifier
              )
            ) {
              setIntegrations([...integrations, integration])
            } else {
              toast({
                title: 'Integration already exists',
              })
            }
          }}
        />
      </Box>
    </SimpleGrid>
  )
}

import React, { useEffect, useRef } from 'react'

import {
  Textarea,
  Box,
  Button,
  Text,
  useColorModeValue as mode,
  Spinner,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { IoMdReturnLeft } from 'react-icons/io'
import TextareaAutosize from 'react-textarea-autosize'

export const AddNoteSection = ({
  note,
  handleInputChange,
  sendNote,
  sendNoteLoading,
  errors,
}) => {
  const { user } = useAuth()

  const noteRef = useRef(null)

  useEffect(() => {
    if (noteRef.current) {
      noteRef.current.focus()
    }
  }, [])

  if (!user) return null

  return (
    <Box my="14px" minH="54px">
      <Textarea
        ref={noteRef}
        w="100%"
        as={TextareaAutosize}
        pl="20px"
        pt="18px"
        _placeholder={{ color: '#8B8B8B' }}
        pb="17px"
        maxLength={255}
        _focus={{ outline: 'transparent' }}
        _hover={{ outline: 'transparent' }}
        minH="unset"
        pr="36"
        overflow="hidden"
        borderRadius="10px"
        bg={mode('white', 'grayAlpha.500')}
        value={note}
        minRows={1}
        onChange={handleInputChange}
        placeholder="Type a new note here..."
        borderWidth="2px"
        borderColor={errors?.sendNote ? 'red' : mode('black', 'white')}
        resize="none"
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            sendNote()
          }
        }}
      />
      <Button
        zIndex={2}
        leftIcon={
          <IoMdReturnLeft
            style={{ marginTop: 4 }}
            size={20}
            color={mode('white', 'black')}
          />
        }
        pos="sticky"
        _disabled={{ opacity: 1 }}
        w="117px"
        h="33px"
        isDisabled={sendNoteLoading}
        isLoading={sendNoteLoading}
        onClick={sendNote}
        spinner={<Spinner size="xs" color={mode('white', 'black')} />}
        float="right"
        marginRight={['16px', '16px', '21px', '21px']}
        marginTop="-53.5px"
        variant="village-black"
        p="0"
      >
        <Text color={mode('white', 'black')}>to add</Text>
      </Button>
    </Box>
  )
}

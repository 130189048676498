import {
  Avatar,
  Box,
  Button,
  chakra,
  Checkbox,
  IconButton,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import GetInTouchDropdown from 'components/GetInTouchDropdown'
import PathsModal from 'components/PathsModal'
import RelationshipConnector from 'components/RelationshipConnector'
import RelationshipStrength from 'components/RelationshipStrength'
import Row from 'components/Row'
import Skeleton from 'components/Skeleton'
import { useAuth } from 'context/AuthContext'
import moment from 'moment'
import { useState } from 'react'
import { FaArrowRight, FaLinkedinIn } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import api from 'services/api'
import { encryptId } from 'utils/encryption'
import AdminButton from './AdminButton'
import JobHistoryPopover from './JobHistoryPopover'
import AddToBoard from './KanbanBoard/AddToBoard'
import { ProfilePageModal } from './Profile/ProfilePageModal'
import RequestIntro from './RequestIntro'
import Similarity from './Similarity'

export default function RelationshipsRow({
  relationship,
  toggleSelect,
  isSelected,
  loading,
  isTopWarmthLikely,
  isDeepFetch,
}) {
  const { user, selectedUser } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const {
    isOpen: isProfileModalOpen,
    onOpen: onProfileModalOpen,
    onClose: onProfileModalClose,
  } = useDisclosure()

  const userIdentityId = user?.identityId

  if (loading)
    return (
      <Row>
        <Skeleton
          height="24px"
          my="4px"
          w="100%"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
        />
      </Row>
    )

  const connectors =
    relationship.connectors && typeof relationship.connectors === 'string'
      ? JSON.parse(relationship.connectors)
      : []

  const isUserConnectedAndHaveAWarmConnection =
    connectors.findIndex((c) => c.identity_id === userIdentityId) !== -1 &&
    relationship.direct_warmth &&
    relationship.direct_warmth > 0

  const userIsTheOnlyConnector =
    connectors.length === 1 && connectors[0].identity_id === userIdentityId

  const showWeeklyIntros =
    user?.id === 79 && typeof relationship.similarity_score === 'number'

  return (
    <Row>
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="2%">
        <Checkbox isChecked={isSelected} onChange={toggleSelect} />
      </Box>

      <Box minW="15%" maxW="15%" d="flex" alignItems="center" px={1}>
        <Avatar
          name={`${relationship?.firstname} ${relationship?.lastname}`}
          mr={2}
          size="md"
          src={relationship.avatar ? relationship.avatar : ''}
          bg={'primary.500'}
          color="white"
        />
        <Box
          d="flex"
          alignItems="flex-start"
          flexDir="column"
          minW="100%"
          maxW="100%"
        >
          <Text
            as="a"
            href={`/people/profile/${encryptId(
              relationship.right_identity_id
            )}`}
            onAuxClick={() =>
              window.open(
                `/people/profile/${encryptId(relationship.right_identity_id)}`
              )
            }
            onClick={(e) => {
              e.preventDefault()
              onProfileModalOpen()
            }}
            _hover={{
              textDecoration: 'underline',
              textUnderlineOffset: '2px',
            }}
            target="_blank"
            maxW="70%"
            isTruncated
            fontWeight="bold"
          >
            {relationship?.firstname} {relationship?.lastname}
          </Text>
        </Box>
      </Box>

      <Box minW="14%" maxW="14%" d="flex" alignItems="center" px={1}>
        <RelationshipConnector
          openPathsModal={() => {
            onOpen()
          }}
          connectors={connectors}
          isDeepFetch={isDeepFetch}
        />
      </Box>

      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RelationshipStrength
          relationship={relationship}
          identities={
            isUserConnectedAndHaveAWarmConnection &&
            [relationship.right_identity_id, userIdentityId].sort(
              (a, b) => a - b
            )
          }
          isTopWarmthLikely={isTopWarmthLikely}
        />
        {showWeeklyIntros && (
          <Similarity
            identity_id={relationship.right_identity_id}
            similarity={relationship.similarity}
            similarity_score={relationship.similarity_score}
            similarity_sentence={relationship.similarity_sentence}
          />
        )}
      </Box>

      <Box
        minW="20%"
        maxW="20%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        px={1}
      >
        {relationship.headline ? (
          <Text>{relationship.headline}</Text>
        ) : relationship.work_position ? (
          <JobHistoryPopover identity_id={relationship.right_identity_id}>
            <Box isTruncated textAlign="center">
              <Text
                as={Link}
                to={`/companies?industry=&name=${encodeURIComponent(
                  relationship.work_company_name
                )}`}
              >
                {relationship.work_position} at{' '}
                <chakra.span fontWeight="bold">
                  {relationship.work_company_name}
                </chakra.span>
              </Text>
              <Text
                color={
                  relationship.work_end
                    ? mode('red.800', 'red.100')
                    : mode('gray.400', 'grayAlpha.50')
                }
                fontSize="sm"
                fontStyle="italic"
              >
                {moment(relationship.work_start).format("MMM[']YY")} -{' '}
                {relationship.work_end
                  ? `${moment(relationship.work_end).format("MMM[']YY")} (past)`
                  : 'Present'}
              </Text>
            </Box>
          </JobHistoryPopover>
        ) : (
          ''
        )}
      </Box>

      <Box
        minW="10%"
        maxW="10%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
        px={1}
      >
        {relationship.location
          ? `${relationship.location}${
              relationship.country_code ? `, ${relationship.country_code}` : ''
            }`
          : relationship.country_name
          ? relationship.country_name
          : ''}
      </Box>

      <Box
        minW="8%"
        maxW="8%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
        px={1}
      >
        <Box d="flex">
          {relationship.linkedin_profile && (
            <IconButton
              icon={<FaLinkedinIn />}
              size="sm"
              colorScheme="gray"
              as="a"
              href={`https://linkedin.com/in/${relationship.linkedin_profile}`}
              target="_blank"
            />
          )}
        </Box>
      </Box>

      <Box
        minW="19%"
        maxW="19%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddToBoard
          selected={[
            {
              entity_id: relationship.right_identity_id,
              entity: isDeepFetch ? 'profile' : 'identity',
            },
          ]}
          mr={2}
        />
        {isUserConnectedAndHaveAWarmConnection || userIsTheOnlyConnector ? (
          <GetInTouchDropdown
            contactId={relationship.right_identity_id}
            isDeepFetch={isDeepFetch}
            linkedinUrl={
              relationship.linkedin_profile
                ? `https://linkedin.com/in/${relationship.linkedin_profile}`
                : false
            }
          />
        ) : (
          <RequestIntro
            selected={[
              {
                entity_id: relationship.right_identity_id,
                entity: isDeepFetch ? 'profile' : 'identity',
                name: `${relationship.firstname} ${relationship.lastname}`,
                avatar: relationship.avatar,
                connectors: JSON.parse(relationship.connectors),
                work: relationship.work_position
                  ? `${relationship.work_position} at ${relationship.work_company_name}`
                  : '',
              },
            ]}
          />
        )}
        {showWeeklyIntros && (
          <AdminButton
            handleClick={async () => {
              await api.post('/pick', {
                user_id: selectedUser?.id,
                right_identity_id: relationship.right_identity_id,
              })

              toast({
                title: `Pick will be added to next week's Weekly Intros`,
                status: 'success',
              })
            }}
          />
        )}
      </Box>

      {Boolean(relationship.connectors) && (
        <PathsModal
          relationship={relationship}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}

      <ProfilePageModal
        UserProfile={true}
        profileToVisit={encryptId(relationship?.right_identity_id)}
        isOpen={isProfileModalOpen}
        onClose={onProfileModalClose}
      />
    </Row>
  )
}

function DeepFetchRow({
  relationship,
  toggleSelect,
  isSelected,
  loading,
  deepFetchProfile,
  isDeepFetchProfileLoading,
}) {
  const [avatarUrl, setAvatarUrl] = useState(relationship.avatar)

  if (loading)
    return (
      <Row>
        <Skeleton
          height="24px"
          my="4px"
          w="100%"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
        />
      </Row>
    )

  return (
    <Row>
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="2%">
        <Checkbox isChecked={isSelected} onChange={toggleSelect} />
      </Box>

      <Box minW="15%" maxW="15%" d="flex" alignItems="center" px={1}>
        <Avatar
          name={relationship.name}
          mr={2}
          size="md"
          src={avatarUrl ? avatarUrl : ''}
          bg={'primary.500'}
          color="white"
          onError={() => setAvatarUrl(relationship.backupAvatar)}
        />
        <Box
          d="flex"
          alignItems="flex-start"
          flexDir="column"
          minW="100%"
          maxW="100%"
        >
          <Text maxW="70%" isTruncated fontWeight="bold">
            {relationship?.name}
          </Text>
        </Box>
      </Box>

      <Box minW="14%" maxW="14%" d="flex" alignItems="center" px={1}>
        <Button
          onClick={deepFetchProfile}
          isLoading={isDeepFetchProfileLoading}
          rightIcon={<FaArrowRight />}
          size="sm"
          color="oldGray.50"
          variant="link"
          textDecor="underline"
          fontWeight="semibold"
        >
          Lookup likely paths
        </Button>
      </Box>

      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RelationshipStrength relationship={relationship} />
      </Box>

      <Box
        minW="20%"
        maxW="20%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        px={1}
      ></Box>

      <Box
        minW="10%"
        maxW="10%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
        px={1}
      >
        {relationship.location}
      </Box>

      <Box
        minW="8%"
        maxW="8%"
        d="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
        px={1}
      >
        <Box d="flex">
          {relationship.linkedin_profile && (
            <IconButton
              icon={<FaLinkedinIn />}
              size="sm"
              colorScheme="gray"
              as="a"
              href={`https://linkedin.com/in/${relationship.linkedin_profile}`}
              target="_blank"
            />
          )}
        </Box>
      </Box>

      <Box
        minW="19%"
        maxW="19%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddToBoard
          selected={[
            {
              entity_id: relationship.id,
              entity: 'profile',
            },
          ]}
          mr={2}
        />
        <GetInTouchDropdown
          isDeepFetch={true}
          linkedinUrl={
            relationship.linkedin_profile
              ? `https://linkedin.com/in/${relationship.linkedin_profile}`
              : false
          }
        />
      </Box>
    </Row>
  )
}

export { DeepFetchRow }

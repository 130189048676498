import { Button } from '@chakra-ui/react'
import { FaGoogle } from 'react-icons/fa'
import LoginButton from 'components/LoginButton'
import { useAuth } from 'context/AuthContext'
import { useState } from 'react'

export default function NewIntegrationButton({ setIntegration, ...props }) {
  const { addIntegration } = useAuth()

  const [isLoading, setIsLoading] = useState(false)

  return (
    <LoginButton
      customButton={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          isLoading={isLoading}
          size="sm"
          my={2}
          colorScheme="middle"
          variant="outline"
          leftIcon={<FaGoogle />}
          {...props}
        >
          Add Google Account
        </Button>
      )}
      customCallback={async (googleCode) => {
        setIsLoading(true)
        const integration = await addIntegration({ code: googleCode })
        setIntegration(integration)
        setIsLoading(false)
      }}
      extraPermissions
    />
  )
}

import {
  Box,
  Icon,
  Spinner,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FaGoogle } from 'react-icons/fa'

export function Integration({ integration }) {
  const wereAllScopesGranted =
    integration &&
    integration?.sync_data?.missingScopes &&
    integration?.sync_data?.missingScopes.length === 0

  return (
    <Box
      borderRadius="md"
      w="270px"
      h="200px"
      p={8}
      minH="200px"
      d="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
      pos="relative"
    >
      <Text
        d="flex"
        justifyContent="center"
        alignItems="center"
        fontWeight="semibold"
        color={mode('blue.500', 'blue.300')}
      >
        <Icon
          as={FaGoogle}
          w="6"
          h="6"
          mr={2}
          bg={mode('blue.500', 'blue.300')}
          color="white"
          borderRadius="50%"
          p={1}
        />{' '}
        Google Integration
      </Text>
      {Boolean(integration.is_sync_complete) && (
        <Text fontSize="lg" my={'auto'} fontWeight="semibold">
          Finished{'  '} ✅
        </Text>
      )}

      {!wereAllScopesGranted && integration.sync_data.missingScopes && (
        <Box>
          <Text fontSize="xx-small" color="red" mb={1}>
            Missing scopes: {integration.sync_data.missingScopes.join(', ')}
          </Text>

          <Text fontSize="xx-small" fontWeight="bold">
            To fix this must enable the scopes in the "Authorize Google" button
          </Text>
        </Box>
      )}

      {wereAllScopesGranted && !integration.is_sync_complete && (
        <Box textAlign="center" mt={4}>
          <Spinner />
          <Text fontSize="xs" my={'auto'} color="black" fontWeight="semibold">
            We're crunching through all your contacts...
          </Text>
        </Box>
      )}

      <Text fontSize="x-small" color={mode('gray.500', 'gray.400')}>
        {integration?.sync_data?.email}
        <Text fontSize="x-small">{integration.account_identifier}</Text>
      </Text>
    </Box>
  )
}

import React, { useState } from 'react'
import {
  Box,
  Text,
  Container,
  Flex,
  Avatar,
  AvatarGroup,
  Button,
  useDisclosure,
  useColorModeValue as mode,
  Divider,
} from '@chakra-ui/react'
import { FaRegHandPaper, FaRegHandPointRight } from 'react-icons/fa'
import moment from 'moment'
import Editor from '@sunsama/rich-markdown-editor'
import { useAuth } from 'context/AuthContext'
import { RecommendationModalContainer } from 'components/OpportunityMarketplace/RecommendationModal/RecommendationModalContainer'
import { ApplicationModal } from 'components/OpportunityMarketplace/ApplicationModal/ApplicationModal'
import { RelationshipSummary } from 'components/OpportunityMarketplace/RelationshipSummary'
import { ProfileTitle } from 'components/ProfileTitle'

export const OpportunityView = ({
  opportunity,
  shareOpportunity,
  shareLoading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isApplicationModalOpen,
    onOpen: onApplicationModalOpen,
    onClose: onApplicationModalClose,
  } = useDisclosure()

  const {
    expireAt,
    title,
    content,
    company_name,
    poster_name,
    company_logo,
    avatar,
    relationshipWithCompany,
    relationshipWithPoster,
    poster_identity_id,
    id,
    company_id,
    previousApplication,
    friendsRecommendations,
  } = opportunity

  const [applied, setApplied] = useState(!!previousApplication)

  const { user } = useAuth()

  const daysToExpire = moment(expireAt).diff(moment(), 'days')

  const ownOpportunity = user && user.identityId === poster_identity_id

  const renderRelationship = () => {
    if (!user || ownOpportunity) {
      return null
    }

    const connectors =
      relationshipWithPoster.connectors &&
      typeof relationshipWithPoster.connectors === 'string'
        ? JSON.parse(relationshipWithPoster.connectors)
        : []

    return (
      <Box mt="6" p="2" bg={mode('blue.25', 'grayAlpha.500')}>
        <RelationshipSummary
          mb="2"
          relationship={relationshipWithCompany}
          pathCount={
            relationshipWithCompany ? relationshipWithCompany.total : 0
          }
          pathName={company_name}
          warmthScore={
            relationshipWithCompany ? relationshipWithCompany.warmth_score : 0
          }
          isCompanyModal
          companyId={company_id}
        />
        <RelationshipSummary
          relationship={relationshipWithPoster}
          pathCount={connectors.length}
          pathName={poster_name.split(' ')[0]}
          warmthScore={
            relationshipWithPoster ? relationshipWithPoster.warmth_score : 0
          }
        />
      </Box>
    )
  }

  return (
    <Container maxW="1130px">
      <Box px="4" py="2">
        <Text
          fontSize="3xl"
          fontWeight="bold"
          mt="5"
          color={mode('black', 'white')}
        >
          {title}
        </Text>
        <Box mt="10">
          <Text as="span">
            {company_id && (
              <>
                <Avatar
                  borderRadius="0"
                  width="29px"
                  height="29px"
                  size="sm"
                  name={company_name}
                  src={company_logo}
                />
                <ProfileTitle
                  mx="2"
                  fontWeight="bold"
                  companyId={company_id}
                  name={company_name}
                />
              </>
            )}
            • Posted by{' '}
            <Avatar
              width="29px"
              height="29px"
              size="sm"
              name={poster_name}
              src={avatar}
            />
            <ProfileTitle
              mx="2"
              fontWeight="bold"
              identityId={poster_identity_id}
              name={poster_name}
            />
            {' • '}
            <Text ml="2" color={mode('gray.10', 'white')} as="span">
              {daysToExpire < 0
                ? `Expired on ${moment(expireAt).format('DD/MM/YYYY')}`
                : `Expires in ${moment(expireAt).diff(moment(), 'days')} days`}
            </Text>
          </Text>
        </Box>
        {renderRelationship()}
        <Divider mt="5" mb="30px" />
        <Box pb="32" mt="5">
          <Editor readOnly defaultValue={content} theme={{}} />
        </Box>
      </Box>
      <Box
        borderTopRadius="8px"
        position="fixed"
        zIndex={5}
        bottom="0"
        bg={mode('gray.20', 'grayAlpha.500')}
        p="6"
        w="100%"
        mx={['-4', '-4', '-4', '-4', 0]}
        maxW="1130px"
      >
        <Flex
          flexWrap="wrap"
          justifyContent={['center', 'center', 'space-between']}
          alignItems="center"
          sx={{ gap: 16 }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ gap: 16 }}
          >
            {user && (
              <>
                <AvatarGroup size="sm" max={3}>
                  {friendsRecommendations.map((friend) => (
                    <Avatar
                      size="sm"
                      name={friend.fullName}
                      src={friend.avatar}
                    />
                  ))}
                </AvatarGroup>
                <Text fontSize="sm" fontWeight="semibold" color="green.400">
                  {friendsRecommendations.length}{' '}
                  {friendsRecommendations.length > 1 ? 'friends' : 'friend'}{' '}
                  recommended matches
                </Text>
              </>
            )}
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            sx={{ gap: 7 }}
          >
            {/*
            <Button
              borderWidth="2px"
              variant="villageOutline"
              isLoading={shareLoading}
              onClick={shareOpportunity}
              p="1.25rem 0.75rem"
            >
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={mode('black', 'white')}
              >
                Share
              </Text>
            </Button> */}
            <Button
              onClick={() => onOpen()}
              borderWidth="2px"
              variant="village-black"
              p="1.25rem 0.625rem"
            >
              <FaRegHandPointRight size={16} color={mode('white', 'black')} />
              <Text
                ml="1.5"
                fontSize="sm"
                fontWeight="bold"
                color={mode('white', 'black')}
              >
                Recommend person or company
              </Text>
            </Button>
            <Button
              borderWidth="2px"
              variant="village-black"
              isDisabled={applied || ownOpportunity}
              onClick={onApplicationModalOpen}
              p="1.25rem 0.625rem"
            >
              <FaRegHandPaper size={16} color={mode('white', 'black')} />
              <Text
                ml="1.5"
                fontSize="sm"
                fontWeight="bold"
                color={mode('white', 'black')}
              >
                I'm Interested
              </Text>
            </Button>
            <RecommendationModalContainer
              opportunityId={id}
              isOpen={isOpen}
              onClose={onClose}
            />
            <ApplicationModal
              setApplied={setApplied}
              opportunityId={id}
              isOpen={isApplicationModalOpen}
              onClose={onApplicationModalClose}
            />
          </Flex>
        </Flex>
      </Box>
    </Container>
  )
}

const placeholders = {
  people: {
    name: `Name (e.g. Andrew Chen)`,
    company: `Company (e.g. Google)`,
    position: `Position (e.g. CEO)`,
    location: `Location (e.g. United States)`,
  },
  companies: {
    name: `Company name (e.g. Microsoft)`,
    industry: `Industry (e.g. Venture Capital)`,
  },
  jobs: {
    role: `Role (e.g. Software Engineer)`,
    industry: `Industry (e.g. Internet, Venture Capital, Financial Services)`,
  },
}

export default placeholders

import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'

export const ButtonWithConfirmation = ({ children, onClick, ...props }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant="village" onClick={onOpen} {...props}>
          {children}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Are you sure?</PopoverHeader>
        <PopoverBody>
          <Button
            w="100%"
            variant="village"
            onClick={(data) => {
              onClick(data)
              onClose()
            }}
          >
            Confirm
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

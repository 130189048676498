import { OpportunitySkeleton } from 'components/OpportunityMarketplace/OpportunitySkeleton'
import { useOpportunity } from 'context/OpportunityContext'
import { usePathParams } from 'hooks/usePathParams'
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import { OpportunityMarketplaceView } from './OpportunityMarketplaceView'

export const OpportunityMarketplaceViewContainer = () => {
  const { category, list } = useParams()

  const [categoryId, setCategoryId] = useState(category || 'all')

  const [listId, setListId] = useState(list || 'warm')

  const { setValue: changeFilter } = usePathParams('/opportunities', {
    0: categoryId,
    1: listId,
  })

  const [opportunities, setOpportunities] = useState([])

  const [ownOpportunities, setOwnOpportunities] = useState([])

  const [page, setPage] = useState(1)

  const [hasMore, setHasMore] = useState(true)

  const [loading, setLoading] = useState(true)

  const [filterLoading, setFilterLoading] = useState(false)

  const scrollRef = useRef(null)

  const {
    setCompanies,
    setCategories,
    categories,
    setLocation,
  } = useOpportunity()

  const fetchInitialOpportunities = async () => {
    try {
      setLoading(true)
      /* const opportunitiesForMe = api.get(
        `/opportunities?category_id=${categoryId}`
      ) */

      const myOwnOpportunities = api.get('/own/opportunities')

      const opportunityTypesReq = api.get('/forminfos/opportunities')

      const [
        //opportunitiesForMeResponse,
        myOwnOpportunitiesResponse,
        opportunityTypesResponse,
      ] = await Promise.allSettled([
        // opportunitiesForMe,
        myOwnOpportunities,
        opportunityTypesReq,
      ])

      /* if (opportunitiesForMeResponse.status === 'fulfilled') {
        setOpportunities(opportunitiesForMeResponse.value.data.opportunities)
      } */

      if (myOwnOpportunitiesResponse.status === 'fulfilled') {
        setOwnOpportunities(myOwnOpportunitiesResponse.value.data.opportunities)
      }

      if (opportunityTypesResponse.status === 'fulfilled') {
        setCategories([
          { id: null, title: 'All', url_friendly_string: 'all' },
          ...opportunityTypesResponse.value.data.categories,
        ])
        setCompanies([
          {
            id: null,
            name: 'None',
            logo: null,
            period_to: '2050',
            title: null,
          },
          ...opportunityTypesResponse.value.data.companies,
        ])
        setLocation(opportunityTypesResponse.value.data.location)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDifferentParams = async (page, scrollBackToTop) => {
    try {
      const { data } = await api.get(
        `/opportunities?category_id=${categoryId}&warmth=${listId}&page=${page}`
      )

      if (page === 1) {
        setPage(1)
        setHasMore(true)
      }

      if (scrollBackToTop && scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }

      if (data.opportunities.length === 0) {
        setHasMore(false)
      }

      page === 1
        ? setOpportunities(data.opportunities)
        : setOpportunities([...opportunities, ...data.opportunities])
      setFilterLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchInitialOpportunities()
  }, [])

  useEffect(() => {
    setOpportunities([])
    setFilterLoading(true)
    fetchDifferentParams(1, opportunities.length > 0)
  }, [categoryId, listId])

  if (loading) return <OpportunitySkeleton />

  return (
    <OpportunityMarketplaceView
      opportunities={opportunities}
      ownOpportunities={ownOpportunities}
      setOwnOpportunities={setOwnOpportunities}
      setCategoryId={setCategoryId}
      categoryId={categoryId}
      listId={listId}
      setListId={setListId}
      changeFilter={changeFilter}
      setPage={setPage}
      page={page}
      opportunityTypes={categories}
      hasMore={hasMore}
      fetchMore={fetchDifferentParams}
      filterLoading={filterLoading}
      scrollRef={scrollRef}
    />
  )
}

import { Box, Divider } from '@chakra-ui/react'
import AdminSettings from 'components/AdminSettings'
// import ExtensionStatus from 'components/ExtensionStatus'
import { Integrations } from 'components/Integrations'
import { useAuth } from 'context/AuthContext'

const adminIds = [79, 88]

export default function Settings() {
  const { user } = useAuth()

  return (
    <Box p={6}>
      {/* {Boolean(user.is_sync_complete) && <ExtensionStatus />} */}
      <Integrations />
      <Divider mb={6} />
      {adminIds.includes(user.id) && <AdminSettings />}

      {/* <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <Plan price="0" />
        <Plan price="50" priceId="price_1Jcql1A9VvvgMn8UH56OfTYR" />
        <Plan price="100" priceId="price_1JcqlIA9VvvgMn8UbZPExCUK" />
      </Stack> */}
    </Box>
  )
}

import {
  Avatar,
  Box,
  Button,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import AuthorizeGmail from 'components/AuthorizeGmail'
import EmailTemplate from 'components/EmailTemplate'
import TripleButtonGroup from 'components/TripleButtonGroup'
import { useAuth } from 'context/AuthContext'
import { stateToHTML } from 'draft-js-export-html'
import useRichTextEditorState from 'hooks/useRichTextEditorState'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { MdComment } from 'react-icons/md'
import api from 'services/api'
import { encryptId } from 'utils/encryption'
import { htmlParser } from 'utils/parsers'
import FeedbackBadge from './FeedbackBadge'

export default function Introducer({ introducer, updateRequested }) {
  const {
    intro_id,
    intro_identity,
    intro_name,
    intro_reason,
    intro_updated_date,
    introducer_avatar,
    introducer_comment,
    introducer_email,
    introducer_feedback,
    introducer_firstname,
    introducer_lastname,
    introducer_token,
  } = introducer

  const {
    user: { name: userName, email: userEmail, authorized_gmail },
  } = useAuth()

  const {
    onOpen: onModalOpen,
    isOpen: isModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  const toast = useToast()

  const [
    editorState,
    setEditorState,
    updateEditorState,
  ] = useRichTextEditorState(
    `Hi ${introducer_firstname},\n\nThank you for offering and introduction to ${intro_name}. I've provided a clean forwardable and some context for the intro below.\n\nIf everything looks good, could you please forward this to ${
      intro_name.split(' ')[0]
    }?\n\nBrief:\nAdd a brief description of the intro here.\n\nBio:\nAdd a bio here about you.\n\n--\nBest regards,\n${userName}`
  )

  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [subject, setSubject] = useState(
    `${introducer_firstname}, here is the forwardable email.`
  )
  const [emailLockedText, setEmailLockedText] = useState(
    `<div style="font-family:arial, helvetica, sans-serif;" class="locked"><p>Sent with Village</p></div>`
  )

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      setIsLoading(true)

      try {
        const {
          data: { html, subject },
        } = await api.post('/template', {
          templateId: 'd-920cd1b104dd461ebb7a429b8782f540',
          templateData: {
            targetFullName: intro_name,
            targetProfileId: encryptId(intro_identity),
            reason: intro_reason,
            requesterFullName: userName,
            introducerFirstName: introducer_firstname,
            brief: 'XXXXX',
          },
        })

        setSubject(subject)
        setEmailLockedText(htmlParser(html, 'div.locked'))
        updateEditorState(html)
      } catch (err) {
        console.log('err', err)
      }
      setIsLoading(false)
    }

    isModalOpen && fetchEmailTemplate()
  }, [isModalOpen])

  const handleSendForwardable = async () => {
    setIsSendingEmail(true)
    try {
      await api.post(`/intros/forwardable/${intro_id}`, {
        emailBody: stateToHTML(editorState.getCurrentContent()),
        subject,
      })

      toast({
        title: 'Forwardable sent',
        description: 'Your email has been sent!',
        status: 'success',
      })

      updateRequested({
        introducer: introducer,
        key: 'introducer_feedback',
        value: 'pending',
      })

      onModalClose()
    } catch (err) {
      console.error(err)
    }
    setIsSendingEmail(false)
  }

  const handleUpdateFeedback = async (feedback) => {
    try {
      await api.patch(`/intro/${intro_id}?token=${introducer_token}`, {
        introducer_feedback: feedback,
      })

      toast({
        title: 'Request saved',
        description: 'Your request has been saved',
        status: 'success',
      })

      updateRequested({
        introducer: introducer,
        key: 'introducer_feedback',
        value: feedback,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box
      w="100%"
      py={4}
      px={10}
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.600')}
      bg={mode('gray.100', 'grayAlpha.700')}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      _last={{
        borderBottomRightRadius: '25px',
        borderBottomLeftRadius: '25px',
      }}
    >
      <Box w="40%">
        <Box d="flex" alignItems="center" fontWeight="bold">
          <Box w="2.5rem">
            <Avatar
              src={introducer_avatar}
              name={`${introducer_firstname} ${introducer_lastname}`}
              size="sm"
              mr={2}
            />
          </Box>
          {`${introducer_firstname} ${introducer_lastname}`}{' '}
          <chakra.span fontSize="smaller" fontWeight="normal" ml={1}>
            ({introducer_email})
          </chakra.span>
        </Box>

        <Text
          fontStyle={'italic'}
          fontSize="sm"
          ml="2.5rem"
          d="flex"
          alignItems="center"
        >
          <Icon as={MdComment} mr={1} />{' '}
          <chakra.strong mr={1}>Comment:</chakra.strong>{' '}
          {introducer_comment ? introducer_comment : `None`}
        </Text>
      </Box>

      <Box
        w="30%"
        d="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexDir={introducer_feedback && 'column'}
      >
        <FeedbackBadge feedback={introducer_feedback} introName={intro_name} />
        {introducer_feedback && (
          <Text
            fontSize="xx-small"
            fontWeight="normal"
            color={mode('grayAlpha.600', 'grayAlpha.5')}
            mt={1}
          >
            on {moment(intro_updated_date).format('MMM DD, YYYY [at] h:mma')}
          </Text>
        )}
      </Box>

      <Box w="40%" alignItems="center" justifyContent="flex-end">
        <TripleButtonGroup
          mainButton={
            introducer_feedback === 'accepted' && {
              onClick: onModalOpen,
              label: 'Send Forwardable email',
            }
          }
          secondaryButton={
            [null, 'accepted', 'pending'].includes(introducer_feedback) && {
              onClick: () => handleUpdateFeedback('completed'),
              label: 'Mark as completed',
            }
          }
          linkButton={
            [null, 'accepted', 'pending'].includes(introducer_feedback) && {
              onClick: () => handleUpdateFeedback('canceled'),
              label: 'Mark as canceled',
            }
          }
        />
      </Box>

      <Modal
        isOpen={isModalOpen}
        onOpen={onModalOpen}
        onClose={onModalClose}
        size="2xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <EmailTemplate
              from={
                authorized_gmail
                  ? `${userName} (${authorized_gmail})`
                  : `${userName} (no sending account set up yet)`
              }
              to={`${introducer_firstname} ${introducer_lastname} (${introducer_email})`}
              subject={subject}
              setSubject={setSubject}
              editorState={editorState}
              setEditorState={setEditorState}
              lockedText={emailLockedText}
              isLoading={isLoading}
              mb={4}
              maxH={`min(35rem, 45vh)`}
              overflowY="auto"
              overflowX="hidden"
              borderRadius="md"
              css={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: `${mode(
                    'var(--chakra-colors-gray-200)',
                    'var(--chakra-colors-grayAlpha-200)'
                  )}`,
                  borderRadius: '24px',
                },
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Box
              pos="absolute"
              w="100%"
              bottom={authorized_gmail && '-4rem'}
              zIndex="999"
              left="50%"
              transform={`translate(-50%, -50%)`}
              pb={6}
              pt={10}
              px={12}
              bgGradient={mode(
                'linear(rgba(0,0,0,0.0),white, white, white)',
                'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
              )} // add 4 colors, first 0 opacity the rest same color modal
            >
              {!authorized_gmail && (
                <Box
                  p={3}
                  mt={12}
                  border="1px dashed"
                  borderColor={mode('grayAlpha.50', 'grayAlpha.5')}
                  borderRadius="md"
                  mb={3}
                >
                  <AuthorizeGmail />
                </Box>
              )}
              <Box d="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="village"
                  onClick={handleSendForwardable}
                  isLoading={isSendingEmail}
                  isDisabled={!authorized_gmail}
                >
                  Send Forwardable Email
                </Button>
              </Box>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

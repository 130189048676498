import {
  Box,
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import Skeleton from 'components/Skeleton'
import { useEffect, useState } from 'react'
import api from 'services/api'
import { getExtensionStatus } from 'services/extension'
import { etaParser } from 'utils/parsers'
import { Step } from './Step'
import { StepContent } from './StepContent'
import StepIntegrations from './StepIntegrations'
import StepNetwork from './StepNetwork'
import { Steps } from './Steps'
import { useSteps } from './useSteps'

export default function SyncSteps({ isModalOpen }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const { nextStep, activeStep, setActiveStep } = useSteps({
    initialStep: 0,
  })

  const [data, setData] = useState({
    inviter: 'John',
    totalFriends: 0,
    topCompany: ['Google', 'Facebook', 'Amazon'],
    topPeople: ['John', 'Jane', 'Jack'],
    totalWarmthCompany: 0,
    totalWarmthPeople: 0,
  })

  const [isNetworkDataLoading, setIsNetworkDataLoading] = useState(true)

  const [isIntegrationLoading, setIsIntegrationLoading] = useState(true)

  const [googleIntegration, setGoogleIntegration] = useState({
    message: 'Verifying integration',
    eta: false,
    sync_data: false,
    contacts: 0,
  })

  const [additionalIntegrations, setAdditionalIntegrations] = useState([])

  const [linkedinIntegration, setLinkedinIntegration] = useState({
    message: 'Verifying integration',
    eta: false,
    sync_data: false,
  })

  const [isLoggedOnLinkedin, setIsLoggedOnLinkedin] = useState('no reply')

  const fetchIntegration = async () => {
    try {
      const { data } = await api.get(`/integration/initial`)

      if (
        process.env.REACT_APP_ENVIRONMENT === 'production' &&
        data.isFinished
      ) {
        window.location.reload()
      }

      setGoogleIntegration({
        ...googleIntegration,
        ...data.mainGoogleIntegration,
        sync_data:
          data.mainGoogleIntegration && data.mainGoogleIntegration.sync_data
            ? JSON.parse(data.mainGoogleIntegration.sync_data)
            : false,
      })

      setAdditionalIntegrations(data.extraGoogleIntegrations)

      setLinkedinIntegration({
        ...linkedinIntegration,
        ...data.linkedinIntegration,
        sync_data:
          data.linkedinIntegration && data.linkedinIntegration.sync_data
            ? JSON.parse(data.linkedinIntegration.sync_data)
            : false,
      })

      setIsIntegrationLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const fetchNetworkData = async () => {
      try {
        const { data } = await api.get('/inviter')
        setData(data)

        setIsNetworkDataLoading(false)
      } catch (err) {}
    }

    fetchNetworkData()

    if (isBasicIntegrationDone) {
      setActiveStep(1)
    }
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      fetchIntegration()
      const interval = setInterval(fetchIntegration, 3000)
      return () => clearInterval(interval)
    }
  }, [isModalOpen])

  useEffect(async () => {
    if (isModalOpen) {
      const res = await getExtensionStatus(isLoggedOnLinkedin === 'no reply')

      if (res === 'no reply') {
        setIsLoggedOnLinkedin('no reply')
      } else {
        setIsLoggedOnLinkedin(res.linkedin_login)
      }
    }
  }, [linkedinIntegration])

  const wereAllScopesGranted =
    googleIntegration &&
    ((googleIntegration?.sync_data?.missingScopes &&
      googleIntegration?.sync_data?.missingScopes.length === 0) ||
      googleIntegration?.sync_data.insufficientPermissions === false)

  const isBasicIntegrationDone =
    isLoggedOnLinkedin === true && wereAllScopesGranted

  return (
    <Modal size="2xl" isOpen={isModalOpen} onClose={() => undefined} isCentered>
      <ModalOverlay
        css={`
          backdrop-filter: blur(2px);
        `}
      />

      <ModalContent background="transparent">
        <Box
          py="10"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Box mb={4}>
            <Text fontSize="3xl" fontWeight="bold">
              You're almost there.
            </Text>
          </Box>
          <Divider mx="auto" w="25%" mb={6} />
          <Steps
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isBasicIntegrationDone={isBasicIntegrationDone}
            mt={2}
          >
            <Step
              title={
                <Skeleton isLoaded={!isNetworkDataLoading} as="span">
                  {data?.inviter}{' '}
                  {Boolean(data.totalFriends) &&
                    `and ${data.totalFriends} other friend${
                      data.totalFriends > 1 ? 's' : ''
                    } `}
                  already discovered their Village
                  {Boolean(data.totalFriends) && 's'}.
                </Skeleton>
              }
            >
              <StepContent>
                <StepNetwork
                  nextStep={nextStep}
                  data={data}
                  isLoading={isNetworkDataLoading}
                />
              </StepContent>
            </Step>

            <Step
              title={
                isBasicIntegrationDone
                  ? `Your Village is being built. Eta ${etaParser(
                      googleIntegration.eta
                    )}.`
                  : 'Discover your Village to join them'
              }
            >
              <StepContent>
                <StepIntegrations
                  isIntegrationLoading={isIntegrationLoading}
                  googleIntegration={googleIntegration}
                  setGoogleIntegration={setGoogleIntegration}
                  additionalIntegrations={additionalIntegrations}
                  setAdditionalIntegrations={setAdditionalIntegrations}
                  isLoggedOnLinkedin={isLoggedOnLinkedin}
                  activeStep={activeStep}
                  nextStep={nextStep}
                  isBasicIntegrationDone={isBasicIntegrationDone}
                />
              </StepContent>
            </Step>
            {/* 
            <Step title="Connect with other Villages">
              <StepContent>
                <StepInvites prevStep={() => setActiveStep(1)} />
              </StepContent>
            </Step> */}
          </Steps>
        </Box>{' '}
      </ModalContent>
    </Modal>
  )
}

import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { SidebarItemWrapper } from 'components/NewUI/SidebarItemWrapper'
import { PostedByListItem } from './PostedByListItem'

const listItems = [
  { label: 'Close friends of friends', id: 'warm' },
  { label: 'Close friends', id: 'private' },
  { label: 'Anyone', id: 'public' },
]

export const PostedByList = ({ listId, setListId, changeFilter }) => {
  return (
    <SidebarItemWrapper mt="6">
      <Text fontSize="lg">Posted by</Text>
      <Box mt="2.5">
        {listItems.map((item, index) => (
          <PostedByListItem
            key={index}
            active={listId === item.id}
            onClick={() => {
              setListId(item.id)
              changeFilter(1, item.id)
            }}
            label={item.label}
          />
        ))}
      </Box>

      {/*<Accordion px="27px" allowToggle p="0" mt="40px">
        <AccordionItem mb="36px" px="27px" p="0" border="0">
          {({ isExpanded }) => (
            <>
              <AccordionButton ml="27px" w="calc(99% - 27px)" p="0">
                <AiFillCaretDown
                  style={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                  width="17px"
                  height="12px"
                  color={mode('black', 'white')}
                />
                <Text
                  ml="13px"
                  fontSize="16px"
                  color={mode('black', 'white')}
                  fontWeight="bold"
                >
                  Coworkers
                </Text>
              </AccordionButton>
              <AccordionPanel p="0">
                <Box
                  px="27px"
                  py="9px"
                  bg={mode('#ECECEC', 'white')}
                  mx="-27px"
                  mt="14px"
                >
                  <Text color="black" px="54px">
                    From LinkedIn
                  </Text>
                </Box>

                <Box px="27px" py="9px" bg="transparent" mx="-27px">
                  <Text color={mode('black', 'white')} px="54px">
                    From LinkedIn
                  </Text>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
                </Accordion> */}
    </SidebarItemWrapper>
  )
}

import React from 'react'
import {
  Box,
  Container,
  Text,
  Flex,
  Image,
  AvatarGroup,
  Avatar,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import { CgArrowLongRight } from 'react-icons/cg'

const intents = [
  'Find warm investors',
  'Headhunt top talent',
  'Connect to strategic partners',
  'Onboard early adopters',
  'Robust people/company search',
  'Manage Kanban Pipeline',
  'Double opt-in warm intros',
  'Receive referrals for your private opportunities',
]

export const CommunitiesView = () => {
  return (
    <Container maxWidth="1130px">
      <Flex mt="8" direction="column" alignItems="center" textAlign="center">
        <Flex
          w="60%"
          mt="5"
          mb="9"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box backgroundColor="orange.400" p="34">
            <Text fontSize="2xl">Y</Text>
          </Box>
          <Text color="#606060" fontWeight="bold" fontSize="40px">
            X
          </Text>
          <Image src="/logo-transparent.png" h="55px" />
        </Flex>
        <Text fontSize="35px" as="span" color="turquoise.500">
          Join the{' '}
          <Text as="span" fontWeight="bold">
            Y Combinator{' '}
          </Text>
          community of{' '}
          <Text as="span" fontWeight="bold">
            3,490 founders
          </Text>{' '}
          on Village
        </Text>
        <Text mt="6" as="span" fontSize="2xl">
          ✨ Discover warm intros to{' '}
          <Text as="span" fontWeight="bold">
            34,235 people
          </Text>{' '}
          and{' '}
          <Text as="span" fontWeight="bold">
            7,889 companies
          </Text>
        </Text>
        <Flex mt="8" w="60%" justifyContent="space-evenly">
          <Box textAlign="right">
            <AvatarGroup>
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Ryan Florence"
                src="https://bit.ly/ryan-florence"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <Text fontSize="xs" mt="3">
              34.235 Warm People
            </Text>
          </Box>
          <Box textAlign="right">
            <AvatarGroup size="md">
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Ryan Florence"
                src="https://bit.ly/ryan-florence"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Segun Adebayo"
                src="https://bit.ly/sage-adebayo"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Kent Dodds"
                src="https://bit.ly/kent-c-dodds"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar
                w="40px"
                h="40px"
                border="2px"
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
              />
            </AvatarGroup>
            <Text fontSize="xs" mt="3">
              7.889 Warm Companies
            </Text>
          </Box>
        </Flex>
        <Flex sx={{ gap: 5 }} mt="26px">
          {intents.slice(0, 4).map((intent, index) => (
            <Flex
              px="4"
              py="2.5"
              h="35px"
              alignItems="center"
              key={index}
              borderRadius="3xl"
              color={mode('white', '#606060')}
              bg={mode('black', '#222222')}
              borderColor="black"
              borderWidth={1}
            >
              <Text as="span" fontSize="xs">
                {intent}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex alignItems="center" mt="74px" sx={{ gap: 18 }}>
          <Box borderRadius="full" border="2px" borderColor="turquoise.500">
            <Avatar
              w="40px"
              h="40px"
              name="Christian Nwamba"
              src="https://bit.ly/code-beast"
            />
          </Box>
          <Text
            color="turquoise.500"
            fontSize="20px"
            fontWeight="bold"
            as="span"
          >
            Ash,{' '}
            <Text fontWeight="normal" as="span">
              your startup ActivePieces is eligible to join!
            </Text>
          </Text>
        </Flex>
        <Button
          filter="drop-shadow(6px 9px 0px #383838)"
          rightIcon={<CgArrowLongRight />}
          variant="village"
          mt="22px"
        >
          Skip the waitlist
        </Button>
        <Text color="#727272" mt="27px" as="span">
          Not Andrew?{' '}
          <Text
            as="span"
            sx={{ textUnderlineOffset: '2px' }}
            textDecor="underline"
          >
            Join the waitlist
          </Text>
        </Text>
        <Flex
          w="60%"
          sx={{ gap: 10 }}
          justifyContent="center"
          wrap="wrap"
          pb="20"
          mt="72px"
        >
          {intents.slice(4).map((intent, index) => (
            <Flex
              px="4"
              py="2.5"
              h="39px"
              alignItems="center"
              key={index}
              borderRadius="3xl"
              color={mode('white', '#606060')}
              bg={mode('black', '#222222')}
              borderColor="black"
              borderWidth={1}
            >
              <Text as="span">{intent}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

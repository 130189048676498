
import { useState } from "react";
import qs from 'query-string'

const setQueryStringWithoutPageReload = qsValue => { 
  const newurl = window.location.protocol + "//" +
                 window.location.host + 
                 window.location.pathname + 
                 qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

const setQueryStringValue = ( 
  value, 
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({...values, ...value }); 
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const getQueryStringValue = (
  initialValue, 
  queryString = window.location.search
) => { 
  const values = qs.parse(queryString); 
  const valuesKeys = Object.keys(values);

  const initialValueCopy = initialValue

  for (const key of valuesKeys) {
    initialValueCopy[key] = values[key]
  }

  return initialValueCopy;
};

function useQueryString(initialValue) {
  const [value, setValue] = useState(getQueryStringValue(initialValue));

  const onSetValue = 
    newValue => {
      setValue(newValue);
      setQueryStringValue(newValue);
    }


  return [value, onSetValue];
}

export default useQueryString;
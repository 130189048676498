import {
  Avatar,
  Badge,
  Box,
  Button,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { IoIosGitNetwork } from 'react-icons/io'
import { Link } from 'react-router-dom'
import api from 'services/api'
import { encryptId } from 'utils/encryption'
import GetInTouchDropdown from './GetInTouchDropdown'
import AddToBoard from './KanbanBoard/AddToBoard'
import PathsModal from './PathsModal'
import RelationshipConnector from './RelationshipConnector'
import RelationshipStrength from './RelationshipStrength'

export default function CompanyModal({
  company,
  isOpen,
  onOpen,
  onClose,
  // pastCompanies,
  ...rest
}) {
  const {
    user: { identityId: userIdentityId },
  } = useAuth()

  const [relationships, setRelationships] = useState([])

  const [isPathsModalOpen, setIsPathsModalOpen] = useState(false)

  useEffect(() => {
    const fetchComapnyRelationships = async () => {
      const { data } = await api.get(`/company/${company.id}`)
      setRelationships(data)
      console.log('data', data)
    }

    if (isOpen) {
      fetchComapnyRelationships()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody maxH="75vh" overflowY="auto" overflowX="hidden" p={6}>
          <Text mb={2} p={2}>
            People from{' '}
            <chakra.span fontWeight="bold">{company.name}</chakra.span>
          </Text>

          <Box d="flex" flexDir="column" alignItems="center" p={2}>
            {relationships
              .sort((a, b) => {
                if (a.current > b.current && a.warmth_score > 0) return -1
                if (a.current < b.current && b.warmth_score > 0) return 1

                if (a.warmth_score > b.warmth_score) return -1
                if (a.warmth_score < b.warmth_score) return 1

                return 0
              })
              .slice(0, 5)
              .map((relationship, relationshipIndex) => {
                const connectors = JSON.parse(relationship.connectors)

                const encryptedId = encryptId(relationship.right_identity_id)

                const encryptedUrl = `/people/profile/${encryptedId}`

                const positions = relationship.position

                const jobHistory = relationship.period_from.map(
                  (from, index) => {
                    const to = relationship.period_to[index]
                    const periodFrom = moment(from).format(`MMM'YY`)
                    const periodTo =
                      to === null ? 'now' : moment(to).format(`MMM'YY`)
                    return {
                      period: `${periodFrom} - ${periodTo}`,
                      position: positions[index],
                    }
                  }
                )

                const isUserConnectedAndHaveAWarmConnection =
                  connectors.findIndex(
                    (c) => c.identity_id === userIdentityId
                  ) !== -1 &&
                  relationship.direct_warmth &&
                  relationship.direct_warmth > 0

                const userIsTheOnlyConnector =
                  connectors.length === 1 &&
                  connectors[0].identity_id === userIdentityId

                return (
                  <Box
                    key={relationshipIndex}
                    w="100%"
                    minH="6rem"
                    maxH="6rem"
                    d="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="1px"
                    borderColor={mode('gray.200', 'grayAlpha.200')}
                    _last={{
                      borderBottom: 'none',
                    }}
                  >
                    <Box
                      minW="22.5%"
                      maxW="22.5%"
                      d="flex"
                      alignItems="center"
                      px={1}
                    >
                      <Avatar
                        name={`${relationship?.firstname} ${relationship?.lastname}`}
                        mr={2}
                        size="md"
                        src={relationship.avatar ? relationship.avatar : ''}
                        bg="primary.500"
                        color="white"
                      />
                      <Box
                        d="flex"
                        alignItems="flex-start"
                        flexDir="column"
                        minW="100%"
                        maxW="100%"
                      >
                        <Text
                          as="a"
                          href={encryptedUrl}
                          onAuxClick={() => window.open(encryptedUrl)}
                          target="_blank"
                          isTruncated
                          maxW="70%"
                          fontWeight="bold"
                        >
                          {relationship?.firstname} {relationship?.lastname}
                        </Text>
                      </Box>
                    </Box>

                    <Box minW="17.5%" maxW="17.5%">
                      <Tooltip
                        bgColor={mode('#2D3748', '#CBD5E0')}
                        color={mode('#F7FAFC', '#1A202C')}
                        label={`${jobHistory[0]?.position} (${jobHistory[0]?.period})`}
                      >
                        <Box>
                          <Text isTruncated maxW="90%">
                            {jobHistory[0]?.position}
                          </Text>
                          <Text
                            fontSize="sm"
                            textColor={mode('#718096', '#505050')}
                            d="flex"
                            alignItems="center"
                          >
                            {jobHistory[0]?.period}{' '}
                            {jobHistory[0]?.period.slice(-3) !== 'now' && (
                              <Badge
                                ml={2}
                                bgColor={mode('#EDF2F7', '#E2E8F029')}
                                color={mode('#1A202C', '#E2E8F0')}
                                size="xs"
                                borderRadius="full"
                              >
                                PAST
                              </Badge>
                            )}
                          </Text>
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box minW="20%" maxW="20%">
                      <RelationshipConnector
                        openPathsModal={() =>
                          setIsPathsModalOpen(relationship.id)
                        }
                        connectors={connectors}
                      />
                    </Box>
                    <Box
                      minW="12.5%"
                      maxW="12.5%"
                      d="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <RelationshipStrength
                        relationship={relationship}
                        identities={
                          isUserConnectedAndHaveAWarmConnection ||
                          userIsTheOnlyConnector
                            ? [
                                relationship.right_identity_id,
                                userIdentityId,
                              ].sort((a, b) => a - b)
                            : undefined
                        }
                      />
                    </Box>

                    <Box
                      minW="27.5%"
                      maxW="27.5%"
                      d="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <AddToBoard
                        selected={[
                          {
                            entity_id: relationship.right_identity_id,
                            entity: 'identity',
                          },
                        ]}
                        mr={2}
                      />
                      {isUserConnectedAndHaveAWarmConnection ||
                      userIsTheOnlyConnector ? (
                        <GetInTouchDropdown
                          contactId={relationship.right_identity_id}
                          float="right"
                          linkedinUrl={
                            relationship.linkedin_profile
                              ? relationship.linkedin_profile.substring(2)
                              : false
                          }
                        />
                      ) : (
                        <Button
                          onClick={() => setIsPathsModalOpen(relationship.id)}
                          variant="villageOutline"
                          w="135px"
                          color="middle.500"
                          borderColor="middle.500"
                          leftIcon={
                            <Icon
                              as={IoIosGitNetwork}
                              color="middle.500"
                              h="5"
                              w="5"
                            />
                          }
                          float="right"
                        >
                          Get intro
                        </Button>
                      )}
                    </Box>

                    {Boolean(connectors) && (
                      <PathsModal
                        relationship={relationship}
                        isOpen={isPathsModalOpen === relationship.id}
                        onOpen={() => setIsPathsModalOpen(relationship.id)}
                        onClose={() => setIsPathsModalOpen(false)}
                      />
                    )}
                  </Box>
                )
              })}
          </Box>

          <Button
            mt={2}
            p={2}
            variant="link"
            textDecor="underline"
            fontWeight="semibold"
            rightIcon={<FaArrowRight />}
            color={mode('#718096', '#E2E8F0')}
            as={Link}
            to={`/people?company=${encodeURIComponent(company.name)}`}
          >
            Show all {company.total_network} people
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

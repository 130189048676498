import {
  Box,
  Checkbox,
  Button,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

function RelationshipsHeader({ selected, handleSelectAll }) {
  const isChecked = selected.length > 9
  const isIndeterminate = selected.length > 0 && !isChecked

  return (
    <Box
      w="100%"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
      color={mode('black', 'grayAlpha.5')}
      fontSize="sm"
    >
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="2%">
        <Checkbox
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          onChange={() => handleSelectAll(isChecked)}
        />
      </Box>
      <Box minW="15%" maxW="15%" d="flex" alignItems="center">
        Name
      </Box>
      <Box minW="14%" maxW="14%" d="flex" alignItems="center">
        Connection
      </Box>
      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Strength
      </Box>
      <Box
        minW="20%"
        maxW="20%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Work
      </Box>

      <Box
        minW="10%"
        maxW="10%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Location
      </Box>

      <Box
        minW="8%"
        maxW="8%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Social
      </Box>
      <Box
        minW="19%"
        maxW="19%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        Actions
      </Box>
    </Box>
  )
}

function CompaniesHeader({ selected, handleSelectAll }) {
  const isChecked = selected.length > 9
  const isIndeterminate = selected.length > 0 && !isChecked

  return (
    <Box
      w="100%"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
      color={mode('black', 'grayAlpha.5')}
      fontSize="sm"
    >
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="2%">
        <Checkbox
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          onChange={() => handleSelectAll(isChecked)}
        />
      </Box>
      <Box minW="15%" maxW="15%" d="flex" alignItems="center">
        Name
      </Box>
      <Box minW="14%" maxW="14%" d="flex" alignItems="center">
        People
      </Box>
      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Top Warmth
      </Box>
      <Box
        minW="14%"
        maxW="14%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Industry
      </Box>
      <Box
        minW="10%"
        maxW="10%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Employee Size
      </Box>
      <Box
        minW="8%"
        maxW="8%%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Social
      </Box>
      <Box
        minW="12%"
        maxW="12%"
        d="flex"
        alignItems="center"
        justifyContent="center"
      >
        Website
      </Box>
      <Box
        minW="11%"
        maxW="11%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        Actions
      </Box>
    </Box>
  )
}

function JobsHeader() {
  return (
    <Box
      w="100%"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
      color={mode('black', 'grayAlpha.5')}
      fontSize="sm"
    >
      <Box d="flex" alignItems="center" justifyContent="flex-start" w="1%" />
      <Box w="29%" d="flex" alignItems="center">
        Name
      </Box>
      <Box w="14%" d="flex" alignItems="center">
        People
      </Box>
      <Box w="14%" d="flex" alignItems="center" justifyContent="center">
        Top Warmth
      </Box>
      <Box w="14%" d="flex" alignItems="center" justifyContent="center">
        Industry
      </Box>
      <Box w="14%" d="flex" alignItems="center" justifyContent="center">
        Employee Size
      </Box>
      <Box
        w="14%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
        pr={2}
      >
        Referral
      </Box>
    </Box>
  )
}

function IntrosHeader() {
  return (
    <Box
      w="100%"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      color={mode('black', 'grayAlpha.5')}
      fontSize="sm"
    >
      <Box w="25%" d="flex" alignItems="center" pl={2}>
        Name
      </Box>
      <Box w="50%" d="flex" alignItems="center" justifyContent="center">
        Status
      </Box>
      <Box
        w="25%"
        d="flex"
        alignItems="center"
        justifyContent="flex-end"
        pr={2}
      >
        Action
      </Box>
    </Box>
  )
}

function OpportunitiesHeader({
  activeField,
  setActiveField,
  orderBy,
  setOrderBy,
}) {
  const renderIcon = (field) => {
    if (activeField !== field) return null

    if (orderBy === 'asc') {
      return <FaChevronUp size="10" />
    }

    return <FaChevronDown size="10" />
  }

  const changeFilter = (field) => {
    if (activeField === field) {
      setOrderBy(orderBy === 'asc' ? 'desc' : 'asc')
    } else {
      setActiveField(field)
      setOrderBy('asc')
    }
  }

  return (
    <Box
      w="100%"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
      color={mode('black', 'grayAlpha.5')}
      fontSize="sm"
    >
      <Button
        sx={{ gap: 8 }}
        d="inline-flex"
        alignItems="center"
        justifyContent="flex-start"
        fontWeight="normal"
        variant="unstyled"
        w="20%"
        onClick={() => changeFilter('name')}
      >
        <Text fontSize="sm" color={mode('black', 'grayAlpha.5')}>
          Name
        </Text>
        {renderIcon('name')}
      </Button>
      <Button
        sx={{ gap: 8 }}
        d="inline-flex"
        alignItems="center"
        fontWeight="normal"
        variant="unstyled"
        w="20%"
        onClick={() => changeFilter('has_viewed')}
      >
        <Text fontSize="sm" color={mode('black', 'grayAlpha.5')}>
          Viewed
        </Text>
        {renderIcon('has_viewed')}
      </Button>
      <Button
        sx={{ gap: 8 }}
        d="inline-flex"
        alignItems="center"
        fontWeight="normal"
        variant="unstyled"
        w="20%"
        onClick={() => changeFilter('is_applied')}
      >
        <Text fontSize="sm" color={mode('black', 'grayAlpha.5')}>
          Applied
        </Text>
        {renderIcon('is_applied')}
      </Button>
      <Button
        sx={{ gap: 8 }}
        d="inline-flex"
        alignItems="center"
        fontWeight="normal"
        variant="unstyled"
        w="20%"
        onClick={() => changeFilter('recommendation_count')}
      >
        <Text fontSize="sm" color={mode('black', 'grayAlpha.5')}>
          Leads recommended
        </Text>
        {renderIcon('recommendation_count')}
      </Button>
      <Button
        sx={{ gap: 8 }}
        d="inline-flex"
        alignItems="center"
        fontWeight="normal"
        variant="unstyled"
        w="20%"
        onClick={() => changeFilter('latest_activity')}
      >
        <Text fontSize="sm" color={mode('black', 'grayAlpha.5')}>
          Latest activity
        </Text>
        {renderIcon('latest_activity')}
      </Button>
    </Box>
  )
}

export {
  RelationshipsHeader,
  CompaniesHeader,
  JobsHeader,
  IntrosHeader,
  OpportunitiesHeader,
}

import { Box, Spinner } from '@chakra-ui/react'
import LandingInfo from 'components/LandingInfo'
import Request from 'components/WeeklyIntrosRequests/Request'
import Target from 'components/WeeklyIntrosRequests/Target'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import api from 'services/api'

const getQueryStringValue = () => {
  const values = qs.parse(window.location.search)

  if (values) {
    return values
  } else {
    return ''
  }
}

export default function Meeting() {
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdatingPick, setIsUpdatingPick] = useState(false)
  const [data, setData] = useState({
    pick: null,
    stats: null,
  })
  const [from, setFrom] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const fetchIntroducer = async (token, from) => {
      setIsLoading(true)

      try {
        const { data } = await api.get(`/pick?token=${token}&from=${from}`)

        setData(data)
      } catch (err) {
        console.log(err)
      }

      setIsLoading(false)
    }

    const { from, token } = getQueryStringValue()
    if (token) {
      setFrom(from)
      setToken(token)
      fetchIntroducer(token, from)
    }
  }, [])

  useEffect(() => {
    const { feedback, from } = getQueryStringValue()
    if (
      feedback &&
      data.pick &&
      data.pick.id &&
      ((from === 'introducer' && data.pick.status === 'interested') ||
        (from === 'target' && data.pick.status === 'recommended'))
    ) {
      updatePick(feedback)
    }
  }, [data.pick])

  const updatePick = async (value) => {
    setIsUpdatingPick(value)
    try {
      await api.patch(`/pick/${data.pick.id}?token=${token}&from=${from}`, {
        status: value,
      })
      const newData = { ...data }
      newData.pick.status = value
      setData(newData)
    } catch (err) {}
    setIsUpdatingPick(false)
  }

  if (isLoading) {
    return (
      <Box d="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <Box>
      <LandingInfo
        title={`Hey, ${data?.pick && data.pick[from]?.firstname}!`}
        subtitle={
          from === 'target'
            ? `Are you interested to meet with ${data?.pick?.requester?.firstname}?`
            : `${data?.pick?.requester?.firstname} is interested to meet with ${data?.pick?.target?.firstname}.`
        }
        person={data?.stats?.person}
        stats={data?.stats}
      >
        {from === 'target' ? (
          <Target
            pick={data?.pick}
            updatePick={updatePick}
            isUpdatingPick={isUpdatingPick}
          />
        ) : (
          <Request
            pick={data?.pick}
            updatePick={updatePick}
            isUpdatingPick={isUpdatingPick}
          />
        )}
      </LandingInfo>
    </Box>
  )
}

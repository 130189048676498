import {
  Avatar,
  Box,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import JobHistoryPopover from 'components/JobHistoryPopover'
import { ProfileTitle } from 'components/ProfileTitle'
import TripleButtonGroup from 'components/TripleButtonGroup'

export default function Target({ pick, updatePick, isUpdatingPick }) {
  const { requester, introducer } = pick

  const isTruncated = useBreakpointValue({ base: false, lg: true })

  return (
    <Box bg={mode('gray.100', 'grayAlpha.700')} borderRadius="25px" mb={[8, 4]}>
      <Box
        py={4}
        d="flex"
        flexDir={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box w={['100%', 'auto']} pl={[2, 4]} pr={[2, 0]}>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDir={['column', 'row']}
            mb={1}
          >
            <Box w="4rem" mr={[0, 2]} mb={[2, 0]}>
              <Avatar
                name={`${requester.firstname} ${requester.lastname}`}
                size="lg"
                src={requester.avatar}
                bg={'primary.500'}
                color="white"
              />
            </Box>

            <Box
              d="flex"
              alignItems={['center', 'flex-start']}
              flexDir="column"
              textAlign={['center', 'left']}
              mb={[2, 0]}
            >
              <ProfileTitle
                name={`${requester.firstname} ${requester.lastname}`}
                identityId={requester.id}
                fontWeight="bold"
                maxW="80%"
              />

              <JobHistoryPopover identity_id={requester.id}>
                <Text
                  isTruncated={isTruncated}
                  maxW={'45ch'}
                  minW="25ch"
                  fontSize="sm"
                >
                  {requester.headline}
                </Text>
              </JobHistoryPopover>

              <Text
                fontSize="sm"
                isTruncated={isTruncated}
                d={isTruncated && 'flex'}
                alignItems="center"
                maxW="80vw"
              >
                Both of you are friends with{' '}
                <Avatar src={introducer.avatar} size="2xs" mx={1} />{' '}
                {introducer.firstname}, {pick.similarity_sentence}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box ml={['0', 'auto']} w={['100%', 'auto']} pl={[2, 0]} pr={[2, 4]}>
          <TripleButtonGroup
            mainButton={{
              label: `Yes, sure!`,
              onClick: () => {
                updatePick('accepted')
              },
              isDisabled: isUpdatingPick || pick.status !== 'recommended',
            }}
            secondaryButton={{
              label: `Not really`,
              onClick: () => {
                updatePick('denied')
              },
              isDisabled: isUpdatingPick || pick.status !== 'recommended',
            }}
          />
        </Box>
      </Box>
      {['accepted', 'denied'].includes(pick.status) && (
        <Box
          py={1}
          px={4}
          color={mode('grayAlpha.700', 'grayAlpha.0')}
          bg={
            pick.status === 'accepted'
              ? mode('green.300', 'green.900')
              : mode('red.300', 'red.900')
          }
          borderBottomLeftRadius="25px"
          borderBottomRightRadius="25px"
        >
          <Text fontSize="smaller">
            {pick.status === 'accepted'
              ? `Sweet! We’ll send you both an email to kick-off the intro. Good luck!`
              : `Thanks for letting us know!`}
          </Text>
        </Box>
      )}
    </Box>
  )
}

import React from 'react'
import Editor from '@sunsama/rich-markdown-editor'
import { light, dark } from './SunsamaEditorTheme'
import { urlMatcher } from 'utils/parsers'
import Embed from 'react-embed'
import api from 'services/api'
import { useColorMode } from '@chakra-ui/react'

export const SunsamaEditor = ({
  placeholder,
  autoFocus,
  defaultValue,
  onChange,
}) => {
  const theme = useColorMode().colorMode

  const uploadImage = async (file) => {
    try {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        const res = await api.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        return res.data.url
      }
    } catch (e) {
      console.log(e)
    }
  }

  const EmbedManager = (props) => {
    const { attrs } = props

    return <Embed url={attrs.href} />
  }

  return (
    <Editor
      embeds={[
        {
          matcher: (url) => {
            return urlMatcher(url)
          },
          component: EmbedManager,
        },
      ]}
      className="sunsama-editor"
      defaultValue={defaultValue}
      onChange={(value) => onChange(value())}
      autoFocus={autoFocus}
      theme={theme === 'light' ? light : dark}
      uploadImage={async (file) => uploadImage(file)}
      placeholder={placeholder}
      disabledExtensions={['notice']}
    />
  )
}

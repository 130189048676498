import {
  Box,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import { useKanban } from 'context/KanbanContext'

import KanbanBoard from 'components/KanbanBoard'
import BoardCard from 'components/KanbanBoard/BoardCard'
import NewBoardPopover from 'components/KanbanBoard/NewBoardPopover'

export default function MyBoards() {
  const {
    onOpen: onFormOpen,
    isOpen: isFormOpen,
    onClose: onFormClose,
  } = useDisclosure()
  const { boards, currentBoard } = useKanban()

  const sortedBoards = boards
    ? Object.keys(boards).sort((a, b) => a.localeCompare(b))
    : []

  return (
    <Box pos="relative" h="100%">
      <Box
        as="section"
        d="flex"
        justifyContent="flex-start"
        alignItems="center"
        gridGap="4"
        bg={mode('gray.50', 'grayAlpha.900')}
        p={6}
        maxW="100%"
        overflowX="auto"
      >
        {sortedBoards.map((board, boardIndex) => (
          <BoardCard key={boardIndex} data={boards[board]} title={board} />
        ))}

        <NewBoardPopover
          isOpen={isFormOpen}
          onOpen={onFormOpen}
          onClose={onFormClose}
        >
          <Box
            h="100px"
            minW="210px"
            maxW="210px"
            bg={mode('white', 'grayAlpha.700')}
            fontWeight="semibold"
            d="flex"
            justifyContent="center"
            alignItems="center"
            border={'1px solid'}
            borderColor={mode('gray.200', 'grayAlpha.800')}
            borderRadius="md"
            cursor="pointer"
            _hover={{ bg: mode('gray.50', 'grayAlpha.600') }}
          >
            <Text>Create new board</Text>
          </Box>
        </NewBoardPopover>
      </Box>

      <Box p={6}>
        {currentBoard && boards[currentBoard]?.id && (
          <KanbanBoard data={boards[currentBoard]} />
        )}
      </Box>
    </Box>
  )
}

import {
  Avatar,
  AvatarBadge,
  Box,
  chakra,
  Collapse,
  Icon,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'
import Container from 'components/Container'
import moment from 'moment'
import {
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaQuestion,
  FaTimes,
} from 'react-icons/fa'
import StatusBadge from './StatusBadge'

const colors = {
  accepted: 'green.500',
  denied: 'red.500',
  unknown: 'gray.500',
}

const text = {
  accepted: 'Accepted',
  denied: 'Think not a good fit',
  unknown: `Don't know the person`,
  null: 'Pending',
}

export default function IntroRequestGroup({
  person,
  data,
  children,
  isIntroducer,
}) {
  const { isOpen, onToggle } = useDisclosure()

  const introsPending = isIntroducer
    ? data.filter((introducer) => !introducer.introducer_feedback).length
    : false

  return (
    <>
      <Container
        isSelected={isOpen}
        onClick={onToggle}
        cursor="pointer"
        justifyContent="flex-start"
        borderBottomRightRadius={isOpen && '0px'}
        borderBottomLeftRadius={isOpen && '0px'}
        pos="relative"
      >
        <Box w="25%" d="flex" alignItems="center" fontWeight="bold">
          <Icon as={isOpen ? FaCaretDown : FaCaretRight} mx={2} />
          <Avatar src={person.avatar} name={person.name} size="md" mr={2} />
          <Box>
            <Text>{person.name}</Text>
            {person.latest_update && (
              <Text fontSize="xs" fontWeight="normal">
                Latest activity on:{' '}
                {moment(person.latest_update).format('MMM DD, YYYY [at] h:mma')}
              </Text>
            )}
          </Box>
        </Box>

        <Box w="50%" d="flex" justifyContent="center" alignItems="center">
          {isIntroducer ? (
            `${
              Boolean(introsPending)
                ? `${introsPending} due actions`
                : `No further actions`
            } `
          ) : (
            <>
              <StatusBadge
                feedbacks={Object.entries(
                  data
                    .map((d) =>
                      d.introducer_feedback === 'unknown'
                        ? 'denied'
                        : d.introducer_feedback
                    )
                    .reduce((acc, curr) => {
                      if (acc[curr]) {
                        acc[curr] += 1
                      } else {
                        acc[curr] = 1
                      }

                      return acc
                    }, {})
                )}
              />

              <AvatarGroup size="sm" max={3} spacing="-0.5rem">
                {data.map((introducer, introducerIndex) => (
                  <Avatar
                    key={introducerIndex}
                    src={introducer.introducer_avatar}
                    name={`${introducer.introducer_firstname} ${introducer.introducer_lastname}`}
                    w="40px"
                    h="40px"
                  >
                    <AvatarBadge
                      borderRadius="full"
                      bg={
                        ['denied', 'unknown', 'canceled'].includes(
                          introducer.introducer_feedback
                        )
                          ? 'red.500'
                          : ['accepted', 'pending', 'completed'].includes(
                              introducer.introducer_feedback
                            )
                          ? 'green.500'
                          : 'yellow.500'
                      }
                      right="2px"
                      p="0.4rem"
                      w={4}
                      h={4}
                    >
                      <Icon
                        as={
                          ['denied', 'unknown', 'canceled'].includes(
                            introducer.introducer_feedback
                          )
                            ? FaTimes
                            : ['accepted', 'pending', 'completed'].includes(
                                introducer.introducer_feedback
                              )
                            ? FaCheck
                            : FaQuestion
                        }
                        color="white"
                        w="0.6rem"
                        h="0.6rem"
                      />
                    </AvatarBadge>
                  </Avatar>
                ))}
              </AvatarGroup>
            </>
          )}
        </Box>
        {isOpen && (
          <chakra.span
            pos="absolute"
            bottom="-0.6rem"
            left="1.75rem"
            zIndex="999"
            fontSize="0.7rem"
            fontWeight="bold"
            bg={mode('#f1f1f1', 'grayAlpha.700')}
            px={2}
            textTransform="uppercase"
            color={mode('gray.500', 'grayAlpha.5')}
          >
            {isIntroducer
              ? `Intros requested by ${person.name}:`
              : `Intro to ${person.name} requested from:`}
          </chakra.span>
        )}
      </Container>
      <Collapse in={isOpen}>
        <Box borderTop="1px" borderColor={mode('gray.200', 'grayAlpha.600')}>
          {children}
        </Box>
      </Collapse>
    </>
  )
}

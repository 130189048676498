import { Box, Button, Input } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

export default function FakeLogin() {
  const { state } = useLocation()
  const { fakeSignIn, signed, loading } = useAuth()

  const [id, setId] = useState('')
  const [secret, setSecret] = useState('')

  if (!loading && signed) {
    return <Redirect to={state ? state.from : '/dashboard'} />
  }

  return (
    <Box d="flex" justifyContent="center" alignItems="center">
      <Input
        borderRadius="0"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <Input
        borderRadius="0"
        value={secret}
        onChange={(e) => setSecret(e.target.value)}
      ></Input>
      <Button
        borderRadius="0"
        variant="village"
        onClick={() => fakeSignIn({ id, secret })}
      />
    </Box>
  )
}

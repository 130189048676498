import {
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'

import api from 'services/api'

const exampleProviders = ['gmail', 'outlook', 'icloud']

export default function Waitlist({ isOpen, onClose }) {
  const toast = useToast()

  const [waitlistEmails, setWaitlistEmails] = useState(['', '', ''])
  const [isSendingWaitlist, setIsSendingWaitlist] = useState(false)

  const sendWaitlistEmails = async (e) => {
    e.preventDefault()

    const notEmptyEmails = waitlistEmails.filter((email) => email.length > 0)

    if (notEmptyEmails.length === 0) {
      return toast({
        title: 'Error',
        description: 'Something went wrong. Please check the inputs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    setIsSendingWaitlist(true)

    try {
      await api.post(`/waitlist`, { emails: notEmptyEmails })

      setIsSendingWaitlist(false)
      onClose()
      toast({
        title: 'Success',
        description: 'We added your emails to the waitlist.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch ({ response }) {
      setIsSendingWaitlist(false)
      const description = response?.data?.message ?? 'Something went wrong'
      toast({
        title: 'Error',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          bg="grayAlpha.700"
          p={10}
          textAlign="left"
          as="form"
          onSubmit={sendWaitlistEmails}
        >
          <Text fontSize="3xl" fontWeight="bold" mb={2} color="white">
            When someone who knows you gets invited, we’ll let them know you're
            on the waitlist.
          </Text>
          <Text textColor="grayAlpha.5">
            Type the emails you use so your friends can identify you.
          </Text>
          <Stack spacing={2} my={6} alignItems="flex-start">
            {waitlistEmails.map((email, index) => (
              <Input
                key={index}
                bg="black"
                borderColor="grayAlpha.700"
                color="white"
                type="email"
                value={email}
                onChange={({ target: { value } }) =>
                  setWaitlistEmails(
                    waitlistEmails.map((e, i) => (i === index ? value : e))
                  )
                }
                placeholder={
                  index < 3
                    ? `example@${exampleProviders[index]}.com`
                    : `example@${exampleProviders[0]}.com`
                }
                _placeholder={{
                  color: 'grayAlpha.50',
                }}
                size="lg"
              />
            ))}
            <Button
              onClick={() => {
                const emailsCopy = [...waitlistEmails]
                emailsCopy.push('')
                setWaitlistEmails(emailsCopy)
              }}
              variant="link"
              size="sm"
              textDecor="underline"
              color="white"
            >
              + Add more emails
            </Button>
          </Stack>
          <Center>
            <Button
              fontSize="xl"
              h={16}
              w={48}
              variant="village"
              type="submit"
              isLoading={isSendingWaitlist}
            >
              Join waitlist.
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import { Box, Button } from '@chakra-ui/react'

export default function TripleButtonGroup({
  mainButton,
  secondaryButton,
  linkButton,
  ...props
}) {
  return (
    <Box alignItems="center" justifyContent="flex-end" {...props}>
      <Box
        d="flex"
        flexDir={['column', 'row']}
        alignItems="center"
        justifyContent="flex-end"
        mb={2}
      >
        {mainButton && (
          <Button
            variant="village"
            size="md"
            w={['100%', 'auto']}
            mr={[0, 2]}
            mb={[2, 0]}
            {...mainButton}
          >
            {mainButton.label}
          </Button>
        )}

        {secondaryButton && (
          <Button
            variant="villageOutline"
            size="md"
            w={['100%', 'auto']}
            {...secondaryButton}
          >
            {secondaryButton.label}
          </Button>
        )}
      </Box>
      {linkButton && (
        <Box textAlign="right">
          <Button
            variant="link"
            size="xs"
            mr={[0, 3]}
            mb={[2, 0]}
            textDecor="underline"
            w={['100%', 'auto']}
            fontWeight="normal"
            color="grayAlpha.5"
            {...linkButton}
          >
            {linkButton.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

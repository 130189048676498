import React from 'react'
import {
  Box,
  Flex,
  useColorModeValue as mode,
  Button,
  Text,
  Input,
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { InfoTooltip } from './InfoTooltip'
import { MenuSelect } from 'components/MenuSelect'
import { intentParser } from 'utils/parsers'
import { useOpportunity } from 'context/OpportunityContext'
import DatePicker from 'react-datepicker'

export const OpportunityModalStepOne = ({
  values,
  control,
  errors,
  companies,
  categories,
  currentCompany,
}) => {
  const { category_id } = values

  const { location } = useOpportunity()

  const shouldHideCompany = category_id === 2 || category_id === 19

  return (
    <>
      <Controller
        name="category_id"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Text color={mode('black', 'white')} fontWeight="bold">
                What are you looking for?
              </Text>
              <Flex mt="4" flexWrap="wrap" sx={{ gap: '7px' }}>
                {categories.map((category) => {
                  if (!category.id) return
                  const isActive = value === category.id
                  return (
                    <Button
                      key={category.id}
                      onClick={() => onChange(category.id)}
                      color={
                        isActive
                          ? mode('white', 'black')
                          : mode('black', 'white')
                      }
                      _hover={{
                        bg: mode('gray.500', 'white'),
                        color: mode('white', 'black'),
                      }}
                      borderRadius="3xl"
                      py="1"
                      px="2.5"
                      h="9"
                      bg={
                        isActive
                          ? mode('black', 'white')
                          : mode('white', 'black')
                      }
                      fontWeight="400"
                      borderColor="black"
                      borderWidth={1}
                    >
                      <Text fontSize="sm">{category.title}</Text>
                    </Button>
                  )
                })}
              </Flex>
            </>
          )
        }}
      />

      {!shouldHideCompany && (
        <Controller
          name="company_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Box mt="7">
                <Text color={mode('black', 'white')} fontWeight="bold">
                  Associate company with this posting
                </Text>
                <MenuSelect
                  value={
                    value !== undefined
                      ? companies.find((company) => company.id === value)
                      : null
                  }
                  onChange={(option) => onChange(option.id)}
                  options={companies}
                />
                {errors.company_id && (
                  <Text mt="1" fontSize="xs" color="red.500">
                    This field is required
                  </Text>
                )}
              </Box>
            )
          }}
        />
      )}

      <Controller
        name="title"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Box mt="7">
              <Text color={mode('black', 'white')} fontWeight="bold">
                Title
              </Text>
              <Input
                mt="1"
                value={value}
                onChange={onChange}
                py="6"
                bg={mode('gray.1', 'black')}
                borderColor={errors.title ? 'red.500' : 'transparent'}
                color={mode('black', 'white')}
                placeholder={
                  intentParser(category_id, currentCompany, location)?.title ??
                  'Opportunity title'
                }
              />
              {errors.title && (
                <Text mt="1" fontSize="xs" color="red.500">
                  This field is required
                </Text>
              )}
            </Box>
          )
        }}
      />

      {category_id !== 19 && (
        <Controller
          name="referralReward"
          control={control}
          rules={{
            required: {
              value: false,
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Box mt="7">
                <Flex sx={{ gap: 8 }} alignItems="center">
                  <Text color={mode('black', 'white')} fontWeight="bold">
                    Referral Reward
                  </Text>
                  <InfoTooltip
                    placement="bottom-end"
                    infoText="Give an incentive to people making good referrals"
                  />
                </Flex>
                <Input
                  mt="1"
                  value={value}
                  onChange={onChange}
                  py="6"
                  bg={mode('gray.1', 'black')}
                  color={mode('black', 'white')}
                  borderColor="transparent"
                  placeholder="Referral reward"
                />
              </Box>
            )
          }}
        />
      )}

      <Controller
        name="expireAt"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Box mt="7">
              <Text color={mode('black', 'white')} fontWeight="bold">
                Expiry date
              </Text>
              <DatePicker
                selected={value}
                onChange={(date) => onChange(date)}
                className="date-picker"
              />
              {errors.expireAt && (
                <Text mt="1" fontSize="xs" color="red.500">
                  This field is required
                </Text>
              )}
            </Box>
          )
        }}
      />
    </>
  )
}

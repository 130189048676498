import { OpportunityViewSkeleton } from 'components/OpportunityMarketplace/OpportunityViewSkeleton'
import { useQuery } from 'hooks/useQuery'
import { useToast } from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import { OpportunityView } from './OpportunityView'

export const OpportunityViewContainer = () => {
  const { id, token } = useParams()

  const [loading, setLoading] = useState(true)

  const [shareLoading, setShareLoading] = useState(false)

  const [opportunity, setOpportunity] = useState({})

  const errors = {
    403: 'You are not authorized to view this opportunity.',
    404: 'Opportunity not found.',
    500: 'An error occured.',
  }

  const toast = useToast()

  const hideToken = () => {
    localStorage.setItem('opportunityToken', token)
    window.history.replaceState(null, '', `/opportunity/${id}`)
  }

  const getOpportunityEndpoint = () => {
    if (token) {
      hideToken()
      return `/opportunities/shared/${token}`
    }

    return `/opportunities/${id}`
  }

  const fetchOpportunity = async () => {
    const endpoint = getOpportunityEndpoint()

    try {
      setLoading(true)
      const { data } = await api.get(endpoint)
      setOpportunity(data.opportunity)
      setLoading(false)
    } catch (e) {
      const { status } = e.response
      if (status === 403 || status === 500 || status === 404) {
        toast({
          title: 'Error',
          description: errors[status],
          status: 'error',
        })
      }
    }
  }

  const shareOpportunity = async () => {
    try {
      setShareLoading(true)
      const { data } = await api.post(`/opportunities/${opportunity.id}/share`)
      await navigator.clipboard.writeText(data.url)
      toast({
        title: 'Successfully copied to clipboard.',
        status: 'success',
      })
      setShareLoading(false)
    } catch (e) {
      toast({
        title: 'Something went wrong while sharing opportunity',
        status: 'error',
      })
      setShareLoading(false)
    }
  }

  useEffect(() => {
    fetchOpportunity()
  }, [])

  if (loading) return <OpportunityViewSkeleton />

  return (
    <OpportunityView
      shareLoading={shareLoading}
      shareOpportunity={shareOpportunity}
      opportunity={opportunity}
    />
  )
}

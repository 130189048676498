import React from 'react'
import { Text, useColorModeValue as mode, useColorMode } from '@chakra-ui/react'

export const OpportunityTag = ({ categoryId, ...styleProps }) => {
  const { colorMode } = useColorMode()

  if (!categoryId) return null

  // notion-${colorMode}-${column.color}.50

  const parseText = {
    1: 'Looking for talent',
    2: 'Looking for a job',
    3: 'Looking for investors',
    20: 'Looking for founders',
    12: 'Looking for partners',
    14: 'Looking to collaborate',
    16: 'Looking for early customers',
  }

  const parseBgColor = {
    1: `notion-${colorMode}-purple.200`,
    2: `notion-${colorMode}-blue.200`,
    3: `notion-${colorMode}-yellow.200`,
    20: `notion-${colorMode}-yellow.200`,
    12: `notion-${colorMode}-pink.200`,
    14: `notion-${colorMode}-pink.200`,
    16: `notion-${colorMode}-gray.200`,
  }

  /*const parseTextColor = {
    1: `notion-${colorMode}-purple.700`,
    2: '#545e63',
    3: '#52513d',
    20: '#52513d',
    12: '#b52b53',
    14: '#b52b53',
    16: '#3d3d3d',
  } */

  return (
    <Text
      {...styleProps}
      d="inline-flex"
      borderRadius="md"
      py="1.5"
      px="3"
      bg={parseBgColor[categoryId]}
      fontWeight="bold"
      isTruncated
      fontSize="xs"
    >
      {parseText[categoryId]}
    </Text>
  )
}

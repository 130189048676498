import React from 'react'
import {
  Container,
  Box,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { ProfileInfo } from 'components/Profile/Info'
import { WorkHistory } from 'components/Profile/WorkHistory'
import { EducationHistory } from 'components/Profile/EducationHistory'
import { BoardSection } from 'components/Profile/BoardSection'
import { ProfileStats } from 'components/Profile/Stats'
import { IntroActivity } from 'components/Profile/IntroActivity'
import { useAuth } from 'context/AuthContext'

export const UserProfileView = ({
  profileData,
  activeBoard,
  setActiveBoard,
  handleInputChange,
  sendNote,
  note,
  errors,
  deleteNote,
  sendNoteLoading,
}) => {
  const {
    contactInfo,
    identityInfo,
    relationships,
    workHistory,
    education_history,
    helpfulness,
    inviter_identity_info,
    intros_between,
    board_data,
    path_between_users,
    detailedWorkInfo,
    strongConnection,
    introActivity,
    privateNotes,
  } = profileData

  const renderExpertise = () => {
    return (
      <>
        <Text mt="6" mb="5" fontSize="2xl">
          Work
        </Text>
        {Array.isArray(workHistory) && workHistory.length > 0 ? (
          <WorkHistory history={workHistory} />
        ) : (
          'No work history found.'
        )}

        <Text mt="6" mb="5" fontSize="2xl">
          Education
        </Text>
        {Array.isArray(education_history) && education_history.length > 0 ? (
          <EducationHistory history={education_history} />
        ) : (
          'No education history found.'
        )}
      </>
    )
  }

  const { user } = useAuth()

  const isOwnProfile = identityInfo?.identity_id === user?.identityId

  const renderIntroActivity = () => {
    if (errors.introActivity) return null

    const introActivityCount = Object.values(introActivity).flat().length

    if (introActivityCount === 0) return null

    return (
      <Box my="6" px="1">
        <Text mb="4" fontSize="2xl" fontWeight="bold">
          Latest activity
        </Text>
        <IntroActivity
          user
          identityName={identityInfo?.firstname}
          identityId={identityInfo?.identity_id}
          intros={introActivity}
        />
      </Box>
    )
  }

  return (
    <Container maxW="1130px">
      <Box p="6" mt="4">
        <Box borderRadius="3xl" p="22px" bg={mode('gray.25', 'grayAlpha.500')}>
          <ProfileInfo
            identityId={identityInfo?.identity_id}
            title={`${identityInfo?.firstname} ${identityInfo?.lastname}`}
            avatar={identityInfo?.avatar}
            headline={
              detailedWorkInfo
                ? `${detailedWorkInfo?.current_work_position} @ ${detailedWorkInfo?.current_work_company_name}`
                : identityInfo?.headline
            }
            country={identityInfo?.country_name}
            contactInfo={contactInfo}
            linkedinPath={detailedWorkInfo?.linkedin_profile}
          />
          <ProfileStats
            contactInfo={contactInfo}
            helpfulness={helpfulness}
            inviterInfo={inviter_identity_info}
            introsBetween={intros_between}
            relationships={relationships}
            path={path_between_users}
            strongConnection={strongConnection}
            identityId={identityInfo?.identity_id}
          />
        </Box>

        <Box p="2">
          {!isOwnProfile && (
            <>
              <BoardSection
                privateNotes={privateNotes}
                setActiveBoard={setActiveBoard}
                activeBoard={activeBoard}
                boardData={board_data}
                deleteNote={deleteNote}
                errors={errors}
                sendNoteLoading={sendNoteLoading}
                sendNote={sendNote}
                handleInputChange={handleInputChange}
                note={note}
              />
            </>
          )}
          {renderIntroActivity()}
          <Box px="1">{renderExpertise()}</Box>
        </Box>
      </Box>
    </Container>
  )
}

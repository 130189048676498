import {
  Avatar,
  Box,
  Collapse,
  Container,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useBreakpointValue,
  useColorMode,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import Emoji from 'components/Emoji'
import { useAuth } from 'context/AuthContext'
import { CgAbstract, CgProfile } from 'react-icons/cg'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { encryptId } from 'utils/encryption'
import DesktopNav from './Desktop'
import MobileNav from './Mobile'

const navItems = [
  {
    href: '/dashboard',
    label: 'For you',
  },
  {
    href: '/network',
    label: 'Search Network',
  },

  {
     href: '/opportunities',
     label: 'Opportunities',
     isBeta: true,
  },

  {
    href: '/myboards',
    label: 'Boards',
  },
  {
    href: '/intros/outgoing',
    label: 'Intro Requests',
    isBeta: true,
  },
]

const profileItems = [
  {
    href: '/settings',
    label: 'Settings',
  },
]

const adminIds = [79, 88]

function DesktopNavContainer({ children, location }) {
  const routesWithFullView = [
    '/people',
    '/companies',
    '/jobs',
    '/myboards',
    '/intros/incoming',
    '/intros/outgoing',
    '/settings',
  ]

  if (routesWithFullView.includes(location.pathname)) {
    return (
      <Flex
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
        borderBottom={'1px solid'}
        borderColor={mode('gray.200', 'grayAlpha.700')}
      >
        {children}
      </Flex>
    )
  }

  return (
    <Box
      borderBottom={'1px solid'}
      borderColor={mode('gray.200', 'grayAlpha.700')}
    >
      <Container
        d="flex"
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        alignItems={'center'}
        maxW="1130px"
      >
        {children}
      </Container>
    </Box>
  )
}

export default function Navbar({ setIsSyncProgressModalOpen }) {
  const { toggleColorMode } = useColorMode()
  const { isOpen, onToggle } = useDisclosure()
  const { user, logout } = useAuth()
  const location = useLocation()

  return (
    <Box>
      <DesktopNavContainer location={location}>
        <Flex flex={{ base: 1 }} justify={{ base: 'flex-start', md: 'start' }}>
          <Box textAlign={useBreakpointValue({ base: 'center', md: 'left' })}>
            <Image h="30px" src="/logo-transparent.png" />
          </Box>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav navItems={navItems} location={location} />
          </Flex>
        </Flex>

        <Flex mr="2" display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <Icon as={FaTimes} w={3} h={3} />
              ) : (
                <Icon as={FaBars} w={4} h={4} />
              )
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        {user && (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            alignItems="center"
            direction={'row'}
            spacing={4}
          >
            {adminIds.includes(user.id) && (
              <IconButton
                aria-label="Open sync progress"
                icon={<CgAbstract />}
                onClick={() => setIsSyncProgressModalOpen(true)}
                variant="ghost"
                mr={2}
              />
            )}

            {/* <IconButton
              aria-label="Toggle theme"
              icon={<CgDarkMode />}
              onClick={toggleColorMode}
              variant="ghost"
              mr={2}
            /> */}
            <Box h="100%" d="flex" justifyContent="center" alignItems="center">
              <Menu>
                <MenuButton>
                  <Avatar name={user.name} src={user.avatar} size="sm" />
                </MenuButton>

                <MenuList>
                  <MenuItem
                    icon={<CgProfile size={14} />}
                    minH="48px"
                    fontSize="sm"
                    as={Link}
                    to={`/people/profile/${encryptId(user.identityId)}`}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    icon={<Emoji emoji="⚙️" />}
                    minH="48px"
                    fontSize="sm"
                    as={Link}
                    to="/settings"
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    icon={<Emoji emoji="🚪" />}
                    minH="48px"
                    fontSize="sm"
                    onClick={logout}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Stack>
        )}
      </DesktopNavContainer>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={[...navItems, ...profileItems]} />
      </Collapse>
    </Box>
  )
}

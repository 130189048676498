import {
  Box,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { FaCog } from 'react-icons/fa'

export default function AdvancedFilters({ filters, setFilter, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box {...rest}>
        <IconButton
          aria-label="Advanced filters"
          icon={<FaCog />}
          onClick={onOpen}
          borderRadius="full"
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Advanced Filters 🚀</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={2} align="initial">
              {Object.entries(filters).map(([filterKey, filter]) => {
                if (filter.type === 'checkbox') {
                  return (
                    <Checkbox
                      isChecked={Boolean(filter.value)}
                      onChange={() =>
                        setFilter(filterKey, Boolean(!filter.value))
                      }
                    >
                      {filter.label}
                    </Checkbox>
                  )
                }
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

import React from 'react'
import { Flex, Text, useColorModeValue as mode, Link } from '@chakra-ui/react'
import moment from 'moment'
import { useAuth } from 'context/AuthContext'
import { encryptId } from 'utils/encryption'

export const IntroActivity = ({ intros, user, identityId, identityName }) => {
  const { user: loggedInUser } = useAuth()

  if (!intros) return null

  const isOwnProfile = !!loggedInUser
    ? loggedInUser.identityId === identityId
    : false

  const parseIntros = () => {
    if (user) {
      const {
        requestedIntrosByUser,
        introsRequestedFromUser,
        introsRequestedToUser,
      } = intros

      const allIntros = [
        ...requestedIntrosByUser,
        ...introsRequestedFromUser,
        ...introsRequestedToUser,
      ].sort((a, b) => moment(b.created_date).diff(moment(a.created_date)))

      return allIntros
    }

    return intros
  }

  const introStatuses = {
    denied: 'Denied',
    null: 'Pending',
    pending: 'Pending',
    accepted: 'Accepted',
    completed: 'Completed',
    unknown: 'Unknown',
    canceled: 'Canceled',
  }

  const introStatusEnum = Object.freeze(introStatuses)

  const introStatusColors = {
    canceled: 'red.400',
    denied: 'red.400',
    null: 'yellow.400',
    pending: 'yellow.400',
    accepted: 'green.400',
    completed: 'green.400',
    unknown: 'yellow.400',
  }

  const introStatusColorsEnum = Object.freeze(introStatusColors)

  const profileNameToRender = isOwnProfile ? 'You' : identityName

  const renderActivity = (intro) => {
    const introFromName =
      intro.intro_from_id === loggedInUser?.identityId
        ? 'You'
        : intro.intro_from

    const introWantedByName =
      intro.intro_wanted_by_id === loggedInUser?.identityId
        ? 'You'
        : intro.intro_wanted_by

    const introToName =
      intro.intro_to_id === loggedInUser?.identityId ? 'You' : intro.intro_to

    if (user) {
      /* requestedintrosbyuser */
      /* TODO: refactor this in your spare time */
      if (intro.intro_wanted_by === undefined) {
        return (
          <Text as="span">
            <Text
              as={profileNameToRender === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                profileNameToRender === 'You'
                  ? null
                  : `/people/profile/${encryptId(identityId)}`
              }
            >
              {profileNameToRender}
            </Text>
            {' '}asked{' '}
            <Text
              as={introFromName === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                introFromName === 'You'
                  ? null
                  : `/people/profile/${encryptId(intro.intro_from_id)}`
              }
            >
              {introFromName}
            </Text>{' '}
            to meet{' '}
            <Text
              as={introToName === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={`/people/profile/${encryptId(intro.intro_to_id)}`}
            >
              {intro.intro_to}
            </Text>
            {'. '}
            Status:
            <Text
              as="span"
              color={introStatusColorsEnum[intro.introducer_feedback]}
            >
              {' ' + introStatusEnum[intro.introducer_feedback]}
            </Text>
          </Text>
        )
      }
      /* introsrequestedfromuser */
      if (intro.intro_from === undefined) {
        return (
          <Text as="span">
            <Text
              as={introWantedByName === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                introWantedByName === 'You'
                  ? null
                  : `/people/profile/${encryptId(intro.intro_wanted_by_id)}`
              }
            >
              {introWantedByName}
            </Text>{' '}
            asked{' '}
            <Text
              as={profileNameToRender === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                profileNameToRender === 'You'
                  ? null
                  : `/people/profile/${encryptId(identityId)}`
              }
            >
              {profileNameToRender}
            </Text>{' '}
            to meet{' '}
            <Text
              as={intro.intro_to === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                intro.intro_to === 'You'
                  ? null
                  : `/people/profile/${encryptId(intro.intro_to_id)}`
              }
            >
              {intro.intro_to}
            </Text>
            {'. Status:'}
            <Text
              as="span"
              color={introStatusColorsEnum[intro.introducer_feedback]}
            >
              {' ' + introStatusEnum[intro.introducer_feedback]}
            </Text>
          </Text>
        )
      }
      /* introsrequestedtouser */
      if (intro.intro_to === undefined) {
        return (
          <Text as="span">
            <Text
              as={introWantedByName === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={
                introWantedByName === 'You'
                  ? null
                  : `/people/profile/${encryptId(identityId)}`
              }
            >
              {introWantedByName}
            </Text>{' '}
            asked{' '}
            <Text
              as={introFromName === 'You' ? 'span' : Link}
              color={mode('black', 'white')}
              href={`/people/profile/${encryptId(intro.intro_from_id)}`}
            >
              {introFromName}
            </Text>{' '}
            to meet{' '}
            <Text
              as={Link}
              color={mode('black', 'white')}
              href={`/people/profile/${encryptId(identityId)}`}
            >
              {identityName}
            </Text>
            {'. Status:'}
            <Text
              as="span"
              color={introStatusColorsEnum[intro.introducer_feedback]}
            >
              {' ' + introStatusEnum[intro.introducer_feedback]}
            </Text>
          </Text>
        )
      }
    }

    return (
      <Text as="span">
        <Text
          as={introWantedByName === 'You' ? 'span' : Link}
          color={mode('black', 'white')}
          href={`/people/profile/${encryptId(intro.intro_wanted_by_id)}`}
        >
          {introWantedByName}
        </Text>{' '}
        asked{' '}
        <Text
          as={introFromName === 'You' ? 'span' : Link}
          color={mode('black', 'white')}
          href={`/people/profile/${encryptId(intro.intro_from_id)}`}
        >
          {introFromName}
        </Text>{' '}
        to meet{' '}
        <Text
          as={introToName === 'You' ? 'span' : Link}
          color={mode('black', 'white')}
          href={`/people/profile/${encryptId(intro.intro_to_id)}`}
        >
          {introToName}
        </Text>
        {'. Status:'}
        <Text
          as="span"
          color={introStatusColorsEnum[intro.introducer_feedback]}
        >
          {' ' + introStatusEnum[intro.introducer_feedback]}
        </Text>
      </Text>
    )
  }

  const introActivity = () => {
    const introsToRender = parseIntros()
    if (!introsToRender.length) return 'No activity found.'

    return introsToRender.map((intro, index) => {
      return (
        <Flex
          direction={['column', 'column', 'row']}
          key={index}
          alignItems="flex-start"
          textAlign="left"
          sx={{ gap: [16, 16, 12, 12] }}
          mt={[8, 8, 0, 0]}
          mb="11px"
          lineHeight="19px"
        >
          <Flex
            ml={['0', '0', '-1', '-1']}
            maxW="120px"
            w={['auto', 'auto', '30%', '30%']}
            justifyContent={['center', 'center', 'flex-end', 'flex-end']}
          >
            <Text color="#9D9D9D">
              {moment(intro.created_date).format('MMM DD @ HH:mm')}
            </Text>
          </Flex>
          <Text as="span">{renderActivity(intro)}</Text>
        </Flex>
      )
    })
  }

  return introActivity()
}

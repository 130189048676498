import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  chakra,
  Icon,
  Stack,
  Text,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import RelationshipStrength from 'components/RelationshipStrength'
import Skeleton from 'components/Skeleton'

import { useEffect, useState } from 'react'
import { BsTriangleFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import api from 'services/api'

const useCounter = () => {
  const [count, setCount] = useState(5)

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => setCount((prevCount) => prevCount + 1),
  //     5000
  //   )

  //   return () => clearInterval(interval)
  // }, [])

  return { count, setCount }
}

export default function StepNetwork({ nextStep, data, isLoading }) {
  const { count: currentIndex, setCount: setIndex } = useCounter(
    data.topCompany.length + data.topPeople.length - 1
  )

  if (
    !isLoading &&
    currentIndex >= data.topCompany.length + data.topPeople.length
  ) {
    setIndex(0)
  }

  return (
    <Stack spacing={4}>
      <Text fontSize="md" color="white">
        They are giving you access to strong and
        <br /> reliable introductions to:
      </Text>
      <Stack justifyContent="center" alignItems="center">
        <Box w="100%" d="flex" alignItems="center" mb={3}>
          <Box mr={4}>
            <Text color="green.400" mb={2} fontSize="xl">
              <Skeleton isLoaded={!isLoading} as="span">
                <chakra.span fontWeight="bold">
                  {data?.totalWarmthPeople.toLocaleString()}+
                </chakra.span>
              </Skeleton>{' '}
              people
            </Text>
            <Skeleton isLoaded={!isLoading} mx={1} d="flex" alignItems="center">
              <AvatarGroup mr={1}>
                {data?.topPeople.map((relationship, index) => (
                  <Avatar
                    key={`relationship-avatar-${index}`}
                    name={`${relationship?.firstname} ${relationship?.lastname}`}
                    w={10}
                    h={10}
                    src={relationship.avatar ? relationship.avatar : ''}
                    bg={'primary.500'}
                    border="none"
                    onMouseEnter={() => setIndex(index)}
                    boxShadow={'0 0 0 3px var(--chakra-colors-green-400)'}
                  />
                ))}{' '}
              </AvatarGroup>
            </Skeleton>
          </Box>
          <Box>
            <Text color="green.400" mb={2} fontSize="xl">
              <Skeleton isLoaded={!isLoading} as="span">
                <chakra.span fontWeight="bold">
                  {data?.totalWarmthCompany.toLocaleString()}+
                </chakra.span>
              </Skeleton>{' '}
              companies
            </Text>
            <Skeleton isLoaded={!isLoading} mx={1} d="flex" alignItems="center">
              <AvatarGroup mr={1}>
                {data?.topCompany.map((company, index) => (
                  <Avatar
                    key={`company-avatar-${index}`}
                    name={company.name}
                    w={10}
                    h={10}
                    src={company.logo}
                    bg={'primary.500'}
                    border="none"
                    onMouseEnter={() => setIndex(index + data.topPeople.length)}
                    boxShadow={'0 0 0 3px var(--chakra-colors-green-400)'}
                  />
                ))}{' '}
              </AvatarGroup>
            </Skeleton>
          </Box>
        </Box>

        <Box w="100%" my={2}>
          <Box bg="grayAlpha.600" borderRadius="md" h={20} pos="relative">
            <Icon
              as={BsTriangleFill}
              color="grayAlpha.600"
              w={5}
              h={5}
              pos="absolute"
              left={`calc(${1 + currentIndex * 1.75}rem + ${
                currentIndex >= data.topPeople.length
                  ? data?.totalWarmthPeople.toString().length * 1
                  : 0
              }rem)`}
              top="-3"
            />
            <Box overflow="hidden" w="100%" h="100%" p={2}>
              {!isLoading &&
                [...data?.topPeople, ...data?.topCompany].map((item, index) => {
                  const avatar = item.avatar ?? item.logo
                  const name = item.firstname
                    ? `${item.firstname}${
                        item.lastname ? ` ${item.lastname}` : ' '
                      }`
                    : item.name

                  return (
                    <Box
                      key={`item-${index}`}
                      h={index === currentIndex ? '100%' : '0'}
                      opacity={index === currentIndex ? '1' : '0'}
                      transform={
                        index === currentIndex ? 'none' : 'translateY(-500px)'
                      }
                      transition="all .25s ease-in-out"
                      d="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box d="flex" justifyContent="center" alignItems="center">
                        <Avatar
                          w={10}
                          h={10}
                          src={avatar}
                          mr={4}
                          ml={2}
                          boxShadow={'0 0 0 3px var(--chakra-colors-green-400)'}
                        />
                        <Box maxW={72}>
                          <Text fontSize="xs" color="grayAlpha.5">
                            {item.connector} can introduce you to:
                          </Text>
                          <Text
                            color="white"
                            fontSize="md"
                            fontWeight="bold"
                            isTruncated
                          >
                            {name}
                          </Text>

                          {item.current_work_position && (
                            <Tooltip
                              label={`${item.current_work_position} at ${item.current_work_company_name}`}
                              bg={mode('white', 'grayAlpha.700')}
                              color={mode('black', 'white')}
                            >
                              <Text color="white" fontSize="sm" isTruncated>
                                {item.current_work_position} at{' '}
                                {item.current_work_company_name}
                              </Text>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>

                      <Box mr={2}>
                        <RelationshipStrength
                          relationship={{ warmth_score: item.warmth_score }}
                        />
                      </Box>
                    </Box>
                  )
                })}
            </Box>
          </Box>
        </Box>
      </Stack>

      <Box>
        <Button
          rightIcon={<FaArrowRight />}
          size="md"
          variant="village"
          borderRadius="md"
          onClick={nextStep}
        >
          Continue
        </Button>
      </Box>
    </Stack>
  )
}

import { Box, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import React from 'react'
import { FaShoppingBag } from 'react-icons/fa'
import { PostOpportunityModal } from './PostOpportunityModal'

export const PostedOpportunityWrapper = ({
  opportunity,
  display,
  setActiveOpportuntiyId,
}) => {
  const {
    title,
    status,
    id,
    applicationCount,
    category_id,
    recommendationCount,
    encrypted_id,
  } = opportunity

  const parseCategoryName = {
    1: 'jobs',
    2: 'talent',
    3: 'startups',
    20: 'investors',
    12: 'partnerships',
    14: 'collaborations',
    16: 'offers',
  }

  const isExpired = status === 'closed'

  return (
    <Box
      borderWidth={2}
      borderColor={mode('gray.1', 'grayAlpha.500')}
      display={display}
      borderRadius="4px"
      bg="transparent"
      p="2.5"
      isTruncated
      px="3"
    >
      <Text
        as="a"
        _hover={{
          textDecoration: 'underline',
          textUnderlineOffset: '2px',
        }}
        href={`/opportunity/${encrypted_id}`}
        fontSize="sm"
      >
        {title}
      </Text>
      <Flex sx={{ gap: 17 }} mt="2">
        <Text fontSize="xs" fontWeight="bold" color="gray.15">
          {parseCategoryName[category_id].toUpperCase()}
        </Text>
        <Text
          fontSize="xs"
          fontWeight="bold"
          color={isExpired ? 'red.500' : 'green.400'}
        >
          {isExpired ? 'INACTIVE' : 'ACTIVE'}
        </Text>
      </Flex>
      <Flex alignItems="center" sx={{ gap: 17 }} mt="2">
        <Flex alignItems="center" sx={{ gap: 8 }}>
          <FaShoppingBag size={12} />
          <Text fontSize="xs">
            {(applicationCount ?? 0) + (recommendationCount ?? 0)}
          </Text>
        </Flex>
        <Text
          as="button"
          color="turquoise.500"
          fontWeight="bold"
          fontSize="xs"
          textDecor="underline"
          sx={{ textUnderlineOffset: 2 }}
          variant="link"
          onClick={() => setActiveOpportuntiyId(id)}
        >
          Edit
        </Text>
        <Text
          as="a"
          color="turquoise.500"
          fontWeight="bold"
          fontSize="xs"
          textDecor="underline"
          sx={{ textUnderlineOffset: 2 }}
          variant="link"
          href={`/tracking/opportunities/${id}`}
        >
          {'View stats ->'}
        </Text>
      </Flex>
    </Box>
  )
}

export const PostedOpportunity = ({
  opportunity,
  ownOpportunities,
  setOwnOpportunities,
  isOpen,
  setActiveOpportuntiyId,
  display,
}) => {
  return (
    <>
      <PostedOpportunityWrapper
        opportunity={opportunity}
        display={display}
        setActiveOpportuntiyId={setActiveOpportuntiyId}
      />
      <PostOpportunityModal
        ownOpportunities={ownOpportunities}
        setOwnOpportunities={setOwnOpportunities}
        opportunity={opportunity}
        isOpen={isOpen}
        onClose={() => setActiveOpportuntiyId(null)}
      />
    </>
  )
}

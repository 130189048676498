import {
  Avatar,
  Button,
  useColorModeValue as mode,
  Flex,
} from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'

import { FaArrowRight } from 'react-icons/fa'

export default function CompanyPeople({ avatars, total, onOpen, ...props }) {
  const avatarsWithRest = [
    ...avatars,
    ...Array(total - avatars.length).fill(''),
  ]

  return (
    <Flex alignItems="center">
      <AvatarGroup size="sm" max={2} spacing="-0.5rem">
        {avatarsWithRest.map((a) => (
          <Avatar src={a} w="40px" h="40px" />
        ))}
      </AvatarGroup>
      <Button
        rightIcon={<FaArrowRight />}
        size="sm"
        color={mode('oldGray.500', 'oldGray.200')}
        variant="link"
        textDecor="underline"
        fontWeight="semibold"
        ml={2}
        onClick={onOpen}
        isDisabled={total === 0}
        {...props}
      >
        {total > 1 && `${total} people`}
        {total === 1 && '1 person'}
        {total === 0 && 'No people'}
      </Button>
    </Flex>
  )
}

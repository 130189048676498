import React from 'react'
import {
  IconButton,
  Box,
  Avatar,
  Flex,
  Text,
  AvatarBadge,
} from '@chakra-ui/react'
import { IoLocationOutline } from 'react-icons/io5'
import { TiWeatherCloudy } from 'react-icons/ti'
import { FaLinkedinIn } from 'react-icons/fa'
import { useAuth } from 'context/AuthContext'
import { encryptId } from 'utils/encryption'

export const ProfileInfo = ({
  title,
  avatar,
  headline,
  country,
  contactInfo,
  website,
  linkedinPath,
  identityId,
  companySlug,
}) => {
  const user = useAuth()

  const renderLinkedInButton = () => {
    let href = null

    if (contactInfo) {
      if (contactInfo.account_identifier !== null) {
        const linkedInProfile = contactInfo.account_identifier
          .split(',')
          .find((element) => element.includes('l:'))

        if (linkedInProfile) {
          href = linkedInProfile.substring(2)
        }
      }
    }

    if (linkedinPath) {
      href = identityId
        ? `https://www.linkedin.com/in/${linkedinPath}`
        : `https://www.linkedin.com/${linkedinPath}`
    }

    if (!href) return null

    return (
      <IconButton
        mt={['2', '2', '0']}
        ml={['0', '0', 'auto']}
        icon={<FaLinkedinIn />}
        size="xs"
        colorScheme="gray"
        as="a"
        href={href}
        target="_blank"
      />
    )
  }

  return (
    <Flex
      direction={['column', 'column', 'row']}
      as="section"
      alignItems="center"
      justifyContent={['center', 'center', 'flex-start']}
    >
      <Avatar size="2xl" name={title} src={avatar}>
        {user && user?.signed && user?.user?.identityId === identityId && (
          <AvatarBadge
            bg="primary.500"
            color="white"
            fontWeight="bold"
            fontSize="sm"
            transform="translateY(7px)"
            left="auto"
            right="auto"
            bottom="1"
            w="60%"
          >
            You
          </AvatarBadge>
        )}
      </Avatar>
      <Box
        textAlign={['center', 'center', 'left']}
        ml={['0', '0', '8']}
        mt={['4', '4', '0']}
      >
        <Text
          as="a"
          href={
            companySlug
              ? `/company/profile/${companySlug}`
              : `/people/profile/${encryptId(identityId)}`
          }
          fontSize="3xl"
          mb="0.5"
          _hover={{
            textDecoration: 'underline',
            textUnderlineOffset: '2px',
          }}
          fontWeight="bold"
        >
          {title}
        </Text>
        {headline && (
          <Text mb="2" fontSize="md">
            {headline}
          </Text>
        )}
        {website && (
          <Text as="a" target="_blank" href={website} fontSize="md">
            {website}
          </Text>
        )}
        {country && (
          <Flex
            mt={website ? '2' : '0'}
            justifyContent={['center', 'center', 'flex-start']}
            alignItems="center"
          >
            <IoLocationOutline fontSize={16} color="#ABABAB" />
            <Text fontStyle="italic" color="#ABABAB" ml="1" fontSize="md">
              {country}
            </Text>
            {/*<TiWeatherCloudy
            fontSize={16}
            color="#ABABAB"
            style={{ marginLeft: 4 }}
          />
          <Text fontStyle="italic" color="#ABABAB" ml="0.5" fontSize="md">
            over there now!
          </Text> */}
          </Flex>
        )}
      </Box>
      {renderLinkedInButton()}
    </Flex>
  )
}

import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import api from 'services/api'
import { useToast } from 'hooks/useToast'
import { ApplicationForm } from './ApplicationForm'
import { useForm } from 'react-hook-form'
import { useQuery } from 'hooks/useQuery'

export const ApplicationModal = ({
  isOpen,
  onClose,
  opportunityId,
  setApplied,
}) => {
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const query = useQuery()

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const { reason, fullName, linkedinUrl, email } = data
      await api.post(`/opportunities/${opportunityId}/applications`, {
        reason,
        fullName,
        linkedin_profile_url: linkedinUrl,
        email,
        token: query.get('token'),
      })
      setLoading(false)
      toast({ status: 'success', title: 'Application sent successfully' })
      setApplied(true)
      onClose()
    } catch (e) {
      setLoading(false)
      toast({ status: 'error', title: 'Something went wrong' })
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: null,
      linkedinUrl: null,
      email: null,
      reason: '',
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          px="0"
          maxH="75vh"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          overflowX="hidden"
        >
          <ApplicationForm errors={errors} control={control} />
        </ModalBody>
        <Box p="4" ml="auto">
          <Button
            ml="2"
            borderRadius="25px"
            variant="village-black"
            w="137px"
            isLoading={loading}
            color={mode('white', 'black')}
            onClick={() => handleSubmit(onSubmit)()}
            h="41px"
          >
            Submit
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}

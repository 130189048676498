import { chakra, Box, Text } from '@chakra-ui/react'
import '@fontsource/roboto/500.css'
import { useAuth } from 'context/AuthContext'
import GoogleAuthorize from 'react-google-authorize'

export default function LoginButton({
  customButton,
  customCallback,
  extraPermissions,
  ...props
}) {
  const { signIn } = useAuth()

  const responseGoogle = async (response) => {
    try {
      if (!response.code) throw Error()

      if (customCallback) {
        await customCallback(response.code)
      } else {
        await signIn({ code: response.code, extraPermissions })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <GoogleAuthorize
      scope={
        extraPermissions &&
        `profile email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/calendar.events.readonly`
      }
      accessType={extraPermissions && 'offline'}
      approvalPrompt={extraPermissions && 'force'}
      prompt={extraPermissions && 'consent'}
      clientId="561701119639-v7t16ktki50r8ah0bks7rhi4d8c1il31.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={(err) => console.log('fail', err)}
      autoLoad={false}
      cookiePolicy={'single_host_origin'}
      responseType="code"
      render={
        customButton
          ? customButton
          : (renderProps) => (
              <Box
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                w="184px"
                h="42px"
                bg="#4285F4"
                borderRadius="2px"
                boxShadow="0 3px 4px 0 rgba(0,0,0,.25)"
                _hover={{
                  cursor: 'pointer',
                }}
                _active={{
                  bg: '#1669F2',
                }}
                {...props}
              >
                <Box
                  pos="absolute"
                  mt="1px"
                  ml="1px"
                  w="40px"
                  h="40px"
                  borderRadius="2px"
                  bg="white"
                >
                  <chakra.img
                    pos="absolute"
                    mt="11px"
                    ml="11px"
                    w="18px"
                    h="18px"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  />
                </Box>
                <Text
                  float="right"
                  margin="11px 11px 0 0"
                  color="white"
                  fontSize="14px"
                  letterSpacing="0.2px"
                  fontFamily="Roboto"
                  fontWeight="500"
                >
                  Sign in with Google
                </Text>
              </Box>
            )
      }
    />
  )
}

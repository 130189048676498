import { useEffect, useState, useRef } from 'react'
import api from 'services/api'
import useQueryString from 'hooks/useQueryString'
import { OpportunityTrackingView } from './OpportunityTrackingView'
import { useParams } from 'react-router-dom'

export const OpportunityTrackingViewContainer = () => {
  const [activeField, setActiveField] = useState('latest_activity')

  const [orderBy, setOrderBy] = useState('desc')

  const [offset, setOffset] = useState(0)

  const [limit, setLimit] = useState(10)

  const [total, setTotal] = useState(0)

  const [data, setData] = useState([])

  const cancelSource = useRef()

  const { id } = useParams()

  const [filters, setFilters] = useQueryString({
    name: '',
  })

  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async () => {
    try {
      if (cancelSource.current) {
        cancelSource.current.cancel()
      }
      setIsLoading(true)
      cancelSource.current = api.CancelToken.source()

      const { data } = await api.get(
        `/opportunities/${id}/stats?name=${filters.name}&offset=${offset}&orderBy=${activeField}&sort=${orderBy}`,
        {
          cancelToken: cancelSource.current.token,
        }
      )
      setTotal(data.total)
      setData(data.results)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [offset, filters, activeField, orderBy])

  const changeFilter = (type, value) => {
    setOffset(0)
    setFilters({ ...filters, [type]: value })
  }

  return (
    <OpportunityTrackingView
      isLoading={isLoading}
      filters={filters}
      activeField={activeField}
      setActiveField={setActiveField}
      changeFilter={changeFilter}
      total={total}
      offset={offset}
      setOffset={setOffset}
      limit={limit}
      setLimit={setLimit}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      data={data}
    />
  )
}

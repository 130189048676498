import {
  Box,
  chakra,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Text,
  useColorMode,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useKanban } from 'context/KanbanContext'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { FaEllipsisH, FaTrashAlt } from 'react-icons/fa'

import Task from './Task'
import { notionDarkColors as notionColors } from 'styles/notion'

export default function Column({ index, column, tasks, isOpportunity }) {
  const { colorMode } = useColorMode()
  const {
    handleDeleteColumn,
    handleChangeColumnColor,
    handleChangeColumnName,
  } = useKanban()

  return (
    <Draggable
      isDragDisabled={!!isOpportunity}
      draggableId={column.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          minW="260px"
          maxW="260px"
          bg={`notion-${colorMode}-${column.color}.50`}
          p={2}
          mr={4}
          borderRadius="md"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Text
            fontSize="sm"
            color={`notion-${colorMode}-${column.color}.700`}
            mb={3}
            ml={1}
            mt={1}
            d="flex"
            justifyContent="flex-start"
            alignItems="center"
            pos="relative"
          >
            <chakra.span
              {...provided.dragHandleProps}
              w="100%"
              h="100%"
              pos="absolute"
              cursor="pointer !important"
            />

            <Editable
              onSubmit={(newValue) => {
                if (newValue !== column.title) {
                  handleChangeColumnName(newValue, column.id)
                }
              }}
              defaultValue={column.title}
              zIndex="1"
            >
              <EditablePreview
                bg={`notion-${colorMode}-${column.color}.200`}
                px={1}
                mr={2}
                borderRadius="md"
              />
              <EditableInput
                w="100%"
                _focus={{
                  boxShadow: 'none',
                }}
              />
            </Editable>

            <chakra.span color={`notion-${colorMode}-${column.color}.500`}>
              {tasks.filter((t) => t).length}
            </chakra.span>

            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FaEllipsisH />}
                variant="ghost"
                size="sm"
                colorScheme="gray"
                color={`notion-${colorMode}-${column.color}.500`}
                ml="auto"
              />
              <MenuList zIndex={5}>
                <MenuGroup title="Actions">
                  <MenuItem
                    icon={<FaTrashAlt />}
                    onClick={() => handleDeleteColumn(column.id)}
                  >
                    Delete
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />

                <MenuOptionGroup
                  defaultValue={column.color}
                  title="Colors"
                  type="radio"
                >
                  {Object.keys(notionColors).map((rawColor) => {
                    const color = rawColor.split('-', 3)[2]

                    return (
                      <MenuItemOption
                        value={color}
                        flexDir="row-reverse"
                        onClick={() =>
                          handleChangeColumnColor(color, column.id)
                        }
                      >
                        <Box d="flex" alignItems="center">
                          <Box
                            ml={3}
                            w="18px"
                            h="18px"
                            bg={`notion-${colorMode}-${color}.200`}
                            border="1px solid"
                            borderColor={`notion-${colorMode}-${color}.500`}
                            borderRadius="3px"
                            mr={2}
                          />
                          <chakra.span
                            textTransform="capitalize"
                            color={mode('black', 'white')}
                          >
                            {color}
                          </chakra.span>
                        </Box>
                      </MenuItemOption>
                    )
                  })}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Text>
          {/* isCombineEnabled */}
          <Droppable isDropDisabled={isOpportunity} droppableId={column.id}>
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map((task, taskIndex) => (
                  <Task
                    key={task.task_id}
                    task={task}
                    index={taskIndex}
                    color={column.color}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
      )}
    </Draggable>
  )
}

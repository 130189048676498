import { ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import theme from 'styles/theme'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PWQQMNL',
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

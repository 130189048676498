import { Box, useColorMode } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import NewUserForm from 'components/NewUserForm'
import SyncSteps from 'components/SyncStepsOld'
import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import api from 'services/api'

export default function DashboardLayout({ children }) {
  const { user } = useAuth()
  const { colorMode, toggleColorMode } = useColorMode()

  const [isSyncProgressModalOpen, setIsSyncProgressModalOpen] = useState(
    user.username && !user.is_sync_complete
  )

  useEffect(() => {
    if (user.username && !user.is_sync_complete) {
      setIsSyncProgressModalOpen(true)
    } else {
      setIsSyncProgressModalOpen(false)
    }

    if (user.username && user.is_sync_complete) {
      const alreadyFetchedDataForCache = localStorage.getItem(
        'alreadyFetchDataForCache'
      )

      if (!alreadyFetchedDataForCache) {
        api.get('/relationships?offset=0&name=&company=&position=&location=')
        api.get('/companies?offset=0&name=&industry=&firstDegree=0')
        localStorage.setItem('alreadyFetchDataForCache', true)
      }
    }

    if (colorMode === 'light' && !user.is_sync_complete) {
      toggleColorMode()
    } else if (colorMode === 'dark' && user.is_sync_complete) {
      toggleColorMode()
    }
  }, [user.username, user.is_sync_complete])

  return (
    <Box>
      {/* {user.isAdmin && <AdminModal />} */}
      <NewUserForm isModalOpen={!user.username} />
      <SyncSteps
        isModalOpen={isSyncProgressModalOpen}
        closeModal={() => setIsSyncProgressModalOpen(false)}
      />
      <Navbar setIsSyncProgressModalOpen={setIsSyncProgressModalOpen} />
      {/* {Boolean(user.is_sync_complete) && <ExtensionDownload />} */}
      <Box minH="100vh">{children}</Box>
    </Box>
  )
}

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useKanban } from 'context/KanbanContext'
import { useRef, useState } from 'react'

export default function NewBoardPopover({
  isOpen,
  onClose,
  onOpen,
  afterSubmit,
  children,
}) {
  const { boards, handleCreateBoard } = useKanban()
  const toast = useToast()

  const [boardName, setBoardName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const initialFocusRef = useRef()

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />

        <PopoverBody
          as="form"
          onSubmit={async (e) => {
            e.preventDefault()

            if (boardName.length < 3) {
              toast({
                title: 'Name must be at least 3 characters long',
                variant: 'subtle',
              })
              return
            }

            const isThereABoardWithTheSameName = Object.keys(boards).find(
              (board) => board === boardName
            )

            if (isThereABoardWithTheSameName) {
              toast({
                title: 'There is already a board with that name',
                variant: 'subtle',
              })
              return
            }

            setIsLoading(true)

            const newBoard = await handleCreateBoard(boardName)
            setBoardName('')
            onClose && onClose()
            afterSubmit && afterSubmit(newBoard.id)

            setIsLoading(false)
          }}
        >
          <Input
            size="md"
            placeholder="Board's name"
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            mb={1}
            ref={initialFocusRef}
            border="none"
            _focus={{
              border: 'none',
            }}
          />

          <Button
            variant="village"
            size="md"
            w="100%"
            type="submit"
            isLoading={isLoading}
          >
            Create board
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

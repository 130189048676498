import { Box, useColorModeValue as mode } from '@chakra-ui/react'
import React from 'react'

export const SidebarItemWrapper = ({ children, ...styleProps }) => {
  return (
    <Box
      {...styleProps}
      borderRadius="md"
      py="5"
      px="6"
      bg={mode('gray.5', 'whiteAlpha.400')}
    >
      {children}
    </Box>
  )
}

import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from '@chakra-ui/react'

import LoginButton from 'components/LoginButton'

export default function Register() {
  return (
    <Box>
      <Box borderRadius="md" borderWidth="1px" p={12}>
        <Heading mb={4} size="md" color="white" textAlign="center">
          First time here?
        </Heading>

        <LoginButton />
      </Box>
      <Text textAlign="center" fontSize="sm" mt={4} color="white">
        Already a member?{' '}
        <Link as={RouterLink} color="blue.500" to="/login">
          Log in
        </Link>
      </Text>
    </Box>
  )
}

import { FaCheck } from 'react-icons/fa'
import {
  Box,
  Circle,
  Collapse,
  Heading,
  HStack,
  Icon,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { useStep } from './useStep'

export const Step = (props) => {
  const { title, children, ...boxProps } = props
  const { isActive, isCompleted, step, selectStep } = useStep()

  const mutedColor = mode('gray.600', 'whiteAlpha.800')

  return (
    <Box {...boxProps}>
      <HStack spacing="4">
        <Circle
          size="8"
          fontWeight="bold"
          color={isActive ? 'black' : isCompleted ? 'white' : mutedColor}
          bg={isActive ? 'white' : 'transparent'}
          borderColor={isCompleted ? 'white' : 'white'}
          borderWidth={isActive ? '0px' : '1px'}
          onClick={selectStep}
          cursor={selectStep && 'pointer'}
        >
          {isCompleted ? <Icon as={FaCheck} /> : step}
        </Circle>
        <Heading
          fontSize="xl"
          fontWeight="semibold"
          color={isActive || isCompleted ? 'inherit' : mutedColor}
        >
          {title}
        </Heading>
      </HStack>
      <Collapse in={isActive}>{children}</Collapse>
    </Box>
  )
}

import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import {
  FaEnvelope,
  FaLinkedinIn,
  FaPhoneAlt,
  FaWhatsapp,
} from 'react-icons/fa'
import { MdTextsms } from 'react-icons/md'
import { FaCommentDots } from 'react-icons/fa'

import api from 'services/api'

import { parseIdentifier, parseLink } from './utils'

const VillageButton = ({ ...props }) => (
  <Button variant="village" w="135px" {...props}>
    {props.buttonText}
  </Button>
)

export default function GetInTouchDropdown({
  contactId,
  buttonText,
  isDeepFetch,
  linkedinUrl,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [contact, setContact] = useState({
    account_identifier: [],
    account_type: [],
  })

  useEffect(() => {
    const fetchConnection = async () => {
      setIsLoading(true)
      try {
        const { data } = await api.get(`/contact/${contactId}`)
        setContact({
          ...data,
          account_identifier: data.account_identifier.split(','),
          account_type: data.account_type.split(','),
        })

        setIsLoading(false)
      } catch (err) {
        console.error(err)
      }
    }

    if (isDeepFetch && linkedinUrl) {
      setContact({
        account_identifier: [`l:${linkedinUrl}`],
        account_type: ['linkedin'],
      })
    }

    isOpen && contactId && fetchConnection()
  }, [isOpen])

  // {isLoading ? (
  //   <Box d="flex" justifyContent="center" alignItems="center">
  //     <Spinner mr={2} />
  //     Loading...
  //   </Box>
  // ) : (
  //   'Get in touch'
  // )}

  return (
    <Menu
      closeOnSelect={false}
      isOpen={isOpen && !isLoading}
      onClose={() => setIsOpen(!isOpen)}
      transform="translate(-25px)"
    >
      <MenuButton
        as={VillageButton}
        size="sm"
        variant="village"
        leftIcon={<Icon as={FaCommentDots} color="white" h="4" w="4" />}
        onClick={() => setIsOpen(!isOpen)}
        buttonText={buttonText ? buttonText : 'Message'}
        {...props}
      />

      <MenuList>
        {contact &&
          contact.account_identifier &&
          contact.account_identifier.length &&
          contact.account_identifier.map((identifier, index) => {
            const type = contact.account_type[index]
            return (
              <>
                <MenuItem
                  _hover={{ bg: 'transparent !important', cursor: 'default' }}
                >
                  <Tooltip label={parseIdentifier(identifier)}>
                    <Text mr={2} fontSize="sm" maxW="175px" isTruncated>
                      {parseIdentifier(identifier)}
                    </Text>
                  </Tooltip>
                  {type === 'email' && (
                    <Tooltip label="Send email">
                      <IconButton
                        as="a"
                        href={parseLink(type, identifier)}
                        target="_blank"
                        size="xs"
                        icon={<FaEnvelope />}
                        colorScheme="gray"
                        borderRadius="3xl"
                        ml="auto"
                        aria-label="Send email"
                      />
                    </Tooltip>
                  )}
                  {type === 'linkedin' && (
                    <Tooltip label="Send message on Linkedin">
                      <IconButton
                        as="a"
                        href={parseLink(type, identifier)}
                        target="_blank"
                        size="xs"
                        icon={<FaLinkedinIn />}
                        colorScheme="gray"
                        borderRadius="3xl"
                        ml="auto"
                        aria-label="Send message on Linkedin"
                      />
                    </Tooltip>
                  )}
                  {type === 'phone' && (
                    <Box d="flex" ml="auto">
                      <Tooltip label="Call">
                        <IconButton
                          as="a"
                          href={parseLink(type, identifier, 'call')}
                          target="_blank"
                          icon={<FaPhoneAlt />}
                          colorScheme="gray"
                          borderRadius="3xl"
                          size="xs"
                          mr={1}
                          aria-label="Call"
                        />
                      </Tooltip>

                      <Tooltip label="Send text message">
                        <IconButton
                          as="a"
                          href={parseLink(type, identifier, 'sms')}
                          target="_blank"
                          icon={<MdTextsms />}
                          colorScheme="gray"
                          borderRadius="3xl"
                          size="xs"
                          mr={1}
                          aria-label="Send text message"
                        />
                      </Tooltip>

                      <Tooltip label="Send message on Whatsapp">
                        <IconButton
                          as="a"
                          href={parseLink(type, identifier, 'whatsapp')}
                          target="_blank"
                          icon={<FaWhatsapp />}
                          colorScheme="gray"
                          borderRadius="3xl"
                          size="xs"
                          aria-label="Send message on Whatsapp"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </MenuItem>
                {index !== contact.account_identifier.length - 1 && (
                  <MenuDivider />
                )}
              </>
            )
          })}
      </MenuList>
    </Menu>
  )
}

import {
  Avatar,
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { ButtonWithConfirmation } from 'components/ButtonWithConfirmation'
import { HorizontalProfile } from 'components/HorizontalProfile'
import Similarity from 'components/Similarity'
import { useToast } from 'hooks/useToast'
import { useState } from 'react'
import api from 'services/api'

export default function Pick({ pick, deletePick }) {
  const { introducer, target, relationship_id } = pick

  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeSimilaritySentence = async (newValue) => {
    setIsLoading(true)
    try {
      await api.patch('/admin/pick', {
        relationship_id,
        similarity_sentence: newValue,
      })

      toast({
        title: 'Updated similarity sentence',
      })
    } catch (err) {
      toast({
        title: 'Error',
      })
    }

    setIsLoading(false)
  }

  return (
    <Box d="flex" alignItems="center">
      <Box w="100%">
        <HorizontalProfile
          entity={{
            name: `${target.firstname} ${target.lastname}`,
            title: `${target.current_work_company_name} at ${target.current_work_position}`,
            subtitle: (
              <>
                Both of you are close friends with{' '}
                <Avatar src={introducer.avatar} size="2xs" ml={1} mt="2px" />{' '}
                {introducer.firstname}
              </>
            ),
            avatar: target.avatar ? target.avatar : '',
            identityId: pick.target_identity_id,
          }}
        />

        <Text d="flex" alignItems="center" ml={10}>
          <Similarity
            identity_id={pick.target_identity_id}
            similarity={target.similarity}
            similarity_score={target.similarity_score}
            similarity_sentence={target.similarity_sentence}
            d="inline-flex"
            ml={0}
            mr={1}
          />

          <Editable
            onSubmit={(newValue) => {
              if (newValue !== target.similarity_sentence) {
                handleChangeSimilaritySentence(newValue)
              }
            }}
            defaultValue={target.similarity_sentence}
            d="inline-block"
            w="100%"
          >
            <EditablePreview />
            <EditableInput w="100%" />
          </Editable>

          {isLoading && <Spinner />}
        </Text>
      </Box>

      <ButtonWithConfirmation
        variant="villageOutline"
        size="sm"
        color="red"
        borderColor="red"
        onClick={deletePick}
        ml="auto"
      >
        Delete pick
      </ButtonWithConfirmation>
    </Box>
  )
}

import {
  chakra,
  Badge,
  Box,
  List,
  ListIcon,
  ListItem,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import { useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import api from 'services/api'
import { parseStrengthColor } from 'utils/parsers'

function RelationshipDataSection({ emoji, name, data }) {
  return (
    <Box>
      <Text fontWeight="bold" mb={1}>
        <chakra.span mr={1}>{emoji}</chakra.span> {name}
      </Text>
      <List spacing={1}>
        {data.map((item, index) => (
          <ListItem key={`${name}-index`}>
            {' '}
            <ListIcon as={MdCheckCircle} color="middle.500" />
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default function RelationshipStrength({
  relationship,
  identities,
  companySize,
  isTopWarmthLikely,
  connectorPeriod,
  ...props
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [relationshipData, setRelationshipData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getRelationshipData = async () => {
    setIsLoading(true)
    onOpen()

    try {
      const { data } = await api.post('/relationship', {
        left_identity_id: identities[0],
        right_identity_id: identities[1],
      })
      setRelationshipData(data)
    } catch (err) {
      console.log(err)
    }

    setIsLoading(false)
  }

  function parseText(value, companySize) {
    const parsedValue = Number(value)

    if (Number(companySize) > 30 || isTopWarmthLikely) return 'Not sure'

    if (parsedValue === -1 || isNaN(parsedValue)) return 'Cold'

    if (parsedValue === 0) return 'Not sure'
    if (parsedValue < 2.5) return 'Maybe'
    if (parsedValue <= 5) return 'Average'
    if (parsedValue <= 7.5) return 'Good'
    return 'Excellent'
  }

  function renderLabel() {
    if (connectorPeriod) {
      return (
        <RelationshipDataSection
          emoji="💼"
          name="work"
          data={[connectorPeriod]}
        />
      )
    }

    if (isLoading) return 'Loading...'

    return (
      <>
        {relationshipData ? (
          <Stack
            spacing={4}
            divider={
              <StackDivider borderColor={mode('gray.200', 'grayAlpha.50')} />
            }
          >
            {Boolean(relationshipData.work.length) && (
              <RelationshipDataSection
                emoji="💼"
                name="work"
                data={relationshipData.work.map((w) => {
                  if (!w.period) {
                    return `Both worked at ${w.company_name}`
                  }

                  if (!w.period.end)
                    return `Working together at ${
                      w.company_name
                    } since ${moment(w.period.start).format(`MMM'YY`)}`

                  return `Worked together at ${w.company_name} from ${moment(
                    w.period.start
                  ).format(`MMM'YY`)} to ${moment(w.period.end).format(
                    `MMM'YY`
                  )}`
                })}
              />
            )}

            {Boolean(relationshipData.education.length) && (
              <RelationshipDataSection
                emoji="📚"
                name="education"
                data={relationshipData.education.map((e) => {
                  if (!e.period) {
                    return `Both studied at ${e.company_name}`
                  }

                  if (!e.period.end)
                    return `Studying together at ${
                      e.company_name
                    } since ${moment(e.period.start).format(`MMM'YY`)}`

                  return `Studied together at ${e.company_name} from ${moment(
                    e.period.start
                  ).format(`MMM'YY`)} to ${moment(e.period.end).format(
                    `MMM'YY`
                  )}`
                })}
              />
            )}

            {Boolean(relationshipData.meetings) && (
              <RelationshipDataSection
                emoji="📞"
                name="meetings"
                data={[relationshipData.meetings]}
              />
            )}

            {!relationshipData.work.length &&
              !relationshipData.education.length &&
              !relationshipData.meetings && (
                <Text>🙉 No relevant data for this relationship</Text>
              )}
          </Stack>
        ) : (
          <Text>ℹ️ Open the paths for more information</Text>
        )}
      </>
    )
  }

  return (
    <Tooltip
      label={renderLabel()}
      hasArrow
      borderRadius="md"
      bg={mode('white', 'grayAlpha.700')}
      color={mode('black', 'white')}
      boxShadow="md"
      p={4}
      isOpen={isOpen}
      onOpen={
        identities && !connectorPeriod
          ? getRelationshipData
          : connectorPeriod
          ? onOpen
          : undefined
      }
      onClose={onClose}
    >
      <Badge
        colorScheme={parseStrengthColor(
          relationship.warmth_score,
          companySize,
          isTopWarmthLikely
        )}
        borderRadius="full"
        padding="0.5rem 0.75rem"
        {...props}
      >
        {parseText(relationship.warmth_score, companySize, isTopWarmthLikely)}
      </Badge>
    </Tooltip>
  )
}

import React from 'react'
import { Flex, Avatar, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import moment from 'moment'
import { AdvancedTooltip } from 'components/AdvancedTooltip'
import { ProfileTitle } from 'components/ProfileTitle'

export const StatsRow = ({ data }) => {
  if (!data) return null

  const {
    avatar,
    name,
    has_viewed,
    is_applied,
    recommendation_count,
    latest_activity,
    last_viewed_date,
    last_recommendation_date,
    application_date,
    id,
  } = data

  console.log('data', data)

  return (
    <Flex
      textAlign="center"
      py="4"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
    >
      <Flex w="20%" sx={{ gap: 18 }} alignItems="center">
        <Avatar size="md" name={name} src={avatar} />
        <ProfileTitle name={name} isTruncated identityId={id} />
      </Flex>
      <AdvancedTooltip
        p="2"
        tooltipContent={
          <Text fontSize="sm">
            {last_viewed_date
              ? `Last viewed on ${moment(last_viewed_date).format(
                  'MMM Do YYYY HH:mm:ss'
                )}`
              : 'This user has not viewed the opportunity yet.'}{' '}
          </Text>
        }
      >
        <Flex justifyContent="center" w="20%">
          {has_viewed ? <FaCheck size="20" /> : <IoClose size="20" />}
        </Flex>
      </AdvancedTooltip>

      <AdvancedTooltip
        p="2"
        tooltipContent={
          <Text fontSize="sm">
            {application_date
              ? `Applied on ${moment(application_date).format(
                  'MMM Do YYYY HH:mm:ss'
                )}`
              : 'This user has not applied to the opportunity.'}
          </Text>
        }
      >
        <Flex justifyContent="center" w="20%">
          {is_applied ? <FaCheck size="20" /> : <IoClose size="20" />}
        </Flex>
      </AdvancedTooltip>
      <AdvancedTooltip
        p="2"
        tooltipContent={
          <Text fontSize="sm">
            {recommendation_count
              ? `Last recommended on ${moment(last_recommendation_date).format(
                  'MMM Do YYYY HH:mm:ss'
                )}`
              : 'This user has not recommended anyone.'}
          </Text>
        }
      >
        <Flex justifyContent="center" w="20%">
          {recommendation_count ?? 0}
        </Flex>
      </AdvancedTooltip>
      <Flex justifyContent="center" w="20%">
        {latest_activity
          ? moment(latest_activity).format('MMM Do YYYY HH:mm:ss')
          : 'No activity found.'}
      </Flex>
    </Flex>
  )
}

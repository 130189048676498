import {
  Box,
  chakra,
  Checkbox,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import PicksTracker from 'components/AdminSettings/PicksTracker'
import AddToBoard from 'components/KanbanBoard/AddToBoard'
import RelationshipsRow, { DeepFetchRow } from 'components/RelationshipsRow'
import RequestIntro from 'components/RequestIntro'
import TableFilters from 'components/TableFilters'
import { RelationshipsHeader } from 'components/TableHeader'
import TablePagination from 'components/TablePagination'
import { useAuth } from 'context/AuthContext'
import { useSelected } from 'context/SelectedContext'
import useDeepFetch from 'hooks/useDeepFetch'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import api from 'services/api'
import placeholders from 'utils/placeholders'

let peoplesRequest = null

export default function People() {
  const { user, selectedUser } = useAuth()
  const { selected, toggleSelect, handleSelectAll } = useSelected()

  const {
    data: deepFetchData,
    clearData: clearDeepFetchData,
    callDeepFetch,
    deepFetchProfile,
    deepFetchProfileLoadingIndex,
    isLoading: isDeepFetchLoading,
    setIsLoading: setIsDeepFetchLoading,
  } = useDeepFetch()

  const [isOnlyFirstDegreeEnabled, setIsOnlyFirstDegreeEnabled] = useState(
    false
  )
  const [isSearchingWeeklyPicks, setIsSearchingWeeklyPicks] = useState(false)

  const [relationships, setRelationships] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)

  const [filters, setFilters] = useQueryString({
    name: '',
    company: '',
    position: '',
    location: '',
  })

  const fetchData = async () => {
    setIsLoading(true)
    setIsDeepFetchLoading(false)
    clearDeepFetchData()

    if (peoplesRequest) {
      peoplesRequest.cancel()
    }

    peoplesRequest = api.CancelToken.source()

    try {
      const { data } = await api.get(
        `/relationships?offset=${offset}${Object.entries(filters)
          .map(([key, value]) => `&${key}=${encodeURIComponent(value)}`)
          .join('')}${isOnlyFirstDegreeEnabled ? '&firstDegree=1' : ''}${
          isSearchingWeeklyPicks ? '&weeklyPicks=1' : ''
        }${selectedUser ? `&inBehalfOf=${selectedUser.id}` : ''}`,
        { cancelToken: peoplesRequest.token }
      )
      setRelationships(data.relationships)
      setTotal(data.total)
      setIsLoading(false)

      const hasFilters = Object.values(filters).find((f) => f !== '')
      if (hasFilters && data.total < 10) {
        setIsDeepFetchLoading(true)
        callDeepFetch(
          filters,
          data.relationships.map(
            (relationship) => relationship.linkedin_profile
          )
        )
      }
    } catch (err) {
      if (api.isCancel(err)) {
        console.log('Request canceled', err.message)
      } else {
        console.log(err)
        setRelationships([])
        setTotal(0)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [
    offset,
    filters,
    isOnlyFirstDegreeEnabled,
    isSearchingWeeklyPicks,
    selectedUser,
  ])

  const changeFilter = (type, value) => {
    setOffset(0)
    setFilters({ ...filters, [type]: value })
  }

  return (
    <Box>
      <AddToBoard isFloating selected={selected} />
      <RequestIntro
        isFloating
        selected={selected.filter((s) =>
          ['identity', 'profile'].includes(s.entity)
        )}
      />

      {selectedUser && <PicksTracker userId={selectedUser.id} />}

      <Box mb={6}>
        <Text fontSize="sm" mb={3}>
          Filters{' '}
          <chakra.span fontSize="smaller">
            (Add commas to search by multiple values)
          </chakra.span>
        </Text>

        <Box w="100%" d="flex" justifyContent="center" alignItems="center">
          <TableFilters
            filters={filters}
            setFilter={changeFilter}
            placeholders={placeholders['people']}
            w="100%"
          />
          <Checkbox
            isChecked={isOnlyFirstDegreeEnabled}
            onChange={() =>
              setIsOnlyFirstDegreeEnabled(!isOnlyFirstDegreeEnabled)
            }
            colorScheme="primary"
            d="flex"
            justifyContent="center"
            alignItems="center"
            color={mode('gray.400', 'grayAlpha.5')}
            ml={4}
          >
            <chakra.span minW="17ch" maxW="17ch" isTruncated>
              first degree only
            </chakra.span>
          </Checkbox>
          {user?.id === 79 && (
            <Checkbox
              isChecked={isSearchingWeeklyPicks}
              onChange={() =>
                setIsSearchingWeeklyPicks(!isSearchingWeeklyPicks)
              }
              colorScheme="primary"
              d="flex"
              justifyContent="center"
              alignItems="center"
              color={mode('gray.400', 'grayAlpha.5')}
              ml={4}
            >
              <chakra.span minW="17ch" maxW="17ch" isTruncated>
                searching weekly picks
              </chakra.span>
            </Checkbox>
          )}
        </Box>
      </Box>
      <RelationshipsHeader
        selected={selected}
        handleSelectAll={(isChecked) =>
          handleSelectAll(isChecked, [
            ...relationships.map((el) => ({
              entity: 'identity',
              entity_id: el.right_identity_id,
              name: `${el.firstname} ${el.lastname}`,
              avatar: el.avatar,
              connectors: JSON.parse(el.connectors),
            })),
            ...deepFetchData.map((el) => {
              if (el.isRaw) {
                return {
                  entity: 'profile',
                  entity_id: el.id,
                  name: el.name,
                  avatar: `https://village.sfo3.cdn.digitaloceanspaces.com/l-${el.profile_id}.jpg`,
                  connectors: [],
                }
              } else {
                const companyConnectors = el.companies.map((c) => c.connectors)

                const connectors = companyConnectors.flat()

                return {
                  entity: 'profile',
                  entity_id: el.profile.id,
                  name: `${el.profile.firstname} ${el.profile.lastname}`,
                  avatar: el.avatar_url,
                  connectors,
                }
              }
            }),
          ])
        }
      />
      <Box borderRadius="md" minH="1083px" pos="relative">
        {(isLoading || isDeepFetchLoading) &&
          [...Array(10)].map((el, i) => (
            <RelationshipsRow
              key={i}
              loading={isLoading || isDeepFetchLoading}
            />
          ))}
        {!isLoading &&
          relationships &&
          relationships.map((relationship) => {
            const isSelected =
              selected.findIndex(
                (s) =>
                  s.entity_id === relationship.right_identity_id &&
                  s.entity === 'identity'
              ) !== -1

            return (
              <RelationshipsRow
                key={relationship.right_identity_id}
                relationship={relationship}
                relationships={relationships}
                toggleSelect={() =>
                  toggleSelect(
                    {
                      entity_id: relationship.right_identity_id,
                      entity: 'identity',
                      name: `${relationship.firstname} ${relationship.lastname}`,
                      avatar: relationship.avatar,
                      connectors: JSON.parse(relationship.connectors),
                      work: relationship.work_position
                        ? `${relationship.work_position} at ${relationship.work_company_name}`
                        : '',
                    },
                    isSelected
                  )
                }
                isSelected={isSelected}
              />
            )
          })}
        {deepFetchData.length > 0 &&
          deepFetchData.map((profile, index) => {
            if (profile.isRaw) {
              const relationship = {
                id: profile.id,
                avatar: `https://village.sfo3.cdn.digitaloceanspaces.com/l-${profile.profile_id}.jpg`,
                backupAvatar: profile.avatar,
                name: profile.name,
                linkedin_profile: profile.profile_id,
                work: profile.work,
                location: profile.location,
                warmth_score: -1,
              }

              const isSelected =
                selected.findIndex(
                  (s) => s.entity_id === profile.id && s.entity === 'profile'
                ) !== -1

              return (
                <DeepFetchRow
                  key={index}
                  relationship={relationship}
                  toggleSelect={() =>
                    toggleSelect(
                      {
                        entity_id: profile.id,
                        entity: 'profile',
                        name: relationship.name,
                        avatar: relationship.avatar,
                        connectors: [],
                        work: relationship.work ? relationship.work : '',
                      },
                      isSelected
                    )
                  }
                  isSelected={isSelected}
                  isDeepFetch
                  deepFetchProfile={() =>
                    deepFetchProfile(profile.profile_id, index)
                  }
                  isDeepFetchProfileLoading={
                    deepFetchProfileLoadingIndex === index
                  }
                />
              )
            } else {
              const profileWithoutPaths = profile.companies.length === 0

              const companyConnectors = profile.companies.map(
                (c) => c.connectors
              )

              const connectors = companyConnectors.flat()

              const relationship = {
                right_identity_id: profile.profile.id,
                avatar: profile.profile.avatar_url,
                firstname: profile.profile.firstname,
                lastname: profile.profile.lastname,
                linkedin_profile: `l:${
                  profile.profile.profile_url.split('/in/')[1]
                }`,
                work_position: profile.profile.job_history[0].position,
                work_company_name: profile.profile.job_history[0].company_name,
                work_start: profile.profile.job_history[0].date_from,
                country: profile.profile.location,
                warmth_score: profileWithoutPaths
                  ? -1
                  : Math.max(...profile.companies.map((c) => c.top_warmth)),
                connectors: JSON.stringify(connectors),
              }

              const isSelected =
                selected.findIndex(
                  (s) =>
                    s.entity_id === relationship.right_identity_id &&
                    s.entity === 'profile'
                ) !== -1

              const isTopWarmthLikely =
                !profileWithoutPaths &&
                connectors.findIndex((c) => c.company_size <= 30) === -1

              return (
                <RelationshipsRow
                  key={index}
                  relationship={relationship}
                  relationships={relationships}
                  toggleSelect={() =>
                    toggleSelect(
                      {
                        entity_id: relationship.right_identity_id,
                        entity: 'profile',
                        name: `${relationship.firstname} ${relationship.lastname}`,
                        avatar: relationship.avatar,
                        connectors,
                        work: relationship.work_position
                          ? `${relationship.work_position} at ${relationship.work_company_name}`
                          : '',
                      },
                      isSelected
                    )
                  }
                  isSelected={isSelected}
                  isTopWarmthLikely={isTopWarmthLikely}
                  isDeepFetch
                />
              )
            }
          })}

        <TablePagination
          count={total}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
        />
      </Box>
    </Box>
  )
}

import React from 'react'
import { Box } from '@chakra-ui/react'
import { OpportunitiesHeader } from 'components/TableHeader'
import TablePagination from 'components/TablePagination'
import TableFilters from 'components/TableFilters'
import placeholders from 'utils/placeholders'
import { StatsRow } from 'components/OpportunityMarketplace/StatsRow'
import RelationshipsRow from 'components/RelationshipsRow'

export const OpportunityTrackingView = ({
  isLoading,
  filters,
  activeField,
  setActiveField,
  changeFilter,
  total,
  offset,
  setOffset,
  limit,
  setLimit,
  orderBy,
  setOrderBy,
  data,
}) => {
  const renderRows = () => {
    if (isLoading) {
      return [...Array(10)].map((el, i) => (
        <RelationshipsRow loading={isLoading} key={i} />
      ))
    }

    return data.map((item, index) => (
      <StatsRow isLoading={isLoading} data={item} key={index} />
    ))
  }

  return (
    <Box p="4">
      <TableFilters
        mt="4"
        filters={filters}
        setFilter={changeFilter}
        placeholders={placeholders['people']}
      />
      <OpportunitiesHeader
        activeField={activeField}
        setActiveField={setActiveField}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      {renderRows()}
      <Box mt="16">
        <TablePagination
          count={total}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
        />
      </Box>
    </Box>
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  useColorModeValue as mode,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  CloseButton,
  Text,
  Grid,
  Input,
  Flex,
  Box,
  Checkbox,
  Divider,
  Spinner,
  Button,
} from '@chakra-ui/react'
import { SharedRow } from './SharedRow'
import { MenuSelect } from 'components/MenuSelect'
import api from 'services/api'
import { useToast } from 'hooks/useToast'
import InfiniteScroll from 'react-infinite-scroller'

const strengthOptions = [
  { value: 'Any', label: 'Any' },
  { value: 'Not sure', label: 'Not sure' },
  { value: 'Maybe', label: 'Maybe' },
  { value: 'Average', label: 'Average' },
  { value: 'Good', label: 'Good' },
  { value: 'Excellent', label: 'Excellent' },
]

export const SharePreferencesModal = ({
  isOpen,
  onClose,
  preferenceValues,
  selected,
  setSelected,
  setQueryParams,
  isEdit,
}) => {
  const {
    strength,
    setStrength,
    location,
    setLocation,
    company,
    setCompany,
    name,
    setName,
  } = preferenceValues

  const [results, setResults] = useState([])

  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const [count, setCount] = useState(0)

  const [page, setPage] = useState(1)

  const [hasMore, setHasMore] = useState(true)

  const [getAll, setGetAll] = useState(false)

  const [selectAllInView, setSelectAllInView] = useState(false)

  const [allIdentities, setAllIdentities] = useState([])

  const cancelSource = useRef()

  const fetchPeople = async (page, scrollBackToTop) => {
    try {
      if (cancelSource.current) {
        cancelSource.current.cancel()
      }

      setLoading(true)
      cancelSource.current = api.CancelToken.source()

      const body = {
        page,
        strength,
        location,
        company,
        name,
        selectedIdentities: selected.map((s) => s.id),
        getAll,
      }

      const { data } = await api.post('/opportunity/findpeople', body, {
        cancelToken: cancelSource.current.token,
      })
      if (data.results.length === 0) {
        setHasMore(false)
      }

      if (scrollBackToTop && scrollRef.current) {
        scrollRef.current.scrollTo(0, 0)
      }

      if (page === 1) {
        setPage(1)
        setHasMore(true)
        setAllIdentities(data.allIdentities)
      }

      if (page > 1) {
        setPage(page)
        if (selectAllInView) {
          setSelected([
            ...selected,
            ...data.results.map((r) => ({ ...r, bulk: true })),
          ])
        }
      }

      page === 1
        ? setResults(data.results)
        : setResults([...results, ...data.results])
      if (page === 1) {
        setCount(data.count)
      }
      setQueryParams(data.queryParams)
      setLoading(false)
    } catch (e) {
      console.log('e', e)
      if (api.isCancel(e)) {
        return
      }
      toast({ title: 'Something went wrong.', status: 'error' })
    }
  }

  /* count of people that are on both selected and results array */
  const countPeople = () => {
    return selected.filter((s) => results.find((r) => r.id === s.id)).length
  }

  useEffect(() => {
    fetchPeople(1, true)
    if (selectAllInView) {
      setSelectAllInView(false)
    }
  }, [strength, location, company, name])

  const onSelect = (person) => {
    const isSelected = selected.some((p) => p.id === person.id)
    if (!isSelected) {
      setSelected([...selected, person])
      return
    }

    setSelected(selected.filter((p) => p.id !== person.id))
  }

  const scrollRef = useRef()

  const removeAll = () => {
    let removalCondition = (s) => s.bulk !== true

    if (isEdit) {
      removalCondition = (s) => {
        if (s.bulk && s.bulk === true) {
          return false
        }
        if (s.privacy_id !== undefined) {
          return false
        }

        return true
      }
    }

    const nonBulkSelected = selected.filter(removalCondition)
    setSelected(nonBulkSelected)
    setGetAll(false)
    setSelectAllInView(false)
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent my="0">
        <ModalHeader py="0">
          <CloseButton
            pos="absolute"
            right="5"
            top="5"
            onClick={() => onClose()}
          />
        </ModalHeader>

        <ModalBody
          px="0"
          pt="6"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${mode(
                'var(--chakra-colors-gray-200)',
                'var(--chakra-colors-grayAlpha-200)'
              )}`,
              borderRadius: '24px',
            },
          }}
          overflowX="hidden"
        >
          <Box h="80vh">
            <Text
              px="6"
              color={mode('black', 'white')}
              fontSize="2xl"
              fontWeight="bold"
            >
              Select people you want to share this opportunity with
            </Text>
            <Flex mt="9" sx={{ gap: 22 }}>
              <Box px="6" w="50%" mt="4">
                <Grid mb="9" templateColumns="repeat(2,1fr)" gap="2.5">
                  <Input
                    borderColor={mode('transparent', 'grayAlpha.700')}
                    bg={mode('gray.1', 'black')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    color={mode('black', 'white')}
                    placeholder="Name"
                  />
                  <Input
                    borderColor={mode('transparent', 'grayAlpha.700')}
                    value={company}
                    bg={mode('gray.1', 'black')}
                    onChange={(e) => setCompany(e.target.value)}
                    color={mode('black', 'white')}
                    placeholder="Company"
                  />
                  <Input
                    borderColor={mode('transparent', 'grayAlpha.700')}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    bg={mode('gray.1', 'black')}
                    color={mode('black', 'white')}
                    placeholder="Location"
                  />
                  <MenuSelect
                    borderColor={mode('transparent', 'grayAlpha.700')}
                    mt="0"
                    px="1"
                    borderRadius="base"
                    h="auto"
                    borderWidth={mode(1, 0)}
                    bg={mode('gray.1', 'black')}
                    value={strengthOptions.find(
                      (option) => option.value === strength
                    )}
                    onChange={(option) => setStrength(option.value)}
                    options={strengthOptions}
                  />
                </Grid>
                <Flex mb="3" alignItems="flex-start" sx={{ gap: 20 }}>
                  <Checkbox
                    mt="1"
                    size="lg"
                    isChecked={selectAllInView}
                    onChange={() => {
                      setSelectAllInView(!selectAllInView)
                      if (!selectAllInView) {
                        setSelected([
                          ...selected,
                          ...results
                            .filter((r) => !selected.find((s) => s.id === r.id))
                            .map((r) => ({ ...r, bulk: true })),
                        ])
                        return
                      }
                    }}
                  />
                  <Text fontSize="xs" color="turquoise.500" as="span">
                    <Text as="span" fontWeight="bold">
                      {countPeople()} people
                    </Text>{' '}
                    in the below view are selected.{' '}
                    <Text
                      as="button"
                      onClick={() => {
                        setGetAll(true)
                        setSelectAllInView(true)
                        setSelected([
                          ...selected,
                          ...allIdentities
                            .filter((i) => !selected.find((s) => s.id === i.id))
                            .map((i) => ({ ...i, bulk: true })),
                        ])
                      }}
                      fontWeight="bold"
                      textDecoration="underline"
                    >
                      {getAll ? 'Selected' : 'Select'} all {count} that match
                      the search criteria
                    </Text>
                  </Text>
                </Flex>
                <Divider mx="-6" w="calc(100% + 22px + 3rem)" />
                <Box
                  h="45vh"
                  overflowY="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: `${mode(
                        'var(--chakra-colors-gray-200)',
                        'var(--chakra-colors-grayAlpha-200)'
                      )}`,
                      borderRadius: '24px',
                    },
                  }}
                  overflowX="hidden"
                  mt="4"
                  pb="16"
                  ref={scrollRef}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      if (!loading) {
                        fetchPeople(page + 1)
                      }
                    }}
                    hasMore={hasMore}
                    loader={<Spinner />}
                    useWindow={false}
                    getScrollParent={() => scrollRef.current}
                  >
                    {results.map((result, index) => (
                      <SharedRow
                        key={index}
                        selected={selected.some((p) => p.id === result.id)}
                        currPosition={result.current_work_position}
                        currCompany={result.current_work_company_name}
                        currCompanyId={result.current_work_company_id}
                        id={result.id}
                        name={result.fullName}
                        avatar={result.avatar}
                        warmthScore={result.warmth_score}
                        onChange={() => onSelect(result)}
                      />
                    ))}
                  </InfiniteScroll>
                  {!hasMore && results.length === 0 && 'No results found.'}
                  {!hasMore &&
                    results.length > 0 &&
                    'Could not find more results.'}
                </Box>
              </Box>
              <Box
                p="6"
                overflowY="auto"
                h="70vh"
                w="50%"
                bg={mode('#F3F3F3', 'grayAlpha.200')}
              >
                <Flex>
                  <Text
                    fontSize="lg"
                    color="turquoise.500"
                    fontWeight="bold"
                    as="span"
                  >
                    {selected.length} people
                  </Text>
                  <Text
                    ml="1"
                    as="span"
                    color="turquoise.500"
                    fontSize="lg"
                    fontWeight="normal"
                  >
                    added so far
                  </Text>
                  <Text
                    as="button"
                    textDecor="underline"
                    onClick={() => removeAll()}
                    color="red.300"
                    variant="link"
                    ml="auto"
                  >
                    Clear
                  </Text>
                </Flex>

                <Box
                  overflowY="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: `${mode(
                        'var(--chakra-colors-gray-200)',
                        'var(--chakra-colors-grayAlpha-200)'
                      )}`,
                      borderRadius: '24px',
                    },
                  }}
                  overflowX="hidden"
                  mt="8"
                >
                  {selected.slice(0, 50).map((result, index) => (
                    <SharedRow
                      alreadySelected={true}
                      key={index}
                      selected={true}
                      currPosition={result.current_work_position}
                      currCompany={result.current_work_company_name}
                      currCompanyId={result.current_work_company_id}
                      id={result.id}
                      name={result.fullName}
                      avatar={result.avatar}
                      warmthScore={result.warmth_score}
                      onChange={() => onSelect(result)}
                    />
                  ))}
                </Box>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
        <Flex
          pos="absolute"
          bottom="0"
          bgGradient={mode(
            'linear(rgba(0,0,0,0.0),white, white, white)',
            'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
          )}
          w="100%"
          alignItems="center"
          py="4"
          px="10"
        >
          <Button variant="village" ml="auto" onClick={onClose}>
            Continue
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

const fetchJobsFromExtension = (companies, role) => {
  return new Promise((resolve) => {
    window.chrome.runtime &&
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        {
          message: 'getLinkedinCompanies',
          companies: companies,
          role: role,
        },
        function (reply) {
          if (reply) {
            return resolve(reply)
          } else {
            console.log('no reply')
          }
        }
      )
  })
}

const getExtensionStatus = (shouldTriggerReset) => {
  return new Promise((resolve) => {
    window.chrome.runtime &&
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        {
          message: 'getExtensionStatus',
          shouldTriggerReset,
        },
        function (reply) {
          if (reply) {
            return resolve(reply)
          } else {
            return resolve('no reply')
          }
        }
      )
  })
}

const deepFetch = (filters) => {
  const parsedFilters = Object.values(filters)
    .filter((filter) => filter !== '')
    .join(' ')

  return new Promise((resolve) => {
    window.chrome.runtime &&
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        {
          message: 'deepFetch',
          filters: parsedFilters,
        },
        function (reply) {
          if (reply) {
            return resolve(reply)
          } else {
            return resolve('no reply')
          }
        }
      )
  })
}

const deepFetchProfile = (profileId) => {
  return new Promise((resolve) => {
    window.chrome.runtime &&
      window.chrome.runtime.sendMessage(
        process.env.REACT_APP_CHROME_EXTENSION_ID,
        {
          message: 'deepFetchProfile',
          profileId,
        },
        function (reply) {
          if (reply) {
            return resolve(reply)
          } else {
            return resolve('no reply')
          }
        }
      )
  })
}

export {
  fetchJobsFromExtension,
  getExtensionStatus,
  deepFetch,
  deepFetchProfile,
}

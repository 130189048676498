import React, { createContext, useContext, useState } from 'react'

const OpportunityContext = createContext()

export const useOpportunity = () => {
  const context = useContext(OpportunityContext)

  return context
}

export const OpportunityProvider = ({ children }) => {
  const [categories, setCategories] = useState([])

  const [companies, setCompanies] = useState([])

  const [location, setLocation] = useState('')

  return (
    <OpportunityContext.Provider
      value={{
        categories,
        companies,
        location,
        setCompanies,
        setCategories,
        setLocation,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  )
}

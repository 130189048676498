import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, useToast } from '@chakra-ui/react'

import api from 'services/api'
import ProfileCard from 'components/ProfileCard'

export default function Connection() {
  const { id } = useParams()
  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [contact, setContact] = useState(false)

  useEffect(() => {
    const fetchConnection = async () => {
      setLoading(true)
      try {
        const { data } = await api.get(`/contact/${id}`)
        setContact({
          ...data,
          account_identifier: data.account_identifier.split(','),
          account_type: data.account_type.split(','),
        })
        toast({ title: 'Contact found.' })
        setLoading(false)
      } catch (err) {
        toast({
          title: `err`,
          status: 'error',
          isClosable: true,
        })
      }
    }

    fetchConnection()
  }, [])

  return (
    <Box d="flex" justifyContent="center" alignItems="center" p={6}>
      {loading ? (
        <h1>Loading... </h1>
      ) : (
        <ProfileCard contact={contact} loading={loading} />
      )}
    </Box>
  )
}

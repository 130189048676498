import React from 'react'
import {
  Text,
  Input,
  Box,
  useColorModeValue as mode,
  Textarea,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { Controller } from 'react-hook-form'

export const ApplicationForm = ({ errors, control }) => {
  const { user } = useAuth()

  return (
    <Box pt="27px" px="20px">
      {!user && (
        <>
          <Text mb="2" px="2">
            Contact Info
          </Text>

          <Controller
            name="fullName"
            control={control}
            rules={{
              required: {
                value: user ? false : true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                borderColor={errors.fullName ? 'red.500' : 'transparent'}
                mb="2"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                bg={mode('gray.50', 'grayAlpha.800')}
                placeholder="Full Name"
                type="text"
              />
            )}
          />
          <Controller
            name="linkedinUrl"
            control={control}
            rules={{
              required: {
                value: user ? false : true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                borderColor={errors.linkedinUrl ? 'red.500' : 'transparent'}
                mb="2"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                bg={mode('gray.50', 'grayAlpha.800')}
                placeholder="Linkedin Profile URL"
                type="text"
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: user ? false : true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                borderColor={errors.email ? 'red.500' : 'transparent'}
                mb="4"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                bg={mode('gray.50', 'grayAlpha.800')}
                placeholder="Email"
                type="text"
              />
            )}
          />
        </>
      )}
      <Text px="2">Why do you want to apply to this opportunity?</Text>
      <Controller
        name="reason"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Textarea
            borderColor={errors.reason ? 'red.500' : 'transparent'}
            h="140px"
            placeholder="Type a reason"
            mt="4"
            bg={mode('gray.50', 'grayAlpha.800')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            resize="none"
            maxLength={255}
            outline="transparent"
            _focus={{ outline: 'transparent' }}
            _hover={{ outline: 'transparent' }}
          />
        )}
      />
    </Box>
  )
}

import { Box, Icon } from '@chakra-ui/react'

import { FaEnvelope, FaLinkedin, FaPhoneAlt } from 'react-icons/fa'

function parseLink(type, identifier, option) {
  if (type === 'email') return `mailto:${identifier.substring(2)}`
  if (type === 'linkedin') return identifier.substring(2)

  if (type === 'phone' && option === 'call')
    return `tel:${identifier.substring(2)}`
  if (type === 'phone' && option === 'sms')
    return `sms:${identifier.substring(2)}`
  if (type === 'phone' && option === 'whatsapp')
    return `https://wa.me/${identifier.substring(2)}`
}

function parseIdentifier(identifier) {
  const startsWith = identifier[0]

  if (startsWith === 'e') return identifier.substring(2)

  if (startsWith === 'l') return `@${identifier.substring(30)}`

  if (startsWith === 'p') return identifier.substring(2)
}

export { parseIdentifier, parseLink }

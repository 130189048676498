import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { stateToHTML } from 'draft-js-export-html'
import { useToast } from 'hooks/useToast'
import { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { IoIosGitNetwork } from 'react-icons/io'
import api from 'services/api'
import { encryptId } from 'utils/encryption'
import { reasonParser } from 'utils/parsers'
import { steps } from './data'
import ModalHeader from './ModalHeader'
import RequestIntroFromHeader from './RequestIntroFromHeader'
import Selected from './Selected'
import TemplateStep from './TemplateStep'
import { useStep } from './useStep'

const text = {
  accepted: 'accepted',
  denied: 'not a good fit',
  unknown: `don't know the person`,
  pending: 'waiting for forwardable',
  completed: 'completed',
  null: 'pending',
}

export default function RequestIntro({
  isFloating,
  selected,
  isOpportunity,
  ...props
}) {
  const {
    onOpen: onModalOpen,
    isOpen: isModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  const toast = useToast()

  const {
    user: { identityId: userIdentityId },
  } = useAuth()

  const [introducer, setIntroducer] = useState(false)
  const [reason, setReason] = useState(Array(selected.length).fill(''))

  const [chosenAccount, setChosenAccount] = useState('')

  const [isRequestingIntro, setIsRequestingIntro] = useState(false)

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
  })

  const [options, setOptions] = useState(
    selected
      .map((s) => s.connectors)
      .flat()
      .reduce((acc, connector) => {
        if (userIdentityId === connector.identity_id) {
          return acc
        }

        const connectorInAcc = acc.find(
          (a) => a.identity_id === connector.identity_id
        )

        if (connectorInAcc) {
          connectorInAcc.count += 1
        } else {
          acc.push({
            ...connector,
            count: 1,
          })
        }

        return acc
      }, [])
      .sort((a, b) => b.calculated_warmth_score - a.calculated_warmth_score)
      .sort((a, b) => b.count - a.count)
  )

  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false)
  const [accounts, setAccounts] = useState([])

  const [repeatedIntros, setRepeatedIntros] = useState([])
  const [isVerifyingIntro, setIsVerifyingIntro] = useState(false)

  const isFinalStepAllowed = !reason.some((r) => !reasonParser(r))

  const notifyRepeatedIntros = (data) => {
    const intros = data ? data : repeatedIntros

    for (let i = 0; i < intros.length; i++) {
      let intro = intros[i]

      setTimeout(() => {
        toast({
          title: 'Repeated request',
          description: `You already have a request from ${
            introducer.name
          } to introduce you to ${intro.name} with feedback: ${
            text[intro.status]
          }.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      }, i * 1500)
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      setReason(Array(selected.length).fill(''))
      const selectedOptions = selected
        .map((s) => s.connectors)
        .flat()
        .reduce((acc, connector) => {
          if (userIdentityId === connector.identity_id) {
            return acc
          }

          const connectorInAcc = acc.find(
            (a) => a.identity_id === connector.identity_id
          )

          if (connectorInAcc) {
            connectorInAcc.count += 1
          } else {
            acc.push({
              ...connector,
              count: 1,
            })
          }

          return acc
        }, [])
        .sort((a, b) => b.calculated_warmth_score - a.calculated_warmth_score)
        .sort((a, b) => b.count - a.count)
      setOptions(selectedOptions)
    }
  }, [selected.length, isModalOpen])

  useEffect(() => {
    if (!isModalOpen) {
      setIntroducer(false)
      setChosenAccount('')
      setIsRequestingIntro(false)
      setStep(0)
    }
  }, [isModalOpen])

  useEffect(() => {
    const verifyIntro = async () => {
      setIsVerifyingIntro(true)
      try {
        const { data } = await api.post('/intros/verify', {
          introducer: introducer.identity_id,
          email: chosenAccount,
          requests: selected.map((s, i) => ({
            name: s.name,
            entity: s.entity,
            entity_id: s.entity_id,
          })),
        })
        setRepeatedIntros(data)
        if (data.length) {
          notifyRepeatedIntros(data)
        }
      } catch (err) {
        console.error(err)
      }
      setIsVerifyingIntro(false)
    }

    const fetchConnection = async () => {
      setIsFetchingAccounts(true)
      try {
        const { data } = await api.get(`/contact/${introducer.identity_id}`)

        if (data.account_identifier) {
          const rawAccounts = data.account_identifier.split(',')
          const emailAccounts = rawAccounts.filter(
            (acc) => typeof acc === 'string' && acc.startsWith('e:')
          )

          setAccounts(emailAccounts)

          if (emailAccounts.length) {
            setChosenAccount(emailAccounts[0].substring(2))
          }
        }

        setIsFetchingAccounts(false)
      } catch (err) {
        console.error(err)
      }
    }

    introducer.identity_id && verifyIntro()
    introducer.identity_id && chosenAccount === '' && fetchConnection()
  }, [introducer.identity_id])

  const handleRequestIntro = async (editorState, subject) => {
    setIsRequestingIntro(true)

    try {
      await api.post('/intro', {
        emailBody: stateToHTML(editorState.getCurrentContent()),
        subject,
        introducer: introducer.identity_id,
        email: chosenAccount,
        requests: selected.map((s, i) => ({
          name: s.name,
          avatar: s.avatar,
          work: s.work,
          entity: s.entity,
          entity_id: s.entity_id,
          profileId: encryptId(s.entity_id),
          reason: reasonParser(reason[i]),
        })),
      })

      toast({
        title: 'Intro request sent',
        description: 'Your request has been sent!',
        status: 'success',
      })

      onModalClose()
    } catch (err) {
      console.error(err)
    }

    setIsRequestingIntro(false)
  }

  const renderButton = () => {
    if (isOpportunity) {
      return (
        <Text
          {...props}
          as="button"
          color="#5AB7B9"
          fontWeight="bold"
          textDecor="underline"
          sx={{ textUnderlineOffset: 2 }}
          variant="link"
          onClick={onModalOpen}
        >
          {'Get Introduced ->'}
        </Text>
      )
    }

    if (isFloating) {
      return (
        <Button
          leftIcon={<IoIosGitNetwork color="white" />}
          variant="village"
          visibility={selected.length === 0 ? 'hidden' : 'visible'}
          pos={'fixed'}
          left={'48'}
          bottom={'3'}
          zIndex={'99999'}
          onClick={onModalOpen}
          {...props}
        >
          Request Intro
        </Button>
      )
    }

    return (
      <Button
        variant="villageOutline"
        w="135px"
        leftIcon={<IoIosGitNetwork color="middle.500" h="5" w="5" />}
        onClick={onModalOpen}
        color="middle.500"
        borderColor="middle.500"
      >
        Get intro
      </Button>
    )
  }

  return (
    <>
      {renderButton()}

      <Modal
        isOpen={isModalOpen}
        onOpen={onModalOpen}
        onClose={onModalClose}
        size="2xl"
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader
            currentStep={currentStep}
            steps={steps}
            setStep={setStep}
            isFinalStepAllowed={
              isFinalStepAllowed &&
              repeatedIntros.length === 0 &&
              !!chosenAccount
            }
          />

          <ModalCloseButton />
          {currentStep === 0 && (
            <ModalBody>
              <RequestIntroFromHeader
                options={options}
                introducer={introducer}
                setIntroducer={setIntroducer}
                chosenAccount={chosenAccount}
                setChosenAccount={setChosenAccount}
                accounts={accounts}
                isFetchingAccounts={isFetchingAccounts}
              />
            </ModalBody>
          )}

          {currentStep === 1 && (
            <ModalBody pos="relative">
              <Text fontSize="2xl" mb={4} fontWeight="bold">
                People you want to be introduced to:
              </Text>

              <Stack
                spacing={2}
                mb={4}
                maxH={`min(35rem, 55vh)`}
                overflowY="auto"
                bg={mode('gray.50', 'grayAlpha.800')}
                p={4}
                pb={12}
                borderRadius="md"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: `${mode(
                      'var(--chakra-colors-gray-200)',
                      'var(--chakra-colors-grayAlpha-200)'
                    )}`,
                    borderRadius: '24px',
                  },
                }}
              >
                {selected.map((item, itemIndex) => {
                  const connector = introducer.identity_id
                    ? item.connectors.find(
                        (c) => c.identity_id === introducer.identity_id
                      )
                    : false

                  item.strength = connector
                    ? connector.calculated_warmth_score
                    : false

                  return (
                    <Selected
                      key={itemIndex}
                      selected={item}
                      reason={reason[itemIndex]}
                      setReason={(inputValue) => {
                        const newReason = [...reason]
                        newReason[itemIndex] = inputValue
                        setReason(newReason)
                      }}
                    />
                  )
                })}
              </Stack>

              {currentStep === 1 && (
                <Box
                  pos="absolute"
                  w="100%"
                  bottom="-4rem"
                  zIndex="999"
                  left="50%"
                  transform={`translate(-50%, -50%)`}
                  d="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pb={6}
                  pt={10}
                  px={12}
                  bgGradient={mode(
                    'linear(rgba(0,0,0,0.0),white, white, white)',
                    'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
                  )} // add 4 colors, first 0 opacity the rest same color modal
                >
                  <Button
                    variant="outline"
                    padding="1.25rem"
                    isDisabled={currentStep === 0}
                    onClick={() => setStep(currentStep - 1)}
                    mr="auto"
                  >
                    Back
                  </Button>

                  <Button
                    variant="village"
                    isDisabled={!chosenAccount}
                    onClick={() => {
                      if (!isFinalStepAllowed) {
                        toast({
                          title: 'Please fill in all the reasons',
                          status: 'error',
                        })
                        setIsRequestingIntro(false)
                      } else if (repeatedIntros.length > 0) {
                        notifyRepeatedIntros()
                      } else {
                        setStep(2)
                      }
                    }}
                    rightIcon={<FaArrowRight />}
                  >
                    See Preview
                  </Button>
                </Box>
              )}
            </ModalBody>
          )}

          {currentStep === 2 && (
            <TemplateStep
              chosenAccount={chosenAccount}
              handleRequestIntro={handleRequestIntro}
              isRequestingIntro={isRequestingIntro}
              introducer={introducer}
              targets={selected.map((s, sIndex) => ({
                ...s,
                reason: reasonParser(reason[sIndex]),
              }))}
              returnButton={
                <Button
                  variant="outline"
                  padding="1.25rem"
                  isDisabled={currentStep === 0}
                  onClick={() => setStep(currentStep - 1)}
                  mr="auto"
                >
                  Back
                </Button>
              }
            />
          )}

          {currentStep === 0 && (
            <ModalFooter>
              <Button
                variant="outline"
                padding="1.25rem"
                isDisabled={currentStep === 0}
                onClick={() => setStep(currentStep - 1)}
                mr="auto"
              >
                Back
              </Button>

              <Button
                variant="village"
                isDisabled={!chosenAccount || repeatedIntros.length}
                isLoading={isVerifyingIntro}
                onClick={() => setStep(1)}
                rightIcon={<FaArrowRight />}
              >
                Next
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

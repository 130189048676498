import {
  Box,
  Button,
  Flex,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { NavLink, useLocation } from 'react-router-dom'
import { UserSelector } from './AdminSettings/UserSelector'

export const ViewContainer = ({ links, children }) => {
  const { user } = useAuth()
  const location = useLocation()

  return (
    <Box>
      <Flex
        p={2}
        borderBottom={'1px solid'}
        borderColor={mode('gray.200', 'grayAlpha.700')}
      >
        <Stack direction={'row'} spacing={4} ml={4}>
          {links.map(({ name, icon, path }) => (
            <Button
              variant="ghost"
              aria-current="page"
              fontSize="sm"
              fontWeight={location.pathname === path ? 'bold' : '500'}
              bg={
                location.pathname === path
                  ? mode('gray.50', 'grayAlpha.700')
                  : 'transparent'
              }
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: mode('gray.50', 'grayAlpha.700'),
              }}
              color={mode('black', 'white')}
              p={2}
              as={NavLink}
              to={path}
              leftIcon={icon || null}
            >
              {name}
            </Button>
          ))}
        </Stack>
        {user.id === 79 && <UserSelector />}
      </Flex>

      <Box p={6}>{children}</Box>
    </Box>
  )
}

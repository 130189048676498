import React from 'react'
import {
  Flex,
  Text,
  Button,
  useColorModeValue as mode,
  Box,
} from '@chakra-ui/react'
import moment from 'moment'
import { FaLock } from 'react-icons/fa'
import { AddNoteSection } from './AddNoteSection'

export const BoardSection = ({
  boardData,
  activeBoard,
  setActiveBoard,
  privateNotes,
  deleteNote,
  note,
  handleInputChange,
  sendNote,
  sendNoteLoading,
  errors,
}) => {
  if (!boardData || !boardData.length) return null

  const arrangeBoardData = () => {
    const result = {}

    for (const board of boardData) {
      result[board.board_id] = {
        title: board.board_title,
        id: board.board_id,
        tasks: boardData.filter((task) => task.board_id === board.board_id),
      }
    }

    return result
  }

  const renderBoardTitles = () => {
    const arrangedBoardData = arrangeBoardData()

    const titlesToRender = []

    for (const [key, value] of Object.entries(arrangedBoardData)) {
      titlesToRender.push(
        <Button
          ml="26.5px"
          borderRadius={0}
          key={key}
          _focus={{ outline: 'none' }}
          variant="link"
          onClick={() => setActiveBoard(value.id)}
        >
          <Text
            px="5.5px"
            py="15px"
            fontWeight={activeBoard === value.id ? 'bold' : 'normal'}
            color={mode('black', 'white')}
          >
            {value.title}
            {activeBoard === value.id && (
              <Box
                zIndex="1"
                left="0"
                position="absolute"
                bottom="0px"
                w="100%"
                h="3px"
                bg={mode('black', 'white')}
              />
            )}
          </Text>
        </Button>
      )
    }

    return titlesToRender.map((title) => title)
  }

  const renderTaskContents = () => {
    const arrangedBoardData = arrangeBoardData()

    const activeBoardData = arrangedBoardData[activeBoard] || privateNotes

    const tasks = activeBoardData?.tasks || privateNotes?.notes

    let filteredTasks = tasks.filter((task) => task.note_id !== null || task.id)

    if (!filteredTasks.length) return null

    return (
      <Box pr="4">
        {filteredTasks.map((t) => (
          <Flex
            alignItems="flex-start"
            direction={['column', 'row', 'row']}
            textAlign={['center', 'center', 'center', 'left']}
            key={t.id}
            mt={[8, 8, 0, 0]}
            mb="11px"
            lineHeight="19px"
          >
            <Flex
              maxW={['500px', '122px']}
              ml={['0', '0', '-1', '-1']}
              w={['100%', '100%', '30%', '30%']}
              justifyContent={[
                'space-between',
                'space-between',
                'flex-end',
                'flex-end',
              ]}
              mr="13px"
            >
              <Text fontSize={16} color="#9D9D9D">
                {t.created_date
                  ? moment(t.created_date).format('MMM DD @ HH:mm')
                  : 'Unknown time'}
              </Text>
              <Button
                ml="auto"
                display={['inline-flex', 'none']}
                onClick={() => deleteNote(t.id || t.note_id, t.task_id)}
                variant="link"
              >
                <Text
                  fontWeight="400"
                  textDecor="underline"
                  color="#A0A0A0"
                  style={{ textUnderlineOffset: '2px' }}
                >
                  delete
                </Text>
              </Button>
            </Flex>
            <Text
              w={['85%', '85%', '88%', '88%']}
              textAlign="left"
              pr="2"
              fontSize={16}
              color={mode('black', 'white')}
              as="span"
            >
              {t.note ?? 'No content found.'}
            </Text>
            <Button
              display={['none', 'inline-flex', 'inline-flex', 'inline-flex']}
              onClick={() => deleteNote(t.id || t.note_id, t.task_id)}
              ml={['0', 'auto', 'auto', 'auto']}
              variant="link"
            >
              <Text
                fontWeight="400"
                textDecor="underline"
                style={{ textUnderlineOffset: '2px' }}
                color="#A0A0A0"
              >
                delete
              </Text>
            </Button>
          </Flex>
        ))}
      </Box>
    )
  }

  return (
    <>
      <Box
        display="inline-block"
        overflowX="scroll"
        overflowY="hidden"
        whiteSpace="nowrap"
        w="100%"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Button
          borderRadius={0}
          onClick={() => setActiveBoard('priv')}
          variant="link"
        >
          <FaLock size="13px" color={mode('black', 'white')} />
          <Text
            px="5.5px"
            py="15px"
            ml="4px"
            fontWeight={activeBoard === 'priv' ? 'bold' : 'normal'}
            color={mode('black', 'white')}
          >
            Private Notes
            {activeBoard === 'priv' && (
              <Box
                left="0"
                position="absolute"
                zIndex={1}
                bottom="0px"
                w="100%"
                h="3px"
                bg={mode('#000000', 'white')}
              />
            )}
          </Text>
        </Button>
        {renderBoardTitles()}
      </Box>
      <Box
        bottom="7px"
        position="relative"
        borderBottomWidth={1}
        borderColor={mode('#EFEFEF', 'grayAlpha.500')}
      />
      <AddNoteSection
        note={note}
        errors={errors}
        sendNote={sendNote}
        sendNoteLoading={sendNoteLoading}
        handleInputChange={handleInputChange}
      />
      {renderTaskContents()}
    </>
  )
}

import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'

const secret = 'SuCkMyBa7l5m5t/A'

const prefix = '!vNnNM'

const encryptId = (id) => {
  if (id) {
    return encodeURIComponent(
      AES.encrypt(id.toString(), CryptoJS.enc.Utf8.parse(secret), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
    ).concat(prefix)
  }
}

// the decipher function
const decryptId = (id) => {
  if (typeof id !== 'string') return null

  const decodedId = decodeURIComponent(id)

  if (!decodedId.endsWith(prefix)) return null

  return AES.decrypt(decodedId, CryptoJS.enc.Utf8.parse(secret), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8)
}

export { encryptId, decryptId }

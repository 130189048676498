import React from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

export const AdvancedTooltip = ({
  children,
  tooltipContent,
  ...contentProps
}) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent border="none" {...contentProps}>
        {tooltipContent}
      </PopoverContent>
    </Popover>
  )
}

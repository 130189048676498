import {
  Box,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import GetInTouchDropdown from './GetInTouchDropdown'
import AddToBoard from './KanbanBoard/AddToBoard'
import PathsConnection from './PathsConnection'
import RequestIntro from './RequestIntro'

export default function PathsModal({
  relationship,
  isOpen,
  onOpen,
  onClose,
  ...rest
}) {
  const {
    user: { identityId: userIdentityId },
  } = useAuth()

  const connectors = relationship.connectors
    ? JSON.parse(relationship.connectors).sort((a, b) => {
        return b.calculated_warmth_score - a.calculated_warmth_score
      })
    : []

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="75vh" overflowY="auto" overflowX="hidden">
          <Text mb={2}>
            Your paths to{' '}
            <chakra.span fontWeight="bold">
              {relationship.firstname} {relationship.lastname}
            </chakra.span>
            {connectors.findIndex((c) => c.period) !== -1 &&
              ' through work history'}
          </Text>

          <Stack p={2} mb={2}>
            {connectors.map((connector, connectorIndex) => (
              <Box
                key={connectorIndex}
                w="100%"
                minH="6rem"
                maxH="6rem"
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px"
                borderColor={mode('gray.50', 'grayAlpha.400')}
                _last={{
                  borderBottom: 'none',
                }}
              >
                <Box minW="67.5%" maxW="67.5%" d="flex" alignItems="center">
                  <PathsConnection
                    connector={connector}
                    target={relationship}
                  />
                </Box>

                <Box
                  w="35%"
                  d="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {Number(connector.identity_id) !== userIdentityId && (
                    <AddToBoard
                      selected={[
                        {
                          entity_id: connector.identity_id,
                          entity: 'identity',
                        },
                      ]}
                      entity="identity"
                      mr={2}
                    />
                  )}
                  {Number(connector.identity_id) !== userIdentityId ? (
                    <RequestIntro
                      selected={[
                        {
                          entity_id: relationship.right_identity_id,
                          entity: 'identity',
                          name: `${relationship.firstname} ${relationship.lastname}`,
                          avatar: relationship.avatar,
                          connectors: [connector],
                          work: relationship.current_work_position
                            ? `${relationship.current_work_position} at ${relationship.current_work_company_name}`
                            : '',
                        },
                      ]}
                    />
                  ) : (
                    <GetInTouchDropdown
                      contactId={relationship.right_identity_id}
                      float="right"
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

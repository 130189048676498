const notionDarkColors = {
  'notion-dark-gray': {
    50: '#33373b',
    200: '#474c50',
    400: '#3c4144',
    500: '#6b7074',
    700: '#fff',
  },
  'notion-dark-brown': {
    50: '#3b3633',
    200: '#5c473d',
    400: '#4c3d35',
    500: '#9b6245',
    700: '#fff',
  },
  'notion-dark-orange': {
    50: '#3d3631',
    200: '#88542c',
    400: '#553b29',
    500: '#a85c1e',
    700: '#fff',
  },
  'notion-dark-yellow': {
    50: '#383731',
    200: '#92763f',
    400: '#4f4029',
    500: '#896b2a',
    700: '#fff',
  },
  'notion-dark-green': {
    50: '#313935',
    200: '#325241',
    400: '#2e443a',
    500: '#3d7c56',
    700: '#fff',
  },
  'notion-dark-blue': {
    50: '#313840',
    200: '#2a4e6b',
    400: '#2d4256',
    500: '#2e75a4',
    700: '#fff',
  },
  'notion-dark-purple': {
    50: '#393541',
    200: '#534474',
    400: '#453a5b',
    500: '#7b60b4',
    700: '#fff',
  },

  // 50: fundo geral
  // 200: fundo titulo
  // 400: fundo task
  // 500: texto numero tasks
  // 700: texto titulo

  'notion-dark-pink': {
    50: '#3c353a',
    200: '#6a3b63',
    400: '#51384d',
    500: '#a94c9d',
    700: '#fff',
  },
  'notion-dark-red': {
    50: '#423333',
    200: '#7a363b',
    400: '#5e3436',
    500: '#c24152',
    700: '#fff',
  },
}

const notionLightColors = {
  'notion-light-gray': {
    50: '#f9f9f580',
    200: '#e3e2e080',
    500: '#91918e80',
    700: '#32302c',
  },
  'notion-light-brown': {
    50: '#faf6f5b3',
    200: '#eee0da',
    500: '#bb846c',
    700: '#442a1e',
  },
  'notion-light-orange': {
    50: '#fcf5f2b3',
    200: '#fadec9',
    500: '#d7813a',
    700: '#49290e',
  },
  'notion-light-yellow': {
    50: '#faf7edb3',
    200: '#fdecc8',
    500: '#cb9433',
    700: '#402c1b',
  },
  'notion-light-green': {
    50: '#f4f8f3b3',
    200: '#dbeddb',
    500: '#6c9b7d',
    700: '#1c3829',
  },
  'notion-light-blue': {
    50: '#f1f8fbb3',
    200: '#d3e5ef',
    500: '#5b97bd',
    700: '#183347',
  },
  'notion-light-purple': {
    50: '#f9f6fcb3',
    200: '#e8deee',
    500: '#a782c3',
    700: '#412454',
  },

  'notion-light-pink': {
    50: '#fbf5fbb3',
    200: '#f5e0e9',
    500: '#cd749f',
    700: '#4c2337',
  },
  'notion-light-red': {
    50: '#fdf5f3b3',
    200: '#ffe2dd',
    500: '#e16f64',
    700: '#5d1715',
  },
}

export { notionDarkColors, notionLightColors }

import { Box, Icon, IconButton, Input } from '@chakra-ui/react'
import {
  getDefaultKeyBinding,
  RichUtils,
  AtomicBlockUtils,
  EditorState,
} from 'draft-js'
import { useRef, useState } from 'react'
import { FaBold, FaImage, FaItalic, FaUnderline } from 'react-icons/fa'
import InlineLinkControl from './Link'
import Editor from '@draft-js-plugins/editor'
import createImagePlugin from '@draft-js-plugins/image'
import api from 'services/api'

export default function RichTextEditor({
  children,
  editorState,
  setEditorState,
  props,
  isOpportunity,
}) {
  const editorRef = useRef(null)

  const imagePlugin = createImagePlugin()

  function onChange(editorState) {
    setEditorState(editorState)
  }

  function handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return true
    }
    return false
  }

  function mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
      if (newEditorState !== editorState) {
        onChange(newEditorState)
      }
      return
    }
    return getDefaultKeyBinding(e)
  }

  function toggleInlineStyle(inlineStyle) {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor'
  var contentState = editorState.getCurrentContent()
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder'
    }
  }

  return (
    <div className="RichEditor-root">
      <InlineStyleControls
        isOpportunity={isOpportunity}
        editorState={editorState}
        setEditorState={setEditorState}
        onToggle={toggleInlineStyle}
      />

      <Box {...props}>
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={setEditorState}
            ref={editorRef}
            spellCheck={true}
            plugins={[imagePlugin]}
          />
        </div>

        {children}
      </Box>
    </div>
  )
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'
    default:
      return null
  }
}

function StyleButton(props) {
  const onToggle = (e) => {
    e.preventDefault()
    props.onToggle(props.style)
  }

  let className = 'RichEditor-styleButton'
  if (props.active) {
    className += ' RichEditor-activeButton'
  }

  return (
    <IconButton
      icon={<Icon as={props.icon} />}
      size="xs"
      variant="ghost"
      className={className}
      onMouseDown={onToggle}
    >
      {props.label}
    </IconButton>
  )
}

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: FaBold },
  { label: 'Italic', style: 'ITALIC', icon: FaItalic },
  { label: 'Underline', style: 'UNDERLINE', icon: FaUnderline },
]

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  const insertImage = (url) => {
    const contentState = props.editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: url }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(props.editorState, {
      currentContent: contentStateWithEntity,
    })
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
  }

  const uploadImage = async (file) => {
    try {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)

        const res = await api.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (res.data.url) {
          props.setEditorState(insertImage(res.data.url))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const inputFile = useRef(null)

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
      {props.isOpportunity && (
        <>
          <StyleButton
            as="Input"
            type="file"
            label="Image"
            onToggle={() => inputFile.current.click()}
            icon={FaImage}
          />
          <input
            onChange={(e) => uploadImage(e.target.files[0])}
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
        </>
      )}
      <InlineLinkControl
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
    </div>
  )
}

import { Box, Stack, ModalHeader as ChakraModalHeader } from '@chakra-ui/react'

import * as React from 'react'

import { Step } from './Step'

export default function ModalHeader({
  currentStep,
  steps,
  setStep,
  isFinalStepAllowed,
}) {
  return (
    <ChakraModalHeader
      d="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box bg="bg-surface" w="80%">
        <Box
          py={{
            base: '4',
            md: '8',
          }}
        >
          <Stack
            spacing="0"
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            {steps.map((step, id) => (
              <Step
                key={id}
                cursor={
                  !isFinalStepAllowed && id === 2 ? 'not-allowed' : 'pointer'
                }
                onClick={
                  !isFinalStepAllowed && id === 2 ? null : () => setStep(id)
                }
                title={step.title}
                description={step.description}
                isActive={currentStep === id}
                isCompleted={currentStep > id}
                isFirstStep={id === 0}
                isLastStep={steps.length === id + 1}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </ChakraModalHeader>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  useColorModeValue as mode,
  Input,
  Button,
  ModalHeader,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'

const classifyInput = (input, type) => {
  const linkedInRegex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const fullNameRegex = /^[a-zA-Z\s]{2,}$/

  if (input.match(linkedInRegex) && type === 'linkedinUrl') {
    return input
  }
  if (input.match(emailRegex) && type === 'email') {
    return input
  }

  if (input.match(fullNameRegex) && type === 'fullName') {
    return input
  }
  return null
}

export const NewProfileModal = ({
  isOpen,
  onClose,
  inputValue,
  selected,
  setSelected,
  setReason,
  reason,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      fullName: classifyInput(inputValue, 'fullName'),
      linkedinUrl: classifyInput(inputValue, 'linkedinUrl'),
      email: classifyInput(inputValue, 'email'),
    },
  })

  const [entity, setEntity] = useState('identity')

  const isCompany = entity === 'company'

  const onSubmit = (data) => {
    const selectedCopy = [...selected]

    const { fullName, linkedinUrl, email } = data

    const newProfile = {
      name: fullName,
      linkedin_profile_url: linkedinUrl,
      email,
      website: isCompany ? email : null,
      id: null,
      entityType: isCompany ? 'company' : 'identity',
    }

    setSelected([...selectedCopy, newProfile])
    setReason([...reason, ''])
    resetFunc()
    onClose()
  }

  const resetFunc = () => {
    reset({
      fullName: classifyInput(inputValue, 'fullName'),
      linkedinUrl: classifyInput(inputValue, 'linkedinUrl'),
      email: classifyInput(inputValue, 'email'),
    })
  }

  useEffect(() => {
    resetFunc()
  }, [inputValue])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader py="0">
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          px="0"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${mode(
                'var(--chakra-colors-gray-200)',
                'var(--chakra-colors-grayAlpha-200)'
              )}`,
              borderRadius: '24px',
            },
          }}
          overflowX="hidden"
        >
          <Box mt="5" p="4">
            <RadioGroup colorScheme="teal" onChange={setEntity} value={entity}>
              <Stack mb="4" direction="row">
                <Radio value="identity">People</Radio>
                <Radio value="company">Company</Radio>
              </Stack>
            </RadioGroup>
            <Controller
              name="fullName"
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  borderColor={errors.fullName ? 'red.500' : 'transparent'}
                  mb="2"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  bg={mode('gray.50', 'grayAlpha.800')}
                  placeholder={
                    isCompany
                      ? 'Company Name (required)'
                      : 'Full Name (required)'
                  }
                  type="text"
                />
              )}
            />
            <Controller
              name="linkedinUrl"
              control={control}
              rules={{
                required: {
                  value: isCompany ? false : true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  borderColor={errors.linkedinUrl ? 'red.500' : 'transparent'}
                  mb="2"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  bg={mode('gray.50', 'grayAlpha.800')}
                  placeholder={
                    isCompany
                      ? 'LinkedIn Company URL (optional)'
                      : 'Linkedin Profile URL (required)'
                  }
                  type="text"
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  borderColor={errors.email ? 'red.500' : 'transparent'}
                  mb="4"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  bg={mode('gray.50', 'grayAlpha.800')}
                  placeholder={
                    isCompany ? 'Website (optional)' : 'Email (optional)'
                  }
                  type="text"
                />
              )}
            />
            <Button
              d="flex"
              variant="village"
              w="137px"
              ml="auto"
              onClick={() => handleSubmit(onSubmit)()}
              h="10"
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import {
  Flex,
  IconButton,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import React from 'react'
import { IoCloseSharp } from 'react-icons/io5'

export const SelectedUser = ({ name, onClick }) => {
  return (
    <Flex
      borderRadius="md"
      px="2"
      bg={mode('black', 'oldGray.200')}
      alignItems="center"
    >
      <Text color={mode('white', 'black')}>{name}</Text>
      <IconButton
        onClick={onClick}
        _focus={{
          outline: 'none',
        }}
        color={mode('white', 'black')}
        icon={<IoCloseSharp />}
        size="sm"
      />
    </Flex>
  )
}

import { Box, chakra, Text, Tooltip } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import api from 'services/api'

export default function Similarity({
  identity_id,
  similarity,
  similarity_score,
  similarity_sentence,
  ...props
}) {
  const parsedSimilarity = similarity && JSON.parse(similarity)

  const [data, setData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        setIsLoading(true)
        const { data } = await api.get(`/admin/contact/${identity_id}`)

        const hasEmail =
          data?.contactData && data.contactData.account_type.includes('email')

        setData({
          hasEmail,
          ...data,
        })
      } catch (err) {}
      setIsLoading(false)
    }

    identity_id && fetchContactData()
  }, [])

  return (
    <Tooltip
      label={
        <Box p={2}>
          <Text fontSize="sm" mb={2}>
            <strong>Similarity:</strong>
          </Text>

          {parsedSimilarity
            ? Object.entries(parsedSimilarity.readableSimilarity).map(
                ([key, value]) => {
                  if (!value || value.length === 0 || key.includes('Score'))
                    return ''

                  return (
                    <Text fontSize="sm" mb={2}>
                      ({parsedSimilarity.readableSimilarity[`${key}Score`]}){' '}
                      <strong>{key}:</strong>
                      <br /> {value}
                    </Text>
                  )
                }
              )
            : ''}
          <Text fontSize="sm">
            <strong>Similarity sentence:</strong>
            <br />
            {similarity_sentence}
          </Text>

          {data && (
            <Box>
              {data.connector && (
                <Text>
                  {' '}
                  <strong>Connector:</strong>
                  <br />
                  {data.connector.firstname} {data.connector.lastname}
                </Text>
              )}

              {data.contactData && (
                <Text>
                  {' '}
                  <strong>Contact accounts:</strong>
                  <br />
                  {data.contactData.account_identifier}
                </Text>
              )}
            </Box>
          )}
        </Box>
      }
      color="white"
      bg="black"
    >
      <chakra.span
        borderRadius="full"
        w={6}
        h={6}
        d="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="xs"
        ml={1}
        bg={data.hasEmail || isLoading ? 'middle.500' : 'red.500'}
        color="white"
        fontWeight="bold"
        {...props}
      >
        {similarity_score}
      </chakra.span>
    </Tooltip>
  )
}

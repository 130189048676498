import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import useTimeoutFunction from 'hooks/useTimeoutFunction'
import { useState } from 'react'
import { FaCheck, FaChevronDown, FaMinus, FaTimes } from 'react-icons/fa'
import api from 'services/api'

const lookingForOptions = [
  'Recruit top talent ',
  'Browse top job opportunities',
  'Invest in startups',
  'Connect with investors',
  'Business development',
  'Find clients',
  'Help my network',
  'Catch up with my network',
  'Build new friendships',
]

export default function NewUserForm({ isModalOpen }) {
  const { setUserUsername } = useAuth()
  const { timeoutFunction } = useTimeoutFunction()
  const toast = useToast()

  const [username, setUsername] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null)

  const [options, setOptions] = useState([])
  const [channels, setChannels] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const verifyUsername = async (username) => {
    const { data } = await api.get(`/verify-username?username=${username}`)
    setIsUsernameAvailable(data.is_available)
    setIsVerifying(false)
  }

  const handleUsernameChange = (e) => {
    setIsUsernameAvailable(null)
    setUsername(e.target.value)
    if (e.target.value.length > 3) {
      setIsVerifying(true)
      timeoutFunction(() => verifyUsername(e.target.value), 500)
    }
  }

  const handleContinue = async () => {
    setIsLoading(true)

    try {
      await api.post('/onboarding', {
        username,
        onboarding_data: {
          options,
          channels,
        },
      })

      setUserUsername(username)
    } catch (err) {
      console.log(err)
      toast({
        title: 'Error',
        description: 'Something went wrong',
        status: 'error',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isModalOpen} onClose={() => undefined} isCentered>
      <ModalOverlay
        css={`
          backdrop-filter: blur(2px);
        `}
      />
      <ModalContent background={mode('white', 'transparent')} pb={12}>
        <ModalHeader fontSize="2xl">Onboarding</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <FormControl id="username" isRequired>
              <FormLabel fontSize="md">Reserve username</FormLabel>
              <InputGroup>
                <InputLeftElement
                  h="100%"
                  fontSize="2xl"
                  lineHeight="0"
                  children="@"
                  w={12}
                />
                <Input
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  maxLength="16"
                  bg="transparent"
                  h={16}
                  paddingInlineStart={12}
                  paddingInlineEnd={12}
                  fontSize="2xl"
                  // border="0"
                />

                {username.length <= 3 && (
                  <InputRightElement
                    h="100%"
                    w={12}
                    children={<Icon as={FaMinus} color="gray.200" />}
                  />
                )}

                {username.length > 3 && isVerifying && (
                  <InputRightElement
                    h="100%"
                    w={12}
                    children={<Icon as={Spinner} color="blue.200" />}
                  />
                )}

                {username.length > 3 && isUsernameAvailable === true && (
                  <InputRightElement
                    h="100%"
                    w={12}
                    children={<Icon as={FaCheck} color="green.500" />}
                  />
                )}
                {username.length > 3 && isUsernameAvailable === false && (
                  <InputRightElement
                    h="100%"
                    w={12}
                    children={<Icon as={FaTimes} color="red.500" />}
                  />
                )}
              </InputGroup>
            </FormControl>

            <FormControl id="options" isRequired>
              <FormLabel fontSize="md">
                What are your networking goals?
              </FormLabel>
              <Menu closeOnSelect={false} w="100%">
                <MenuButton
                  as={Button}
                  rightIcon={<FaChevronDown />}
                  fontSize="2xl"
                  h={16}
                  variant="outline"
                  w="100%"
                  // border="0"
                >
                  <Text fontWeight="normal" isTruncated>
                    {options.length > 0
                      ? options.join(', ')
                      : 'Select options...'}
                  </Text>
                </MenuButton>
                <MenuList w="100%">
                  <MenuOptionGroup
                    title="Select options"
                    type="checkbox"
                    value={options}
                    onChange={(value) => setOptions(value)}
                  >
                    {/* .toLowerCase().split(' ').join('-') */}
                    {lookingForOptions.map((option) => (
                      <MenuItemOption value={option}>{option}</MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl id="channels" isRequired>
              <FormLabel fontSize="md">
                What are the top channels you use to connect with your
                professional contacts?
              </FormLabel>
              <Textarea
                value={channels}
                onChange={(e) => setChannels(e.target.value)}
                // border="0"
                fontSize="2xl"
                rows="3"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            isDisabled={
              isUsernameAvailable !== true ||
              username.length <= 3 ||
              !options.length ||
              !channels.length
            }
            isLoading={isLoading}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Box, Button, Input } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { getQueryStringValue } from 'utils/getQueryStringValue'

export default function Demo() {
  const { state } = useLocation()
  const { demoSignIn, signed, loading } = useAuth()

  const [secret, setSecret] = useState(getQueryStringValue({ value: 'pass' }))

  if (!loading && signed) {
    return <Redirect to={state ? state.from : '/dashboard'} />
  }

  return (
    <Box d="flex" justifyContent="center" alignItems="center">
      <Input
        borderRadius="0"
        value={secret}
        onChange={(e) => setSecret(e.target.value)}
        placeholder="pass"
      ></Input>
      <Button
        borderRadius="0"
        variant="village"
        onClick={() => demoSignIn({ secret })}
      >
        Login
      </Button>
    </Box>
  )
}

import { Button, Icon } from '@chakra-ui/react'
import { FaEnvelope, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa'

export default function ContactButton({ type, identifier }) {
  function resolveIcon(type) {
    if (type === 'phone') return FaWhatsapp
    if (type === 'email') return FaEnvelope
    if (type === 'linkedin') return FaLinkedinIn
  }

  function resolveColorScheme(type) {
    if (type === 'phone') return 'whatsapp'
    if (type === 'email') return 'blue'
    if (type === 'linkedin') return 'linkedin'
  }

  function resolveLink(type, identifier) {
    if (type === 'phone') return `https://wa.me/${identifier.substring(2)}`
    if (type === 'email') return `mailto:${identifier.substring(2)}`
    if (type === 'linkedin') return identifier.substring(2)
  }

  function resolveMessage(type) {
    if (type === 'phone') return 'Message on Whatsapp'
    if (type === 'email') return 'Send an e-mail'
    if (type === 'linkedin') return 'Text on Linkedin'
  }

  return (
    <Button
      w="100%"
      leftIcon={<Icon as={resolveIcon(type)} />}
      colorScheme={resolveColorScheme(type)}
      as="a"
      href={resolveLink(type, identifier)}
      target="_blank"
    >
      {resolveMessage(type)}
    </Button>
  )
}

import { Link as RouterLink, Redirect, useLocation } from 'react-router-dom'
import { Box, Heading, Link, Text } from '@chakra-ui/react'

import { useAuth } from 'context/AuthContext'

import LoginButton from 'components/LoginButton'

export default function Login() {
  const { state } = useLocation()
  const { signed, loading } = useAuth()

  if (!loading && signed) {
    return <Redirect to={state ? state.from : '/dashboard'} />
  }

  return (
    <Box>
      <Box borderRadius="md" borderWidth="1px" p={8}>
        <Heading mb={4} size="md" textAlign="center" color="white">
          Already a member?
        </Heading>

        <LoginButton />
      </Box>
      {/* <Text textAlign="center" fontSize="sm" mt={4}>
        First time here?{' '}
        <Link as={RouterLink} color="blue.500" to="/register">
          Join Now
        </Link>
      </Text> */}
    </Box>
  )
}

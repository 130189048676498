import { Box, chakra, Text } from '@chakra-ui/react'
import AdvancedFilters from 'components/AdvancedFilters'
import CompaniesRow from 'components/CompaniesRow'
import AddToBoard from 'components/KanbanBoard/AddToBoard'
import TableFilters from 'components/TableFilters'
import { CompaniesHeader } from 'components/TableHeader'
import TablePagination from 'components/TablePagination'
import { useSelected } from 'context/SelectedContext'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import api from 'services/api'
import placeholders from 'utils/placeholders'

let companiesRequest = null

export default function Companies() {
  const { selected, toggleSelect, handleSelectAll } = useSelected()

  const [companies, setCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [total, setTotal] = useState(0)

  const [filters, setFilters] = useQueryString({
    name: '',
    industry: '',
  })

  const [advancedFilters, setAdvancedFilters] = useState({
    // pastCompanies: {
    //   type: 'checkbox',
    //   new: 'value',
    //   value: 1,
    //   label: 'Filter through past companies',
    // },
    firstDegree: {
      type: 'checkbox',
      new: 'value',
      value: 0,
      label: 'Only first degree connections',
    },
  })

  const fetchData = async () => {
    setIsLoading(true)

    if (companiesRequest) {
      companiesRequest.cancel()
    }

    companiesRequest = api.CancelToken.source()

    try {
      const { data } = await api.get(
        `/companies?offset=${offset}${Object.entries(filters)
          .map(([key, value]) => `&${key}=${encodeURIComponent(value)}`)
          .join('')}${Object.entries(advancedFilters)
          .map(([key, filter]) => {
            if (filter.type === 'checkbox') {
              return `&${key}=${Number(filter.value)}`
            }
            return `&${key}=${encodeURIComponent(filter.value)}`
          })
          .join('')}`,
        { cancelToken: companiesRequest.token }
      )
      setCompanies(data.companies)
      setTotal(data.total)
      setIsLoading(false)
    } catch (err) {
      if (api.isCancel(err)) {
        console.log('Request canceled', err.message)
      } else {
        console.log(err)
        setCompanies([])
        setTotal(0)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [filters, advancedFilters, offset])

  const changeFilter = (type, value) => {
    setOffset(0)
    setFilters({ ...filters, [type]: value })
  }

  return (
    <Box>
      <AddToBoard isFloating selected={selected} />
      <Box mb={6}>
        <Text fontSize="sm" color="black" mb={3}>
          Filters{' '}
          <chakra.span fontSize="smaller">
            (Add commas to search by multiple values)
          </chakra.span>
        </Text>

        <Box d="flex" justifyContent="center" alignItems="center">
          <TableFilters
            filters={filters}
            setFilter={changeFilter}
            placeholders={placeholders['companies']}
            w="100%"
            mr={2}
          />
          <AdvancedFilters
            filters={advancedFilters}
            setFilter={(type, value) => {
              setOffset(0)
              setAdvancedFilters({
                ...advancedFilters,
                [type]: { ...advancedFilters[type], value },
              })
            }}
            ml="auto"
          />
        </Box>
      </Box>
      <CompaniesHeader
        selected={selected}
        handleSelectAll={(isChecked) =>
          handleSelectAll(
            isChecked,
            companies.map((el) => ({
              entity: 'company',
              entity_id: el.id,
            }))
          )
        }
      />
      <Box borderRadius="md" minH="1083px" pos="relative">
        {isLoading &&
          [...Array(10)].map((el, i) => (
            <CompaniesRow key={i} loading={isLoading} />
          ))}
        {!isLoading &&
          companies &&
          companies.map((company) => {
            const isSelected =
              selected.findIndex(
                (s) => s.entity_id === company.id && s.entity === 'company'
              ) !== -1
            return (
              <CompaniesRow
                key={company.id}
                company={company}
                companies={companies}
                toggleSelect={() =>
                  toggleSelect(
                    {
                      entity_id: company.id,
                      entity: 'company',
                      name: company.name,
                      avatar: company.logo,
                      connectors: [],
                      work: company.industry,
                    },
                    isSelected
                  )
                }
                isSelected={isSelected}
                // pastCompanies={advancedFilters.pastCompanies.value}
              />
            )
          })}
        <TablePagination
          count={total}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
        />
      </Box>
    </Box>
  )
}

import qs from 'query-string'

const getQueryStringValue = ({ value, ifNoValue }) => {
  const values = qs.parse(window.location.search)

  if (values[value]) {
    return values[value]
  } else {
    return ifNoValue ? ifNoValue : ''
  }
}

export { getQueryStringValue }

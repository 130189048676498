import { Box, useColorModeValue as mode } from '@chakra-ui/react'

export default function Row({ children, ...props }) {
  return (
    <Box
      w="100%"
      minH="6rem"
      maxH="6rem"
      py={4}
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px"
      borderColor={mode('gray.200', 'grayAlpha.700')}
      {...props}
    >
      {children}
    </Box>
  )
}

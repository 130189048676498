import React from 'react'
import {
  Flex,
  useColorModeValue as mode,
  Button,
  Text,
  Box,
  useDisclosure,
  Grid,
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { IoEarthSharp, IoCheckmark } from 'react-icons/io5'
import { MenuSelect } from 'components/MenuSelect'
import { FaUserLock, FaLock, FaLockOpen } from 'react-icons/fa'
import { InfoTooltip } from './InfoTooltip'
import { SharePreferencesModal } from './SharePreferencesModal/SharePreferencesModal'

export const OpportunityModalStepThree = ({
  control,
  errors,
  preferenceValues,
  selected,
  setSelected,
  setQueryParams,
  useQueryParams,
  setUseQueryParams,
  queryParams,
  sharedWithPeopleCount,
  removeAllSharedWith,
  setRemoveAllSharedWith,
  isEdit,
}) => {
  const privacyOptions = [
    {
      value: 'yes-publicly',
      label: 'Public',
      explanation: 'allow to share link widely - e.g. on whatsapp group',
      icon: (
        <FaLockOpen color={mode('black', 'white')} width="10px" height="12px" />
      ),
    },
    {
      value: 'yes-trackable',
      label: 'Low-key confidential',
      explanation: 'allow only trackable sharing',
      icon: (
        <FaLock color={mode('black', 'white')} width="10px" height="12px" />
      ),
    },
    {
      value: 'no',
      label: 'Strictly confidential',
      explanation: 'do not allow sharing',
      icon: (
        <FaLock color={mode('black', 'white')} width="10px" height="12px" />
      ),
    },
  ]

  const sharedWith = [
    {
      value: 'custom',
      label: 'Confidential',
      explanation: 'Only people I selected above',
      icon: <FaLock size={12} />,
    },
    {
      value: 'private',
      label: 'Semi-confidential',
      explanation: 'Include close friends',
      icon: <FaUserLock size={15} />,
      rightIcon: (
        <InfoTooltip
          placement="left"
          infoText="People you’ve interacted with more than once. Contacts you haven’t interacted with a few times at least won’t be able to see the opportunity"
        />
      ),
    },
    {
      value: 'semi-private',
      label: 'Semi-confidential',
      explanation: 'Include close friends of friends',
      icon: <FaUserLock size={15} />,
      rightIcon: (
        <InfoTooltip
          placement="left"
          infoText="Not all your friends of friends will see this opportunity. We only include people who are close to your close friends."
        />
      ),
    },
    {
      value: 'anyone',
      label: 'Public',
      explanation: 'Anyone',
      icon: <IoEarthSharp size={12} />,
    },
  ]

  const lookingOptions = [
    {
      value: 'actively',
      label: 'Yes',
      explanation:
        'Recommended for widest reach and urgency. We’ll send tokenized links so your direct connections can make personalized recommendations',
    },
    {
      value: 'passively',
      label: 'No',
      explanation: 'Only Village users would see this opportunity',
    },
  ]

  const {
    isOpen: isSharePreferencesModalOpen,
    onOpen: onSharePreferencesModalOpen,
    onClose: onSharePreferencesModalClose,
  } = useDisclosure()

  return (
    <>
      <Text color={mode('black', 'white')} fontWeight="bold">
        Who do you want to actively seek referrals from?
      </Text>
      <Text fontSize="sm" color="gray.10">
        We’ll send out private links to these people so you can track their
        status and help them seamlessly make referrals. We recommend you select
        people who are vested in your success such as your teammates, close
        friends, investors, and business partners.
      </Text>
      <Button
        fontSize="sm"
        borderColor={mode('black', 'white')}
        color={
          selected.length > 0 ? mode('white', 'black') : mode('black', 'white')
        }
        bg={selected.length > 0 ? mode('black', 'white') : 'transparent'}
        onClick={() => onSharePreferencesModalOpen()}
        h="9"
        fontWeight="normal"
        mt="3"
        mb="6"
        variant="villageOutline"
      >
        {selected.length > 0
          ? `${selected.length} people selected`
          : 'Select people'}
      </Button>
      <Controller
        name="sharedWith"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'This is required.',
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Text mt="2" color={mode('black', 'white')} fontWeight="bold">
                Who can view this opportunity on Village?
              </Text>
              <Text fontSize="sm" color="gray.10">
                Track & receive recommendations passively from your Village
                without bombarding them with emails. If you’re looking for a
                job, we automatically exclude people with your current
                employers.
              </Text>
              <Flex mt="4" flexWrap="wrap" sx={{ gap: '7px' }}>
                {sharedWith.map((shareSetting) => {
                  const isActive = value === shareSetting.value
                  return (
                    <Button
                      key={shareSetting.value}
                      leftIcon={shareSetting.icon}
                      rightIcon={shareSetting.rightIcon}
                      onClick={() => onChange(shareSetting.value)}
                      borderRadius="3xl"
                      py="2.5"
                      px="4"
                      color={
                        isActive
                          ? mode('white', 'black')
                          : mode('black', 'white')
                      }
                      _hover={{
                        bg: mode('gray.500', 'white'),
                        color: mode('white', 'black'),
                      }}
                      bg={
                        isActive
                          ? mode('black', 'white')
                          : mode('white', 'black')
                      }
                      h="9"
                      borderColor="black"
                      borderWidth={1}
                    >
                      <Text as="span" fontWeight="bold" fontSize="sm">
                        {shareSetting.label}:
                        <Text as="span" fontWeight="normal" fontSize="sm">
                          {' '}
                          {shareSetting.explanation}
                        </Text>
                      </Text>
                    </Button>
                  )
                })}
              </Flex>
              {/* <MenuSelect
                mt="4"
                value={
                  value
                    ? sharedWith.find((option) => option.value === value)
                    : null
                }
                onChange={(option) => {
                  const selectedValue = option.value
                  onChange(selectedValue)
                  if (selectedValue === 'custom') {
                    onSharePreferencesModalOpen()
                  }
                }}
                placeholder="Share with.."
                options={sharedWith}
              /> */}
              {errors.sharedWith && (
                <Text mt="1" fontSize="xs" color="red.500">
                  This field is required
                </Text>
              )}
            </>
          )
        }}
      />
      {/* <Controller
        name="sharePermission"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Box mt="7">
              <Text color={mode('black', 'white')} fontWeight="bold">
                Confidentiality
              </Text>
              <MenuSelect
                value={
                  value
                    ? privacyOptions.find(
                        (option) =>
                          option.value === value || option.value === value.value
                      )
                    : null
                }
                onChange={onChange}
                placeholder="Privacy"
                options={privacyOptions}
              />
              {errors.sharePermission && (
                <Text mt="1" fontSize="xs" color="red.500">
                  This field is required
                </Text>
              )}
            </Box>
          )
        }}
      /> */}

      {/* <Controller
        name="status"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Box lineHeight={1.3}>
              <Text mt="7" color={mode('black', 'white')} fontWeight="bold">
                Are you actively seeking referrals?
              </Text>
              <MenuSelect
                value={
                  value
                    ? lookingOptions.find((option) => option.value === value)
                    : null
                }
                onChange={(option) => onChange(option.value)}
                placeholder="Looking options"
                options={lookingOptions}
              />
              {errors.status && (
                <Text mt="1" fontSize="xs" color="red.500">
                  This field is required
                </Text>
              )}
            </Box>
          )
        }}
      /> */}
      <SharePreferencesModal
        isEdit={isEdit}
        selected={selected}
        setSelected={setSelected}
        isOpen={isSharePreferencesModalOpen}
        onClose={onSharePreferencesModalClose}
        preferenceValues={preferenceValues}
        setQueryParams={setQueryParams}
        useQueryParams={useQueryParams}
        setUseQueryParams={setUseQueryParams}
        queryParams={queryParams}
        sharedWithPeopleCount={sharedWithPeopleCount}
        removeAllSharedWith={removeAllSharedWith}
        setRemoveAllSharedWith={setRemoveAllSharedWith}
      />
    </>
  )
}

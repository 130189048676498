import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue as mode,
  Avatar,
  Text,
} from '@chakra-ui/react'
import { AiFillCaretDown } from 'react-icons/ai'

export const MenuSelect = ({
  value,
  onChange,
  options,
  placeholder,
  ...props
}) => {
  /* wish we could type options so I wouldn't have to write conditions for options. */
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            mt="2"
            borderRadius="full"
            borderWidth={1}
            bg="transparent"
            fontSize="sm"
            borderColor={mode('black', 'white')}
            as={Button}
            px={value?.label || value?.logo ? '2' : '4'}
            h="9"
            rightIcon={
              <AiFillCaretDown
                width="15px"
                height="9px"
                color={mode('black', 'white')}
              />
            }
            {...props}
          >
            {value ? (
              <MenuItem icon={value.icon}>
                {value.logo && (
                  <Avatar width="15px" height="15px" src={value.logo} />
                )}
                <Text fontSize="sm" isTruncated ml={value.logo && 2.5}>
                  {value.label ?? value.name}
                </Text>
              </MenuItem>
            ) : (
              <Text color="gray.10" fontSize="sm">
                {placeholder}
              </Text>
            )}
          </MenuButton>
          <MenuList>
            {options.map((option, index) => {
              const { label, icon, logo, name, explanation } = option

              return (
                <MenuItem
                  onClick={() => onChange(option)}
                  icon={icon}
                  key={index}
                >
                  {logo && <Avatar width="15px" height="15px" src={logo} />}
                  {!explanation && <Text ml={logo && 2}>{label ?? name}</Text>}
                  {explanation && (
                    <Text fontWeight="bold" as="span">
                      {label} -{' '}
                      <Text fontWeight="normal" as="span">
                        {explanation}
                      </Text>
                    </Text>
                  )}
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

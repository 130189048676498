import { Badge } from '@chakra-ui/react'

function StatusBadgeStyled({ ...props }) {
  return (
    <Badge
      borderRadius="full"
      fontSize="x-small"
      size="sm"
      py="0.5rem"
      pl={3}
      pr={6}
      transform="translateX(1rem)"
      {...props}
    />
  )
}

export default function StatusBadge({ feedbacks }) {
  const completed = feedbacks.find((status) => status[0] === 'completed')

  if (completed) {
    return <StatusBadgeStyled colorScheme="green">completed</StatusBadgeStyled>
  }

  const pending = feedbacks.find((status) => status[0] === 'pending')

  if (pending) {
    return (
      <StatusBadgeStyled colorScheme="yellow">
        waiting for intro
      </StatusBadgeStyled>
    )
  }

  const accepted = feedbacks.find((status) => status[0] === 'accepted')

  if (accepted) {
    return (
      <StatusBadgeStyled colorScheme="green">Ready for intro</StatusBadgeStyled>
    )
  }

  const allCanceled = feedbacks.every((status) => status[0] === 'canceled')

  if (allCanceled) {
    return (
      <StatusBadgeStyled
        borderRadius="full"
        fontSize="x-small"
        colorScheme="red"
        size="sm"
      >
        All canceled
      </StatusBadgeStyled>
    )
  }

  const allRejected = feedbacks.every(
    (status) => status[0] === 'denied' || status[0] === 'canceled'
  )

  if (allRejected) {
    return (
      <StatusBadgeStyled
        borderRadius="full"
        fontSize="x-small"
        colorScheme="red"
        size="sm"
      >
        All rejected
      </StatusBadgeStyled>
    )
  }

  return (
    <StatusBadgeStyled colorScheme="orange">
      Pending someone to accept
    </StatusBadgeStyled>
  )
}

import React, { useRef, useState } from 'react'
import { RecommendationModal } from './RecommendationModal'
import api from 'services/api'
import { useToast } from 'hooks/useToast'

export const RecommendationModalContainer = ({
  isOpen,
  onClose,
  opportunityId,
}) => {
  const [options, setOptions] = useState([])

  const [loading, setLoading] = useState(false)

  const [searchLoading, setSearchLoading] = useState(false)

  const cancelSource = useRef()

  const toast = useToast()

  const handleSearchOptions = async (inputValue) => {
    try {
      if (cancelSource.current) {
        cancelSource.current.cancel()
      }
      setSearchLoading(true)
      cancelSource.current = api.CancelToken.source()

      const { data } = await api.get(
        `/identity/recommendations?input=${inputValue}`,
        {
          cancelToken: cancelSource.current.token,
        }
      )
      setSearchLoading(false)

      setOptions(data.results)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async (data) => {
    try {
      const { reason, selected } = data

      selected.forEach((user, index) => {
        user.reason = reason[index]
      })

      setLoading(true)

      await api.post('/opportunity/recommend', {
        opportunityId,
        recommendations: selected,
      })

      setLoading(false)

      onClose()

      toast({ title: 'Successfully sent recommendation', status: 'success' })
    } catch (e) {
      setLoading(false)
      toast({ title: 'Something went wrong', status: 'error' })
    }
  }

  return (
    <RecommendationModal
      opportunityId={opportunityId}
      isOpen={isOpen}
      options={options}
      onClose={onClose}
      handleSearchOptions={handleSearchOptions}
      loading={loading}
      searchLoading={searchLoading}
      handleSubmit={handleSubmit}
    />
  )
}

import { useColorModeValue as mode } from '@chakra-ui/react'

import { useEffect, useState } from 'react'

import IntroRequest from 'components/IntroRequest'
import IntroRequestGroup from 'components/IntroRequestGroup'
import { IntrosHeader } from 'components/TableHeader'

import api from 'services/api'

export default function IntrosRequestedFromMe() {
  const [intros, setIntros] = useState([])

  useEffect(() => {
    const fetchIntros = async () => {
      try {
        const { data } = await api.get(`/intros?introducer=true`)

        setIntros(
          data.reduce((acc, curr) => {
            if (acc[curr.introducer_user_id]) {
              acc[curr.introducer_user_id].push(curr)
            } else {
              acc[curr.introducer_user_id] = [curr]
            }

            return acc
          }, {})
        )
      } catch (err) {
        console.log(err)
      }
    }

    fetchIntros()
  }, [])

  const updateIntro = ({ value, intro, key }) => {
    const newIntros = { ...intros }
    const introRef = newIntros[intro.introducer_user_id].find(
      (i) => i.intro_id === intro.intro_id
    )
    introRef[key] = value
    setIntros(newIntros)
  }

  return (
    <>
      <IntrosHeader />
      {Object.values(intros).map((d, i) => {
        const person = {
          name: `${d[0].introducer_firstname} ${d[0].introducer_lastname}`,
          avatar: d[0].introducer_avatar,
        }

        return (
          <IntroRequestGroup
            key={`group-${i}`}
            data={d}
            person={person}
            isIntroducer
          >
            {d.map((intro, introIndex) => (
              <IntroRequest
                key={`introducer-${introIndex}`}
                intro={intro}
                token={intro.introducer_token}
                requester={{
                  firstname: d[0].introducer_firstname,
                }}
                mb={0}
                borderRadius={0}
                borderBottom="1px solid"
                borderColor={mode('gray.200', 'grayAlpha.600')}
                _last={{
                  border: 'none',
                  borderBottomRightRadius: '25px',
                  borderBottomLeftRadius: '25px',
                }}
                updateIntro={updateIntro}
                isIntroducer
              />
            ))}
          </IntroRequestGroup>
        )
      })}
    </>
  )
}

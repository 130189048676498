import {
  Box,
  Icon,
  Input,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FaLock } from 'react-icons/fa'
import RichTextEditor from './RichTextEditor'
import Skeleton from './Skeleton'

const MyCustomBox = styled(Box)`
  ol {
    padding-left: 1rem;
  }
  a {
    text-decoration: underline;
  }
  * {
    color: var(--chakra-colors-grayAlpha-5) !important;
  }
  .accept {
    color: green !important;
    margin-left: 0.25rem;
  }
  .deny {
    color: red !important;
    margin-left: 0.25rem;
  }
  .locked:first-child {
    margin-top: 0px !important;
  }
`

function EmailLine({ title, content, setContent, ...props }) {
  return (
    <Box
      d="flex"
      alignItems="center"
      bg={mode('grayAlpha.0', 'grayAlpha.800')}
      p={3}
      mb={2}
      borderRadius="md"
      {...props}
    >
      <Skeleton isLoaded={!props.isLoading}>
        <Text color={mode('gray.600', 'grayAlpha.50')} w="10ch">
          {title}
        </Text>{' '}
      </Skeleton>
      <Skeleton isLoaded={!props.isLoading} w={setContent && '100%'}>
        {setContent ? (
          <Input
            w="100%"
            h="1.5rem"
            p={0}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            bg={mode('grayAlpha.0', 'grayAlpha.800')}
            border="none"
          />
        ) : (
          <Text cursor="not-allowed">{content}</Text>
        )}
      </Skeleton>
    </Box>
  )
}

export default function EmailTemplate({
  from,
  to,
  subject,
  setSubject,
  lockedText,
  editorState,
  setEditorState,
  isLoading,
  ...props
}) {
  return (
    <Box p={4}>
      <EmailLine title="From" isLoading={isLoading} content={from} />
      <EmailLine title="To" isLoading={isLoading} content={to} />
      <EmailLine
        title="Subject"
        isLoading={isLoading}
        content={subject}
        setContent={setSubject}
        isTruncated
      />

      {isLoading ? (
        <Stack spacing={3} bg={mode('grayAlpha.0', 'grayAlpha.800')} p={4}>
          {[...Array(6)].map((_, i) => (
            <Skeleton height="20px" />
          ))}
        </Stack>
      ) : (
        <Box bg={mode('grayAlpha.0', 'grayAlpha.800')}>
          <Box p={4} borderTopRightRadius="md" borderTopLeftRadius="md">
            <RichTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              props={{ ...props }}
            >
              <Box
                mt={2}
                p={4}
                bg={mode('grayAlpha.1', 'grayAlpha.500')}
                color={mode('grayAlpha.5', 'grayAlpha.5')}
                pos="relative"
                borderRadius="md"
              >
                <Box
                  p={2}
                  bg={mode('grayAlpha.1', 'grayAlpha.500')}
                  pos="absolute"
                  right={0}
                  top="-1.1rem"
                  borderRadius="full"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon as={FaLock} w="0.75rem" h="0.75rem" />
                </Box>
                <MyCustomBox
                  pointerEvents="none"
                  color={mode('grayAlpha.5', 'grayAlpha.5')}
                  dangerouslySetInnerHTML={{
                    __html: lockedText,
                  }}
                />
              </Box>
            </RichTextEditor>
          </Box>
        </Box>
      )}
    </Box>
  )
}

import {
  Avatar,
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
} from '@chakra-ui/react'
import JobHistoryPopover from 'components/JobHistoryPopover'
import { useAuth } from 'context/AuthContext'

export const UserSelector = () => {
  const { user, users, selectedUser, setSelectedUser } = useAuth()

  return (
    <Menu>
      <JobHistoryPopover identity_id={selectedUser && selectedUser.Identity.id}>
        <MenuButton as={Button} size="sm" ml="auto" my="auto">
          <Text fontWeight="normal" isTruncated d="flex" alignItems="center">
            {selectedUser ? (
              <>
                <Avatar size="xs" mr={2} src={selectedUser.Identity?.avatar} />
                {selectedUser.Identity.firstname}{' '}
                {selectedUser.Identity.lastname}
                <Badge variant="gray" fontSize="x-small" size="sm" ml={2}>
                  {selectedUser.id}
                </Badge>
              </>
            ) : (
              'Select options...'
            )}
          </Text>
        </MenuButton>
      </JobHistoryPopover>

      <Portal>
        <MenuList minWidth="240px">
          <MenuOptionGroup
            value={selectedUser?.id}
            title="Searching in behalf of..."
            type="radio"
          >
            {users
              .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
              .map((u) => (
                <MenuItemOption
                  key={u.id}
                  value={u.id}
                  isChecked={selectedUser?.id === u.id}
                  onClick={() => setSelectedUser(u)}
                >
                  <Avatar size="xs" mr={2} src={u.Identity?.avatar} />
                  {u.Identity?.firstname} {u.Identity?.lastname}
                  <Badge variant="gray" fontSize="x-small" size="sm" ml={2}>
                    {u.id}
                  </Badge>
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

import {
  Box,
  Button,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useState } from 'react'
import api from 'services/api'
import PicksTracker from './PicksTracker'

export default function AdminSettings() {
  const [email, setEmail] = useState('')

  const sendInvite = async (e) => {
    e.preventDefault()

    try {
      const { data } = await api.post('/invite', { email })
      window.alert(JSON.stringify(data))
    } catch (err) {
      window.alert(err)
    }
  }

  return (
    <Box as="section">
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Track picks</Tab>
          <Tab>Send invite codes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PicksTracker />
          </TabPanel>
          <TabPanel>
            <Box
              as="form"
              d="flex"
              w={`min(95vw, 400px)`}
              onSubmit={sendInvite}
            >
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button variant="village" ml={1} type="submit">
                Send invite
              </Button>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

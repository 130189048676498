import { useToast } from '@chakra-ui/react'

import { useState, useEffect, createContext, useContext } from 'react'
import api from 'services/api'
import { useAuth } from './AuthContext'

const KanbanContext = createContext()

export const useKanban = () => {
  const context = useContext(KanbanContext)

  return context
}

export function KanbanProvider({ children }) {
  const { signed } = useAuth()
  const toast = useToast()

  const [boards, setBoards] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentBoard, setCurrentBoard] = useState(null)
  // const [taskBeingDraggedOverId, setTaskBeingDraggedOverId] = useState(null)

  const fetchKanbanData = async () => {
    setIsLoading(true)
    try {
      const { data } = await api.get('/kanban')

      const parsedData = data.reduce((acc, data) => {
        if (typeof acc[data.board_title] === 'undefined') {
          acc[data.board_title] = {
            id: data.board_id.toString(),
            tasks: {},
            columns: [],
            opportunity_id: data.opportunity_id,
          }
        }

        if (data.task_id) {
          acc[data.board_title].tasks[`${data.task_id}`] = {
            ...data,
            task_id: data.task_id.toString(),
          }

          if (data.hasOwnProperty('introducer_feedback')) {
            Reflect.set(
              acc[data.board_title].tasks[`${data.task_id}`],
              'introducer_feedback',
              parseFeedbacks(data.introducer_feedback)
            )
          }

          if (data.hasOwnProperty('recommenders')) {
            Reflect.set(
              acc[data.board_title].tasks[`${data.task_id}`],
              'recommenders',
              data.recommenders
            )
          }

          if (
            typeof acc[data.board_title].columns[data.column_index] ===
            'undefined'
          ) {
            acc[data.board_title].columns[data.column_index] = {
              id: data.column_id.toString(),
              title: data.column_title,
              color: data.column_color,
              taskIds: [],
              isOpportunity: data.is_opportunity,
            }
          }

          acc[data.board_title].columns[data.column_index].taskIds[
            data.task_index
          ] = data.task_id.toString()
        } else {
          if (
            typeof acc[data.board_title].columns[data.column_index] ===
            'undefined'
          ) {
            acc[data.board_title].columns[data.column_index] = {
              id: data.column_id.toString(),
              title: data.column_title,
              color: data.column_color,
              taskIds: [],
              isOpportunity: data.is_opportunity,
            }
          }
        }

        return acc
      }, {})

      setBoards(parsedData)
      setCurrentBoard(Object.keys(parsedData)[0])
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const parseFeedbacks = (feedbacks) => {
    if (
      feedbacks.includes('accepted') ||
      feedbacks.includes('pending') ||
      feedbacks.includes('completed')
    ) {
      return 'accepted'
    }
    if (
      feedbacks.includes('denied') ||
      feedbacks.includes('canceled') ||
      feedbacks.includes('unknown')
    ) {
      return 'denied'
    }

    return null
  }

  useEffect(() => {
    signed && fetchKanbanData()
  }, [signed])

  const reorderBoard = async (result) => {
    const { destination, source, draggableId } = result

    const boardsCopy = { ...boards }

    const board = boardsCopy[currentBoard]

    const [columnMoved] = board.columns.splice(source.index, 1)

    board.columns.splice(destination.index, 0, columnMoved)

    setBoards(boardsCopy)

    try {
      await api.patch(`/kanban/column/${draggableId}`, {
        board: destination.droppableId,
        index: destination.index,
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result

    // setTaskBeingDraggedOverId(null)

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    if (result.type === 'board') {
      return reorderBoard(result)
    }

    const boardsCopy = { ...boards }

    const sourceColumn = boardsCopy[currentBoard].columns
      .filter((c) => c)
      .find((c) => c.id === source.droppableId)
    sourceColumn.taskIds.splice(source.index, 1)

    const destinationColumn = boardsCopy[currentBoard].columns
      .filter((c) => c)
      .find((c) => c.id === destination.droppableId)
    destinationColumn.taskIds.splice(destination.index, 0, draggableId)

    setBoards(boardsCopy)

    try {
      await api.patch(`/kanban/task/${draggableId}`, {
        column: destination.droppableId,
        index: destination.index,
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  // const onDragUpdate = (result) => {
  //   const { combine } = result
  //   setTaskBeingDraggedOverId(combine ? combine?.draggableId : combine)
  // }

  const onAddTask = async (tasks, boardId) => {
    try {
      const res = await api.post('/kanban/tasks', {
        board: boardId,
        tasks: tasks.map((t) => ({
          entity: t.entity,
          entity_id: t.entity_id,
        })),
      })

      console.log('res', res)

      toast({
        title: res.data,
        status: res.status === 200 ? 'success' : 'info',
        duration: 3000,
      })

      await fetchKanbanData()
      localStorage.setItem('shouldRefreshBoard', true)
    } catch (err) {
      toast({ title: err.data, status: 'error', duration: 3000 })
    }
  }

  const handleCreateBoard = async (boardName) => {
    try {
      const { data: newBoard } = await api.post('/kanban/board', {
        board_title: boardName,
      })

      await fetchKanbanData()
      setCurrentBoard(boardName)

      return newBoard
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleDeleteBoard = async (boardName) => {
    const boardId = boards[boardName].id

    try {
      await api.delete(`/kanban/board/${boardId}`)

      const boardsCopy = { ...boards }
      const { [boardName]: deletedBoard, ...rest } = boardsCopy
      setBoards(rest)

      toast({
        title: 'Board deleted',
        status: 'success',
      })
    } catch (err) {
      toast({
        title: err.response.data.message,
        status: 'error',
        duration: 3000,
      })
    }
  }

  const handleDeleteTask = async (taskId) => {
    const cachedCurrentBoard = currentBoard

    try {
      await api.delete(`/kanban/task/${taskId}`)

      await fetchKanbanData()
      setCurrentBoard(cachedCurrentBoard)

      toast({
        title: 'Task deleted',
        status: 'success',
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleUpdateTaskNote = async (taskId, inputValue) => {
    const boardsCopy = { ...boards }

    const task = boardsCopy[currentBoard].tasks[taskId]

    task.task_content = inputValue

    setBoards(boardsCopy)

    try {
      await api.post(`/board/notes`, {
        taskId,
        note: inputValue,
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleCreateColumn = async (boardId) => {
    const boardName = currentBoard

    try {
      const { data: newColumn } = await api.post('/kanban/column', {
        board_id: boardId,
      })

      const boardsCopy = { ...boards }
      boardsCopy[boardName].columns.push({
        id: newColumn.id.toString(),
        title: newColumn.title,
        color: newColumn.color,
        taskIds: [],
      })

      setBoards(boardsCopy)
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleDeleteColumn = async (columnId) => {
    const cachedCurrentBoard = currentBoard

    if (boards[currentBoard].columns.filter((c) => c).length === 1) {
      toast({
        title: 'Boards must have at least one column',
        status: 'info',
      })
      return
    }

    try {
      await api.delete(`/kanban/column/${columnId}`)

      await fetchKanbanData()
      setCurrentBoard(cachedCurrentBoard)
    } catch (err) {
      console.log('err', err)
      toast({
        title: err.response.data.message,
        status: 'error',
        duration: 3000,
      })
    }
  }

  const handleChangeColumnColor = async (newColor, columnId) => {
    try {
      const boardsCopy = { ...boards }

      const column = boardsCopy[currentBoard].columns
        .filter((c) => c)
        .find((c) => c.id === columnId)

      if (!!column.isOpportunity) {
        toast({
          title:
            'This column is connected to an opportunity and cannot be edited',
          status: 'error',
          duration: 3000,
        })
        return
      }

      column.color = newColor

      setBoards(boardsCopy)

      await api.patch(`/kanban/column/${columnId}`, {
        color: newColor,
      })

      toast({
        title: 'Updated',
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Something went wrong',
        status: 'error',
      })
    }
  }

  const handleChangeColumnName = async (newTitle, columnId) => {
    try {
      const boardsCopy = { ...boards }

      const column = boardsCopy[currentBoard].columns
        .filter((c) => c)
        .find((c) => c.id === columnId)

      if (!!column.isOpportunity) {
        toast({
          title:
            'This column is connected to an opportunity and cannot be edited',
          status: 'error',
          duration: 3000,
        })

        return
      }

      await api.patch(`/kanban/column/${columnId}`, {
        title: newTitle,
      })

      column.title = newTitle

      setBoards(boardsCopy)

      toast({
        title: 'Updated',
        status: 'success',
      })
    } catch (err) {
      toast({
        title: 'Something went wrong',
        status: 'error',
      })
    }
  }

  const handleRefreshBoard = async () => {
    const shouldRefreshBoard = localStorage.getItem('shouldRefreshBoard')

    if (shouldRefreshBoard) {
      localStorage.removeItem('shouldRefreshBoard')
      await fetchKanbanData()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleRefreshBoard)

    // cleanup this component
    return () => {
      window.removeEventListener('storage', handleRefreshBoard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <KanbanContext.Provider
      value={{
        boards,
        setBoards,
        isLoading,
        currentBoard,

        selectBoard: setCurrentBoard,
        // taskBeingDraggedOverId,
        onAddTask,
        onDragEnd,
        // onDragUpdate,
        handleCreateBoard,
        handleDeleteBoard,
        handleDeleteColumn,
        handleDeleteTask,
        handleUpdateTaskNote,
        handleCreateColumn,
        handleChangeColumnColor,
        handleChangeColumnName,
      }}
    >
      {children}
    </KanbanContext.Provider>
  )
}

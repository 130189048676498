import React from 'react'
import {
  Box,
  Flex,
  Text,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react'
import { SidebarItemWrapper } from 'components/NewUI/SidebarItemWrapper'

export const OpportunityType = ({
  changeFilter,
  categoryId,
  setCategoryId,
  opportunityTypes,
}) => {
  const parseCategoryName = {
    1: 'Jobs',
    2: 'Talent',
    3: 'Startups',
    20: 'Investors',
    12: 'Partnerships',
    14: 'Collaborations',
    16: 'Offers',
  }
  return (
    <SidebarItemWrapper mt="6" borderRadius="base" py="8">
      <Box>
        <Text fontSize="lg">Opportunity Type</Text>
      </Box>
      <Flex flexWrap="wrap" sx={{ gap: 6 }} mt="5">
        {opportunityTypes.map((opportunityType) => {
          const isActive = categoryId === opportunityType.url_friendly_string

          return (
            <Button
              key={opportunityType.id}
              onClick={() => {
                setCategoryId(opportunityType.url_friendly_string)
                changeFilter(0, opportunityType.url_friendly_string)
              }}
              h="8"
              bg={
                isActive
                  ? mode('#D6D6D6', 'white')
                  : mode('#F5F5F5', 'whiteAlpha.100')
              }
              px="4"
              py="2"
            >
              <Text
                fontWeight={isActive ? 'bold' : '400'}
                color={
                  isActive ? mode('black', 'black') : mode('black', 'white')
                }
                fontSize="sm"
              >
                {parseCategoryName[opportunityType.id] ?? opportunityType.title}
              </Text>
            </Button>
          )
        })}
      </Flex>
    </SidebarItemWrapper>
  )
}

import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from 'components/Navbar'

export const SharedLayout = ({ children }) => {
  return (
    <Box>
      <Navbar />
      <Box minH="100vh">{children}</Box>
    </Box>
  )
}

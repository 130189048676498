import { Box } from '@chakra-ui/react'

export const Sidebar = ({ children, ...styleProps }) => {
  return (
    <Box
      {...styleProps}
      maxW="315px"
      display={['none', 'none', 'block', 'block']}
      h="100vh"
      position="sticky"
      top="0"
    >
      {children}
    </Box>
  )
}

import { useToast as useToastChakra } from '@chakra-ui/react'

function useToast() {
  const toast = useToastChakra({
    variant: 'subtle',
    position: 'bottom',
    duration: 9000,
    isClosable: true,
  })
  return toast
}

export { useToast }

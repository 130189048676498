import {
  Avatar,
  AvatarBadge,
  Box,
  chakra,
  Text,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { encryptId } from 'utils/encryption'
import RelationshipStrength from './RelationshipStrength'

const UserAvatar = ({ user }) => (
  <Tooltip label={`${user.name}`}>
    <Avatar
      mr={2}
      size="sm"
      src={user.avatar}
      name={`${user.name}`}
      bg="primary.500"
      color="white"
      filter="opacity(0.5)"
    >
      <AvatarBadge
        bg="primary.500"
        color="white"
        fontWeight="bold"
        fontSize="xx-small"
        transform="translateY(7px)"
        left="0"
        px={1}
      >
        You
      </AvatarBadge>
    </Avatar>
  </Tooltip>
)

const TargetAvatar = ({ target }) => (
  <Tooltip label={`${target.firstname} ${target.lastname}`}>
    <Avatar
      ml={2}
      size="sm"
      src={target.avatar}
      name={target.firstname}
      bg="primary.500"
      color="white"
      filter="opacity(0.5)"
    />
  </Tooltip>
)

export default function PathsConnection({ connector, target }) {
  const { user, selectedUser } = useAuth()

  const selectedUserIdentity = selectedUser
    ? {
        ...selectedUser.Identity,
        name: `${selectedUser?.Identity?.firstname} ${selectedUser?.Identity?.lastname}`,
      }
    : {
        ...user,
        id: user.identityId,
      }

  return (
    <Box
      w="100%"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      pos="relative"
    >
      {connector.identity_id === selectedUserIdentity.id ? (
        <>
          <Box
            bg={mode('white', 'grayAlpha.700')}
            zIndex="2"
            d="flex"
            alignItems="center"
            pr={1}
          >
            <UserAvatar user={selectedUserIdentity} />
          </Box>

          <Box
            bg={mode('white', 'grayAlpha.700')}
            zIndex="2"
            d="flex"
            alignItems="center"
            justifyContent="center"
            px={1}
          >
            <RelationshipStrength
              relationship={{
                warmth_score: connector.connector_target_warmth_score,
              }}
              identities={[
                target.right_identity_id,
                connector.identity_id,
              ].sort((a, b) => a - b)}
              companySize={connector.company_size && connector.company_size}
              connectorPeriod={connector.period}
              fontSize="x-small"
              size="sm"
              padding="0.25rem 0.5rem"
            />
          </Box>

          <Box zIndex="2" bg={mode('white', 'grayAlpha.700')}>
            <TargetAvatar target={target} />
          </Box>
        </>
      ) : (
        <>
          <Box
            bg={mode('white', 'grayAlpha.700')}
            zIndex="2"
            d="flex"
            alignItems="center"
            pr={1}
          >
            <UserAvatar user={selectedUserIdentity} />

            <RelationshipStrength
              relationship={{
                warmth_score: connector.user_connector_warmth_score,
              }}
              identities={[user.identityId, connector.identity_id].sort(
                (a, b) => a - b
              )}
              fontSize="x-small"
              size="sm"
              padding="0.25rem 0.5rem"
            />
          </Box>

          <Box
            d="flex"
            justifyContent="flex-start"
            alignItems="center"
            bg={mode('white', 'grayAlpha.700')}
            zIndex="2"
            as="a"
            href={`/people/profile/${encryptId(connector.identity_id)}`}
            onAuxClick={() =>
              window.open(`/people/profile/${encryptId(connector.identity_id)}`)
            }
            target="_blank"
            w="15ch"
            pl={2}
          >
            <Avatar
              mr={2}
              size="md"
              src={connector.avatar}
              name={connector.name}
              bg="primary.500"
              color="white"
            />
            <Tooltip label={connector.name}>
              <Text
                fontSize="sm"
                _hover={{
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px',
                }}
                noOfLines={2}
              >
                {connector.name}
              </Text>
            </Tooltip>
          </Box>

          <Box
            d="flex"
            alignItems="center"
            justifyContent="flex-end"
            zIndex="2"
            bg={mode('white', 'grayAlpha.700')}
            pl={1}
          >
            <RelationshipStrength
              relationship={{
                warmth_score: connector.connector_target_warmth_score,
              }}
              identities={[
                target.right_identity_id,
                connector.identity_id,
              ].sort((a, b) => a - b)}
              companySize={connector.company_size && connector.company_size}
              connectorPeriod={connector.period}
              fontSize="x-small"
              size="sm"
              padding="0.25rem 0.5rem"
            />

            <TargetAvatar target={target} />
          </Box>
        </>
      )}

      <chakra.span
        pos="absolute"
        borderColor={mode('gray.300', 'grayAlpha.100')}
        borderBottom="1px dashed"
        h="1px"
        w="100%"
        top="50%"
        zIndex="1"
      />
    </Box>
  )
}

import {
  Avatar,
  Box,
  chakra,
  Divider,
  Input,
  InputGroup,
  Text,
  Flex,
  useColorModeValue as mode,
  InputLeftElement,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { FaCheck } from 'react-icons/fa'

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default function Typeahead({
  inputStyle,
  firstOptions,
  options,
  fetchOptions,
  chooseOption,
  disableChooseOption,
  placeholder,
  secondaryAction,
  isDisabled,
  isOptionSelected,
  divideResults,
  leftIcon,
}) {
  const [inputValue, setInputValue] = useState('')

  const [isInputFocused, setIsInputFocused] = useState(false)

  const optionsContainerRef = useRef(null)
  useOutsideAlerter(optionsContainerRef, () => {
    setIsInputFocused(false)
  })

  useEffect(() => {
    if (inputValue.length > 1) {
      fetchOptions(inputValue)
    }
  }, [inputValue])

  const renderOptions = () => {
    if (!divideResults) {
      return options.map((option, optionIndex) => (
        <Box
          key={`option-${optionIndex}`}
          onClick={() => {
            if (disableChooseOption) {
              return
            }

            setIsInputFocused(false)
            setInputValue('')
            chooseOption(option)
          }}
          p={3}
          cursor="pointer"
          bg={mode('white', 'grayAlpha.700')}
          _hover={{
            bg: mode('gray.25', 'grayAlpha.600'),
          }}
        >
          <Flex alignItems="center">
            {option.avatar && (
              <Avatar
                name={option.title}
                src={option.avatar}
                size="xs"
                mr={2}
              />
            )}
            <Text mt="1" fontWeight={'bold'}>
              {option.title}
            </Text>
          </Flex>
          <Text>{option.subtitle}</Text>
          {!!isOptionSelected && isOptionSelected(option) && (
            <FaCheck
              style={{
                position: 'absolute',
                right: 25,
                marginTop: option.avatar ? -32 : -24,
              }}
            />
          )}
        </Box>
      ))
    }

    if (!options.length) return null

    return divideResults.map((divideResult, divideResultIndex) => {
      const resultsForEntity = options.filter(
        (o) => o.entity === divideResult.entity
      )

      if (!resultsForEntity.length) {
        return null
      }

      return (
        <>
          <Text px="3" py="2" fontWeight="900" key={divideResultIndex}>
            {divideResult.title}
          </Text>
          {resultsForEntity.map((option, optionIndex) => (
            <Box
              key={`option-${optionIndex}`}
              onClick={() => {
                if (disableChooseOption) {
                  return
                }

                setIsInputFocused(false)
                setInputValue('')
                chooseOption(option)
              }}
              p={3}
              cursor="pointer"
              bg={mode('white', 'grayAlpha.700')}
              _hover={{
                bg: mode('gray.25', 'grayAlpha.600'),
              }}
            >
              <Flex alignItems="center">
                {option.avatar && (
                  <Avatar
                    name={option.title}
                    src={option.avatar}
                    size="xs"
                    mr={2}
                  />
                )}
                <Text mt="1" fontWeight={'bold'}>
                  {option.title}
                </Text>
              </Flex>
              <Text>{option.subtitle}</Text>
              {!!isOptionSelected && isOptionSelected(option) && (
                <FaCheck
                  style={{
                    position: 'absolute',
                    right: 25,
                    marginTop: option.avatar ? -32 : -24,
                  }}
                />
              )}
            </Box>
          ))}
        </>
      )
    })
  }

  return (
    <Box ref={optionsContainerRef}>
      <InputGroup>
        <InputLeftElement mt="21px" children={leftIcon} />
        <Input
          isDisabled={isDisabled}
          type="text"
          data-lpignore="true"
          id="invited"
          p={6}
          pr="5rem"
          placeholder={
            placeholder ?? 'Enter a name or email of one of your contacts'
          }
          size="md"
          h="60px"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onFocus={() => setIsInputFocused(true)}
          {...inputStyle}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setIsInputFocused(false)
            }
          }}
        />
      </InputGroup>

      {isInputFocused && (
        <Box
          mt={2}
          borderRadius="md"
          boxShadow="xl"
          overflow={'hidden'}
          pos="absolute"
          bg={mode('white', 'grayAlpha.700')}
          w="100%"
          zIndex="999"
        >
          {!!secondaryAction && secondaryAction(inputValue, setInputValue)}
          {firstOptions && (
            <Box mb={2}>
              {' '}
              <Text pl={3} fontWeight="bold" mb={2}>
                Suggestions{' '}
                <chakra.span fontWeight="normal" fontSize="sm">
                  (based on relationship warmth)
                </chakra.span>
              </Text>
              {firstOptions
                .filter((fOption) =>
                  fOption.title
                    .toLowerCase()
                    .includes(inputValue.toLocaleLowerCase())
                )
                .map((fOption, fOptionIndex) => (
                  <Box
                    key={`suggestion-${fOptionIndex}`}
                    onClick={() => {
                      if (disableChooseOption) {
                        return
                      }

                      setIsInputFocused(false)
                      setInputValue('')
                      chooseOption(fOption)
                    }}
                    p={3}
                    cursor="pointer"
                    bg={mode('white', 'grayAlpha.700')}
                    _hover={{
                      bg: mode('gray.25', 'grayAlpha.600'),
                    }}
                    d="flex"
                    alignItems="center"
                  >
                    {fOption.avatar && (
                      <Avatar
                        name={fOption.title}
                        src={fOption.avatar}
                        size="sm"
                        mr={2}
                      />
                    )}
                    <Box>
                      <Text fontWeight={'bold'}>{fOption.title}</Text>
                      <Text>{fOption.subtitle}</Text>
                    </Box>
                  </Box>
                ))}
              {Boolean(options.length) && <Divider />}
            </Box>
          )}
          {renderOptions()}
        </Box>
      )}
    </Box>
  )
}

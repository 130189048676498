import { Box, Container, Image } from '@chakra-ui/react'

export default function LandingLayout({ children }) {
  return (
    <Container maxW="container.lg" minH="100vh">
      <Box w="100%" py={4} my={4}>
        <Image src="/logo-transparent.png" h="55px" />
      </Box>

      <Box d="flex" flexDir="column" justifyContent="center">
        {children}
      </Box>
    </Container>
  )
}

import IntroRequestGroup from 'components/IntroRequestGroup'
import Introducer from 'components/IntroRequestGroup/Introducer'
import { IntrosHeader } from 'components/TableHeader'
import { useEffect, useState } from 'react'
import api from 'services/api'

export default function MyIntroRequestsToOthers() {
  const [requested, setRequested] = useState([])

  useEffect(() => {
    const fetchIntros = async () => {
      try {
        const { data } = await api.get(`/intros`)

        setRequested(
          Object.values(
            data.reduce((acc, curr) => {
              if (acc[`${curr.intro_entity}-${curr.intro_identity}`]) {
                acc[`${curr.intro_entity}-${curr.intro_identity}`].push(curr)
              } else {
                acc[`${curr.intro_entity}-${curr.intro_identity}`] = [curr]
              }

              return acc
            }, {})
          )
        )
      } catch (err) {
        console.log(err)
      }
    }

    fetchIntros()
  }, [])

  const updateRequested = ({ key, value, introducer, requestedIndex }) => {
    const newRequested = [...requested]

    const introducerdRef = newRequested[requestedIndex].find(
      (i) => i.intro_id === introducer.intro_id
    )
    introducerdRef[key] = value
    setRequested(newRequested)
  }

  return (
    <>
      <IntrosHeader />
      {requested.map((d, i) => {
        const person = {
          name: d[0].intro_name,
          avatar: d[0].intro_avatar,
          latest_update: d[0].intro_updated_date,
        }

        return (
          <IntroRequestGroup key={`group-${i}`} data={d} person={person}>
            {d.map((introducer, introducerIndex) => (
              <Introducer
                key={`requested-${introducerIndex}`}
                introducer={introducer}
                updateRequested={({ ...values }) =>
                  updateRequested({ ...values, requestedIndex: i })
                }
              />
            ))}
          </IntroRequestGroup>
        )
      })}
    </>
  )
}

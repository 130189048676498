import React from 'react'
import {
  Box,
  Avatar,
  Flex,
  Text,
  Link,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import moment from 'moment'
import { encryptId } from 'utils/encryption'

export const EducationHistory = ({ history }) => {
  if (!history) return null

  return (
    <Box>
      {history.map((edu, index) => (
        <Flex
          direction={['column', 'column', 'row']}
          key={index}
          alignItems="flex-start"
          textAlign="left"
          sx={{ gap: 16 }}
          lineHeight="19px"
          mb="15px"
        >
          <Flex
            ml={['0', '0', '-1', '-1']}
            maxW="175px"
            w={['auto', 'auto', '35%', '35%']}
            justifyContent={['center', 'center', 'flex-end', 'flex-end']}
          >
            <Text as="span" fontSize={16} color="#9D9D9D">
              {moment(edu.period_from).format("MMM 'YYYY")} -{' '}
              {edu.period_to
                ? moment(edu.period_to).format("MMM 'YYYY")
                : 'Present'}
            </Text>
          </Flex>
          <Text
            as="span"
            ml={['0', '0', '10px', '10px']}
            fontSize={16}
            color={mode('black', 'white')}
          >
            {`${edu.major ? `${edu.major},` : ''} ${
              edu.degree ? `${edu.degree}` : ''
            } `}{' '}
            at
            <Avatar
              ml="7px"
              size="2xs"
              mr="6px"
              name={edu.name}
              src={edu.logo}
            />
            <Link
              textDecoration="underline"
              fontWeight="bold"
              color={mode('black', 'white')}
              href={`/company/profile/${edu.slug ?? encryptId(edu.id)}`}
            >
              {edu.name}
            </Link>
          </Text>
        </Flex>
      ))}
    </Box>
  )
}

import {
  Avatar,
  Badge,
  Box,
  chakra,
  Textarea,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import RelationshipStrength from 'components/RelationshipStrength'

export default function Selected({ selected, reason, setReason }) {
  return (
    <Box
      mb={3}
      pb={3}
      borderBottom="1px solid"
      borderColor={mode('gray.200', 'grayAlpha.700')}
    >
      <Box
        w="100%"
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box d="flex" alignItems="center" w="40%">
          <Avatar
            mr={2}
            size="sm"
            src={selected.avatar}
            name={selected.name}
            bg="primary.500"
            color="white"
          ></Avatar>

          <Text
            d="flex"
            justifyContent="flex-start"
            alignItems="center"
            isTruncated
            maxW="70%"
            fontWeight="bold"
          >
            <chakra.span isTruncated>{selected.name}</chakra.span>
          </Text>
        </Box>

        <Box w="40%">
          {' '}
          <Text
            d="flex"
            justifyContent="flex-start"
            alignItems="center"
            isTruncated
            maxW="90%"
            fontWeight="bold"
            fontSize="sm"
          >
            <chakra.span isTruncated>{selected.work}</chakra.span>
          </Text>
        </Box>

        <Box w="20%" textAlign="right">
          {selected.strength ? (
            <RelationshipStrength
              relationship={{ warmth_score: selected.strength }}
            />
          ) : (
            <Badge
              borderRadius="full"
              padding="0.5rem 0.75rem"
              colorScheme="gray"
            >
              Not Sure
            </Badge>
          )}
        </Box>
      </Box>

      <Textarea
        _focus={{
          outline: 'none',
        }}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        bg={mode('white', 'black')}
        borderColor={mode('gray.100', 'grayAlpha.700')}
        color={mode('black', 'white')}
        rows="1"
        placeholder="Reason to connect"
      />
    </Box>
  )
}

import React from 'react'
import {
  Container,
  Box,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue as mode,
  Flex,
  Skeleton,
} from '@chakra-ui/react'

export const ProfileSkeleton = () => {
  return (
    <Container maxW="1130px">
      <Box p="6" mt="4">
        <Flex
          direction={['column', 'column', 'row']}
          alignItems="center"
          justifyContent={['center', 'center', 'flex-start']}
          sx={{ gap: 24 }}
        >
          <SkeletonCircle
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
            width="128px"
            height="128px"
          />

          <SkeletonText
            w="55%"
            pr={['0', '0', '55%']}
            flex="1"
            ml={['0', '0', '8']}
            startColor={mode('gray.100', 'grayAlpha.50')}
            endColor={mode('gray.500', 'grayAlpha.500')}
            noOfLines={7}
          />
        </Flex>
        <Flex mt="12" sx={{ gap: 24 }}>
          <Flex flex="1" w="23.5%">
            <SkeletonText
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
              my="10"
              size="10"
              noOfLines={3}
              flex="1"
            />
          </Flex>
          <Flex flex="1" w="23.5%">
            <SkeletonText
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
              my="10"
              size="10"
              noOfLines={3}
              flex="1"
            />
          </Flex>
          <Flex flex="1" w="23.5%">
            <SkeletonText
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
              my="10"
              size="10"
              noOfLines={3}
              flex="1"
            />
          </Flex>
          <Flex flex="1" w="23.5%">
            <SkeletonText
              startColor={mode('gray.100', 'grayAlpha.50')}
              endColor={mode('gray.500', 'grayAlpha.500')}
              my="10"
              size="10"
              noOfLines={3}
              flex="1"
            />
          </Flex>
        </Flex>
        <Skeleton
          w="100%"
          h="91"
          mt="8"
          borderRadius="25"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
        />
        <SkeletonText
          w="20%"
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
          my="10"
          size="10"
          noOfLines={2}
        />
        <SkeletonText
          startColor={mode('gray.100', 'grayAlpha.50')}
          endColor={mode('gray.500', 'grayAlpha.500')}
          my="10"
          size="10"
          noOfLines={10}
        />
      </Box>
    </Container>
  )
}

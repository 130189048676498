import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import { useKanban } from 'context/KanbanContext'
import { useEffect, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IoMdReturnLeft } from 'react-icons/io'
import { ProfilePageModal } from 'components/Profile/ProfilePageModal'
import { encryptId } from 'utils/encryption'
import { AddNoteIcon } from 'components/Icons/AddNote'
import api from 'services/api'
import AvatarGroup from 'components/AvatarGroup'
import { FaCheck, FaQuestion, FaTimes } from 'react-icons/fa'
import StatusBadge from 'components/IntroRequestGroup/StatusBadge'
import { useToast } from 'hooks/useToast'
import { AvatarWithWarmth } from 'components/AvatarWithWarmth'
import { Recommenders } from './Recommendation'

export default function Task({ task, color, index }) {
  const initialFocusRef = useRef()

  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    // taskBeingDraggedOverId,
    handleDeleteTask,
    handleUpdateTaskNote,
  } = useKanban()

  const [inputValue, setInputValue] = useState('')

  const hasRecommendations = task?.recommenders?.length > 0

  const {
    isOpen: isModalOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure()

  useEffect(() => {
    if (isOpen) {
      const textArea = initialFocusRef.current

      textArea.setSelectionRange(
        textArea.textContent.length,
        textArea.textContent.length
      )
    }
  }, [isOpen])

  const handleSubmit = () => {
    if (inputValue.trim() === '') {
      toast({
        title: 'You cannot add an empty note.',
        status: 'error',
      })
      return
    }
    handleUpdateTaskNote(task.task_id, inputValue.trim())
    setInputValue('')
    onClose()
  }

  const hasRelatedIntroRequest = 'introducer_feedback' in task

  const [introData, setIntroData] = useState([])

  const [error, setError] = useState(false)

  const fetchTasksIntroRequests = async () => {
    try {
      const { data } = await api.get(`/intros?identityId=${task.task_identity}`)
      setIntroData(data)
    } catch (e) {
      setError(true)
    }
  }
  const colors = {
    accepted: 'green.500',
    denied: 'red.500',
    unknown: 'gray.500',
  }

  useEffect(() => {
    if (hasRelatedIntroRequest) {
      fetchTasksIntroRequests()
    }
  }, [])

  const renderFeedbackContent = () => {
    return (
      <Flex transform="translateX(-0.5rem)" alignItems="center">
        <StatusBadge
          feedbacks={Object.entries(
            introData
              .map((d) =>
                d.introducer_feedback === 'unknown'
                  ? 'denied'
                  : d.introducer_feedback
              )
              .reduce((acc, curr) => {
                if (acc[curr]) {
                  acc[curr] += 1
                } else {
                  acc[curr] = 1
                }

                return acc
              }, {})
          )}
        />

        <AvatarGroup size="sm" max={3} spacing="-0.5rem">
          {introData.map((introducer, introducerIndex) => (
            <Avatar
              key={introducerIndex}
              src={introducer.introducer_avatar}
              name={`${introducer.introducer_firstname} ${introducer.introducer_lastname}`}
              w="40px"
              h="40px"
              border={introducer.introducer_feedback && '2px solid'}
              borderColor={colors[introducer.introducer_feedback]}
            >
              <AvatarBadge
                borderRadius="full"
                bg={
                  ['denied', 'unknown', 'canceled'].includes(
                    introducer.introducer_feedback
                  )
                    ? 'red.500'
                    : ['accepted', 'pending', 'completed'].includes(
                        introducer.introducer_feedback
                      )
                    ? 'green.500'
                    : 'yellow.500'
                }
                right="2px"
                p="0.4rem"
                w={4}
                h={4}
              >
                <Icon
                  as={
                    ['denied', 'unknown', 'canceled'].includes(
                      introducer.introducer_feedback
                    )
                      ? FaTimes
                      : ['accepted', 'pending', 'completed'].includes(
                          introducer.introducer_feedback
                        )
                      ? FaCheck
                      : FaQuestion
                  }
                  color="white"
                  w="0.6rem"
                  h="0.6rem"
                />
              </AvatarBadge>
            </Avatar>
          ))}
        </AvatarGroup>
      </Flex>
    )
  }

  const renderRecommendations = () => {
    if (task.recommenders) {
      if (task.recommenders.length > 0) {
        return (
          <Flex
            ml="45px"
            sx={{ gap: '4px' }}
            mt="-2"
            alignItems="center"
            flexWrap="wrap"
          >
            <Text fontSize="11px">Recommended by</Text>
            <Recommenders recommenders={task.recommenders} />
          </Flex>
        )
      }
    }
  }

  const renderFeedbacks = () => {
    return (
      <Popover openDelay={0} trigger="hover">
        <PopoverTrigger>
          <Icon
            as={
              ['denied', 'unknown', 'canceled'].includes(
                task?.introducer_feedback
              )
                ? FaTimes
                : ['accepted', 'pending', 'completed'].includes(
                    task?.introducer_feedback
                  )
                ? FaCheck
                : FaQuestion
            }
            color={mode('black', 'white')}
            h="3.5"
            w="3.5"
          />
        </PopoverTrigger>
        <PopoverContent w="100%" py="1" border="none">
          {!error && introData.length > 0
            ? renderFeedbackContent()
            : 'An error occured'}
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <>
      <Draggable draggableId={task.task_id} index={index}>
        {(provided, snapshot) => (
          <Popover
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onClose}
            returnFocusOnClose={false}
          >
            <PopoverTrigger>
              <Box
                border="1px solid"
                borderColor={mode('gray.200', `notion-dark-${color}.400`)}
                borderRadius="md"
                bg={mode('white', `notion-dark-${color}.400`)}
                color={mode('black', 'white')}
                p={2}
                mb={1}
                onClick={(e) => {
                  modalOnOpen()
                  e.stopPropagation()
                }}
                cursor="pointer !important"
                _hover={{
                  bg: mode('gray.50', `notion-dark-${color}.200`),
                  '.task-note-button': {
                    visibility: 'visible',
                  },
                }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                // isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                // filter={taskBeingDraggedOverId === task.task_id && 'opacity(0.5)'}
              >
                <Box
                  d="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  mb={task.task_content && !hasRecommendations ? 2 : 0}
                >
                  {task?.task_entity === 'identity' ||
                  task?.task_entity === 'company' ? (
                    <AvatarWithWarmth
                      relationship={{
                        warmth_score: task?.warmth_score,
                        connectors: task?.connectors,
                        right_identity_id: task?.right_identity_id,
                        total: task?.total,
                        avatars: task?.avatars,
                      }}
                      taskEntity={task?.task_entity}
                      taskIdentity={task?.task_identity}
                      src={task.avatar_url}
                      size="sm"
                      mr={2}
                    />
                  ) : (
                    <Avatar src={task.avatar_url} w="36px" h="36px" mr={2} />
                  )}
                  <Text fontSize="md" isTruncated>
                    {task.name}
                  </Text>
                  <Flex alignItems="center" ml="auto">
                    {hasRelatedIntroRequest && renderFeedbacks()}
                    <IconButton
                      className="task-note-button"
                      transition="all 0s"
                      visibility="hidden"
                      zIndex={1}
                      onClick={(e) => {
                        onOpen()
                        e.stopPropagation()
                      }}
                      _hover={{
                        '& > svg': {
                          fill: mode('rgb(0,0,0,1)', 'rgb(255,255,255,1)'),
                          stroke: mode('rgb(0,0,0,1)', 'rgb(255,255,255,1)'),
                        },
                      }}
                      ml="auto"
                      variant="link"
                      icon={
                        <AddNoteIcon
                          color={mode('rgb(0,0,0,0.5)', 'rgb(255,255,255,0.5)')}
                          width="24px"
                          height="24px"
                        />
                      }
                    />
                  </Flex>
                </Box>

                {renderRecommendations()}

                {task.task_content && (
                  <Text
                    noOfLines={2}
                    color={mode('black', 'white')}
                    fontSize="sm"
                  >
                    {task.task_content}
                  </Text>
                )}
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />

              <PopoverBody>
                <Box
                  mb={4}
                  d="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Avatar src={task.avatar_url} size="xs" mr={1} />
                  <Text fontSize="sm">{task.name}</Text>
                </Box>

                <Textarea
                  ref={initialFocusRef}
                  value={inputValue}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType === 'insertLineBreak') return
                    setInputValue(e.target.value)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      handleSubmit()
                    }
                  }}
                  placeholder="Type here..."
                  whiteSpace="pre-wrap"
                  border="none"
                  _focus={{
                    boxShadow: 'none',
                  }}
                />

                <Box
                  d="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <chakra.button
                    color="red.400"
                    textDecor="underline"
                    fontSize="xs"
                    onClick={() => handleDeleteTask(task.task_id)}
                  >
                    Delete
                  </chakra.button>

                  <Button
                    type="submit"
                    size="xs"
                    onClick={() => handleSubmit()}
                    float="right"
                    leftIcon={<IoMdReturnLeft />}
                  >
                    to add
                  </Button>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Draggable>
      <ProfilePageModal
        boardIdToOpen={task.board_id}
        UserProfile={task.task_entity === 'identity' ? true : false}
        profileToVisit={encryptId(task.task_identity)}
        isOpen={isModalOpen}
        onClose={modalOnClose}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Flex,
  useColorModeValue as mode,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  Button,
  Text,
  useDisclosure,
  CloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { OpportunityModalStepOne } from './OpportunityModalStepOne'
import { OpportunityModalStepTwo } from './OpportunityModalStepTwo'
import { ConfirmationModal } from './ConfirmationModal'
import { OpportunityModalStepThree } from './OpportunityModalStepThree'
import moment from 'moment'
import { intentParser } from 'utils/parsers'
import { useOpportunity } from 'context/OpportunityContext'
import { useToast } from 'hooks/useToast'

export const PostOpportunityModal = ({
  isOpen,
  onClose,
  opportunity,
  ownOpportunities,
  setOwnOpportunities,
}) => {
  const [step, setStep] = useState(1)

  const [editorState, setEditorState] = useState(
    opportunity ? opportunity.content : intentParser(1)?.content
  )

  const [name, setName] = useState('')

  const [company, setCompany] = useState('')

  const [location, setLocation] = useState('')

  const [strength, setStrength] = useState('Any')

  const { companies, categories } = useOpportunity()

  const [isLoading, setIsLoading] = useState(false)

  const [useQueryParams, setUseQueryParams] = useState(false)

  const [queryParams, setQueryParams] = useState({})

  const [removeAllSharedWith, setRemoveAllSharedWith] = useState(false)

  const [selected, setSelected] = useState(
    opportunity ? opportunity.sharedWithPeople : []
  )

  const thirtyDaysFromNow = moment().add(1, 'month').toDate()

  const currentCompany = companies.find(
    (company) => company.period_to === null
  ) ||
    companies[0] || { id: -1, name: 'No company' }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      company_id: opportunity ? opportunity.company_id : null,
      category_id: opportunity ? opportunity.category_id : 1,
      title: opportunity ? opportunity.title : '',
      status: opportunity ? opportunity.status : 'actively',
      sharedWith: opportunity
        ? opportunity.sharedWith
        : intentParser(1)?.sharedWith,
      sharePermission: opportunity
        ? opportunity.sharePermission
        : intentParser(1)?.sharePermission,
      referralReward: opportunity ? opportunity.referralReward : '',
      expireAt: opportunity
        ? moment(opportunity.expireAt).toDate()
        : thirtyDaysFromNow,
    },
  })

  const { category_id } = watch()

  const toast = useToast()

  useEffect(() => {
    if (!opportunity) {
      setValue('sharedWith', intentParser(category_id).sharedWith, {
        shouldTouch: true,
      })
      setValue('sharePermission', intentParser(category_id).sharePermission, {
        shouldTouch: true,
      })
      setEditorState(intentParser(category_id).content)
      setValue('referralReward', intentParser(category_id).reward, {
        shouldTouch: true,
      })
    }
  }, [category_id])

  const closeAndReset = (data) => {
    setStep(1)
    if (data) {
      reset(data)
    } else {
      reset()
    }
    if (!opportunity) {
      setEditorState(intentParser(1).content)
    }
    onConfirmationClose()
    setSelected([])
    onClose()
  }

  const onSubmit = async (data) => {
    if (step === 1) {
      const errorArray = Object.keys(errors)
      if (errorArray.length === 1) {
        return
      }
      setStep(2)
    }
    if (step === 2) {
      if (editorState === '') {
        toast({
          title: 'Opportunity content is required',
          status: 'error',
        })
        return
      }
      setStep(3)
    }

    if (step === 3) {
      const opObject = {
        company_id: data.company_id,
        category_id: data.category_id,
        title: data.title,
        forOp: data.forOp,
        sharedWith: data.sharedWith,
        sharedWithCustom: selected.map((s) => ({
          name: s.fullName,
          email: s.email,
          id: s.id,
        })),
        sharePermission: data.sharePermission.value ?? data.sharePermission,
        expireAt: moment(data.expireAt).format('YYYY-MM-DD'),
        referralReward: data.referralReward,
        content: editorState,
        status: data.status,
      }
      try {
        setIsLoading(true)
        let res = null
        let opportunities = [...ownOpportunities]
        let formData = null

        if (opportunity) {
          res = await api.patch(`/opportunities/${opportunity.id}`, opObject)

          const index = opportunities.findIndex(
            (opp) => opp.id === opportunity.id
          )

          const updatedOpportunity = {
            ...opportunity,
            ...res.data.opportunity,
            expireAt: moment(res.data.opportunity.expireAt).toDate(),
            applicationCount: 0,
          }
          formData = updatedOpportunity
          opportunities.splice(index, 1, updatedOpportunity)
          setOwnOpportunities(opportunities)
        } else {
          res = await api.post(`/opportunities`, opObject)

          const categoryTitle = categories.find(
            (cat) => cat.id === Number(data.category_id)
          ).title

          opportunities = [
            {
              ...res.data,
              category_title: categoryTitle,
              applicationCount: 0,
              sharedWithPeople: selected,
            },
            ...opportunities,
          ]

          setOwnOpportunities(opportunities)
        }

        toast({
          status: 'success',
          title: 'Opportunity sent successfully',
        })
        setIsLoading(false)
        closeAndReset(formData)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
      }
    }
  }

  const deleteOpportunity = async () => {
    try {
      await api.delete(`/opportunities/${opportunity?.id}`)
      setOwnOpportunities(
        ownOpportunities.filter((o) => o.id !== opportunity.id)
      )
      closeAndReset()
      toast({ title: 'Successfully removed opportunity', status: 'success' })
    } catch (e) {
      toast({ title: 'Something went wrong', status: 'error' })
    }
  }

  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure()

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => closeAndReset()}
      isCentered={step !== 2}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf={step === 2 && 'flex-end'}
        my="0"
        w={step === 2 ? '92vw' : '54vw'}
        maxW="92vw"
      >
        <ModalHeader py="0">
          <CloseButton
            pos="absolute"
            right="5"
            top="5"
            disabled={isLoading}
            onClick={() => onConfirmationOpen()}
          />
        </ModalHeader>
        <ModalBody
          pt="10"
          px="10"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: `${mode(
                'var(--chakra-colors-gray-200)',
                'var(--chakra-colors-grayAlpha-200)'
              )}`,
              borderRadius: '24px',
            },
          }}
          overflowX="hidden"
        >
          {step === 1 && (
            <OpportunityModalStepOne
              control={control}
              errors={errors}
              categories={categories}
              companies={companies}
              values={watch()}
              currentCompany={currentCompany}
            />
          )}
          {step === 2 && (
            <OpportunityModalStepTwo
              editorState={editorState}
              setEditorState={setEditorState}
            />
          )}
          {step === 3 && (
            <OpportunityModalStepThree
              isEdit={!!opportunity}
              errors={errors}
              control={control}
              values={watch()}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              useQueryParams={useQueryParams}
              setUseQueryParams={setUseQueryParams}
              sharedWithPeopleCount={opportunity?.sharedWithPeopleCount}
              removeAllSharedWith={removeAllSharedWith}
              setRemoveAllSharedWith={setRemoveAllSharedWith}
              preferenceValues={{
                name,
                setName,
                company,
                setCompany,
                strength,
                setStrength,
                location,
                setLocation,
              }}
              selected={selected}
              setSelected={setSelected}
            />
          )}
        </ModalBody>
        <Flex
          pos={step === 2 && 'absolute'}
          bottom="0"
          bgGradient={mode(
            'linear(rgba(0,0,0,0.0),white, white, white)',
            'linear(rgba(0,0,0,0.0),grayAlpha.700,grayAlpha.700,grayAlpha.700)'
          )}
          w="100%"
          alignItems="center"
          py="4"
          px="10"
        >
          {step > 1 && (
            <Button onClick={() => setStep(step - 1)} variant="link">
              <Text
                fontWeight="400"
                decoration="underline"
                fontSize="sm"
                color={mode('grayAlpha.500', 'gray.1')}
              >
                {'<- Back'}
              </Text>
            </Button>
          )}
          {opportunity && (
            <Button
              ml="auto"
              onClick={() => deleteOpportunity()}
              variant="link"
            >
              <Text
                fontWeight="400"
                decoration="underline"
                fontSize="sm"
                color="red.400"
                mr="4"
              >
                Delete opportunity
              </Text>
            </Button>
          )}
          <Button
            ml={opportunity ? '2' : 'auto'}
            onClick={() => onConfirmationOpen()}
            variant="link"
          >
            <Text
              fontWeight="400"
              decoration="underline"
              fontSize="sm"
              color="red.400"
            >
              Cancel
            </Text>
          </Button>
          <Button
            variant="village"
            ml="4"
            isLoading={isLoading}
            onClick={() => handleSubmit(onSubmit)()}
          >
            {step !== 3 ? 'Continue ->' : 'Confirm ->'}
          </Button>
        </Flex>
      </ModalContent>
      <ConfirmationModal
        isNew={!!opportunity}
        closeAction={() => closeAndReset()}
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
      />
    </Modal>
  )
}

import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Stack,
  Text,
  Portal,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import AvatarGroup from 'components/AvatarGroup'
import { useKanban } from 'context/KanbanContext'
import { FaEllipsisH, FaTrashAlt } from 'react-icons/fa'

export default function BoardCard({ data, title, ...props }) {
  const { currentBoard, handleDeleteBoard, selectBoard } = useKanban()

  const avatars = data.tasks
    ? Object.values(data.tasks).map((task) => task.avatar_url)
    : []

  const isSelected = currentBoard === title

  return (
    <Stack
      spacing={2}
      onClick={isSelected ? undefined : () => selectBoard(title)}
      border={isSelected ? '2px solid' : '1px solid'}
      borderColor={
        isSelected
          ? mode('blue.200', 'blue.800')
          : mode('gray.200', 'grayAlpha.800')
      }
      borderRadius="md"
      minW="210px"
      maxW="210px"
      h="100px"
      p={4}
      cursor="pointer"
      bg={
        isSelected
          ? mode('blue.50', 'blue.700')
          : mode('white', 'grayAlpha.700')
      }
      _hover={!isSelected ? { bg: mode('gray.50', 'grayAlpha.600') } : {}}
      pos="relative"
    >
      {isSelected && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisH />}
            variant="ghost"
            size="sm"
            colorScheme="gray"
            color={`gray.400`}
            pos="absolute"
            top="0"
            right="0"
          />
          <Portal>
            <MenuList>
              <MenuGroup title="Actions">
                <MenuItem
                  icon={<FaTrashAlt />}
                  onClick={() => handleDeleteBoard(currentBoard)}
                >
                  Delete
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Portal>
        </Menu>
      )}

      <Text
        fontWeight="semibold"
        color={isSelected && mode('blue.500', 'blue.100')}
        m="0 !important"
        isTruncated
      >
        {title}
      </Text>

      {avatars.length > 0 && (
        <Box d="flex" alignItems="center">
          <AvatarGroup size="sm" max={2} spacing="-0.5rem" mr={2}>
            {avatars.map((avatar, i) => (
              <Avatar key={i} src={avatar} />
            ))}
          </AvatarGroup>

          <Text
            color={isSelected && mode('blue.500', 'blue.100')}
            fontSize="sm"
            fontWeight="normal"
            w="100%"
            textAlign="center"
            isTruncated
          >
            {avatars.length} total lead{avatars.length > 1 ? 's' : ''}
          </Text>
        </Box>
      )}
    </Stack>
  )
}

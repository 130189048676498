import React from 'react'
import {
  useColorModeValue as mode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
  Flex,
} from '@chakra-ui/react'
import { HiInformationCircle } from 'react-icons/hi'

export const InfoTooltip = ({ infoText, textProps, placement }) => {
  return (
    <Popover
      placement={placement ?? 'left'}
      zIndex="9999999999999"
      trigger="hover"
    >
      <PopoverTrigger>
        <HiInformationCircle color="#D6D6D6" size={15} />
      </PopoverTrigger>
      <PopoverContent zIndex="9999999999999" w="100%" p={3} border="none">
        <Flex>
          <HiInformationCircle color="#D6D6D6" size={15} />
          <Text
            ml="2"
            fontSize="14px"
            color={mode('black', 'white')}
            {...textProps}
          >
            {infoText}
          </Text>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

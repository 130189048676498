import { chakra, Box, Text } from '@chakra-ui/react'

import SearchFilter from './SearchFilter'

export default function TableFilters({
  filters,
  setFilter,
  placeholders,
  ...rest
}) {
  return (
    <Box d="flex" alignItems="center" justifyContent="flex-start" {...rest}>
      {Object.entries(filters).map(([type, value], index) => (
        <SearchFilter
          key={type}
          type={type}
          value={value}
          setFilter={setFilter}
          placeholders={placeholders}
          mr={Object.entries(filters).length - 1 === index ? 0 : 2}
        />
      ))}
    </Box>
  )
}

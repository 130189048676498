import {
  Avatar,
  Box,
  Button,
  chakra,
  Collapse,
  Container,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import LoginButton from 'components/LoginButton'
import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import api from 'services/api'
import { getQueryStringValue } from 'utils/getQueryStringValue'

export default function Home() {
  const { state } = useLocation()
  const { register, signed, loading } = useAuth()

  // const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isWaitlistOpen,
    onOpen: onWaitlistOpen,
    onClose: onWaitlistClose,
  } = useDisclosure()
  const toast = useToast()

  const [isVerifyingCodeLoading, setIsVerifyingCodeLoading] = useState(false)
  const [code, setCode] = useState('')
  const [inviter, setInviter] = useState(null)

  const [isRegisterLoading, setIsRegisterLoading] = useState(false)

  useEffect(() => {
    const inviteCodeFromQueryString = getQueryStringValue({
      value: 'inviteCode',
    })
    if (inviteCodeFromQueryString) {
      setCode(inviteCodeFromQueryString.substring(0, 5))
    }
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = '//embed.typeform.com/next/embed.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const handleGetIn = async () => {
      setIsVerifyingCodeLoading(true)

      try {
        const { data } = await api.get(`/invite?inviteCode=${code}`)
        setInviter(data)
      } catch ({ response }) {
        const description = response?.data?.message ?? 'Something went wrong'

        toast({
          title: 'Error',
          description: description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }

      setIsVerifyingCodeLoading(false)
    }

    if (code.length === 5) {
      handleGetIn()
    }
  }, [code])

  if (!loading && signed) {
    return <Redirect to={state ? state.from : '/dashboard'} />
  }

  return (
    <Container maxW="container.md">
      <Box
        textAlign="center"
        mb={12}
        d="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image src="/logo-transparent.png" h="65px" mb={8} />
        <Heading mb={6} size="3xl" color="white">
          The YC Bookface
          <br /> for everyone.
        </Heading>
        <Text mb={12} fontSize="xl" color="grayAlpha.5">
          Get warm introductions to top investors, talent and early customers
          that founders in your community have access to and recommend.
        </Text>
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Collapse in={inviter}>
            {inviter && (
              <Text
                mb={4}
                d="flex"
                justifyContent="center"
                alignItems="center"
                color="green.300"
              >
                Congrats! You've been invited by{' '}
                {inviter?.avatar && (
                  <Avatar
                    name={`${inviter?.firstname} ${inviter?.lastname}`}
                    src={inviter?.avatar}
                    size="sm"
                    border="2px solid"
                    borderColor="green.300"
                    mx={1}
                  />
                )}
                <chakra.span fontWeight="bold">
                  {inviter?.firstname} {inviter?.lastname}
                </chakra.span>
                , {inviter?.headline.split(',')[0]}
              </Text>
            )}
          </Collapse>

          <InputGroup size="lg" mb={3} w="21rem">
            <Input
              bg="grayAlpha.700"
              borderColor="grayAlpha.700"
              color="white"
              p={6}
              textAlign="center"
              maxLength="5"
              fontSize="3xl"
              fontWeight="bold"
              letterSpacing="5px"
              pr="5rem"
              type="text"
              size="lg"
              h="60px"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              borderRadius="3xl"
              textTransform="uppercase"
              placeholder="_____"
              _placeholder={{
                color: 'grayAlpha.50',
                letterSpacing: '20px',
              }}
            />
            <InputRightElement width="6rem" pr="0.75rem" h="100%">
              <LoginButton
                customButton={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={!inviter || renderProps.disabled}
                    type="submit"
                    variant="village"
                    h="40px"
                    isLoading={isVerifyingCodeLoading || isRegisterLoading}
                  >
                    Get in.
                  </Button>
                )}
                customCallback={async (googleCode) => {
                  setIsRegisterLoading(true)
                  await register({ code: googleCode, invite: inviter.code })
                  setIsRegisterLoading(false)
                }}
              />
            </InputRightElement>
          </InputGroup>

          <Text fontSize="sm" color="grayAlpha.50">
            Already registered?{' '}
            <LoginButton
              customButton={(renderProps) => (
                <chakra.a
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  textDecor="underline"
                  cursor="pointer"
                >
                  Login
                </chakra.a>
              )}
            />
          </Text>

          <Text fontSize="sm" color="turquoise.500">
            Need early access?{' '}
            <chakra.a
              textDecor="underline"
              cursor="pointer"
              fontWeight="bold"
              data-tf-popup="X8m5vRRB"
              data-tf-hide-headers
              data-tf-iframe-props="title=Startup Community - Request Access"
              data-tf-medium="snippet"
            >
              I'm a startup community
            </chakra.a>{' '}
            |{' '}
            <chakra.a
              textDecor="underline"
              cursor="pointer"
              fontWeight="bold"
              data-tf-popup="lw1Yid3A"
              data-tf-hide-headers
              data-tf-size="70"
              data-tf-iframe-props="title=My typeform"
              data-tf-medium="snippet"
            >
              I'm a founder
            </chakra.a>
          </Text>
        </Box>
      </Box>

      {/* <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent bg="grayAlpha.700">
          <ModalBody textAlign="center" p={4}>
            <Box w="100%" d="flex" justifyContent="center" alignItems="center">
              <LoginButton
                customButton={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    variant="village"
                    size="lg"
                    rightIcon={<FaArrowRight />}
                    isLoading={isRegisterLoading}
                  >
                    Join with Google
                  </Button>
                )}
                customCallback={async (googleCode) => {
                  setIsRegisterLoading(true)
                  await register({ code: googleCode, invite: inviter.code })
                  setIsRegisterLoading(false)
                }}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}

      {/* <Waitlist isOpen={isWaitlistOpen} onClose={onWaitlistClose} />  */}
    </Container>
  )
}

import React from 'react'
import {
  useColorModeValue as mode,
  Text,
  Flex,
  Box,
  Checkbox,
  Avatar,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { encryptId } from 'utils/encryption'
import RelationshipStrength from 'components/RelationshipStrength'
import { AiOutlineClose } from 'react-icons/ai'
import { ProfilePageModal } from 'components/Profile/ProfilePageModal'

export const SharedRow = ({
  selected,
  name,
  currPosition,
  currCompany,
  headline,
  onChange,
  warmthScore,
  currCompanyId,
  avatar,
  alreadySelected,
  id,
}) => {
  const {
    isOpen: isProfileModalOpen,
    onOpen: onProfileModalOpen,
    onClose: onProfileModalClose,
  } = useDisclosure()
  const {
    isOpen: isCompanyProfileModalOpen,
    onOpen: onCompanyProfileModalOpen,
    onClose: onCompanyProfileModalClose,
  } = useDisclosure()

  const renderSubtitle = () => {
    if (currCompany) {
      return (
        <Text as="span">
          {currPosition} at{' '}
          <Text
            decoration="underline"
            sx={{ textUnderlineOffset: 2 }}
            as="a"
            href={`/company/profile/${encryptId(currCompanyId)}`}
            onClick={(e) => {
              e.preventDefault()
              onCompanyProfileModalOpen()
            }}
            target="_blank"
          >
            {currCompany}
          </Text>
        </Text>
      )
    }

    return (
      <Text isTruncated as="span">
        {headline}
      </Text>
    )
  }

  return (
    <Flex mb="4" alignItems="center" sx={{ gap: selected ? 14 : 0 }}>
      {!alreadySelected && (
        <Checkbox mt="1" size="lg" isChecked={selected} onChange={onChange} />
      )}
      <Avatar ml={selected ? '0' : '4'} size="md" src={avatar} name={name} />
      <Box ml={selected ? '0' : '3.5'} isTruncated>
        <Text
          as="a"
          d="block"
          href={`/people/profile/${encryptId(id)}`}
          onClick={(e) => {
            e.preventDefault()
            onProfileModalOpen()
          }}
          _hover={{
            textDecoration: 'underline',
            textUnderlineOffset: '2px',
          }}
          target="_blank"
          fontWeight="bold"
        >
          {name}
        </Text>
        {renderSubtitle()}
      </Box>
      {alreadySelected ? (
        <IconButton
          onClick={onChange}
          bg="transparent"
          ml="auto"
          size="sm"
          icon={<AiOutlineClose size="24" />}
        />
      ) : (
        <RelationshipStrength
          ml="auto"
          relationship={{ warmth_score: warmthScore }}
        />
      )}
      <ProfilePageModal
        UserProfile
        profileToVisit={encryptId(id)}
        isOpen={isProfileModalOpen}
        onClose={onProfileModalClose}
      />
      <ProfilePageModal
        profileToVisit={encryptId(currCompanyId)}
        isOpen={isCompanyProfileModalOpen}
        onClose={onCompanyProfileModalClose}
      />
    </Flex>
  )
}

import {
  Box,
  Button,
  Collapse,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import Typeahead from 'components/Typeahead'
import { useEffect, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import api from 'services/api'

export default function RequestIntroFromHeader({
  options,
  introducer,

  setIntroducer,
  chosenAccount,
  setChosenAccount,

  accounts,
  isFetchingAccounts,
}) {
  const [identityOptions, setIdentityOptions] = useState([])

  const handelSearchOptions = async (inputValue) => {
    try {
      const { data } = await api.get('/accounts?q=' + inputValue)
      setIdentityOptions(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handelSearchOptions('a')
  }, [])

  return (
    <Box>
      <Text fontSize="2xl" mb={2} fontWeight="bold">
        Who are you requesting an intro from?
      </Text>

      <Typeahead
        inputStyle={{
          bg: mode('gray.50', 'grayAlpha.800'),
          p: 4,
          borderRadius: 'md',
        }}
        firstOptions={options.map((option) => ({
          ...option,
          title: option.name,
          firstOptions: true,
        }))}
        options={identityOptions.map((option) => ({
          ...option,
          name: `${option.firstname} ${option.lastname}`,
          title: `${option.firstname} ${option.lastname}`,
          subtitle: option?.account_identifier.substring(2),
        }))}
        chooseOption={(option) => {
          if (option.firstOptions) {
            if (option.identity_id === introducer.identity_id) return
            setChosenAccount('')
            setIntroducer(option)
          } else {
            setIntroducer(option)
            setChosenAccount(option?.account_identifier.substring(2))
          }
        }}
        fetchOptions={handelSearchOptions}
      />

      <Collapse in={introducer && !introducer.firstOptions}>
        <Box
          w="100%"
          d="flex"
          alignItems="center"
          p={2}
          mt={2}
          borderRadius="md"
        >
          <Text mr={2}>Request will be sent to:</Text>

          <Text fontWeight="normal" isTruncated>
            {chosenAccount}
          </Text>
        </Box>
      </Collapse>

      <Collapse in={introducer.firstOptions}>
        <Box
          w="100%"
          d="flex"
          alignItems="center"
          p={2}
          mt={2}
          borderRadius="md"
        >
          <Text mr={2}>Request will be sent to:</Text>
          {Boolean(introducer.identity_id) &&
            !isFetchingAccounts &&
            Boolean(accounts.length) && (
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  rightIcon={<FaChevronDown />}
                  variant="unstyled"
                  d="flex"
                  alignItems="center"
                >
                  <Text fontWeight="normal" isTruncated>
                    {chosenAccount}
                  </Text>
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    defaultValue="asc"
                    title="Introducer's email"
                    type="radio"
                    value={chosenAccount}
                  >
                    {accounts.map((ea, eaIndex) => (
                      <MenuItemOption
                        key={eaIndex}
                        value={ea.substring(2)}
                        onClick={() => setChosenAccount(ea.substring(2))}
                      >
                        {ea.substring(2)}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            )}
          {Boolean(introducer.identity_id) && isFetchingAccounts && <Spinner />}
          {Boolean(introducer.identity_id) &&
            !isFetchingAccounts &&
            !accounts.length && (
              <Box fontSize="sm" ml={2}>
                <Text>You've no emails from {introducer.name}.</Text>
                <Text>Please search another contact.</Text>
              </Box>
            )}
        </Box>
      </Collapse>
    </Box>
  )
}

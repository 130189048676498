const colors = {
  almostBlack: '#000',
  lightBlack: '#000',
  almostWhite: '#FFF',
  white: '#FFF',
  white10: 'rgba(255, 255, 255, 0.1)',
  black: '#000',
  black10: 'rgba(0, 0, 0, 0.1)',
  primary: '#1AB6FF',
  greyLight: '#F4F7FA',
  grey: '#E8EBED',
  greyMid: '#C5CCD3',
  greyDark: '#DAE1E9',
}

export const base = {
  ...colors,
  fontFamily:
    "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen, Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif",
  fontFamilyMono:
    "'SFMono-Regular',Consolas,'Liberation Mono', Menlo, Courier,monospace",
  fontWeight: 400,
  zIndex: 999999,
  link: colors.primary,
  placeholder: '#A4A4A4',
  textSecondary: '#FFF',
  textLight: colors.white,
  textHighlight: '#b3e7ff',
  selected: colors.primary,
  codeComment: '#6a737d',
  codePunctuation: '#5e6687',
  codeNumber: '#d73a49',
  codeProperty: '#c08b30',
  codeTag: '#3d8fd1',
  codeString: '#032f62',
  codeSelector: '#6679cc',
  codeAttr: '#c76b29',
  codeEntity: '#22a2c9',
  codeKeyword: '#d73a49',
  codeFunction: '#6f42c1',
  codeStatement: '#22a2c9',
  codePlaceholder: '#3d8fd1',
  codeInserted: '#202746',
  codeImportant: '#c94922',

  blockToolbarBackground: colors.white,
  blockToolbarTrigger: colors.greyMid,
  blockToolbarTriggerIcon: colors.white,
  blockToolbarItem: colors.almostBlack,
  blockToolbarText: colors.almostBlack,
  blockToolbarHoverBackground: colors.greyLight,
  blockToolbarDivider: colors.greyMid,

  noticeInfoBackground: '#F5BE31',
  noticeInfoText: colors.almostBlack,
  noticeTipBackground: '#9E5CF7',
  noticeTipText: colors.white,
  noticeWarningBackground: '#FF5C80',
  noticeWarningText: colors.white,
}

export const light = {
  ...base,
  background: 'transparent',
  text: colors.almostBlack,
  code: colors.lightBlack,
  cursor: colors.black,
  divider: colors.white,
  textSecondary: '#A4A4A4',

  toolbarBorder: `1px solid ${colors.lightBlack}`,
  toolbarBackground: colors.black,
  toolbarInput: colors.black,
  toolbarItem: colors.white,

  tableDivider: colors.greyMid,
  tableSelected: colors.primary,
  tableSelectedBackground: '#E5F7FF',
  divier: 'black',

  quote: colors.greyDark,
  codeSelector: colors.white,
  codeAttr: colors.white,
  codeBackground: '#C4C4C4',
  codeBorder: '#C4C4C4',
  codeString: colors.white,

  horizontalRule: colors.greyMid,
  imageErrorBackground: colors.greyLight,
}

export const dark = {
  ...base,
  background: 'transparent',
  text: colors.almostWhite,
  code: colors.almostWhite,
  cursor: colors.white,
  divider: 'black',
  placeholder: '#A4A4A4',
  textSecondary: colors.white,

  toolbarBorder: `1px solid ${colors.greyLight}`,
  toolbarBackground: colors.white,
  toolbarInput: colors.white,
  toolbarItem: colors.black,

  tableDivider: colors.white,
  tableSelected: colors.primary,
  tableSelectedBackground: '#002333',

  quote: colors.greyDark,
  codeSelector: colors.white,
  codeAttr: colors.white,
  codeBackground: colors.black,
  codeBorder: colors.black,
  codeString: colors.white,
  horizontalRule: colors.lightBlack,
  imageErrorBackground: 'rgba(0, 0, 0, 0.5)',
}

export const steps = [
  {
    title: 'Step 1',
    description: 'Select introducer',
  },
  {
    title: 'Step 2',
    description: 'Add reasons to connect',
  },
  {
    title: 'Step 3',
    description: 'Review and confirm',
  },
]

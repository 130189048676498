import { Box, useColorModeValue as mode } from '@chakra-ui/react'
import { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import TimeSlot from './TimeSlot'

const availability = [
  {
    startTime: '18:00',
    endTime: '19:00',
  },
  {
    startTime: '18:00',
    endTime: '20:00',
  },
  {
    startTime: '18:00',
    endTime: '21:00',
  },
  {
    startTime: '18:00',
    endTime: '22:00',
  },
  {
    startTime: '18:00',
    endTime: '23:00',
  },
  false,
  false,
]

const slotsSize = 30

const generateTimeSlots = (availability) => {
  const { startTime, endTime } = availability

  const timeStart = new Date('01/01/2007 ' + startTime).getHours()
  const timeEnd = new Date('01/01/2007 ' + endTime).getHours()

  const timeDifference = timeEnd - timeStart

  const timeSlots = (timeDifference * 60) / slotsSize

  const timeSlotsArray = []
  for (let i = 0; i < timeSlots; i++) {
    const startTimeObj = new Date('01/01/2007 ' + startTime)

    const newDateObj = new Date(startTimeObj.getTime() + slotsSize * i * 60000)

    let hours = newDateObj.getHours()
    let minutes = newDateObj.getMinutes()
    let ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm

    timeSlotsArray.push(strTime)
  }
  return timeSlotsArray
}

export default function Scheduler() {
  const [value, onChange] = useState(new Date())

  const disabledDays = availability.reduce((acc, curr, idx) => {
    if (curr === false) {
      acc.push(idx === 6 ? 0 : idx + 1)
    }
    return acc
  }, [])

  const valueDay = value.getDay()

  const availableTimes = availability[valueDay === 0 ? 6 : valueDay - 1]

  const slots = generateTimeSlots(availableTimes)

  return (
    <Box w="fit-content" bg={mode('grayAlpha.0', 'grayAlpha.900')} p={6}>
      <Calendar
        className="calendar"
        calendarType="ISO 8601"
        onChange={onChange}
        value={value}
        minDate={new Date()}
        tileDisabled={({ date }) => disabledDays.includes(date.getDay())}
      />

      {slots.map((slot, idx) => (
        <TimeSlot key={idx} time={slot} />
      ))}
    </Box>
  )
}

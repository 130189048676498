import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import ConnectionDegree from './ConnectionDegree'
import ContactButton from './ContactButton'

export default function ProfileCard({ contact, loading }) {
  return (
    <Center py={6}>
      <Box
        minW={'300px'}
        maxW={'320px'}
        w={'full'}
        bg={'white'}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}
      >
        <Avatar size={'xl'} mb={4} src={contact.avatar ? contact.avatar : ''} />
        <Heading fontSize={'2xl'} fontFamily={'body'} mb={2}>
          {contact.firstname} {contact.lastname}
        </Heading>

        <ConnectionDegree degree={'first'} />

        <Text textAlign={'center'} color={'black'} px={3}>
          {contact?.headline ?? <i>No headline</i>}
        </Text>

        <Stack mt={8} spacing={4}>
          {contact.account_identifier.map((identifier, index) => {
            const type = contact.account_type[index]
            return (
              <ContactButton key={index} type={type} identifier={identifier} />
            )
          })}
        </Stack>
      </Box>
    </Center>
  )
}

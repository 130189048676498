import {
  Badge,
  Box,
  Divider,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import Link from 'components/Link'

export default function DesktopNav({ navItems, location }) {
  const networkViews = ['/people', '/companies', '/jobs']

  return (
    <Stack direction={'row'} spacing={4}>
      {navItems.map((navItem) => (
        <>
          {' '}
          {navItem.label === 'Boards' && (
            <Divider orientation="vertical" h="1.5rem" />
          )}{' '}
          <Box key={navItem.label}>
            <Link
              p={2}
              to={navItem.href ?? '#'}
              color={mode('black', 'white')}
              _hover={{
                bg: mode('gray.50', 'grayAlpha.700'),
              }}
              fontWeight={
                navItem.label === 'Search Network' &&
                networkViews.includes(location.pathname)
                  ? 'bold'
                  : 'normal'
              }
            >
              {navItem.label}
              {navItem.isBeta && (
                <Badge
                  bg="black"
                  color="white"
                  borderRadius="full"
                  fontSize="x-small"
                  ml={2}
                >
                  BETA
                </Badge>
              )}
            </Link>
          </Box>
        </>
      ))}
    </Stack>
  )
}

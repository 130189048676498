import { useEffect, useState } from 'react'
import { useRef } from 'react'

var points = []
var velocity2 = 5
var radius = 5
var boundaryX = 85
var boundaryY = 85
var numberOfPoints = 15

export default function GraphLoader() {
  const graphLoaderRef = useRef(null)
  const [context, setContext] = useState(null)

  useEffect(() => {
    const graphLoaderContext = graphLoaderRef.current.getContext('2d')
    console.log('graphLoaderContext', graphLoaderContext)
    setContext(graphLoaderContext)
  }, [])

  useEffect(() => {
    if (context) {
      console.log('has context', context)
      init()
    }
  }, [context])

  function init() {
    // create points
    for (var i = 0; i < numberOfPoints; i++) {
      createPoint()
    }
    // create connections
    for (var i = 0, l = points.length; i < l; i++) {
      var point = points[i]
      if (i == 0) {
        points[i].buddy = points[points.length - 1]
      } else {
        points[i].buddy = points[i - 1]
      }
    }

    // animate
    animate()
  }

  function createPoint() {
    var point = {},
      vx2,
      vy2
    point.x = Math.random() * boundaryX
    point.y = Math.random() * boundaryY
    // random vx
    point.vx = (Math.floor(Math.random()) * 2 - 1) * Math.random()
    vx2 = Math.pow(point.vx, 2)
    // vy^2 = velocity^2 - vx^2
    vy2 = velocity2 - vx2
    point.vy = Math.sqrt(vy2) * (Math.random() * 2 - 1)
    points.push(point)
  }

  function resetVelocity(point, axis, dir) {
    var vx, vy
    if (axis == 'x') {
      point.vx = dir * Math.random()
      vx = Math.pow(point.vx, 2)
      // vy^2 = velocity^2 - vx^2
      vy = velocity2 - vx
      point.vy = Math.sqrt(vy) * (Math.random() * 2 - 1)
    } else {
      point.vy = dir * Math.random()
      vy = Math.pow(point.vy, 2)
      // vy^2 = velocity^2 - vx^2
      vx = velocity2 - vy
      point.vx = Math.sqrt(vx) * (Math.random() * 2 - 1)
    }
  }

  function drawCircle(x, y) {
    context.beginPath()
    context.arc(x, y, radius, 0, 2 * Math.PI, false)
    context.fillStyle = '#38A169'
    context.fill()
  }

  function drawLine(x1, y1, x2, y2) {
    context.beginPath()
    context.moveTo(x1, y1)
    context.lineTo(x2, y2)
    context.strokeStyle = '#38A169'
    context.stroke()
  }

  function draw() {
    for (var i = 0, l = points.length; i < l; i++) {
      // circles
      var point = points[i]
      point.x += point.vx
      point.y += point.vy
      drawCircle(point.x, point.y)
      // lines
      drawLine(point.x, point.y, point.buddy.x, point.buddy.y)
      // check for edge
      if (point.x < 0 + radius) {
        resetVelocity(point, 'x', 1)
      } else if (point.x > boundaryX - radius) {
        resetVelocity(point, 'x', -1)
      } else if (point.y < 0 + radius) {
        resetVelocity(point, 'y', 1)
      } else if (point.y > boundaryY - radius) {
        resetVelocity(point, 'y', -1)
      }
    }
  }

  function animate() {
    context.clearRect(0, 0, 85, 85)
    draw()
    requestAnimationFrame(animate)
  }

  return (
    <canvas
      ref={graphLoaderRef}
      style={{
        width: '85px',
        height: '85px',
        margin: 'auto',
        transform: 'rotate(45deg)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      width="85"
      height="85"
    />
  )
}

import React from 'react'
import { Box } from '@chakra-ui/react'
import { SunsamaEditor } from './SunsamaEditor'

export const OpportunityModalStepTwo = ({ editorState, setEditorState }) => {
  return (
    <Box h="87vh">
      <Box pb="16" mt="2">
        <SunsamaEditor
          autoFocus
          defaultValue={editorState}
          onChange={setEditorState}
          placeholder={`Use this space to explain more about the opportunity and who should apply. ${'\n'}You can use the following shortcuts: Type '/' to insert tables, headers, bullets and more`}
        />
      </Box>
    </Box>
  )
}

import { chakra, Container, Heading, Text } from '@chakra-ui/react'

export default function Privacy() {
  return (
    <Container as="section" maxW="container.md" py={8} color="white">
      <Heading as="h1" mb={3} textAlign="center">
        Privacy Policy
      </Heading>
      <Text mb={5} textAlign="center">
        Last updated: 16 November, 2021
      </Text>
      <Text mb={5}>
        This Privacy Policy describes the policies of Village.do regarding the
        information we collect or receive about our customers. It is applicable
        to the Village.do website and other interactive features, applications,
        services, or downloads that are operated by Village.do and which are
        available through the websites produced and maintained by us
        (“Website”). We have established this security and privacy policy
        (“Privacy Policy”) to let you know the kinds of personal information we
        may gather, why we gather your information, what we use your personal
        information for, when we might disclose your personal information, and
        how you can manage your personal information.
      </Text>
      <Text mb={5}>
        Along with the personal information which we gather or which you provide
        to us, this Privacy Policy describes our policy regarding files which
        you upload to or store on our servers (“Customer Data”).
      </Text>
      <Text mb={5}>
        When you provide personal information and/or Customer Data, you are
        consenting to the manner in which the Village will collect, use,
        disclose and otherwise manage your personal information and Customer
        Data, as set out in this Privacy Policy.
      </Text>
      <Text mb={5}>
        If you do not agree to the terms of this Privacy Policy, please do not
        use the Website, software, or any of our mobile applications. We reserve
        the right to modify or amend the terms of our Privacy Policy from time
        to time without notice.
      </Text>
      <Text mb={5}>
        If you have any questions about this Privacy Policy or don’t see your
        concerns addressed here, you should contact us by email at
        info@village.do
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        What information about me is collected and stored?
      </Heading>
      <Text mb={5}>
        Village’s use of information received from Google APIs will adhere to
        the{' '}
        <chakra.a
          color="blue.500"
          textDecoration="underline"
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          target="_blank"
        >
          Google API Services User Data Policy
        </chakra.a>
        , including the Limited Use requirements. To deliver relationship
        intelligence, we are required to store some of the data synced from your
        Google account. This data is mainly used to infer relationship strength
        between you and your contacts, and to help you save time such as when
        scheduling meetings. To protect your privacy, this data is not stored or
        presented to be made human-readable except for use-cases meant strictly
        for you and related to your workflow, such as looking at previous
        interactions you’ve had with a contact.
      </Text>
      <Text mb={5}>
        One of the basic principles we’ve tried to follow in designing this
        Website is that we ask for only the information we need to provide the
        service you’ve requested. As a result, what information we collect and
        store depends almost entirely on how you choose to make use of our
        Website and our services. In addition to information which you
        explicitly and knowingly provide, some information is collected
        incidentally such as:
      </Text>
      <Text mb={5}>
        Our website uses browser cookies in order to gather information on usage
        patterns. Our software may, with your permission, send us statistics
        about its use (see below). Our servers maintain industry-standard logs
        of their activity: web page visits, including IP address, Referrer:
        header, and user-agent; envelope information of e-mail sent and
        received; and the like.
      </Text>{' '}
      <Heading as="h2" fontSize="lg" mb={1}>
        How do we use the information we collect?
      </Heading>
      <Text mb={5}>
        We may use your information to provide you with important information
        about the product or service that you are using, including critical
        updates and notifications. In general, we do not use your information
        beyond the purpose for which you provided it to us. For example, if you
        ask us a question by email, or subscribe to a mailing list, we will not
        send you product announcements or other promotional communications in
        response (unless that is what you requested). We do not sell, barter, or
        rent your personal information.
      </Text>
      <Text mb={5}>
        We do use your information internally to understand how people use our
        website, services, and software and to make improvements.
      </Text>
      <Text mb={5}>
        In the event we wish to publicly post any of your personal information
        on a Website (as, for instance, a letter), we will contact you with
        details and ask for your express permission to publish that information
        and will not publish without your express permission.
      </Text>
      <Text mb={5}>
        If you have subscribed to any of our newsletter you may unsubscribe at
        any time by following the instructions located within each newsletter
        and on our website, or by contacting us. We gather general non-specific
        statistics about Customer Data that will help us improve the service,
        such as the amount of storage you are consuming on our servers,
        user-agent information sent in HTTP headers, or overall usage patterns.
        In rare cases we may examine metadata such as file names or
        synchronization client state in order to understand how the service is
        performing.
      </Text>
      <Text mb={5}>
        We occasionally have third party agents, subsidiaries, affiliates and
        partners that perform functions on our behalf, such as marketing,
        analytics, credit card processing, shipping or stocking orders, fraud
        protection, etc. These entities have access to personal information only
        to the extent needed to perform their functions. They are contractually
        obligated to maintain the confidentiality and security of that personal
        information and are prohibited from using that information for any other
        purpose.
      </Text>
      <Text mb={5}>
        If you provide information to us for the purpose of making it public
        (for example, on a mailing list, blog, or forum that we operate), we
        cannot control what other parties may do with that information once it
        is public. Please be careful about what you disclose and do not post any
        information that you expect to keep private.
      </Text>
      <Text mb={5}>
        We may provide aggregate, anonymous statistical information derived from
        information we collect to other organizations or to the public. Any
        information we share in this manner will not identify you personally.
      </Text>
      <Text mb={5}>
        We may also use or disclose personal information or Customer Data if
        required to do so by law or in the good-faith belief that such action is
        necessary (a) to conform to applicable law or comply with legal process
        served on us or the Website; (b) to protect and defend our rights or
        property, the Website or our users, or (c) under emergency circumstances
        to protect the personal safety of us, our affiliates, agents, the users
        of the Website, or the public. In cases where we must disclose personal
        information or Customer Data, we will send email to the address
        registered to any accounts affected by that disclosure.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        How long do we retain the information we collect?
      </Heading>
      <Text mb={5}>
        Most information we collect may be stored indefinitely. If you provide
        your credit card information, we do of course transmit the entire credit
        card number to the appropriate credit card company during order
        processing using commercially reasonable encryption methods. In
        addition, we will store the expiration date and last four digits of the
        credit card number in case you contact us with a billing question or we
        need to apply for a refund or credit. We do not store the full credit
        card number or the card security code you enter in the payment form.
      </Text>
      <Text mb={5}>
        We maintain backups of Customer Data in order to recover from hardware
        failures or other problems. These backups are not kept longer than six
        months.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        What steps are taken to keep personal information and customer data
        protected?
      </Heading>
      <Text mb={5}>
        We are committed to protecting your personal information and Customer
        Data. We use a variety of technologies and procedures to help protect
        your personal information and Customer Data from unauthorized access,
        use, or disclosure.
      </Text>
      <Text mb={5}>
        Personal information we collect or receive and Customer Data you upload
        is stored in protected operating environments that are not available to
        the public and, when transmitted, is protected using strong
        cryptography. Our public-facing servers are periodically reviewed for
        known security issues by a third party.
      </Text>
      <Text mb={5}>
        We limit access to your personal information or Customer Data to only
        our employees and third parties who need access for purposes described
        in this Privacy Policy. They are contractually obligated to maintain the
        confidentiality and security of that personal information, are
        prohibited from using that information for any other purpose, and are
        subject to disciplinary action, including possible termination and civil
        and/or criminal prosecution, for failing to do so.
      </Text>
      <Text mb={5}>
        If a password is used to help protect your accounts and personal
        information and Customer Data, it is your responsibility to keep your
        password confidential. Do not share this information with anyone. If you
        are sharing a computer with anyone you should always log out before
        leaving a Website or service to protect access to your personal
        information and Customer Data from subsequent users.
      </Text>
      <Text mb={5}>
        While we use reasonable security measures to safeguard your personal
        information and Customer Data once we receive it, no transmission of
        data over the Internet or any other public network can be guaranteed to
        be 100% secure. You should know that no company, including the Village,
        can fully eliminate security risks associated with personal information
        or Customer Data. Accordingly, the Village cannot ensure or warrant the
        security of any information you transmit to us on our Sites.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Restrictions to use the services
      </Heading>
      <Text mb={5}>
        We do not sell or ship any items ordered through this Website directly
        to anyone who we know to be under the age of 13, nor do we collect any
        personal information from anyone who we know to be under the age of 13.
        If you are under the age of 13, you should use this Website only with
        the involvement and consent of a parent or guardian and should not
        submit any personal information to us. Our Website is not intentionally
        targeted to children under the age of 13.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Passwords
      </Heading>
      <Text mb={5}>
        To use some features of this Website, or our software, you may be asked
        to set up an account and provide a functional email address and
        password. You should be careful about providing your password to others
        – you are responsible for the actions of anyone you’ve shared your
        password with. No one at the Village will ask for your password; if they
        do, you should not provide the information and should email
        info@Village.do to report the incident so we can investigate.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Governing Law/Assignment
      </Heading>
      <Text mb={5}>
        The Website is published in California. California law shall govern in
        any and all disputes, including privacy or defamation issues or
        otherwise.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Changes to this Policy
      </Heading>
      <Text mb={5}>
        As our company and Website continues to develop, we may add new services
        and features to our Website or products. If these additions affect our
        Privacy Policy, this document will be updated appropriately. We will
        post those changes prominently so that you will always know what
        information we gather, how we might use that information and whether we
        will disclose it to anyone. We do, however, recommend that you read this
        Privacy Policy periodically in case you missed any changes to the
        Privacy Policy.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Opt-Out and correction procedures
      </Heading>
      <Text mb={5}>
        If you have previously opted to subscribe to a mailing list, you may
        always opt-out of receiving future e-mail messages and newsletters by
        ending your subscription. Unsubscribe instructions are located within
        each newsletter and on our website, or you may contact our Customer
        Service Department.
      </Text>
      <Text mb={5}>
        We will help you to correct or update personal information that you have
        previously provided to us. To do so, you can access, correct and update
        certain personal information that you have provided to us by emailing
        info@village.do
      </Text>
      <Text mb={5}>
        Questions regarding this statement should be directed to info@village.do
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        Unsolicited Idea Submission Policy
      </Heading>
      <Text mb={5}>
        The most powerful tool that we have in our effort to make our products
        better is the feedback from our users. This means you! We encourage
        folks to send us bug reports, questions and ideas about how to make our
        existing products fit their needs more closely.
      </Text>
      <Text mb={5}>
        While we also encourage folks to send us their ideas about new products,
        we must make it clear that any product idea submitted to Village, either
        by word of mouth, email, telephone or other means immediately becomes
        the property of Village. This means that if we develop your idea or an
        idea similar to yours, you will not be compensated and it also means
        that we can use the idea for any purpose, including giving it away.
      </Text>
    </Container>
  )
}

import { useEffect, useRef, useState } from 'react'

function useTimeoutFunction() {
  const firstRender = useRef(true)
  const [timeoutId, setTimeoutId] = useState(null)

  useEffect(() => {
    firstRender.current = false
  }, [])

  const timeoutFunction = (callbackFn, seconds = 1000) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const timeout = setTimeout(callbackFn, seconds)
    setTimeoutId(timeout)
  }

  return {
    isFirstRender: firstRender.current,
    timeoutFunction,
  }
}

export default useTimeoutFunction

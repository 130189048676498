import {
  Stack,
  StackDivider,
  useColorModeValue as mode,
} from '@chakra-ui/react'

export const StackWithDivider = ({ children, ...styleProps }) => {
  return (
    <Stack
      divider={
        <StackDivider borderColor={mode('gray.200', 'whiteAlpha.200')} />
      }
      {...styleProps}
    >
      {children}
    </Stack>
  )
}

import React, { useEffect, useState } from 'react'
import { CompanyProfileView } from './CompanyProfileView'
import { useToast } from '@chakra-ui/react'
import { ProfileSkeleton } from 'components/Profile/ProfileSkeleton'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'
import { useParams } from 'react-router-dom'
import { decryptId } from 'utils/encryption'

export const CompanyProfileViewContainer = ({
  profileToVisit,
  boardIdToOpen,
}) => {
  const { id } = useParams()

  const getCompanyIdenfitier = () => {
    if (decryptId(profileToVisit) && decryptId(profileToVisit).length > 0) {
      return decryptId(profileToVisit)
    }

    if (profileToVisit) return profileToVisit

    if (decryptId(id) && decryptId(id).length > 0) return decryptId(id)

    return id
  }

  const companySlug = getCompanyIdenfitier()

  const [errors, setErrors] = useState({})

  const [profileData, setProfileData] = useState({})

  const [loading, setLoading] = useState(true)

  const [activeBoard, setActiveBoard] = useState(boardIdToOpen ?? 'priv')

  const [note, setNote] = useState('')

  const { user } = useAuth()

  const toast = useToast()

  const handleInputChange = (e) => {
    e.preventDefault()
    setNote(e.target.value)
  }

  const [sendNoteLoading, setSendNoteLoading] = useState(false)

  const sendNote = async () => {
    try {
      const trimmedNote = note.trim()

      if (trimmedNote === '') {
        toast({
          title: 'You cannot add an empty note.',
          status: 'error',
        })
        setErrors((prevState) => ({
          ...prevState,
          sendNote: true,
        }))
        return
      }

      const task = profileData?.board_data.find(
        (task) => task.board_id === activeBoard
      )

      const taskId = task?.task_id

      const API_URL = activeBoard === 'priv' ? '/notes' : '/board/notes'

      const body = {
        companyId: profileData.company_info.id,
        note: trimmedNote,
        taskId,
      }
      setSendNoteLoading(true)

      const { data } = await api.post(API_URL, body)

      const newNote = data.note

      newNote.board_id = activeBoard
      newNote.note_id = newNote.id

      const newProfileData = {
        ...profileData,
      }

      if (activeBoard === 'priv') {
        Reflect.set(newProfileData, 'privateNotes', {
          notes: [newNote, ...profileData.privateNotes.notes],
        })
      } else {
        Reflect.set(newProfileData, 'board_data', [
          newNote,
          ...profileData.board_data,
        ])
      }

      setProfileData(newProfileData)
      setNote('')
      setSendNoteLoading(false)

      if (errors.sendNote) {
        setErrors((prevState) => ({
          ...prevState,
          sendNote: false,
        }))
      }
    } catch (e) {
      setSendNoteLoading(false)
      toast({
        title: 'An error occured while adding note.',
        status: 'error',
      })
    }
  }

  const deleteNote = async (noteId, taskId) => {
    try {
      const API_URL = activeBoard === 'priv' ? '/notes' : '/board/notes'

      const data = {
        noteId,
        taskId,
      }

      await api.delete(API_URL, { data })

      if (activeBoard !== 'priv') {
        const index = profileData.board_data.findIndex(
          (note) => note.note_id === noteId
        )
        const updatedNote = {
          note_id: null,
          task_id: taskId,
          board_id: activeBoard,
          board_title: profileData.board_data[index].board_title,
        }
        const boardDataCopy = [...profileData.board_data]
        boardDataCopy.splice(index, 1, updatedNote)
        setProfileData({ ...profileData, board_data: boardDataCopy })
        return
      }

      const newProfileData = {
        ...profileData,
        privateNotes: {
          notes: profileData.privateNotes.notes.filter(
            (note) => note.id !== noteId
          ),
        },
      }

      setProfileData(newProfileData)
    } catch (e) {
      toast({
        title: 'An error occured while deleting note.',
        status: 'error',
      })
    }
  }

  const fetchProfileInfo = async () => {
    setLoading(true)
    const introActivityRequest = api.get(
      `/intro/activity?company_slug=${companySlug}`
    )
    const companyInfoRequest = api.get(`/profile/company/${companySlug}`)

    const promises = [companyInfoRequest, introActivityRequest]

    const [companyInfoRes, activityInfoRes] = await Promise.allSettled(promises)

    let profileData = {}

    if (companyInfoRes.status === 'fulfilled') {
      const company = companyInfoRes.value.data

      if (user) {
        const privatesNotesRequest = await api.get(
          `/notes?companyId=${company.company_info.id}`
        )

        if (privatesNotesRequest.status === 200) {
          Reflect.set(profileData, 'privateNotes', privatesNotesRequest.data)
        }
      }

      profileData = {
        ...profileData,
        ...company,
      }
    } else {
      setErrors({ companyInfo: true })
      setLoading(false)
      return
    }

    if (activityInfoRes.status === 'fulfilled') {
      Reflect.set(profileData, 'introActivity', activityInfoRes.value.data)
    }

    setProfileData(profileData)

    setLoading(false)
  }

  useEffect(() => {
    fetchProfileInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySlug])

  if (loading) return <ProfileSkeleton />

  if ('companyInfo' in errors) {
    return 'An error occured.'
  }

  return (
    <CompanyProfileView
      deleteNote={deleteNote}
      sendNote={sendNote}
      sendNoteLoading={sendNoteLoading}
      activeBoard={activeBoard}
      setActiveBoard={setActiveBoard}
      note={note}
      handleInputChange={handleInputChange}
      profileData={profileData}
      errors={errors}
      slug={companySlug}
    />
  )
}

import { Container, Heading, List, ListItem, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function Terms() {
  return (
    <Container as="section" maxW="container.md" py={8} color="white">
      <Heading as="h1" mb={3} textAlign="center">
        Terms of Use
      </Heading>

      <Text mb={5}>
        Thanks for using Village. These Terms of Use (“Terms” or "Agreement")
        are a legal agreement between you and Village LLC (“Village,” “we,”
        “our,” or “us”) and govern your use of all the text, data, information,
        software, graphics, photographs and more that we and our affiliates may
        make available to you (all of which we refer to as “Materials”),
        including our websites and any services, plug-ins, software or other
        Downloadable Tools (as defined below) that we may provide through any of
        our websites (the “Platform” and together with the Materials, this
        “Website”). The terms "us" or "we" or "our" refer to Village, the owner
        of this Website. Village “Users” are the people who have created a
        Village account, A Village “Connection” is a person whose contact
        details or LinkedIn ID is stored inside the app based on your email ID,
        calendar or LinkedIn profile.
      </Text>
      <Text mb={5}>
        If you are entering into these Terms on behalf of a company or other
        organization, you hereby represent and warrant that you are authorized
        to enter into these Terms on behalf of such company or other
        organization.
      </Text>
      <Text mb={5}>
        READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE. USING THIS
        WEBSITE INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. YOU
        CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THESE TERMS. THESE TERMS
        CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING A
        CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND WITH
        RESPECT TO DISPUTES YOU MAY HAVE WITH Village.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        SCOPE
      </Heading>
      <Text mb={5}>
        Village’s Website analyzes your network from your email ID, LinkedIn
        profile or calendar. You may access and use the Website in accordance
        with these Terms. Please note that these Terms govern your use of the
        Website, including, but not limited to, the process through which your
        Invitees schedule appointments with you.
      </Text>
      <Text mb={5}>
        When using the features of the Website, you are subject to accept any
        agreements, policies or guidelines applicable to such features that may
        be posted from time to time. All such agreements, policies or
        guidelines, including the Privacy Policy are hereby incorporated by
        reference into these Terms.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        GENERAL USE
      </Heading>
      <Text mb={5}>
        We invite you to use this Website for your individual purposes and not
        for the purposes of reselling ("Permitted Purposes") – enjoy!
      </Text>
      <Text mb={5}>
        Subject to these Terms, we grant you a limited, personal, non-exclusive
        and non-transferable license to use and to display the Materials and a
        limited, personal, non-exclusive and non-transferable right to access
        and use the Website following the set-up of a Free Account or Paid
        Account as set forth below. You have no other rights in the Website and
        you may not modify, edit, copy, reproduce, create derivative works of,
        reverse engineer, alter, enhance, or in any way exploit any portion of
        the Website in any manner. If you make copies of any portion of this
        Website while engaging in Permitted Purposes then we ask that you be
        sure to keep on these copies all of our copyright and other proprietary
        notices as they appear on this Website.
      </Text>
      <Text mb={5}>
        Subject to these Terms, we also grant you a limited, personal,
        non-exclusive, non-transferable license to download, install and operate
        any plug-ins, software, information, or other content that we may have
        specifically identified within the Website as available for download
        solely in connection with accessing the Website, including, but not
        limited to, relevant Village browser extensions (“Downloadable Tools”).
        Such Downloadable Tools are also subject to the additional terms
        provided to you upon download, including without limitation our End User
        License Agreement.
      </Text>
      <Text mb={5}>
        Our Website is not intended for and may not be used by individuals under
        13 years of age. By registering for a Village account, you state that
        you are at least 13 years of age, and you are responsible for ensuring
        that all Village Users and Invitees are at least 13 years old. Creating
        an account with false information is a violation of our Terms, including
        creating an account on behalf of others or persons under the age of 13.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        ACCOUNT SETUP
      </Heading>
      <Text mb={5}>
        To use the Website, you need to set up a Village account. You may
        establish either a free account (“Free Account”) or a paid account
        (“Paid Account”). You are authorized by Village to set up one Free
        Account only. Village reserves the right to terminate any additional
        Free Accounts set up by you and any Free Accounts that have been
        inactive for over 6 months.
      </Text>
      <Text mb={5}>
        When you create an account, we collect registration-related information,
        which includes your email address and your name. Once you submit the
        required registration information, we alone will determine whether or
        not to approve your proposed account. If approved, you will be sent an
        email detailing how to complete your registration. For so long as you
        use the account, you agree to provide true, accurate, current, and
        complete information that can be accomplished by logging into your
        account and making relevant changes directly. You are responsible for
        complying with these Terms when you access this Website, whether
        directly or through any account that you may set up through or on this
        Website. Because it is your account, it is your job to obtain and
        maintain all equipment and services needed for access to and use of this
        Website as well as paying related charges. You agree to choose a strong
        and secure password(s) and maintain the confidentiality of your
        password(s), including any password of a third-party site that we may
        allow you to use to access this Website. Should you believe your
        password or security for this Website has been breached in any way, you
        must immediately notify us at support@village.do
      </Text>
      <Text mb={5}>
        Village or our billing processors collect your billing information when
        you upgrade to a Paid Account. All information is protected in
        accordance with our Privacy Policy (<Link to="/privacy">link</Link>).
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        SUBSCRIPTION PERIOD AND SUBSCRIPTION FEES
      </Heading>
      <Text mb={5}>
        You agree to pay all applicable fees related to your use of this Website
        which are described fully on our pricing page. By providing a payment
        method, you expressly authorize us to charge the applicable fees on said
        payment method as well as taxes and other charges incurred thereto at
        regular intervals, all of which depend on your particular subscription
        and utilized services.
      </Text>
      <Text mb={5}>
        The subscription period for Paid Accounts can be month-to-month, yearly,
        or another duration described more fully on our pricing page.
      </Text>
      <Text mb={5}>
        Paid Accounts are subject to fees based upon the account type you've
        selected. For renewals, Village will automatically charge your payment
        method in the amount of the then-current fee for your type of account,
        plus applicable taxes. All payments are non-refundable. To the extent
        that payment mechanisms are provided through third parties, you agree
        that Village will have no liability to you arising out of the acts or
        omissions of such third parties. If applicable, you agree to maintain
        current billing information.
      </Text>
      <Text mb={5}>
        BY CREATING A PAID ACCOUNT, YOU CONSENT TO ALLOW Village TO CHARGE YOUR
        PAYMENT METHOD, EITHER DIRECTLY OR THROUGH ITS PAYMENT PROCESSORS, FOR
        THE AMOUNTS DUE FOR YOUR INITIAL SUBSCRIPTION PERIOD AND FOR ADDITIONAL
        SUBSCRIPTION PERIODS UNTIL YOUR ACCOUNT IS CANCELED OR TERMINATED.
        PLEASE PRINT OUT THESE TERMS AND CONDITIONS FOR YOUR RECORDS.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        RENEWAL/CANCELLATION
      </Heading>
      <Text mb={5}>
        Paid Accounts will automatically renew for the same subscription period
        unless you cancel the account by the end of the then-current
        subscription period. You can cancel your account at any time by
        contacting customer service at support@village.do. Cancellation will be
        effective immediately. Please note that after you cancel your account,
        you may not be able to use or access the Platform and you will not be
        entitled to a refund of any fees that you've paid.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        LEGAL COMPLIANCE
      </Heading>
      <Text mb={5}>
        You agree to comply with all applicable domestic and international laws,
        statutes, ordinances, and regulations regarding your use of our Website.
        Village reserves the right to investigate complaints or reported
        violations of our Terms and to take any action we deem appropriate,
        including but not limited to canceling your User account, reporting any
        suspected unlawful activity to law enforcement officials, regulators, or
        other third parties and disclosing any information necessary or
        appropriate to such persons or entities relating to your profile, e-mail
        addresses, usage history, posted materials, IP addresses, and traffic
        information, as allowed under our Privacy Policy. You agree to promptly
        notify Village in the event that you are made aware of any unauthorized
        or illegal use of the Website..
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        INTELLECTUAL PROPERTY
      </Heading>
      <Text mb={5}>
        Our Website may contain our service marks or trademarks as well as those
        of our affiliates or other companies, in the form of words, graphics,
        and logos. Your use of our Website does not constitute any right or
        license for you to use such service marks/trademarks. Our Website is
        also protected under international copyright laws. The copying,
        redistribution, use, or publication by you of any portion of our Website
        is strictly prohibited. Your use of our Website does not grant you
        ownership rights of any kind in our Website.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        NOTICES AND MESSAGE
      </Heading>
      <Text mb={5}>
        By using the Website, you consent to receive emails from us. These
        emails may include notices about applicable fees and charges,
        transactional information and other information concerning or related to
        this Website. These emails are part of your relationship with us. You
        agree that any notices, agreements, disclosures, or other communications
        that we send you electronically will satisfy any legal communication
        requirements, including that such communications be in writing. You
        agree to keep your contact information up to date, and to monitor your
        email settings to ensure that you receive emails from us at your
        discretion and as necessary.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        LINKS TO OTHER WEBSITES
      </Heading>
      <Text mb={5}>
        Our Website may contain links to third party websites. These links are
        provided solely as a convenience to you. By linking to these websites,
        we do not create or have an affiliation with or sponsor such third party
        websites. The inclusion of links within our Website does not constitute
        any endorsement, guarantee, warranty, or recommendation of such third
        party websites. Village has no control over the legal documents and
        privacy practices of third party websites, and by using them, you may be
        giving those third parties permission to use or control your information
        in ways Village would not. As such, you access any third-party websites
        at your own risk.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        FEEDBACK
      </Heading>
      <Text mb={5}>
        Certain areas of this Website are public-facing and allow you to send
        feedback or ideas. We love hearing from our Users. It's incredibly
        helpful and helps us to make a better Village for all. If you choose to
        provide feedback, you agree that we can use them without any
        restrictions or compensation to you.
      </Text>
      <Text mb={5}>
        Any information that you share might be read, collected, and used. To
        request removal of your information, contact support@village.do. In some
        cases, we may not be able to remove your information, in which case we
        will let you know that, and why.
      </Text>
      <Text mb={5}>
        You agree that you are solely responsible for all of your feedback or
        ideas and that any such information is considered both non-confidential
        and non-proprietary..
      </Text>
      <Text mb={5}>
        You grant to us an irrevocable royalty-free license to copy, modify,
        publish and display your feedback or ideas, and to make, use (and permit
        others to use) your feedback for any purpose (including, without
        limitation, to use your feedback in our Website).
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        UNAUTHORIZED ACTIVITIES
      </Heading>
      <Text mb={5}>
        To be clear, we authorize your use of this Website only for Permitted
        Purposes. Any other use of this Website beyond the Permitted Purposes is
        prohibited and, therefore, constitutes unauthorized use of this Website.
        This is because all rights to this Website remain our property.
      </Text>
      <Text mb={5}>
        Unauthorized use of this Website may result in the violation of various
        United States and international copyright laws, the deletion of your
        Village account, or a block from the Website. Unless you have written
        permission from us stating otherwise, you are not authorized to use this
        Website in any of the following ways. (Note that these are examples
        only, and the list below is not a complete list of everything that you
        are not permitted to do):{' '}
      </Text>

      <Text mb={5}>You are not allowed to use the Website:</Text>
      <List mb={5}>
        <ListItem>
          In a manner that modifies, publicly displays, publicly performs,
          reproduces, or distributes any of this Website.
        </ListItem>
        <ListItem>
          In a manner that violates any relevant law or the Terms of this
          agreement.
        </ListItem>
        <ListItem>
          To stalk, harass, spam, or harm another individual, organization, or
          business.
        </ListItem>
        <ListItem>
          To disclose information that you do not have permission to disclose.
        </ListItem>
        <ListItem>
          To impersonate any person or entity, misstate your Personal Data, or
          otherwise misrepresent your affiliation with a person or entity.
        </ListItem>
        <ListItem>
          To interfere with or disrupt this Website or servers or networks
          connected to this Website.
        </ListItem>
        <ListItem>
          To use any data mining, robots, or similar data gathering or
          extraction methods in connection with this Website.
        </ListItem>
        <ListItem>
          To disseminate any viruses, worms, or otherwise harmful code.
        </ListItem>
        <ListItem>
          To attempt to gain unauthorized access to any portion of this Website
          or any other accounts, computer systems, or networks connected to this
          Website, whether through hacking, password mining, or any other means.
        </ListItem>
        <ListItem>
          To submit or store Payment Card Industry data (such as credit card
          numbers) without using an authorized Village payment integration.
        </ListItem>
        <ListItem>
          To disclose any types of information listed in California Customer
          Records statute (Cal. Civ. Code § 1798.80(e)) or other relevant
          privacy regulations, including medical or health insurance
          information, education information, employment information, credit or
          debit card information, or any other financial information, driver’s
          license, or other government identification number.
        </ListItem>
        <ListItem>
          To disclose or collect any types of information considered to be
          protected classification characteristics under California or other
          relevant law, including Age (if 40 years or older), race, color,
          ancestry, national origin, citizenship, religion or creed, marital
          status, medical condition, physical or mental disability, sex
          (including gender, gender identity, gender expression, pregnancy or
          childbirth, and related medical conditions), sexual orientation,
          veteran or military status, and genetic information (including
          familial genetic information).
        </ListItem>
        <ListItem>
          To disclose or collect any information defined by relevant laws as
          biometric information, or any information that falls within the
          definition of “special categories of data” under EU Data Protection
          Laws, “protected health information” under California or other
          relevant law or regulations. “Special categories of data” include, for
          example, information about an individual’s race, ethnic origin,
          politics, religion, trade union membership, genetics, biometrics,
          health, sex life, sexual orientation, or criminal records.
        </ListItem>
      </List>
      <Heading as="h2" fontSize="lg" mb={1}>
        INDEMNIFICATION
      </Heading>
      <Text mb={5}>
        You alone are responsible for any violation of these Terms by you. You
        agree to indemnify and hold Village, its parents, subsidiaries,
        affiliates, officers, agents, employees, resellers or other partners,
        and licensors harmless from any claim, demand, loss, or damages,
        including reasonable attorneys’ fees, arising out of or related to
        Personal Data, or the use thereof, including but not limited to
        allegations that any processing of Personal Data by Village and/or you
        under this Agreement violates any applicable law or regulation, or
        infringes the privacy or intellectual property rights of a third party,
        any content that you disclose to Village via your use of the Village
        Website, your use of the Website, your violation of these Terms, or your
        violation of any relevant law.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        INTELLECTUAL PROPERTY INFRINGEMENT
      </Heading>
      <Text mb={5}>
        We respect the intellectual property rights of others and encourage you
        to do the same. Accordingly, we have a policy of removing information
        that violate intellectual property rights of others, suspending access
        to this Website (or any portion thereof) to any Village User who uses
        this Website in violation of someone’s intellectual property rights,
        and/or terminating in appropriate circumstances the account of any
        Village User who uses the Website in violation of someone’s intellectual
        property rights.
      </Text>
      <Text mb={5}>
        Pursuant to Title 17 of the United States Code, Section 512, we have
        implemented procedures for receiving written notification of claimed
        copyright infringement and for processing such claims in accordance with
        such law. If you believe your copyright or other intellectual property
        right is being infringed by a Village User, please provide written
        notice to us for notice of claims of infringement at support@village.do.
      </Text>
      <Text mb={5}>
        To be sure the matter is handled immediately, your written notice must:
      </Text>
      <List mb={5}>
        <ListItem>Contain your physical or electronic signature.</ListItem>
        <ListItem>
          Identify the copyrighted work or other intellectual property alleged
          to have been infringed.
        </ListItem>
        <ListItem>
          Identify the allegedly infringing material in a sufficiently precise
          manner to allow us to locate that material.
        </ListItem>
        <ListItem>
          Contain adequate information by which we can contact you (including
          postal address, telephone number, and e-mail address).
        </ListItem>
        <ListItem>
          Contain a statement that you have a good faith belief that use of the
          copyrighted material or other intellectual property is not authorized
          by the owner, the owner's agent or the law.
        </ListItem>
        <ListItem>
          Contain a statement that the information in the written notice is
          accurate.
        </ListItem>
        <ListItem>
          Contain a statement, under penalty of perjury, that you are authorized
          to act on behalf of the copyright or other intellectual property right
          owner.
        </ListItem>
        <ListItem>
          Unless the notice pertains to copyright or other intellectual property
          infringement, the Agent will be unable to address the listed concern.
        </ListItem>
      </List>
      <Text mb={5}>
        We will notify you that we have removed or disabled access to
        copyright-protected material that you provided, if such removal is
        pursuant to a validly received Digital Millennium Copyright Act DMCA
        take-down notice. In response, you may provide our Agent with a written
        counter-notification that includes the following information:
      </Text>

      <List mb={5}>
        <ListItem>Your physical or electronic signature.</ListItem>
        <ListItem>
          Identification of the material that has been removed or to which
          access has been disabled, and the location at which the material
          appeared before it was removed or access to it was disabled.
        </ListItem>
        <ListItem>
          A statement from you under the penalty of perjury, that you have a
          good faith belief that the material was removed or disabled as a
          result of a mistake or misidentification of the material to be removed
          or disabled.
        </ListItem>
        <ListItem>
          Your name, physical address, and telephone number, and a statement
          that you consent to the jurisdiction of a court for the judicial
          district in which your physical address is located, or if your
          physical address is outside of the United States, for any judicial
          district in which we may be located, and that you will accept service
          of process from the person who provided notification of allegedly
          infringing material or an agent of such person.
        </ListItem>
        <ListItem>
          We reserve the right, in our sole discretion, to terminate the account
          or access of any Village User or Invitee who is the subject of
          repeated DMCA or other infringement notifications.
        </ListItem>
      </List>

      <Heading as="h2" fontSize="lg" mb={1}>
        WARRANTY DISCLAIMER
      </Heading>
      <Text mb={5}>
        THE WEBSITE AND ALL CONTENT ARE PROVIDED "AS IS" AND "WITH ALL FAULTS"
        AND THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE IS WITH YOU. WE
        EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED OR
        STATUTORY) WHICH INCLUDES BUT IS NOT LIMITED TO, ANY IMPLIED OR
        STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR
        PURPOSE, ACCURACY OF DATA, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL
        PROPERTY RIGHTS.
      </Text>

      <Text mb={5}>
        THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE WEBSITE IS FREE OF
        PROBLEMS. Without limiting the generality of the foregoing, we make no
        warranty that the Website will meet your requirements or will be
        uninterrupted, timely, secure, or error-free, or that defects will be
        corrected. We make no warranty as to the results that may be obtained or
        as to the accuracy or reliability of any information obtained through
        the Website. No advice or information, whether oral or written, obtained
        by you through the Website or from us or our subsidiaries/other
        affiliated companies will create any warranty. We disclaim all equitable
        indemnities.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        LIMITATION OF LIABILITY
      </Heading>
      <Text mb={5}>
        Village WILL NOT BE LIABLE TO YOU OR OTHERS FOR ANY DAMAGES RESULTING
        FROM YOUR USE OF THE WEBSITE OR YOUR DISPLAYING, COPYING, OR DOWNLOADING
        ANY MATERIALS TO OR FROM THE WEBSITE. IN NO EVENT WILL Village BE LIABLE
        TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL,
        INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE,
        PROFITS, OPPORTUNITIES, REPUTATION, USE, OR OTHER ECONOMIC ADVANTAGE)
        HOWEVER ARISING, EVEN IF WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.
      </Text>
      <Text mb={5}>
        Village’s MAXIMUM AGGREGATE LIABILITY TO YOU IN CONNECTION WITH THESE
        TERMS OR THE WEBSITE, REGARDLESS OF THE FORM OF ACTION (WHETHER SUCH
        LIABILITY ARISES DUE TO NEGLIGENCE, BREACH OF CONTRACT,
        MISREPRESENTATION, OR FOR ANY OTHER REASON), WILL AT ALL TIMES BE
        LIMITED TO THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO Village
        IN CONNECTION WITH THESE TERMS IN THE TWELVE (12) MONTHS PRIOR TO THE
        ACTION GIVING RISE TO LIABILITY OR (II) US $100.00.
      </Text>
      <Text mb={5}>
        THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE AGREEMENT
        BETWEEN YOU AND Village AND SHALL APPLY TO ALL CLAIMS OF LIABILITY, EVEN
        IF Village HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN
        IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
      </Text>
      <Heading as="h2" fontSize="lg" mb={1}>
        LOCAL LAWS; EXPORT CONTROL
      </Heading>
      <Text mb={5}>
        We control and operate the Website from our headquarters in the United
        States of America and the entirety of the Website may not be appropriate
        or available for use in other locations. If you use the Website outside
        the United States of America, you are solely responsible for following
        applicable local laws.
      </Text>
      <Text mb={5}>
        Village and you shall comply with all relevant United States export
        control laws and regulations. Without limiting the foregoing: (a) you
        acknowledge that the Website may be subject to the United States Export
        Administration Regulations; (b) you affirm that you are not, and you
        will not (i) permit Village Users or Invitees to access or use the
        Website in a United States embargoed country; (ii) or permit Village
        Users or Invitees on the U.S. Commerce Department’s Denied Persons,
        Entity, or Unverified Lists or (iii) permit Village Users or Invitees on
        the U.S. Treasury Department’s list of Specially Designated Nationals
        and Consolidated Sanctions list; and (c) you are responsible for
        complying with any local laws and regulations which may impact your
        right to import, export, access, or use the Website.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER:
      </Heading>

      <Text mb={5}>
        Please Read This Provision Carefully. It Affects Your Legal Rights.{' '}
      </Text>

      <Text mb={5}>
        This provision facilitates the prompt and efficient resolution of any
        dispute that may arise between you and us. Effectively, then, “dispute”
        is given the broadest meaning enforceable by law and includes any claims
        against other parties relating to services or products provided or
        billed to you (such as our licensors, suppliers, dealers or third-party
        vendors) whenever you also assert claims against us in the same
        proceeding.
      </Text>
      <Text mb={5}>
        This provision provides that all disputes between you and us will be
        resolved by binding arbitration because acceptance of these Terms
        constitutes a waiver of your right to litigate claims and all
        opportunity to be heard by a judge or jury. We prefer this because we
        believe arbitration is less drama-filled than litigation. To be clear,
        there is no judge or jury in arbitration, and court review of an
        arbitration award is limited. The arbitrator must follow this agreement
        and can award the same damages and relief as a court (including
        attorney’s fees). YOU AND Village AGREE THAT ANY AND ALL DISPUTES, AS
        DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED ON ACTS OR
        OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY AND
        FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH
        THIS PROVISION.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        PRE-ARBITRATION CLAIM RESOLUTION:
      </Heading>

      <Text mb={5}>
        For all disputes, you must first give us an opportunity to resolve the
        dispute which is first done by emailing us at support@village.do the
        following information: (1) your name, (2) your address, (3) a written
        description of your claim, and (4) a description of the specific relief
        you seek. If we do not resolve the dispute within 45 days after
        receiving your notification, then you may pursue your dispute in
        arbitration.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        ARBITRATION PROCEDURES
      </Heading>

      <Text mb={5}>
        If this provision applies and the dispute is not resolved as provided
        above (Pre-Arbitration Claim Resolution) either you or we may initiate
        arbitration proceedings. The arbitration will be commenced as an
        individual arbitration, and will in no event be commenced as a class
        arbitration. This provision governs in the event it conflicts with the
        applicable arbitration rules. Under no circumstances will class action
        procedures or rules apply to the arbitration. Because this Website and
        these Terms concern interstate commerce, the Federal Arbitration Act
        (“FAA”) governs the arbitrability of all disputes. However, the
        arbitrator will apply applicable substantive law consistent with the FAA
        and the applicable statute of limitations or condition precedent to
        suit. You or we may initiate arbitration in either Georgia or the
        federal judicial district where you are located.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        CLASS ACTION WAIVER
      </Heading>

      <Text mb={5}>
        Except as otherwise provided in this provision, the arbitrator may not
        consolidate more than one person’s claims, and may not otherwise preside
        over any form of a class or representative proceeding or claims (such as
        a class action, consolidated action or private attorney general action)
        unless both you and Village specifically agree to do so following
        initiation of the arbitration.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        OTHERS
      </Heading>

      <Text mb={5}>
        You understand and agree that by accepting this provision in these
        Terms, you and we are each waiving the right to a jury trial or a trial
        before a judge in a public court. If any clause within this provision
        (other than the Class Action Waiver clause above) is found to be illegal
        or unenforceable, that clause will be severed from this provision whose
        remainder will be given full force and effect. If the Class Action
        Waiver clause is found to be illegal or unenforceable, this entire
        provision will be unenforceable and the dispute will be decided by a
        court. This provision will survive the termination of your account with
        us or our affiliates and your discontinued use of this Website.
        Notwithstanding any provision in this Agreement to the contrary, we
        agree that if we make any change to this provision (other than a change
        to the Notice Address), you may reject any such change and require us to
        adhere to the language in this provision if a dispute between us arises.{' '}
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        GENERAL TERMS
      </Heading>

      <Text mb={5}>
        We think direct communication resolves most issues – if we feel that you
        are not complying with these Terms, we will likely tell you. We may even
        provide you with recommended necessary corrective action(s) if possible.
        However, certain violations of these Terms, as determined by us, may
        require immediate termination of your access to this Website without
        prior notice to you.
      </Text>
      <Text mb={5}>
        The Federal Arbitration Act, California state law and applicable U.S.
        federal law, without regard to the choice or conflicts of law
        provisions, will govern these Terms to the fullest extent permitted by
        applicable law. Except for disputes subject to arbitration as described
        above, any disputes relating to these Terms or this Website will be
        heard in the courts located in Fulton County, Georgia. Except as
        otherwise set forth in the dispute resolution provision, if any of these
        Terms are deemed inconsistent with applicable law, then such term(s)
        will be interpreted to reflect the intentions of the parties, and no
        other terms will be modified. By choosing not to enforce any of these
        Terms, we are not waiving our rights. These Terms, together with our
        Privacy Notice contain the entire agreement between you and us and,
        therefore, supersede all prior or contemporaneous negotiations,
        discussions or agreements between Village and you about the Website and
        our services. Any definitions found in the Privacy Notice are hereby
        adopted by reference in this Agreement. The rights of Village under our
        Terms will survive the termination of our Terms. No additional terms,
        conditions, consent, waiver, alteration, or modification shall be
        binding unless in writing and signed by both parties. Acceptance of any
        order by Village is expressly limited to the terms and conditions of
        this Agreement. Any proposed additional or inconsistent terms or
        conditions, including those in or accompanying any Customer proposal,
        any Customer purchase order, or other agreement or any other Customer
        document issued in connection with the sale or delivery of Products or
        Services is deemed by Village to be a material change and is objected to
        and rejected by Village. Village's acceptance of any orders will not
        constitute acceptance of any terms and conditions contained therein.
      </Text>
      <Text mb={5}>
        Neither You or Village will be liable to the other for any delay or
        failure to perform any obligation under these Terms (except for a
        failure to pay fees) if the delay or failure is due to events which are
        beyond reasonable control, such as a strike, blockade, war, act of
        terrorism, riot, natural disaster, for failure, or diminishment of power
        or telecommunications or data networks or services.
      </Text>
      <Text mb={5}>
        Unless you have specifically notified Village to the contrary in writing
        (including via email to support@village.do), Village may disclose your
        company as a customer of Village (if you use Village for business
        purposes and/or use a business email address as your Village User email
        address) and may use your company’s name and logo on the Website and in
        Village’s promotional content. Village will request your prior written
        consent for any other uses.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        CHANGES
      </Heading>

      <Text mb={5}>
        We may amend our Terms at any time by publishing revised Terms on our
        Website and/or by sending an email to the address you gave us. These
        Terms are current as of the Effective Date set forth above. If any
        changes are made to these Terms, we’ll post them on this page, so please
        be sure to check back periodically. If you continue to use Village after
        those changes are in effect, you agree to the revised Terms. Therefore,
        it is important for you to periodically review our Terms to make sure
        you still agree to them. If you object to any such modifications, your
        sole recourse will be to cease using this Website. Village reserves the
        right to change any and all features of our Website, at any time,
        without notice.
      </Text>

      <Heading as="h2" fontSize="lg" mb={1}>
        CONTACT US
      </Heading>

      <Text mb={5}>
        If you have any questions about these Terms or otherwise need to contact
        us for any reason, you can reach us at{' '}
        <strong>support@village.do</strong>.
      </Text>
    </Container>
  )
}
